import {createRef, useEffect, useMemo, useState} from 'react';
import functionUtils from '../../utils/functions';
import useIsMounted from './useIsMounted';

function useSearchableScroll<TItem, THTMLElement extends HTMLElement>({
  items,
  searchPredicate,
}: {
  items: TItem[];
  searchPredicate: (item: TItem, searchText: string) => boolean;
}) {
  const isMounted = useIsMounted();

  const itemRefs = items.reduce((refs, _, itemIndex) => {
    refs[itemIndex] = createRef();
    return refs;
  }, [] as React.RefObject<THTMLElement>[]);

  const [searchText, setSearchText] = useState('');

  useEffect(() => {
    if (searchText !== '') {
      const scrollToItemIndex = items.findIndex((item) =>
        searchPredicate(item, searchText),
      );

      if (scrollToItemIndex >= 0) {
        itemRefs[scrollToItemIndex].current?.scrollIntoView({
          block: 'start',
          behavior: 'auto',
        });
      }

      clearSearchText();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [items, searchText]);

  const clearSearchText = useMemo(
    () =>
      functionUtils.debounce(() => {
        if (isMounted()) {
          setSearchText('');
        }
      }, 2000),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  function handleKeyDown(event: React.KeyboardEvent<HTMLDivElement>) {
    if (/^[a-zA-Z]$/.test(event.key)) {
      setSearchText((previousValue) => previousValue + event.key);
    }
  }

  return {refs: itemRefs, handleKeyDown};
}

export default useSearchableScroll;
