const paths = {
  home: '/',
  login: '/login',
  passwordRecovery: '/password-recovery',
  passwordReset: '/password-reset',
  signup: '/signup',
  splash: '/splash',
  welcome: '/welcome',
  dashboard: '/dashboard',
  profile: '/profile',
  profileInformation: '/profile/information',
  profileNotifications: '/profile/notifications',
  profileSettings: '/profile/settings',
  profileChangePassword: '/profile/change-password',
  profileMigrateAccount: '/profile/migrate-account',
  bakeries: '/bakeries',
  bakeriesCreate: '/bakeries/create',
  bakeriesInvite: '/bakeries/invite',
  recipes: '/recipes',
  createRecipe: '/recipes/create-recipe',
  editRecipe: '/recipes/edit-recipe',
  statistics: '/statistics',
  programming: '/programming',
  createRecipeSchedule: '/programming/recipe',
  createCleaningSchedule: '/programming/cleaning',
  createOffSchedule: '/programming/off',
};

export default paths;
