import {Box, Typography} from '@mui/material';
import {Link, useNavigate} from 'react-router-dom';
import useLocalization from '../../hooks/common/useLocalization';
import locals from '../../localization/locals';
import paths from '../../routes/paths';
import {Button} from '../common/Button';

function Welcome() {
  const navigate = useNavigate();
  useLocalization();

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        width: '30vw',
      }}>
      <Typography
        variant="h4"
        color="secondary"
        sx={{marginBottom: '5vh', fontWeight: '500'}}>
        {locals.getText('welcome_title')}
      </Typography>
      <Typography
        variant="body2"
        color="secondary"
        sx={{width: '12vw', marginBottom: '25vh'}}>
        {locals.getText('welcome_caption')}
      </Typography>
      <Button sx={{marginBottom: '2vh'}} onClick={() => navigate(paths.login)}>
        {locals.getText('welcome_login_button_label')}
      </Button>
      <Button sx={{marginBottom: '2vh'}} onClick={() => navigate(paths.signup)}>
        {locals.getText('welcome_signup_button_label')}
      </Button>
      <Typography
        variant="caption"
        color="secondary"
        sx={{width: '100%', textAlign: 'right'}}>
        <Link to={paths.home}>
          {locals.getText('welcome_terms_and_conditions_label')}
        </Link>
      </Typography>
    </Box>
  );
}

export default Welcome;
