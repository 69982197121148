import {OvenModelId} from '../models/entities/ovenModel';

const ovenModelUtils = {
  getDescription: function (ovenModelId: OvenModelId) {
    switch (ovenModelId) {
      case OvenModelId.Compactram:
        return 'Compactram';
      case OvenModelId.Electram:
        return 'Electram';
      case OvenModelId.Modulram:
        return 'Modulram';
      case OvenModelId.Rotoram:
        return 'Rotoram';
      case OvenModelId.Turboram:
        return 'Turboram';
    }
  },
};

export default ovenModelUtils;
