import {Box, Paper, Typography} from '@mui/material';
import {Fragment, useEffect, useState} from 'react';
import {useMutation, useQuery, useQueryClient} from 'react-query';
import {useNavigate, useSearchParams} from 'react-router-dom';
import {v4 as uuid} from 'uuid';
import {ReactComponent as DeclineIcon} from '../../assets/icons/decline.svg';
import useQueryOnce from '../../hooks/common/useQueryOnce';
import locals from '../../localization/locals';
import Cleaning from '../../models/entities/cleaning';
import OvenModel from '../../models/entities/ovenModel';
import Schedule, {ScheduleType} from '../../models/entities/schedule';
import OverlapsScheduleParams from '../../models/requests/overlapsSchedule';
import paths from '../../routes/paths';
import services from '../../services/provider';
import useBreadcrumbsStore, {Breadcrumb} from '../../state/breadcrumbs';
import cleaningUtils from '../../utils/cleaning';
import dateUtils, {Day} from '../../utils/dates';
import numberUtils from '../../utils/numbers';
import routerUtils from '../../utils/router';
import scheduleUtils, {TimeSlot} from '../../utils/schedules';
import IconButton from '../common/IconButton';
import LoadingBackdrop from '../common/LoadingBackdrop';
import Span from '../common/Span';
import CleaningList from './CleaningList';
import CreateCleaningScheduleMenu from './CreateCleaningScheduleMenu';
import OvenList from './OvenList';
import SelectTime from './SelectTime';
import stringUtils from '../../utils/strings';

function getStepTitle(step: CreateCleaningScheduleStep) {
  switch (step) {
    case 'ovens':
      return locals.getText('programming_ovens_step_title');
    case 'cleaning':
      return locals.getText('programming_cleaning_step_title');
    case 'time':
      return locals.getText('programming_time_step_title');
  }
}

function getCleaningDuration(cleanings: Cleaning[], cleaningId: number | null) {
  const cleaning = cleanings.find((cleaning) => cleaning.id === cleaningId);
  return cleaning?.duration ?? 0;
}

function getEndTime(
  startTime: string,
  cleanings: Cleaning[],
  selectedCleaningId: number | null,
) {
  const duration = getCleaningDuration(cleanings, selectedCleaningId);
  const endTime = scheduleUtils.increaseTime(startTime, duration);
  return endTime;
}

function hasMultipleOvenPanels(ovenModel?: OvenModel) {
  let ovenPanelCount = 0;
  for (const ovenGroup of ovenModel?.ovenGroups ?? []) {
    if (ovenPanelCount > 1) return true;
    for (const oven of ovenGroup.ovens ?? []) {
      if (ovenPanelCount > 1) return true;
      ovenPanelCount += oven.ovenChambers?.length ?? 0;
      ovenPanelCount += oven.ovenPanels?.length ?? 0;
    }
  }
  for (const oven of ovenModel?.ovens ?? []) {
    if (ovenPanelCount > 1) return true;
    ovenPanelCount += oven.ovenChambers?.length ?? 0;
    ovenPanelCount += oven.ovenPanels?.length ?? 0;
  }
  return ovenPanelCount > 1;
}

export type CreateCleaningScheduleSteps = {
  ovens: {visible: boolean; visited: boolean; error: boolean};
  cleaning: {visible: boolean; visited: boolean; error: boolean};
  time: {visible: boolean; visited: boolean; error: boolean};
};

export type CreateCleaningScheduleStep = keyof CreateCleaningScheduleSteps;

export type OvenPanelData = {
  id: string;
  ovenChamberId?: string;
  ovenId?: string;
  ovenGroupId?: string;
};

function CreateCleaningSchedule() {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const queryClient = useQueryClient();

  const setBreadcrumbs = useBreadcrumbsStore((state) => state.setBreadcrumbs);

  const bakeryId = searchParams.get('bakeryId');
  const ovenModelId = numberUtils.parseInt(searchParams.get('ovenModelId'), 1);
  const day = dateUtils.parseDay(searchParams.get('day'), Day.Monday);
  const scheduleId = searchParams.get('scheduleId');

  const [steps, setSteps] = useState<CreateCleaningScheduleSteps>({
    ovens: {visible: true, visited: true, error: true},
    cleaning: {visible: false, visited: false, error: true},
    time: {visible: false, visited: false, error: true},
  });
  const [selectedStep, setSelectedStep] =
    useState<CreateCleaningScheduleStep>('ovens');

  const [defaultValues, setDefaultValues] = useState<Partial<Schedule>>({});
  const [availableTimeSlots, setAvailableTimeSlots] = useState<TimeSlot[]>([]);

  const [selectedOvenPanels, setSelectedOvenPanels] = useState<OvenPanelData[]>(
    [],
  );
  const selectedOvenPanelIds = selectedOvenPanels.map((panel) => panel.id);
  const selectedOvenPanelIdsSet = new Set(selectedOvenPanelIds);
  const [selectedCleaningId, setSelectedCleaningId] = useState<number | null>(
    null,
  );
  const [selectedDays, setSelectedDays] = useState<Day[]>([day]);
  const selectedDaysSet = new Set(selectedDays);
  const [startTime, setStartTime] = useState('');
  const [endTime, setEndTime] = useState('');
  const [shadowStartTime, setShadowStartTime] = useState('');
  const [shadowEndTime, setShadowEndTime] = useState('');
  const [suggestedStartTime, setSuggestedStartTime] = useState('');
  const [suggestedEndTime, setSuggestedEndTime] = useState('');
  const [isOverlapping, setIsOverlapping] = useState(false);

  const [turnOffAfterSchedule, setTurnOffAfterSchedule] = useState(false);

  const {isLoading: loadingSchedule} = useQueryOnce({
    enabled: scheduleId != null,
    queryFn: () => services.schedule.getSchedule({scheduleId: scheduleId!}),
    onSuccess: (schedule) => {
      if (schedule.type !== ScheduleType.Cleaning) return;
      setDefaultValues({
        id: schedule.id,
        ovenPanelId: schedule.ovenPanelId,
        startTime: schedule.startTime,
        endTime: schedule.endTime,
      });
      setSelectedOvenPanels([
        {
          id: schedule.ovenPanelId,
          ovenChamberId: schedule.ovenChamberId,
          ovenId: schedule.ovenId,
          ovenGroupId: schedule.ovenGroupId,
        },
      ]);
      setSelectedCleaningId(schedule.cleaningId ?? null);
      setSelectedDays([schedule.day]);
      setStartTime(schedule.startTime);
      setEndTime(schedule.endTime);
      setSuggestedStartTime(schedule.startTime);
      setSuggestedEndTime(schedule.endTime);
      setSteps({
        ovens: {visible: true, visited: true, error: false},
        cleaning: {visible: true, visited: true, error: false},
        time: {visible: true, visited: true, error: false},
      });
    },
  });

  const {data: bakery, isLoading: loadingBakery} = useQuery({
    enabled: bakeryId != null,
    queryKey: ['bakery', bakeryId],
    queryFn: () => services.bakery.getBakery({bakeryId: bakeryId!}),
  });

  const {data: ovenModel, isLoading: loadingOvens} = useQuery({
    enabled: bakeryId != null,
    queryKey: ['bakeryOvens', bakeryId, {ovenModelId}],
    queryFn: () =>
      services.bakery.getBakeryOvens({bakeryId: bakeryId!, ovenModelId}),
    select: (ovenModels) => ovenModels[0],
  });

  const {data: cleanings = [], isLoading: loadingCleanings} = useQuery({
    queryKey: ['cleanings', {ovenModelId}],
    queryFn: () => services.cleaning.getCleanings({ovenModelId}),
  });

  const {mutate: suggestStartTime, isLoading: loadingTimeSlot} = useMutation({
    mutationFn: services.schedule.getNearestAvailableTimeSlot,
    onSuccess: (timeSlot) => {
      setStartTime(timeSlot.startTime);
      setEndTime(timeSlot.endTime);
      setSuggestedStartTime(timeSlot.startTime);
      setSuggestedEndTime(timeSlot.endTime);
      setSteps((steps) => ({
        ...steps,
        time: {...steps.time, error: false},
      }));
    },
  });

  const {mutate: onValidateTimeSlot} = useMutation({
    mutationFn: services.schedule.overlapsSchedule,
    onSuccess: ({overlaps, availableTimeSlots}) => {
      setIsOverlapping(overlaps);
      setAvailableTimeSlots(availableTimeSlots ?? []);
      setSteps((steps) => ({
        ...steps,
        time: {...steps.time, error: overlaps},
      }));
    },
  });

  function validateTimeSlot(params: OverlapsScheduleParams) {
    const timeRegex = /^([0-1]?[0-9]|2[0-3]):[0-5][0-9]$/;
    if (
      timeRegex.test(params.startTime) &&
      timeRegex.test(params.endTime) &&
      params.startTime < params.endTime
    ) {
      onValidateTimeSlot(params);
      setEndTime(params.endTime);
    }
  }

  const {
    mutate: createOrUpdateSchedules,
    isLoading: loadingCreateOrUpdateSchedules,
  } = useMutation({
    mutationFn: services.schedule.createOrUpdateSchedules,
    onSuccess: (_, request) => {
      queryClient.invalidateQueries('schedules');
      navigate({
        pathname: paths.programming,
        search: routerUtils
          .createSearchParams({
            countryId: bakery?.countryId,
            cityId: bakery?.cityId,
            bakeryId: bakery?.id,
            ovenModelId,
            day: request.schedules[0]?.day,
          })
          .toString(),
      });
    },
    onError: scheduleUtils.handleError,
  });

  useEffect(() => {
    const breadcrumbs: Breadcrumb[] = [];
    breadcrumbs.push({
      title: locals.getText('programming_breadcrumb'),
      onClick: () => navigate(paths.programming),
    });
    if (bakery != null) {
      breadcrumbs.push({
        title: bakery.countryName ?? '',
        onClick: () =>
          navigate({
            pathname: paths.programming,
            search: routerUtils
              .createSearchParams({
                countryId: bakery.countryId,
              })
              .toString(),
          }),
      });
      breadcrumbs.push({
        title: bakery.cityName ?? '',
        onClick: () =>
          navigate({
            pathname: paths.programming,
            search: routerUtils
              .createSearchParams({
                countryId: bakery.countryId,
                cityId: bakery.cityId,
              })
              .toString(),
          }),
      });
      breadcrumbs.push({
        title: bakery.name,
        onClick: () =>
          navigate({
            pathname: paths.programming,
            search: routerUtils
              .createSearchParams({
                countryId: bakery.countryId,
                cityId: bakery.cityId,
                bakeryId: bakery.id,
              })
              .toString(),
          }),
      });
      if (ovenModel != null) {
        breadcrumbs.push({
          title: ovenModel.description,
          onClick: () =>
            navigate({
              pathname: paths.programming,
              search: routerUtils
                .createSearchParams({
                  countryId: bakery.countryId,
                  cityId: bakery.cityId,
                  bakeryId: bakery.id,
                  ovenModelId: ovenModel.id,
                })
                .toString(),
            }),
        });
      }
    }
    breadcrumbs.push({
      title: locals.getText('add_cleaning_schedule_breadcrumb'),
      onClick: () => {
        setDefaultValues({});
        setAvailableTimeSlots([]);
        setSelectedOvenPanels([]);
        setSelectedCleaningId(null);
        setSelectedDays([day]);
        setStartTime('');
        setEndTime('');
        setShadowStartTime('');
        setShadowEndTime('');
        setSuggestedStartTime('');
        setSuggestedEndTime('');
        setIsOverlapping(false);
        setSteps({
          ovens: {visible: true, visited: true, error: true},
          cleaning: {visible: false, visited: false, error: true},
          time: {visible: false, visited: false, error: true},
        });
        setSelectedStep('ovens');
      },
    });

    if (selectedCleaningId != null) {
      breadcrumbs.push({
        title: cleaningUtils.getDescription(ovenModelId, selectedCleaningId),
      });
    }

    setBreadcrumbs(breadcrumbs);
    return () => setBreadcrumbs([]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bakery, ovenModelId, selectedCleaningId]);

  function handleSelectOvenPanel(ovenPanel: OvenPanelData) {
    if (selectedOvenPanelIdsSet.has(ovenPanel.id)) {
      selectedOvenPanelIdsSet.delete(ovenPanel.id);
      setSelectedOvenPanels((selectedOvenPanels) =>
        selectedOvenPanels.filter(
          (selectedOvenPanel) => selectedOvenPanel.id !== ovenPanel.id,
        ),
      );
    } else {
      selectedOvenPanelIdsSet.add(ovenPanel.id);
      setSelectedOvenPanels((selectedOvenPanels) => [
        ...selectedOvenPanels,
        ovenPanel,
      ]);
    }
    setSteps((steps) => ({
      ...steps,
      ovens: {...steps.ovens, error: selectedOvenPanelIdsSet.size === 0},
      cleaning: {
        ...steps.cleaning,
        visible: steps.cleaning.visible || selectedOvenPanelIdsSet.size > 0,
      },
      time: {...steps.time, error: true},
    }));
    if (
      selectedOvenPanelIdsSet.size === 1 &&
      !hasMultipleOvenPanels(ovenModel)
    ) {
      setSelectedStep('cleaning');
    }
    validateTimeSlot({
      ovenPanelIds: Array.from(selectedOvenPanelIdsSet),
      days: selectedDays,
      startTime,
      endTime: getEndTime(startTime, cleanings, selectedCleaningId),
      ignoreScheduleIds: scheduleId != null ? [scheduleId] : [],
    });
  }

  function handleSelectCleaning(cleaningId: number) {
    setSelectedCleaningId(cleaningId);
    setSteps((steps) => ({
      ...steps,
      cleaning: {...steps.cleaning, error: false},
      time: {...steps.time, visible: true, error: true},
    }));
    if (!steps.time.visible) {
      suggestStartTime({
        ovenPanelIds: selectedOvenPanelIds,
        day: selectedDays[0],
        duration: getCleaningDuration(cleanings, cleaningId),
      });
      return;
    }
    validateTimeSlot({
      ovenPanelIds: selectedOvenPanelIds,
      days: selectedDays,
      startTime,
      endTime: getEndTime(startTime, cleanings, cleaningId),
      ignoreScheduleIds: scheduleId != null ? [scheduleId] : [],
    });
  }

  function handleSelectDay(day: Day) {
    if (selectedDaysSet.has(day)) {
      selectedDaysSet.delete(day);
    } else {
      selectedDaysSet.add(day);
    }
    setSelectedDays(Array.from(selectedDaysSet));
    setSteps((steps) => ({...steps, time: {...steps.time, error: true}}));
    validateTimeSlot({
      ovenPanelIds: selectedOvenPanelIds,
      days: Array.from(selectedDaysSet),
      startTime,
      endTime: getEndTime(startTime, cleanings, selectedCleaningId),
      ignoreScheduleIds: scheduleId != null ? [scheduleId] : [],
    });
  }

  function handleSelectAllDays() {
    setSelectedDays([1, 2, 3, 4, 5, 6, 0]);
    setSteps((steps) => ({...steps, time: {...steps.time, error: true}}));
    validateTimeSlot({
      ovenPanelIds: selectedOvenPanelIds,
      days: [1, 2, 3, 4, 5, 6, 0],
      startTime,
      endTime: getEndTime(startTime, cleanings, selectedCleaningId),
      ignoreScheduleIds: scheduleId != null ? [scheduleId] : [],
    });
  }

  function handleSetStartTime(time: string) {
    setShadowStartTime('');
    setShadowEndTime('');
    setStartTime(time);
    setSteps((steps) => ({...steps, time: {...steps.time, error: true}}));
    validateTimeSlot({
      ovenPanelIds: selectedOvenPanelIds,
      days: selectedDays,
      startTime: time,
      endTime: getEndTime(time, cleanings, selectedCleaningId),
      ignoreScheduleIds: scheduleId != null ? [scheduleId] : [],
    });
  }

  function handleStartTimeFocus() {
    setShadowStartTime(startTime);
    setShadowEndTime(endTime);
    setStartTime('');
    setSteps((steps) => ({...steps, time: {...steps.time, error: true}}));
  }

  function handleStartTimeBlur() {
    if (stringUtils.isNullOrWhiteSpace(startTime)) {
      if (!stringUtils.isNullOrWhiteSpace(shadowStartTime)) {
        setStartTime(shadowStartTime);
        setEndTime(shadowEndTime);
        validateTimeSlot({
          ovenPanelIds: selectedOvenPanelIds,
          days: selectedDays,
          startTime: shadowStartTime,
          endTime: shadowEndTime,
          ignoreScheduleIds: scheduleId != null ? [scheduleId] : [],
        });
        return;
      }
      setStartTime(suggestedStartTime);
      setEndTime(suggestedEndTime);
      validateTimeSlot({
        ovenPanelIds: selectedOvenPanelIds,
        days: selectedDays,
        startTime: suggestedStartTime,
        endTime: suggestedEndTime,
        ignoreScheduleIds: scheduleId != null ? [scheduleId] : [],
      });
    }
  }

  function handleCreateOrUpdateSchedules() {
    let usedDefaultValues = false;
    const endTime = getEndTime(startTime, cleanings, selectedCleaningId);
    const schedules: Schedule[] = [];
    const ovenGroupIdsSet = new Set<string>();
    // oven groups can only have a single oven cleaning at a time,
    // if selected multiple ovens from the same group, we need to increase the time slot
    // the time increase is working only for oven groups with 2 ovens, which is the only scenario we have at this moment
    selectedOvenPanels.forEach((ovenPanel) => {
      selectedDays.forEach((day) => {
        let scheduleId = uuid();
        if (ovenPanel.id === defaultValues.ovenPanelId && !usedDefaultValues) {
          scheduleId = defaultValues.id!;
          usedDefaultValues = true;
        }
        const _startTime = ovenGroupIdsSet.has(ovenPanel.ovenGroupId ?? '')
          ? scheduleUtils.increaseTime(
              startTime,
              scheduleUtils.timeDifference(startTime, endTime) + 1,
            )
          : startTime;
        const _endTime = ovenGroupIdsSet.has(ovenPanel.ovenGroupId ?? '')
          ? scheduleUtils.increaseTime(
              endTime,
              scheduleUtils.timeDifference(startTime, endTime) + 1,
            )
          : endTime;
        schedules.push({
          id: scheduleId,
          type: ScheduleType.Cleaning,
          day,
          startTime: _startTime,
          endTime: _endTime,
          cleaningId: selectedCleaningId ?? undefined,
          ovenPanelId: ovenPanel.id,
          ovenModelId,
        });
        if (turnOffAfterSchedule) {
          schedules.push({
            id: uuid(),
            type: ScheduleType.Off,
            day,
            startTime: scheduleUtils.increaseTime(_endTime, 1),
            endTime: scheduleUtils.increaseTime(_endTime, 1),
            ovenPanelId: ovenPanel.id,
            ovenModelId,
          });
        }
      });
      if (ovenPanel.ovenGroupId != null) {
        ovenGroupIdsSet.add(ovenPanel.ovenGroupId);
      }
    });
    createOrUpdateSchedules({schedules});
  }

  const loading =
    loadingSchedule ||
    loadingBakery ||
    loadingOvens ||
    loadingCleanings ||
    loadingTimeSlot ||
    loadingCreateOrUpdateSchedules;
  const renderOverlappingError =
    selectedStep === 'time' && steps.time.error && isOverlapping;

  return (
    <Fragment>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          width: '20vw',
          marginBlock: '112px',
        }}>
        <CreateCleaningScheduleMenu
          steps={steps}
          setSteps={setSteps}
          selectedStep={selectedStep}
          setSelectedStep={setSelectedStep}
          onFinish={handleCreateOrUpdateSchedules}
        />
      </Box>
      <Box sx={{width: '80vw'}}>
        <Box sx={{display: 'flex'}}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between',
              width: '65vw',
              height: '112px',
            }}>
            <Paper
              elevation={4}
              sx={{
                display: 'flex',
                alignItems: 'center',
                width: '100%',
                marginBlock: 2,
                paddingBlock: 1,
                paddingInline: 2,
                borderRadius: '10px',
                backgroundColor: '#F4F4F4',
              }}>
              <Typography variant="body2" sx={{fontWeight: 'bold'}}>
                {locals.getText('programming_cleaning_programming_title')}
              </Typography>
            </Paper>
            <Typography
              variant="body2"
              sx={{
                fontWeight: 'bold',
                color: 'text.primary',
                paddingInline: 2,
              }}>
              {getStepTitle(selectedStep)}
            </Typography>
            <Box sx={{height: '23px'}}>
              {renderOverlappingError && (
                <Typography
                  variant="caption"
                  sx={{
                    color: 'primary.main',
                    paddingTop: '3px',
                    paddingInline: 2,
                  }}>
                  <Span sx={{fontWeight: 'bold'}}>
                    {locals.getText(
                      'programming_overlapping_times_error_title',
                    )}
                  </Span>
                  <Span>
                    {` - ${locals.getText(
                      'programming_overlapping_times_error_caption',
                    )} `}
                  </Span>
                  {availableTimeSlots.map((timeSlot, index) => (
                    <Span key={index}>
                      {`${timeSlot.startTime} - ${timeSlot.endTime}${
                        index < availableTimeSlots.length - 1 ? ' | ' : ''
                      }`}
                    </Span>
                  ))}
                </Typography>
              )}
            </Box>
          </Box>
          <Box sx={{width: '15vw', textAlign: 'center', padding: 2.2}}>
            <IconButton
              IconComponent={DeclineIcon}
              activeColor="secondary"
              onClick={() =>
                navigate({
                  pathname: paths.programming,
                  search: routerUtils
                    .createSearchParams({
                      countryId: bakery?.countryId,
                      cityId: bakery?.cityId,
                      bakeryId: bakery?.id,
                      ovenModelId,
                    })
                    .toString(),
                })
              }
            />
          </Box>
        </Box>
        <Box>
          {selectedStep === 'ovens' && (
            <OvenList
              ovenGroups={ovenModel?.ovenGroups ?? []}
              ovens={ovenModel?.ovens ?? []}
              selectedOvenPanelIds={selectedOvenPanels.map(
                (ovenPanel) => ovenPanel.id,
              )}
              onSelectOvenPanel={handleSelectOvenPanel}
              isCleaning
              ovensNotFoundMessage={
                loadingOvens
                  ? ''
                  : locals.getText('programming_ovens_not_found_label')
              }
            />
          )}
          {selectedStep === 'cleaning' && (
            <CleaningList
              cleanings={cleanings}
              selectedCleaningId={selectedCleaningId}
              onSelectCleaning={handleSelectCleaning}
              cleaningsNotFoundMessage={
                loadingCleanings
                  ? ''
                  : locals.getText('programming_cleanings_not_found_label')
              }
            />
          )}
          {selectedStep === 'time' && (
            <SelectTime
              startTime={startTime}
              endTime={endTime}
              setStartTime={handleSetStartTime}
              selectedDays={selectedDays}
              onSelectDay={handleSelectDay}
              onSelectAllDays={handleSelectAllDays}
              error={steps.time.error}
              turnOffAfterSchedule={turnOffAfterSchedule}
              setTurnOffAfterSchedule={setTurnOffAfterSchedule}
              turnOffAfterScheduleLabel={locals.getText(
                'programming_turn_off_oven_after_cleaning_label',
              )}
              onStartTimeFocus={handleStartTimeFocus}
              onStartTimeBlur={handleStartTimeBlur}
            />
          )}
        </Box>
      </Box>
      <LoadingBackdrop loading={loading} />
    </Fragment>
  );
}

export default CreateCleaningSchedule;
