import {Box, List, ListItem, SvgIcon, Typography} from '@mui/material';
import {ElementType, useRef, useState} from 'react';
import {ReactComponent as AcceptIcon} from '../../../assets/icons/accept.svg';
import {ReactComponent as AddIcon} from '../../../assets/icons/add.svg';
import {ReactComponent as DeclineIcon} from '../../../assets/icons/decline.svg';
import {ReactComponent as NextIcon} from '../../../assets/icons/next.svg';
import {ReactComponent as NumberFiveIcon} from '../../../assets/icons/number-five.svg';
import {ReactComponent as NumberFourIcon} from '../../../assets/icons/number-four.svg';
import {ReactComponent as NumberOneIcon} from '../../../assets/icons/number-one.svg';
import {ReactComponent as NumberSixIcon} from '../../../assets/icons/number-six.svg';
import {ReactComponent as NumberThreeIcon} from '../../../assets/icons/number-three.svg';
import {ReactComponent as NumberTwoIcon} from '../../../assets/icons/number-two.svg';
import {ReactComponent as SaveIcon} from '../../../assets/icons/save.svg';
import locals from '../../../localization/locals';
import {RecipePhase} from '../../../models/entities/recipe';
import colors from '../../../themes/colors';
import stringUtils from '../../../utils/strings';
import ConfirmPopover from '../../common/ConfirmPopover';
import Span from '../../common/Span';
import {
  RecipeBuild,
  RecipeSettingsAction,
  RecipeSettingsStep,
  RecipeSettingsSteps,
} from './RecipeSettings';

function getPhaseIcon(index: number) {
  switch (index) {
    case 0:
    default:
      return NumberThreeIcon;
    case 1:
      return NumberFourIcon;
    case 2:
      return NumberFiveIcon;
    case 3:
      return NumberSixIcon;
  }
}

function getEmptyPhase(): RecipePhase {
  return {
    temperature: 0,
    duration: 0,
    steamInjectionNumber: 0,
    steamExitValveOpened: false,
    turbineSpeed: 0,
  };
}

type MenuItemProps = {
  IconComponent: ElementType<any>;
  iconColor?: string;
  title: string;
  selected?: boolean;
  onClick?: () => void;
};

function MenuItem(props: MenuItemProps) {
  const {IconComponent, iconColor, title, selected, onClick} = props;

  const [isHovered, setIsHovered] = useState(false);

  function getIconColor() {
    if (iconColor != null) return iconColor;
    return selected ? colors.grey : colors.lightGrey;
  }

  const renderText = isHovered || selected;

  return (
    <ListItem sx={{width: '20vw'}}>
      <Box
        sx={{
          flex: 1,
          height: '25px',
          textAlign: 'right',
          paddingRight: 2,
        }}></Box>
      <Box
        sx={{display: 'flex', alignItems: 'center', cursor: 'pointer'}}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
        onClick={onClick}>
        <Box sx={{width: '25px', height: '25px', textAlign: 'right'}}>
          <SvgIcon
            component={IconComponent}
            inheritViewBox
            sx={{fontSize: '25px', color: getIconColor()}}
          />
        </Box>
        <Box sx={{width: '10vw'}}>
          {renderText && (
            <Typography
              variant="body2"
              sx={{
                color: selected ? colors.grey : colors.lightGrey,
                fontWeight: 'bold',
                marginLeft: 2,
              }}>
              {title}
            </Typography>
          )}
        </Box>
      </Box>
    </ListItem>
  );
}

type PhaseMenuItemProps = {
  phaseIndex: number;
  selected: boolean;
  canDelete: boolean;
  onClick: () => void;
  onDelete: () => void;
};

function PhaseMenuItem(props: PhaseMenuItemProps) {
  const {selected, onClick, phaseIndex, canDelete, onDelete} = props;

  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const [menuIsHovered, setMenuIsHovered] = useState(false);
  const [removeButtonIsHovered, setRemoveButtonIsHovered] = useState(false);

  const containerRef = useRef<HTMLLIElement | null>(null);

  const open = Boolean(anchorEl);
  const renderText = menuIsHovered || selected || open;
  const color = selected || open ? colors.grey : colors.lightGrey;

  return (
    <ListItem
      ref={containerRef}
      sx={{zIndex: open ? 1500 : undefined, width: '20vw'}}>
      <Box
        sx={{
          flex: 1,
          minWidth: '25px',
          height: '25px',
          textAlign: 'right',
          paddingRight: 2,
        }}>
        {canDelete && (
          <SvgIcon
            component={DeclineIcon}
            inheritViewBox
            sx={{
              fontSize: '25px',
              color: open
                ? 'primary.main'
                : removeButtonIsHovered
                ? colors.grey
                : color,
              cursor: 'pointer',
            }}
            onMouseEnter={() => setRemoveButtonIsHovered(true)}
            onMouseLeave={() => setRemoveButtonIsHovered(false)}
            onClick={() => setAnchorEl(open ? null : containerRef.current)}
          />
        )}
      </Box>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          cursor: open ? undefined : 'pointer',
        }}
        onMouseEnter={() => setMenuIsHovered(true)}
        onMouseLeave={() => setMenuIsHovered(false)}
        onClick={() => (open ? setAnchorEl(null) : onClick())}>
        <Box sx={{width: '25px', height: '25px', textAlign: 'right'}}>
          <SvgIcon
            component={getPhaseIcon(phaseIndex)}
            inheritViewBox
            sx={{fontSize: '25px', color}}
          />
        </Box>
        <Box sx={{width: '10vw'}}>
          {renderText && (
            <Typography
              variant="body2"
              sx={{color, fontWeight: 'bold', marginLeft: 2}}>
              {`${locals.getText('recipe_settings_phase_phase')} ${
                phaseIndex + 1
              }`}
            </Typography>
          )}
        </Box>
      </Box>
      <ConfirmPopover
        anchorEl={anchorEl}
        anchorOrigin={{vertical: 'center', horizontal: 'right'}}
        transformOrigin={{vertical: 'center', horizontal: 'left'}}
        value={stringUtils.format(
          locals.getText('recipe_settings_phases_delete_phase_label'),
          (phaseIndex + 1).toString(),
        )}
        onChange={() => null}
        messageElement={
          <Typography
            variant="body2"
            sx={{flex: 1, fontWeight: 500, color: colors.white}}>
            <Span>
              {locals.getText('recipe_settings_phases_delete_phase_label')}
            </Span>
            <Span sx={{fontWeight: 800}}>{` ${locals.getText(
              'recipe_settings_phase_label',
            )} ${phaseIndex + 1}`}</Span>
            <Span>?</Span>
          </Typography>
        }
        onAccept={() => {
          setAnchorEl(null);
          onDelete();
        }}
        onDecline={() => setAnchorEl(null)}
      />
    </ListItem>
  );
}

type RecipeSettingsMenuProps = {
  action: RecipeSettingsAction;
  steps: RecipeSettingsSteps;
  setSteps: (
    setter: (previousValue: RecipeSettingsSteps) => RecipeSettingsSteps,
  ) => void;
  selectedStep: RecipeSettingsStep | 'none';
  setSelectedStep: (step: RecipeSettingsStep) => void;
  selectedPhaseIndex: number;
  setSelectedPhaseIndex: (index: number) => void;
  recipeBuild: RecipeBuild;
  setRecipeBuild: (setter: (previousValue: RecipeBuild) => RecipeBuild) => void;
  onFinish: () => void;
};

function RecipeSettingsMenu(props: RecipeSettingsMenuProps) {
  const {
    action,
    steps,
    setSteps,
    selectedStep,
    setSelectedStep,
    selectedPhaseIndex,
    setSelectedPhaseIndex,
    recipeBuild,
    setRecipeBuild,
    onFinish,
  } = props;

  function handleAddPhase() {
    const length = recipeBuild.phases.length;
    if (length < 4) {
      setRecipeBuild((recipeBuild) => ({
        ...recipeBuild,
        phases: [...recipeBuild.phases, getEmptyPhase()],
      }));
      setSteps((steps) => ({
        ...steps,
        phases: {visible: true, errors: [...steps.phases.errors, true]},
      }));
      setSelectedStep('phases');
      setSelectedPhaseIndex(length);
    }
  }

  function handleDeletePhase(phaseIndex: number) {
    if (phaseIndex <= selectedPhaseIndex) {
      setSelectedPhaseIndex(
        selectedPhaseIndex > 0 ? selectedPhaseIndex - 1 : 0,
      );
    }
    setSteps((steps) => ({
      ...steps,
      phases: {
        visible: true,
        errors: steps.phases.errors.filter((_, index) => index !== phaseIndex),
      },
    }));
    setRecipeBuild((recipeBuild) => ({
      ...recipeBuild,
      phases: recipeBuild.phases.filter((_, index) => index !== phaseIndex),
    }));
  }

  const renderNextButton =
    action !== 'locate' &&
    !steps.general.error &&
    recipeBuild.recipeTypeIds.length >= 1 &&
    (!steps.procedure.visible || !steps.phases.visible);
  const renderAddPhaseButton =
    action !== 'locate' &&
    steps.phases.visible &&
    !steps.phases.errors.some((error) => error) &&
    recipeBuild.phases.length < 4;
  const renderSaveButton =
    (action === 'create' &&
      !steps.location.visible &&
      !steps.general.error &&
      !steps.phases.errors.some((error) => error)) ||
    action === 'locate';
  const renderFinishButton =
    (action === 'create' &&
      !steps.general.error &&
      !steps.phases.errors.some((error) => error) &&
      recipeBuild.bakeryIds.length >= 1) ||
    (action === 'update' &&
      !steps.general.error &&
      !steps.phases.errors.some((error) => error));

  return (
    <List>
      {steps.general.visible && (
        <MenuItem
          IconComponent={NumberOneIcon}
          title={locals.getText('recipe_settings_phase_general')}
          selected={selectedStep === 'general'}
          onClick={() => setSelectedStep('general')}
        />
      )}
      {steps.procedure.visible && (
        <MenuItem
          IconComponent={NumberTwoIcon}
          title={locals.getText('recipe_settings_phase_procedure')}
          selected={selectedStep === 'procedure'}
          onClick={() => setSelectedStep('procedure')}
        />
      )}
      {steps.phases.visible &&
        recipeBuild.phases.map((_, index) => (
          <PhaseMenuItem
            key={index}
            phaseIndex={index}
            selected={selectedStep === 'phases' && index === selectedPhaseIndex}
            canDelete={recipeBuild.phases.length > 1}
            onClick={() => {
              setSelectedStep('phases');
              setSelectedPhaseIndex(index);
            }}
            onDelete={() => handleDeletePhase(index)}
          />
        ))}
      {renderNextButton && (
        <MenuItem
          IconComponent={NextIcon}
          iconColor="primary.main"
          title={locals.getText('recipe_settings_next')}
          selected
          onClick={() => {
            setSteps((steps) => ({
              ...steps,
              procedure: {visible: true, error: false},
              phases: {
                visible: steps.procedure.visible ? true : false,
                errors: steps.phases.errors,
              },
            }));
            setSelectedStep(
              selectedStep === 'general' ? 'procedure' : 'phases',
            );
          }}
        />
      )}
      {renderAddPhaseButton && (
        <MenuItem IconComponent={AddIcon} title="" onClick={handleAddPhase} />
      )}
      {action !== 'locate' && steps.location.visible && (
        <MenuItem
          IconComponent={SaveIcon}
          title={locals.getText('recipe_settings_phase_location')}
          selected={selectedStep === 'location'}
          onClick={() => setSelectedStep('location')}
        />
      )}
      {renderSaveButton && (
        <MenuItem
          IconComponent={SaveIcon}
          iconColor="primary.main"
          title={locals.getText('recipe_settings_phase_save')}
          selected
          onClick={() => {
            if (action === 'locate') {
              onFinish();
              return;
            }
            setSteps((steps) => ({
              ...steps,
              location: {visible: true, error: false},
            }));
            setSelectedStep('location');
          }}
        />
      )}
      {renderFinishButton && (
        <MenuItem
          IconComponent={AcceptIcon}
          iconColor="primary.main"
          title={locals.getText('recipe_settings_phase_conclude')}
          selected
          onClick={onFinish}
        />
      )}
    </List>
  );
}

export default RecipeSettingsMenu;
