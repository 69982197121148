import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
} from '@mui/material';
import {Fragment, useMemo, useState} from 'react';
import locals from '../../localization/locals';
import Oven from '../../models/entities/oven';
import OvenGroup from '../../models/entities/ovenGroup';
import {OvenModelId} from '../../models/entities/ovenModel';
import dateUtils from '../../utils/dates';
import GradientOverflow from '../common/GradientOverflow';

type OvenGroupTableRowProps = {
  ovenGroup: OvenGroup;
  onClick?: () => void;
};

function OvenGroupTableRow(props: OvenGroupTableRowProps) {
  const {ovenGroup, onClick} = props;

  const [isHovered, setIsHovered] = useState(false);

  const color = isHovered ? 'primary.main' : 'text.primary';

  const ovens = useMemo(
    () =>
      Array.from(ovenGroup.ovens ?? []).sort(
        (ovenA, ovenB) =>
          (ovenA.ovenGroupOrder ?? 0) - (ovenB.ovenGroupOrder ?? 0),
      ),
    [ovenGroup],
  );

  return (
    <TableRow
      component="tr"
      sx={{cursor: 'pointer', verticalAlign: 'top'}}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      onClick={onClick}>
      <TableCell>
        <Typography variant="body2" color={color} sx={{fontWeight: 'bold'}}>
          {ovenGroup.description}
        </Typography>
        {ovens.map((oven) => (
          <Fragment key={oven.id}>
            <Typography variant="body2" color={color}>
              {`${locals.getText('bakery_ovens_oven_serial_number_label')} ${
                (oven.ovenGroupOrder ?? 0) + 1
              }: ${oven.serialNumber}`}
            </Typography>
          </Fragment>
        ))}
      </TableCell>
      <TableCell align="right">
        <Typography variant="body2" color={color}>
          {dateUtils.toLocaleDateString(ovenGroup.assignedAt ?? '')}
        </Typography>
      </TableCell>
    </TableRow>
  );
}

type OvenTableRowProps = {
  oven: Oven;
  onClick?: () => void;
};

function ChamberOvenTableRow(props: OvenTableRowProps) {
  const {oven, onClick} = props;

  const [isHovered, setIsHovered] = useState(false);

  const color = isHovered ? 'primary.main' : 'text.primary';

  const ovenChambers = useMemo(
    () =>
      Array.from(oven.ovenChambers ?? []).sort(
        (ovenChamberA, ovenChamberB) =>
          (ovenChamberA.ovenOrder ?? 0) - (ovenChamberB.ovenOrder ?? 0),
      ),
    [oven],
  );

  return (
    <TableRow
      component="tr"
      sx={{cursor: 'pointer', verticalAlign: 'top'}}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      onClick={onClick}>
      <TableCell>
        <Typography variant="body2" color={color} sx={{fontWeight: 'bold'}}>
          {oven.description}
        </Typography>
        {ovenChambers.map((ovenChamber) => (
          <Fragment key={ovenChamber.id}>
            <Typography variant="body2" color={color}>
              {`${locals.getText('bakery_ovens_chamber_serial_number_label')} ${
                (ovenChamber.ovenOrder ?? 0) + 1
              }: ${ovenChamber.serialNumber}`}
            </Typography>
          </Fragment>
        ))}
      </TableCell>
      <TableCell align="right">
        <Typography variant="body2" color={color}>
          {dateUtils.toLocaleDateString(oven.assignedAt ?? '')}
        </Typography>
      </TableCell>
    </TableRow>
  );
}

function OvenTableRow(props: OvenTableRowProps) {
  const {oven, onClick} = props;

  const [isHovered, setIsHovered] = useState(false);

  const color = isHovered ? 'primary.main' : 'text.primary';

  return (
    <TableRow
      component="tr"
      sx={{cursor: 'pointer', verticalAlign: 'top'}}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      onClick={onClick}>
      <TableCell>
        <Typography variant="body2" sx={{color, fontWeight: 'bold'}}>
          {oven.description}
        </Typography>
        <Typography variant="body2" sx={{color}}>
          {`${locals.getText('bakery_ovens_serial_number_label')}: ${
            oven.serialNumber
          }`}
        </Typography>
      </TableCell>
      <TableCell align="right">
        <Typography variant="body2" sx={{color}}>
          {dateUtils.toLocaleDateString(oven.assignedAt ?? '')}
        </Typography>
      </TableCell>
    </TableRow>
  );
}

export type OvenTableProps = {
  ovenGroups: OvenGroup[];
  ovens: Oven[];
  onSelectOvenGroup?: (ovenGroupId: string) => void;
  onSelectOven?: (ovenId: string) => void;
};

function OvenTable(props: OvenTableProps) {
  const {ovenGroups, ovens, onSelectOvenGroup, onSelectOven} = props;

  return (
    <GradientOverflow hideScrollbar>
      <Table>
        <TableBody>
          {ovens.map((oven) =>
            oven.ovenModelId === OvenModelId.Modulram ? (
              <ChamberOvenTableRow
                key={oven.id}
                oven={oven}
                onClick={() => onSelectOven?.(oven.id)}
              />
            ) : (
              <OvenTableRow
                key={oven.id}
                oven={oven}
                onClick={() => onSelectOven?.(oven.id)}
              />
            ),
          )}
          {ovenGroups.map((ovenGroup) => (
            <OvenGroupTableRow
              key={ovenGroup.id}
              ovenGroup={ovenGroup}
              onClick={() => onSelectOvenGroup?.(ovenGroup.id)}
            />
          ))}
        </TableBody>
      </Table>
      <Box height="10vh" />
    </GradientOverflow>
  );
}

export default OvenTable;
