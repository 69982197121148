import {Box, Typography} from '@mui/material';
import {Fragment, useEffect, useMemo, useState} from 'react';
import {useMutation, useQuery, useQueryClient} from 'react-query';
import {useNavigate, useSearchParams} from 'react-router-dom';
import locals from '../../localization/locals';
import {OvenModelId} from '../../models/entities/ovenModel';
import Schedule, {ScheduleType} from '../../models/entities/schedule';
import paths from '../../routes/paths';
import services from '../../services/provider';
import useBreadcrumbsStore, {Breadcrumb} from '../../state/breadcrumbs';
import colors from '../../themes/colors';
import dateUtils, {Day, days} from '../../utils/dates';
import numberUtils from '../../utils/numbers';
import routerUtils from '../../utils/router';
import OvenModelList from '../bakeries/OvenModelList';
import ActionBanner from '../common/ActionBanner';
import GradientOverflow from '../common/GradientOverflow';
import LoadingBackdrop from '../common/LoadingBackdrop';
import {pageHeight} from '../navigation/Navbar';
import BakeryList from './BakeryList';
import CityList from './CityList';
import CountryTabs from './CountryTabs';
import DayTabs from './DayTabs';
import ScheduleList from './ScheduleList';
import SchedulesMenu from './SchedulesMenu';
import SearchField from './SearchField';

export type ScheduleOrderBy = 'time' | 'oven';

function Schedules() {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const queryClient = useQueryClient();

  const setBreadcrumbs = useBreadcrumbsStore((state) => state.setBreadcrumbs);

  const [orderBy, setOrderBy] = useState<ScheduleOrderBy>('time');
  const [searchText, setSearchText] = useState('');
  const [selectedCountryId, setSelectedCountryId] = useState<number | null>(
    searchParams.has('countryId')
      ? numberUtils.parseInt(searchParams.get('countryId'))
      : null,
  );
  const [selectedCityId, setSelectedCityId] = useState<number | null>(
    searchParams.has('cityId')
      ? numberUtils.parseInt(searchParams.get('cityId'))
      : null,
  );
  const [selectedBakeryId, setSelectedBakeryId] = useState<string | null>(
    searchParams.get('bakeryId'),
  );
  const [selectedOvenModelId, setSelectedOvenModelId] =
    useState<OvenModelId | null>(
      searchParams.has('ovenModelId')
        ? numberUtils.parseInt(searchParams.get('ovenModelId'))
        : null,
    );
  const [selectedDay, setSelectedDay] = useState<Day>(
    dateUtils.parseDay(searchParams.get('day'), Day.Monday),
  );

  const {data: countries = [], isLoading: loadingLocations} = useQuery({
    queryKey: ['bakeriesLocations'],
    queryFn: () => services.bakery.getBakeriesLocations({}),
  });

  const {data: ovenModels = [], isLoading: loadingOvenModels} = useQuery({
    enabled: selectedBakeryId !== null,
    queryKey: ['bakeryOvens', selectedBakeryId],
    queryFn: () =>
      services.bakery.getBakeryOvens({bakeryId: selectedBakeryId!}),
  });

  const {data: schedules = [], isLoading: loadingSchedules} = useQuery({
    enabled: selectedOvenModelId !== null,
    queryKey: [
      'schedules',
      {ovenModelId: selectedOvenModelId, day: selectedDay},
    ],
    queryFn: () =>
      services.schedule.getSchedules({
        ovenModelId: selectedOvenModelId!,
        day: selectedDay,
      }),
  });

  const {mutate: deleteSchedule, isLoading: loadingDeleteSchedule} =
    useMutation({
      mutationFn: services.schedule.deleteSchedule,
      onSuccess: (scheduleId) => {
        queryClient.setQueryData<Schedule[] | undefined>(
          ['schedules', {ovenModelId: selectedOvenModelId, day: selectedDay}],
          (schedules) => {
            return schedules?.filter((schedule) => schedule.id !== scheduleId);
          },
        );
        queryClient.invalidateQueries('schedules');
      },
    });

  const sortedSchedules = useMemo(() => {
    if (orderBy === 'time') {
      return Array.from(schedules).sort((scheduleA, scheduleB) => {
        if (scheduleA.startTime > scheduleB.startTime) return 1;
        if (scheduleA.startTime < scheduleB.startTime) return -1;
        return 0;
      });
    }
    return Array.from(schedules).sort((scheduleA, scheduleB) => {
      const ovenKeyA = `${scheduleA.ovenGroupDescription} ${scheduleA.ovenDescription} ${scheduleA.ovenIndex} ${scheduleA.ovenChamberIndex}`;
      const ovenKeyB = `${scheduleB.ovenGroupDescription} ${scheduleB.ovenDescription} ${scheduleB.ovenIndex} ${scheduleB.ovenChamberIndex}`;
      if (ovenKeyA < ovenKeyB) return -1;
      if (ovenKeyA > ovenKeyB) return 1;
      return 0;
    });
  }, [schedules, orderBy]);

  const selectedCountry =
    countries.find((country) => country.id === selectedCountryId) ?? null;
  const selectedCity =
    selectedCountry?.cities?.find((city) => city.id === selectedCityId) ?? null;
  const selectedBakery =
    selectedCity?.bakeries?.find((bakery) => bakery.id === selectedBakeryId) ??
    null;
  const selectedOvenModel =
    ovenModels.find((ovenModel) => ovenModel.id === selectedOvenModelId) ??
    null;

  useEffect(() => {
    if (selectedCountryId == null) {
      setSelectedCountryId(countries.length > 0 ? countries[0].id : null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [countries]);

  useEffect(() => {
    if (selectedOvenModelId == null) {
      setSelectedOvenModelId(ovenModels.length > 0 ? ovenModels[0].id : null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ovenModels]);

  useEffect(() => {
    const breadcrumbs: Breadcrumb[] = [];
    breadcrumbs.push({
      title: locals.getText('programming_breadcrumb'),
      onClick: () => {
        setSearchText('');
        setSelectedCountryId(countries.length > 0 ? countries[0].id : null);
        setSelectedCityId(null);
        setSelectedBakeryId(null);
        setSelectedOvenModelId(null);
        setSelectedDay(Day.Monday);
      },
    });
    if (selectedCountry != null) {
      breadcrumbs.push({
        title: selectedCountry.name,
        onClick: () => {
          setSearchText('');
          setSelectedCityId(null);
          setSelectedBakeryId(null);
          setSelectedOvenModelId(null);
          setSelectedDay(Day.Monday);
        },
      });
    }
    if (selectedCity != null) {
      breadcrumbs.push({
        title: selectedCity.name,
        onClick: () => {
          setSearchText('');
          setSelectedBakeryId(null);
          setSelectedOvenModelId(null);
          setSelectedDay(Day.Monday);
        },
      });
    }
    if (selectedBakery != null) {
      breadcrumbs.push({
        title: selectedBakery.name,
        onClick: () => {
          setSearchText('');
          setSelectedOvenModelId(
            ovenModels.length > 0 ? ovenModels[0].id : null,
          );
          setSelectedDay(Day.Monday);
        },
      });
    }
    if (selectedOvenModel != null) {
      breadcrumbs.push({
        title: selectedOvenModel.description,
        onClick: () => {
          setSearchText('');
          setSelectedDay(Day.Monday);
        },
      });
      breadcrumbs.push({title: days[selectedDay].label()});
    }
    setBreadcrumbs(breadcrumbs);
    return () => setBreadcrumbs([]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    selectedCountry,
    selectedCity,
    selectedBakery,
    selectedOvenModel,
    selectedDay,
  ]);

  function handleSelectCountry(countryId: number) {
    setSelectedCountryId(countryId);
    setSelectedCityId(null);
    setSelectedBakeryId(null);
    setSelectedOvenModelId(null);
    setSelectedDay(Day.Monday);
  }

  function handleSelectCity(cityId: number) {
    setSelectedCityId(cityId);
    setSelectedBakeryId(null);
    setSelectedOvenModelId(null);
    setSelectedDay(Day.Monday);
  }

  function handleSelectBakery(bakeryId: string) {
    setSelectedBakeryId(bakeryId);
    setSelectedOvenModelId(null);
    setSelectedDay(Day.Monday);
  }

  function handleSelectOvenModel(ovenModelId: number) {
    setSelectedOvenModelId(ovenModelId);
  }

  function handleReturnToBakeries() {
    setSelectedBakeryId(null);
    setSelectedOvenModelId(null);
    setSelectedDay(Day.Monday);
  }

  function handleUpdateSchedule(scheduleId: string) {
    const schedule = schedules.find((schedule) => schedule.id === scheduleId);
    if (schedule != null) {
      navigate({
        pathname:
          schedule.type === ScheduleType.Recipe
            ? paths.createRecipeSchedule
            : schedule.type === ScheduleType.Cleaning
            ? paths.createCleaningSchedule
            : paths.createOffSchedule,
        search: routerUtils
          .createSearchParams({
            bakeryId: selectedBakeryId,
            ovenModelId: selectedOvenModelId,
            scheduleId: schedule.id,
            day: schedule.day,
          })
          .toString(),
      });
    }
  }

  const loading =
    loadingLocations ||
    loadingOvenModels ||
    loadingSchedules ||
    loadingDeleteSchedule;

  const renderCountryTabs = selectedOvenModel == null;
  const renderDayTabs = selectedOvenModel != null;
  const renderCityList = selectedCountry != null && selectedCity == null;
  const renderBakeryList = selectedCity != null && selectedBakery == null;
  const renderOvenModelList = selectedBakery != null;
  const renderSchedules = selectedOvenModel != null;
  const renderOvensNotFoundLabel = !loading && selectedOvenModel == null;

  return (
    <Fragment>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          width: '20vw',
        }}>
        <Box
          sx={{
            width: '140px',
            height: `calc(${pageHeight} - 96px)`,
            marginTop: '64px',
          }}>
          {renderOvenModelList && (
            <GradientOverflow>
              <OvenModelList
                ovenModels={ovenModels}
                selectedOvenModelId={selectedOvenModelId ?? undefined}
                onSelectOvenModel={handleSelectOvenModel}
              />
            </GradientOverflow>
          )}
        </Box>
      </Box>
      <Box sx={{width: '80vw'}}>
        <Box sx={{display: 'flex'}}>
          <Box sx={{width: '65vw'}}>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
                height: '112px',
              }}>
              {renderCountryTabs && (
                <CountryTabs
                  countries={countries}
                  selectedCountryId={selectedCountryId}
                  onSelectCountry={handleSelectCountry}
                />
              )}
              {renderDayTabs && (
                <DayTabs
                  selectedDay={selectedDay}
                  onChangeSelectedDay={setSelectedDay}
                />
              )}
              <SearchField
                searchText={searchText}
                onChangeSearchText={setSearchText}
                selectedCity={selectedCity}
                selectedBakery={selectedBakery}
                onReturnToBakeries={handleReturnToBakeries}
              />
            </Box>
            {renderCityList && (
              <CityList
                searchText={searchText}
                cities={selectedCountry?.cities ?? []}
                onSelectCity={handleSelectCity}
                citiesNotFoundMessage={
                  selectedCountry == null
                    ? ''
                    : locals.getText('programming_cities_not_found_label')
                }
              />
            )}
            {renderBakeryList && (
              <BakeryList
                searchText={searchText}
                bakeries={selectedCity?.bakeries ?? []}
                onSelectBakery={handleSelectBakery}
                bakeriesNotFoundMessage={
                  selectedCity == null
                    ? ''
                    : locals.getText('programming_bakeries_not_found_label')
                }
              />
            )}
            {renderSchedules && (
              <Fragment>
                <Box sx={{marginTop: 2}}>
                  <ActionBanner
                    text={locals.getText(
                      'programming_add_recipe_schedule_label',
                    )}
                    onClick={() =>
                      navigate({
                        pathname: paths.createRecipeSchedule,
                        search: routerUtils
                          .createSearchParams({
                            bakeryId: selectedBakeryId,
                            ovenModelId: selectedOvenModelId,
                            day: selectedDay,
                          })
                          .toString(),
                      })
                    }
                  />
                </Box>
                {selectedOvenModelId === OvenModelId.Turboram && (
                  <Box sx={{marginTop: 2}}>
                    <ActionBanner
                      color={colors.blue}
                      text={locals.getText(
                        'programming_add_cleaning_schedule_label',
                      )}
                      onClick={() =>
                        navigate({
                          pathname: paths.createCleaningSchedule,
                          search: routerUtils
                            .createSearchParams({
                              bakeryId: selectedBakeryId,
                              ovenModelId: selectedOvenModelId,
                              day: selectedDay,
                            })
                            .toString(),
                        })
                      }
                    />
                  </Box>
                )}
                <Box sx={{marginTop: 2, marginBottom: 2}}>
                  <ActionBanner
                    text={locals.getText('programming_add_off_schedule_label')}
                    color={colors.orange}
                    onClick={() =>
                      navigate({
                        pathname: paths.createOffSchedule,
                        search: routerUtils
                          .createSearchParams({
                            bakeryId: selectedBakeryId,
                            ovenModelId: selectedOvenModelId,
                          })
                          .toString(),
                      })
                    }
                  />
                </Box>
              </Fragment>
            )}
          </Box>
          <Box sx={{width: '15vw'}}>
            {renderSchedules && (
              <SchedulesMenu orderBy={orderBy} setOrderBy={setOrderBy} />
            )}
          </Box>
        </Box>
        {renderSchedules && (
          <Box
            sx={{
              height:
                selectedOvenModelId === OvenModelId.Turboram
                  ? `calc(${pageHeight} - 268px)`
                  : `calc(${pageHeight} - 248px)`,
              paddingBottom: 6,
            }}>
            <ScheduleList
              schedules={sortedSchedules}
              onSelectSchedule={handleUpdateSchedule}
              onDeleteSchedule={(scheduleId) => deleteSchedule({scheduleId})}
              schedulesNotFoundMessage={
                loading
                  ? ''
                  : locals.getText('programming_schedules_not_found_label')
              }
            />
          </Box>
        )}
        {renderOvensNotFoundLabel && (
          <Box sx={{padding: 2}}>
            <Typography variant="body2" sx={{color: 'text.primary'}}>
              {locals.getText('programming_ovens_not_found_label')}
            </Typography>
          </Box>
        )}
      </Box>
      <LoadingBackdrop loading={loading} />
    </Fragment>
  );
}

export default Schedules;
