const endpoints = {
  login: '/login',
  signup: '/signup',
  passwordRecovery: '/password-recovery',
  passwordReset: '/password-reset',
  countries: '/countries',
  districts: '/districts',
  cities: '/cities',
  users: '/users',
  notifications: '/notifications',
  ovenPanelNotifications: '/oven-panel-notifications',
  bakeries: '/bakeries',
  ovenModels: '/oven-models',
  ovenPanels: '/oven-panels',
  recipeTypes: '/recipe-types',
  recipes: '/recipes',
  cleanings: '/cleanings',
  schedules: '/schedules',
};

export default endpoints;
