import locals from '../localization/locals';
import {OvenModelId} from '../models/entities/ovenModel';

const cleaningUtils = {
  getDescription: function (
    ovenModelId: OvenModelId,
    cleaningId: number,
    cleaningDescription = '',
  ) {
    if (ovenModelId) {
      switch (cleaningId) {
        case 1:
          return locals.getText('turboram_slight_cleaning_label');
        case 2:
          return locals.getText('turboram_medium_cleaning_label');
        case 3:
          return locals.getText('turboram_intense_cleaning_label');
      }
    }
    return cleaningDescription;
  },
};

export default cleaningUtils;
