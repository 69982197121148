import UserNotification from '../models/entities/userNotification';
import ChangePasswordRequest from '../models/requests/changePassword';
import GetUserParams from '../models/requests/getUser';
import GetUserNotificationsParams from '../models/requests/getUserNotifications';
import SetUserNotificationRequest from '../models/requests/setUserNotification';
import SubscribeRequest from '../models/requests/subscribe';
import UnsubscribeRequest from '../models/requests/unsubscribe';
import UpdateUserRequest from '../models/requests/updateUser';
import ChangePasswordResponse from '../models/responses/changePassword';
import GetUserResponse from '../models/responses/getUser';
import GetUserNotificationsResponse from '../models/responses/getUserNotifications';
import SetUserNotificationResponse from '../models/responses/setUserNotification';
import SubscribeResponse from '../models/responses/subscribe';
import UnsubscribeResponse from '../models/responses/unsubscribe';
import UpdateUserResponse from '../models/responses/updateUser';
import baseService from './base';
import endpoints from './endpoints';

export type UserService = {
  getUser: (params: GetUserParams) => Promise<GetUserResponse>;
  updateUser: (request: UpdateUserRequest) => Promise<UpdateUserResponse>;
  changePassword: (
    request: ChangePasswordRequest,
  ) => Promise<ChangePasswordResponse>;
  getUserNotifications: (
    params: GetUserNotificationsParams,
  ) => Promise<GetUserNotificationsResponse>;
  setUserNotification: (
    request: SetUserNotificationRequest,
  ) => Promise<SetUserNotificationResponse>;
  subscribe: (request: SubscribeRequest) => Promise<SubscribeResponse>;
  unsubscribe: (request: UnsubscribeRequest) => Promise<UnsubscribeResponse>;
};

const userService: UserService = {
  getUser: async function (params: GetUserParams): Promise<GetUserResponse> {
    const {userId} = params;
    return baseService
      .get<null, GetUserResponse>(`${endpoints.users}/${userId}`)
      .then((result) => result.data);
  },
  updateUser: async function (
    request: UpdateUserRequest,
  ): Promise<UpdateUserResponse> {
    const {id: userId} = request;
    return baseService
      .put<UpdateUserRequest, UpdateUserResponse>(
        `${endpoints.users}/${userId}`,
        request,
      )
      .then((result) => result.data);
  },
  changePassword: async function (
    request: ChangePasswordRequest,
  ): Promise<ChangePasswordResponse> {
    const {id: userId} = request;
    return baseService
      .put<ChangePasswordRequest, ChangePasswordResponse>(
        `${endpoints.users}/${userId}/password`,
        request,
      )
      .then((result) => result.data);
  },
  getUserNotifications: async function (
    params: GetUserNotificationsParams,
  ): Promise<GetUserNotificationsResponse> {
    const {userId} = params;
    return baseService
      .get<GetUserNotificationsParams, GetUserNotificationsResponse>(
        `${endpoints.users}/${userId}/notifications`,
        params,
      )
      .then((result) => result.data);
  },
  setUserNotification: async function (
    request: SetUserNotificationRequest,
  ): Promise<UserNotification> {
    const {userId, notificationId} = request;
    return baseService
      .put<SetUserNotificationRequest, SetUserNotificationResponse>(
        `${endpoints.users}/${userId}/notifications/${notificationId}`,
        request,
      )
      .then((result) => result.data);
  },
  subscribe: async function (
    request: SubscribeRequest,
  ): Promise<SubscribeResponse> {
    const {userId} = request;
    return baseService
      .post<SubscribeRequest, SubscribeResponse>(
        `${endpoints.users}/${userId}/notifications/subscribe`,
        request,
      )
      .then((result) => result.data);
  },
  unsubscribe: async function (
    request: UnsubscribeRequest,
  ): Promise<UnsubscribeResponse> {
    return baseService
      .post<UnsubscribeRequest, UnsubscribeResponse>(
        `${endpoints.users}/${request.userId}/notifications/unsubscribe`,
        request,
      )
      .then((result) => result.data);
  },
};

export default userService;
