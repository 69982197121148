import localStorageUtils from '../utils/localStorage';
import en from './en.json';
import pt from './pt.json';

export enum Language {
  en = 'en',
  pt = 'pt',
}

export type LocalizationKey = keyof typeof en;

const fallbackLanguage: Language = Language.en;

export const languages = [
  {language: Language.en, name: () => locals.getText(Language.en)},
  {language: Language.pt, name: () => locals.getText(Language.pt)},
];

const locals = {
  init: function () {
    try {
      const language = localStorageUtils.getValue<Language>(
        'language',
        fallbackLanguage,
      );
      localStorageUtils.setValue('language', language);
    } catch (error) {}
  },
  getLanguage: function () {
    return localStorageUtils.getValue<Language>('language', fallbackLanguage);
  },
  setLanguage: function (language: Language) {
    localStorageUtils.setValue('language', language);
  },
  getText: function (key: LocalizationKey) {
    const language = this.getLanguage();
    switch (language) {
      case Language.en:
        return en[key];
      case Language.pt:
      default:
        return pt[key];
    }
  },
  tryGetText: function (key: string) {
    if (key in en) {
      return this.getText(key as LocalizationKey);
    }
    return '';
  },
};

locals.init();

export default locals;
