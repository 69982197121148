import {Box, List, ListItem, Typography} from '@mui/material';
import {useMemo, useState} from 'react';
import locals from '../../localization/locals';
import Oven from '../../models/entities/oven';
import OvenGroup from '../../models/entities/ovenGroup';
import {OvenModelId} from '../../models/entities/ovenModel';
import GradientOverflow from '../common/GradientOverflow';
import Switch from '../common/Switch';
import {pageHeight} from '../navigation/Navbar';
import {OvenPanelData} from './CreateRecipeSchedule';

type OvenGroupListItemProps = {
  ovenGroup: OvenGroup;
  selectedOvenPanelIds: string[];
  onSelectOvenPanel: (ovenPanel: OvenPanelData) => void;
  isCleaning?: boolean;
};

function OvenGroupListItem(props: OvenGroupListItemProps) {
  const {ovenGroup, selectedOvenPanelIds, onSelectOvenPanel, isCleaning} =
    props;

  const [hoveredOvenId, setHoveredOvenId] = useState<string | null>(null);

  const ovens = useMemo(
    () =>
      Array.from(ovenGroup.ovens ?? []).sort(
        (ovenA, ovenB) =>
          (ovenA.ovenGroupOrder ?? 0) - (ovenB.ovenGroupOrder ?? 0),
      ),
    [ovenGroup],
  );

  const selectedFirstId = selectedOvenPanelIds.find((id) =>
    ovens.some((oven) =>
      oven.ovenPanels?.some((ovenPanel) => ovenPanel.id === id),
    ),
  );
  const selectedFirstOrder =
    ovens.find((oven) => oven.ovenPanels?.[0]?.id === selectedFirstId)
      ?.ovenGroupOrder ?? 0;
  const selectedAll = ovens.every((oven) =>
    selectedOvenPanelIds.includes(oven.ovenPanels?.[0]?.id ?? ''),
  );

  return (
    <ListItem sx={{margin: 0, padding: 2, color: 'text.primary'}}>
      <Box sx={{width: '100%'}}>
        <Typography variant="body2" sx={{fontWeight: 'bold'}}>
          {ovenGroup.description}
        </Typography>
        {ovens.map((oven) => {
          const ovenPanel = oven.ovenPanels?.[0];
          if (ovenPanel == null) return null;
          return (
            <Box
              key={oven.id}
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                paddingBlock: '2px',
                color:
                  hoveredOvenId === oven.id ? 'primary.main' : 'text.primary',
                cursor: 'pointer',
              }}
              onMouseEnter={() => setHoveredOvenId(oven.id)}
              onMouseLeave={() => setHoveredOvenId(null)}
              onClick={() =>
                onSelectOvenPanel({
                  id: ovenPanel.id,
                  ovenChamberId: ovenPanel.ovenChamberId,
                  ovenId: oven.id,
                  ovenGroupId: ovenGroup.id,
                })
              }>
              <Typography variant="body2">
                {`${locals.getText('programming_oven_serial_number_label')} ${
                  (oven.ovenGroupOrder ?? 0) + 1
                }: ${oven.serialNumber}`}
              </Typography>
              <Switch
                size="small"
                checked={selectedOvenPanelIds.includes(ovenPanel.id)}
              />
            </Box>
          );
        })}
        {isCleaning && (
          <Box sx={{marginTop: 1}}>
            <Typography
              component="p"
              variant="caption"
              sx={{color: 'text.secondary'}}>
              {locals.getText('turboram_combi_cleaning_warning')}
            </Typography>
            {selectedAll && (
              <Typography
                component="p"
                variant="caption"
                sx={{color: 'primary.main'}}>
                {locals.getText(
                  selectedFirstOrder === 0
                    ? 'turboram_combi_cleaning_top_oven_first_warning'
                    : 'turboram_combi_cleaning_base_oven_first_warning',
                )}
              </Typography>
            )}
          </Box>
        )}
      </Box>
    </ListItem>
  );
}

type ChamberOvenListItemProps = {
  oven: Oven;
  selectedOvenPanelIds: string[];
  onSelectOvenPanel: (ovenPanel: OvenPanelData) => void;
};

function ChamberOvenListItem(props: ChamberOvenListItemProps) {
  const {oven, selectedOvenPanelIds, onSelectOvenPanel} = props;

  const [hoveredOvenChamberId, setHoveredOvenChamberId] = useState<
    string | null
  >(null);

  const ovenChambers = useMemo(
    () =>
      Array.from(oven.ovenChambers ?? []).sort(
        (ovenChamberA, ovenChamberB) =>
          (ovenChamberA.ovenOrder ?? 0) - (ovenChamberB.ovenOrder ?? 0),
      ),
    [oven],
  );

  return (
    <ListItem sx={{margin: 0, padding: 2, color: 'text.primary'}}>
      <Box sx={{width: '100%'}}>
        <Typography variant="body2" sx={{fontWeight: 'bold'}}>
          {oven.description}
        </Typography>
        {ovenChambers.map((ovenChamber) => {
          const ovenPanel = ovenChamber.ovenPanels?.[0];
          if (ovenPanel == null) return null;
          return (
            <Box
              key={ovenChamber.id}
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                padding: '2px',
                color:
                  hoveredOvenChamberId === ovenChamber.id
                    ? 'primary.main'
                    : 'text.primary',
                cursor: 'pointer',
              }}
              onMouseEnter={() => setHoveredOvenChamberId(ovenChamber.id)}
              onMouseLeave={() => setHoveredOvenChamberId(null)}
              onClick={() =>
                onSelectOvenPanel({
                  id: ovenPanel.id,
                  ovenChamberId: ovenChamber.id,
                  ovenId: oven.id,
                })
              }>
              <Typography variant="body2">
                {`${locals.getText(
                  'programming_chamber_serial_number_label',
                )} ${(ovenChamber.ovenOrder ?? 0) + 1}: ${
                  ovenChamber.serialNumber
                }`}
              </Typography>
              <Switch
                size="small"
                checked={selectedOvenPanelIds.includes(ovenPanel.id)}
              />
            </Box>
          );
        })}
      </Box>
    </ListItem>
  );
}

type OvenListItemProps = {
  oven: Oven;
  selectedOvenPanelIds: string[];
  onSelectOvenPanel: (ovenPanel: OvenPanelData) => void;
};

function OvenListItem(props: OvenListItemProps) {
  const {oven, selectedOvenPanelIds, onSelectOvenPanel} = props;

  const [hoveredOvenPanelId, setHoveredOvenPanelId] = useState<string | null>(
    null,
  );

  const ovenPanels = useMemo(
    () =>
      Array.from(oven.ovenPanels ?? []).sort(
        (ovenPanelA, ovenPanelB) =>
          (ovenPanelA.ovenOrder ?? 0) - (ovenPanelB.ovenOrder ?? 0),
      ),
    [oven],
  );

  if (ovenPanels.length === 1) {
    const ovenPanel = ovenPanels[0];
    return (
      <ListItem
        sx={{
          margin: 0,
          padding: 2,
          color:
            hoveredOvenPanelId === ovenPanel.id
              ? 'primary.main'
              : 'text.primary',
          cursor: 'pointer',
        }}
        onMouseEnter={() => setHoveredOvenPanelId(ovenPanel.id)}
        onMouseLeave={() => setHoveredOvenPanelId(null)}
        onClick={() =>
          onSelectOvenPanel({
            id: ovenPanel.id,
            ovenChamberId: ovenPanel.ovenChamberId,
            ovenId: oven.id,
          })
        }>
        <Box sx={{width: '100%'}}>
          <Typography variant="body2" sx={{fontWeight: 'bold'}}>
            {oven.description}
          </Typography>
          {ovenPanels.map((ovenPanel) => (
            <Box
              key={ovenPanel.id}
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                padding: '2px',
              }}>
              <Typography variant="body2">
                {ovenPanels.length === 1
                  ? `${locals.getText('programming_serial_number_label')}: ${
                      oven.serialNumber
                    }`
                  : `${locals.getText('programming_chamber_label')} ${
                      (ovenPanel.ovenOrder ?? 0) + 1
                    }`}
              </Typography>
              <Switch
                size="small"
                checked={selectedOvenPanelIds.includes(ovenPanel.id)}
              />
            </Box>
          ))}
        </Box>
      </ListItem>
    );
  }

  return (
    <ListItem sx={{margin: 0, padding: 2, color: 'text.primary'}}>
      <Box sx={{width: '100%'}}>
        <Typography variant="body2" sx={{fontWeight: 'bold'}}>
          {oven.description}
        </Typography>
        {ovenPanels.map((ovenPanel) => (
          <Box
            key={ovenPanel.id}
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              padding: '2px',
              color:
                hoveredOvenPanelId === ovenPanel.id
                  ? 'primary.main'
                  : 'text.primary',
              cursor: 'pointer',
            }}
            onMouseEnter={() => setHoveredOvenPanelId(ovenPanel.id)}
            onMouseLeave={() => setHoveredOvenPanelId(null)}
            onClick={() =>
              onSelectOvenPanel({
                id: ovenPanel.id,
                ovenChamberId: ovenPanel.ovenChamberId,
                ovenId: oven.id,
              })
            }>
            <Typography variant="body2">
              {ovenPanels.length === 1
                ? `${locals.getText('programming_serial_number_label')}: ${
                    oven.serialNumber
                  }`
                : `${locals.getText('programming_chamber_label')} ${
                    (ovenPanel.ovenOrder ?? 0) + 1
                  }`}
            </Typography>
            <Switch
              size="small"
              checked={selectedOvenPanelIds.includes(ovenPanel.id)}
            />
          </Box>
        ))}
      </Box>
    </ListItem>
  );
}

type OvenListProps = {
  ovenGroups: OvenGroup[];
  ovens: Oven[];
  selectedOvenPanelIds: string[];
  onSelectOvenPanel: (ovenPanel: OvenPanelData) => void;
  isCleaning?: boolean;
  ovensNotFoundMessage?: string;
};

function OvenList(props: OvenListProps) {
  const {
    ovenGroups,
    ovens,
    selectedOvenPanelIds,
    onSelectOvenPanel,
    isCleaning,
    ovensNotFoundMessage,
  } = props;

  return (
    <Box
      sx={{
        width: '65vw',
        height: `calc(${pageHeight} - 112px)`,
        paddingBottom: 6,
      }}>
      <GradientOverflow hideScrollbar>
        <List sx={{margin: 0, padding: 0}}>
          {ovens.map((oven) =>
            oven.ovenModelId === OvenModelId.Modulram ? (
              <ChamberOvenListItem
                key={oven.id}
                oven={oven}
                selectedOvenPanelIds={selectedOvenPanelIds}
                onSelectOvenPanel={onSelectOvenPanel}
              />
            ) : (
              <OvenListItem
                key={oven.id}
                oven={oven}
                selectedOvenPanelIds={selectedOvenPanelIds}
                onSelectOvenPanel={onSelectOvenPanel}
              />
            ),
          )}
          {ovenGroups.map((ovenGroup) => (
            <OvenGroupListItem
              key={ovenGroup.id}
              ovenGroup={ovenGroup}
              selectedOvenPanelIds={selectedOvenPanelIds}
              onSelectOvenPanel={onSelectOvenPanel}
              isCleaning={isCleaning}
            />
          ))}
          {ovens.length === 0 && ovenGroups.length === 0 && (
            <ListItem sx={{margin: 0, padding: 2}}>
              <Typography variant="body2" sx={{color: 'text.primary'}}>
                {ovensNotFoundMessage}
              </Typography>
            </ListItem>
          )}
        </List>
      </GradientOverflow>
    </Box>
  );
}

export default OvenList;
