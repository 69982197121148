import {Box, List, ListItem, Typography} from '@mui/material';
import {useRef, useState} from 'react';
import {useMutation, useQueryClient} from 'react-query';
import {ReactComponent as AddIcon} from '../../assets/icons/add.svg';
import {ReactComponent as DeleteIcon} from '../../assets/icons/delete.svg';
import {ReactComponent as EditIcon} from '../../assets/icons/edit.svg';
import locals from '../../localization/locals';
import Bakery from '../../models/entities/bakery';
import City from '../../models/entities/city';
import Country from '../../models/entities/country';
import services from '../../services/provider';
import stringUtils from '../../utils/strings';
import ConfirmPopover from '../common/ConfirmPopover';
import IconButton from '../common/IconButton';

function UpdateNameContainer({
  defaultName,
  onUpdateName,
}: {
  defaultName: string;
  onUpdateName: (name: string) => void;
}) {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const [name, setName] = useState(defaultName);

  const containerRef = useRef<HTMLDivElement | null>(null);

  function handleUpdateName() {
    if (!stringUtils.isNullOrWhiteSpace(name) && name !== defaultName) {
      onUpdateName(name);
    }
    setAnchorEl(null);
  }

  const open = Boolean(anchorEl);

  return (
    <Box
      ref={containerRef}
      sx={{display: 'flex', justifyContent: 'center', width: '15vw'}}>
      <IconButton
        sx={{zIndex: open ? 1500 : undefined}}
        IconComponent={EditIcon}
        isActive={open}
        onClick={() => {
          setName(defaultName);
          setAnchorEl(open ? null : containerRef.current);
        }}
      />
      <ConfirmPopover
        anchorEl={anchorEl}
        placeHolder={locals.getText('recipe_add_recipe_type_label')}
        value={name}
        onChange={setName}
        onAccept={handleUpdateName}
        onDecline={() => {
          setName(defaultName);
          setAnchorEl(null);
        }}
      />
    </Box>
  );
}

export type BakerySettingsProps = {
  selectedCountry: Country;
  selectedCity: City;
  selectedBakery: Bakery;
};

function BakerySettings(props: BakerySettingsProps) {
  const {selectedCountry, selectedCity, selectedBakery} = props;

  const queryClient = useQueryClient();

  const {mutate: updateBakery} = useMutation({
    mutationFn: services.bakery.updateBakery,
    onMutate: async (request) => {
      await queryClient.cancelQueries('bakeriesLocations');
      const previousData = queryClient.getQueryData<Country[]>([
        'bakeriesLocations',
      ]);
      queryClient.setQueryData<Country[] | undefined>(
        ['bakeriesLocations'],
        (countries) =>
          countries?.map((country) => ({
            ...country,
            cities: country.cities?.map((city) => ({
              ...city,
              bakeries: city.bakeries?.map((bakery) => ({
                ...bakery,
                name:
                  bakery.id === selectedBakery.id
                    ? request.name ?? bakery.name
                    : bakery.name,
              })),
            })),
          })),
      );
      return {previousData};
    },
    onError: (error, request, context) => {
      queryClient.setQueryData(['bakeriesLocations'], context?.previousData);
    },
    onSuccess: () => {
      queryClient.invalidateQueries('bakeriesLocations');
    },
  });

  function handleUpdateName(name: string) {
    updateBakery({id: selectedBakery.id, name});
  }

  return (
    <List sx={{margin: 0, padding: 0}}>
      <ListItem
        sx={{
          alignItems: 'center',
          margin: 0,
          padding: 0,
          color: 'text.primary',
        }}>
        <Box sx={{width: '35vw', padding: 2}}>
          <Typography variant="body2" sx={{fontWeight: 'bold'}}>
            {locals.getText('bakery_settings_name_label')}
          </Typography>
        </Box>
        <Box sx={{width: '30vw', textAlign: 'right', padding: 2}}>
          <Typography variant="body2">{selectedBakery.name}</Typography>
        </Box>
        <UpdateNameContainer
          defaultName={selectedBakery.name}
          onUpdateName={handleUpdateName}
        />
      </ListItem>
      <ListItem
        sx={{
          alignItems: 'center',
          margin: 0,
          padding: 0,
          color: 'text.primary',
        }}>
        <Box sx={{width: '35vw', padding: 2}}>
          <Typography variant="body2" sx={{fontWeight: 'bold'}}>
            {locals.getText('bakery_settings_country_label')}
          </Typography>
        </Box>
        <Box sx={{width: '30vw', textAlign: 'right', padding: 2}}>
          <Typography variant="body2">{selectedCountry.name}</Typography>
        </Box>
      </ListItem>
      <ListItem
        sx={{
          alignItems: 'center',
          margin: 0,
          padding: 0,
          color: 'text.primary',
        }}>
        <Box sx={{width: '35vw', padding: 2}}>
          <Typography variant="body2" sx={{fontWeight: 'bold'}}>
            {locals.getText('bakery_settings_city_label')}
          </Typography>
        </Box>
        <Box sx={{width: '30vw', textAlign: 'right', padding: 2}}>
          <Typography variant="body2">{selectedCity.name}</Typography>
        </Box>
      </ListItem>
      <ListItem
        sx={{
          alignItems: 'center',
          margin: 0,
          padding: 0,
          color: 'text.primary',
        }}>
        <Box sx={{width: '35vw', padding: 2}}>
          <Typography variant="body2" sx={{fontWeight: 'bold'}}>
            {locals.getText('bakery_settings_add_oven_label')}
          </Typography>
        </Box>
        <Box sx={{width: '30vw'}} />
        <Box sx={{width: '15vw', textAlign: 'center', padding: 1}}>
          <IconButton IconComponent={AddIcon} disabled />
        </Box>
      </ListItem>
      <ListItem
        sx={{
          alignItems: 'center',
          margin: 0,
          padding: 0,
          color: 'text.primary',
        }}>
        <Box sx={{width: '35vw', padding: 2}}>
          <Typography variant="body2" sx={{fontWeight: 'bold'}}>
            {locals.getText('bakery_settings_delete_bakery_label')}
          </Typography>
        </Box>
        <Box sx={{width: '30vw'}} />
        <Box sx={{width: '15vw', textAlign: 'center', padding: 1}}>
          <IconButton IconComponent={DeleteIcon} disabled />
        </Box>
      </ListItem>
    </List>
  );
}

export default BakerySettings;
