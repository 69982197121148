import {
  Box,
  InputBase,
  List,
  ListItem,
  Paper,
  SvgIcon,
  Typography,
} from '@mui/material';
import {Fragment, useEffect, useState} from 'react';
import {useQuery} from 'react-query';
import {ReactComponent as SearchIcon} from '../../../../assets/icons/search.svg';
import locals from '../../../../localization/locals';
import City from '../../../../models/entities/city';
import Country from '../../../../models/entities/country';
import services from '../../../../services/provider';
import colors from '../../../../themes/colors';
import arrayUtils from '../../../../utils/arrays';
import stringUtils from '../../../../utils/strings';
import ActionBanner from '../../../common/ActionBanner';
import GradientOverflow from '../../../common/GradientOverflow';
import LoadingBackdrop from '../../../common/LoadingBackdrop';
import Switch from '../../../common/Switch';
import Tabs from '../../../common/Tabs';
import {pageHeight} from '../../../navigation/Navbar';
import {RecipeBuild} from '../RecipeSettings';

function isCountrySelected(country: Country, selectedBakeryIds: Set<string>) {
  for (let city of country.cities ?? []) {
    for (let bakery of city.bakeries ?? []) {
      if (!selectedBakeryIds.has(bakery.id)) {
        return false;
      }
    }
  }
  return true;
}

function isCitySelected(city: City, selectedBakeryIds: Set<string>) {
  for (let bakery of city.bakeries ?? []) {
    if (!selectedBakeryIds.has(bakery.id)) {
      return false;
    }
  }
  return true;
}

function getTotalSelectedBakeriesFromCity(
  city: City,
  selectedBakeryIds: Set<string>,
) {
  return arrayUtils.count(city.bakeries ?? [], (bakery) =>
    selectedBakeryIds.has(bakery.id),
  );
}

type LocationStepProps = {
  recipeBuild: RecipeBuild;
  setRecipeBuild: (setter: (previousValue: RecipeBuild) => RecipeBuild) => void;
};

function LocationStep(props: LocationStepProps) {
  const {recipeBuild, setRecipeBuild} = props;
  const ovenModelId = recipeBuild.ovenModelId;
  const selectedBakeryIds = new Set(recipeBuild.bakeryIds);

  const [searchText, setSearchText] = useState('');
  const [selectedCountry, setSelectedCountry] = useState<Country | null>(null);
  const [selectedCity, setSelectedCity] = useState<City | null>(null);

  const [hoveredItemIndex, setHoveredItemIndex] = useState(-1);

  const {data: countries = [], isLoading} = useQuery({
    queryKey: ['bakeriesLocations', {ovenModelId}],
    queryFn: () => services.bakery.getBakeriesLocations({ovenModelId}),
  });

  useEffect(() => {
    if (selectedCountry == null) {
      setSelectedCountry(countries.length > 0 ? countries[0] : null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [countries]);

  const selectedTabIndex = countries.findIndex(
    (country) => country.id === selectedCountry?.id,
  );

  function handleSelectCountry(countryIndex: number) {
    setSelectedCountry(countries[countryIndex]);
    setSelectedCity(null);
  }

  function handleSelectAll(checked: boolean) {
    if (selectedCountry != null && selectedCity == null) {
      selectedCountry.cities?.forEach((city) => {
        city.bakeries?.forEach((bakery) => {
          if (checked) {
            selectedBakeryIds.add(bakery.id);
          } else {
            selectedBakeryIds.delete(bakery.id);
          }
        });
      });
    }
    if (selectedCity != null) {
      selectedCity.bakeries?.forEach((bakery) => {
        if (checked) {
          selectedBakeryIds.add(bakery.id);
        } else {
          selectedBakeryIds.delete(bakery.id);
        }
      });
    }
    setRecipeBuild((recipeBuild) => ({
      ...recipeBuild,
      bakeryIds: Array.from(selectedBakeryIds),
    }));
  }

  function handleSelectCity(cityId: number, checked: boolean) {
    selectedCountry?.cities?.forEach((city) => {
      if (city.id === cityId) {
        city.bakeries?.forEach((bakery) => {
          if (checked) {
            selectedBakeryIds.add(bakery.id);
          } else {
            selectedBakeryIds.delete(bakery.id);
          }
        });
      }
    });
    setRecipeBuild((recipeBuild) => ({
      ...recipeBuild,
      bakeryIds: Array.from(selectedBakeryIds),
    }));
  }

  function handleSelectBakery(bakeryId: string, checked: boolean) {
    if (checked) {
      selectedBakeryIds.add(bakeryId);
    } else {
      selectedBakeryIds.delete(bakeryId);
    }
    setRecipeBuild((recipeBuild) => ({
      ...recipeBuild,
      bakeryIds: Array.from(selectedBakeryIds),
    }));
  }

  function renderCitiesList() {
    const filteredCities =
      selectedCountry?.cities?.filter(
        (city) =>
          stringUtils.unicodeStartsWith(city.name, searchText) ||
          stringUtils.unicodeIncludes(city.name, ` ${searchText}`),
      ) ?? [];

    return (
      <List sx={{margin: 0, padding: 0}}>
        {selectedCountry != null && filteredCities.length === 0 && (
          <ListItem sx={{margin: 0, padding: 0}}>
            <Typography
              variant="body2"
              sx={{color: 'text.primary', padding: 2}}>
              {locals.getText(
                'recipe_settings_location_step_cities_not_found_label',
              )}
            </Typography>
          </ListItem>
        )}
        {filteredCities.map((city, index) => (
          <ListItem
            key={city.id}
            sx={{margin: 0, padding: 0, cursor: 'pointer'}}>
            <Box
              sx={{display: 'flex', alignItems: 'center'}}
              onMouseEnter={() => setHoveredItemIndex(index)}
              onMouseLeave={() => setHoveredItemIndex(-1)}
              onClick={() => setSelectedCity(city)}>
              <Box sx={{width: '35vw', padding: 2}}>
                <Typography
                  variant="body2"
                  sx={{
                    fontWeight: hoveredItemIndex === index ? 'bold' : 'normal',
                    color:
                      hoveredItemIndex === index
                        ? 'primary.main'
                        : 'secondary.main',
                  }}>
                  {city.name}
                </Typography>
              </Box>
              <Box sx={{width: '30vw', textAlign: 'end', padding: 2}}>
                <Typography
                  variant="body2"
                  sx={{
                    color:
                      hoveredItemIndex === index
                        ? 'primary.main'
                        : 'secondary.main',
                  }}>
                  {getTotalSelectedBakeriesFromCity(city, selectedBakeryIds) > 0
                    ? `${getTotalSelectedBakeriesFromCity(
                        city,
                        selectedBakeryIds,
                      )}/${city.bakeries?.length ?? 0} ${locals.getText(
                        'recipe_settings_location_step_bakeries_label_secondary',
                      )}`
                    : `${city.bakeries?.length ?? 0} ${locals.getText(
                        'recipe_settings_location_step_bakeries_label',
                      )}`}
                </Typography>
              </Box>
            </Box>
            <Box sx={{width: '15vw', textAlign: 'center', padding: 2}}>
              <Switch
                checked={
                  getTotalSelectedBakeriesFromCity(city, selectedBakeryIds) ===
                  (city.bakeries?.length ?? 0)
                }
                onChange={(_, checked) => handleSelectCity(city.id, checked)}
              />
            </Box>
          </ListItem>
        ))}
      </List>
    );
  }

  function renderBakeriesList() {
    const filteredBakeries =
      selectedCity?.bakeries?.filter(
        (bakery) =>
          stringUtils.unicodeStartsWith(bakery.name, searchText) ||
          stringUtils.unicodeIncludes(bakery.name, ` ${searchText}`),
      ) ?? [];

    return (
      <List sx={{margin: 0, padding: 0}}>
        {selectedCity != null && filteredBakeries.length === 0 && (
          <ListItem sx={{margin: 0, padding: 0}}>
            <Typography
              variant="body2"
              sx={{color: 'text.primary', padding: 2}}>
              {locals.getText(
                'recipe_settings_location_step_bakeries_not_found_label',
              )}
            </Typography>
          </ListItem>
        )}
        {filteredBakeries.map((bakery) => (
          <ListItem key={bakery.id} sx={{margin: 0, padding: 0}}>
            <Box sx={{width: '65vw', padding: 2}}>
              <Typography variant="body2" sx={{color: 'text.primary'}}>
                {bakery.name}
              </Typography>
            </Box>
            <Box sx={{width: '15vw', textAlign: 'center', padding: 2}}>
              <Switch
                checked={selectedBakeryIds.has(bakery.id)}
                onChange={(_, checked) =>
                  handleSelectBakery(bakery.id, checked)
                }
              />
            </Box>
          </ListItem>
        ))}
      </List>
    );
  }

  return (
    <Box sx={{width: '80vw', height: pageHeight}}>
      <Box sx={{width: '65vw'}}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            height: '112px',
          }}>
          <Tabs
            values={countries.map((country) => country.name)}
            selectedTabIndex={selectedTabIndex >= 0 ? selectedTabIndex : 0}
            onTabClick={handleSelectCountry}
          />
          <Paper
            elevation={4}
            sx={{
              flex: 1,
              display: 'flex',
              alignItems: 'center',
              minHeight: '36px',
              marginBlock: 2,
              paddingInline: 2,
              borderRadius: '10px',
              backgroundColor: '#F4F4F4',
            }}>
            {selectedCity != null && (
              <Fragment>
                <Typography
                  variant="body2"
                  sx={{fontWeight: 'bold', cursor: 'pointer'}}
                  onClick={() => setSelectedCity(null)}>
                  {selectedCity.name}
                </Typography>
                <Typography variant="body2" sx={{marginInline: '5px'}}>
                  |
                </Typography>
              </Fragment>
            )}
            <InputBase
              sx={{flex: 1, height: '36px', fontSize: '0.875rem'}}
              placeholder={locals.getText('bakeries_search_field_placeholder')}
              value={searchText}
              onChange={(event) => setSearchText(event.target.value)}
            />
            <SvgIcon
              component={SearchIcon}
              inheritViewBox
              sx={{fontSize: '20px', color: colors.grey}}
            />
          </Paper>
        </Box>
        <Box sx={{marginBlock: 2}}>
          <ActionBanner
            text={locals.getText(
              'recipe_settings_location_step_select_all_label',
            )}
            actionType={'switch'}
            checked={
              selectedCity != null
                ? isCitySelected(selectedCity, selectedBakeryIds)
                : selectedCountry != null
                ? isCountrySelected(selectedCountry, selectedBakeryIds)
                : false
            }
            onSwitch={handleSelectAll}
          />
        </Box>
      </Box>
      <Box sx={{height: `calc(${pageHeight} - 196px)`}}>
        <GradientOverflow hideScrollbar>
          {selectedCity != null ? renderBakeriesList() : renderCitiesList()}
        </GradientOverflow>
      </Box>
      <LoadingBackdrop loading={isLoading} />
    </Box>
  );
}

export default LocationStep;
