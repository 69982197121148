import {Box, List, ListItem, Typography} from '@mui/material';
import {useState} from 'react';
import {useMutation, useQuery, useQueryClient} from 'react-query';
import {OvenModelId} from '../../models/entities/ovenModel';
import UserNotification from '../../models/entities/userNotification';
import services from '../../services/provider';
import useAuthStore from '../../state/auth';
import ovenPanelNotificationUtils from '../../utils/notifications';
import OvenModelList from '../bakeries/OvenModelList';
import GradientOverflow from '../common/GradientOverflow';
import LoadingBackdrop from '../common/LoadingBackdrop';
import Switch from '../common/Switch';
import {pageHeight} from '../navigation/Navbar';

function NotificationSettings() {
  const queryClient = useQueryClient();

  const user = useAuthStore((state) => state.user);

  const [selectedOvenModelId, setSelectedOvenModelId] = useState(
    OvenModelId.Turboram,
  );

  const {data: ovenModels, isLoading: loadingOvenModels} = useQuery({
    queryKey: ['ovenModels'],
    queryFn: () => services.ovenModel.getOvenModels({}),
  });

  const {data: userNotifications, isLoading: loadingUserNotifications} =
    useQuery({
      enabled: user != null,
      queryKey: ['userNotifications'],
      queryFn: () => services.user.getUserNotifications({userId: user!.id}),
    });

  const {mutate: setUserNotification} = useMutation({
    mutationFn: services.user.setUserNotification,
    onMutate: async (request) => {
      await queryClient.cancelQueries('userNotifications');
      const previousUserNotifications = queryClient.getQueryData<
        UserNotification[]
      >(['userNotifications']);
      queryClient.setQueryData<UserNotification[] | undefined>(
        ['userNotifications'],
        (userNotifications) =>
          userNotifications?.map((userNotification) => ({
            ...userNotification,
            notify:
              userNotification.notificationId === request.notificationId
                ? request.notify
                : userNotification.notify,
          })),
      );
      return {previousUserNotifications};
    },
    onError: (error, request, context) => {
      queryClient.setQueryData(
        ['userNotifications'],
        context?.previousUserNotifications,
      );
    },
    onSuccess: () => {
      queryClient.invalidateQueries('totalUnseenOvenPanelNotifications');
      queryClient.removeQueries('ovenPanelNotifications');
    },
    onSettled: () => {
      queryClient.invalidateQueries('userNotifications');
    },
  });

  function handleSetUserNotification(notificationId: number, checked: boolean) {
    if (user != null) {
      setUserNotification({
        userId: user.id,
        notificationId,
        notify: checked,
      });
    }
  }

  const loading = loadingOvenModels || loadingUserNotifications;

  return (
    <Box
      sx={{
        display: 'flex',
        width: '65vw',
        height: `calc(${pageHeight} - 112px)`,
      }}>
      <Box
        sx={{
          width: '150px',
          height: `calc(${pageHeight} - 136px)`,
          paddingBlock: 2,
        }}>
        <GradientOverflow>
          <OvenModelList
            ovenModels={
              ovenModels?.filter(
                (ovenModel) => ovenModel.id === OvenModelId.Turboram,
              ) ?? []
            }
            selectedOvenModelId={selectedOvenModelId}
            onSelectOvenModel={setSelectedOvenModelId}
          />
        </GradientOverflow>
      </Box>
      <Box sx={{height: `calc(${pageHeight} - 180px)`, paddingLeft: 2}}>
        <GradientOverflow hideScrollbar>
          <List sx={{alignItems: 'center', margin: 0, padding: 0}}>
            {userNotifications
              ?.filter(
                (userNotification) =>
                  userNotification.ovenModelId === selectedOvenModelId,
              )
              .map((userNotification) => (
                <ListItem
                  key={userNotification.notificationId}
                  sx={{margin: 0, padding: 0}}>
                  <Box sx={{width: 'calc(40vw - 75px)', padding: 2}}>
                    <Typography variant="body2" sx={{color: 'text.primary'}}>
                      {ovenPanelNotificationUtils.getSettingsLabel(
                        userNotification.parameter,
                      )}
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      width: 'calc(25vw - 75px)',
                      textAlign: 'right',
                      padding: 2,
                    }}>
                    <Switch
                      checked={userNotification.notify}
                      onChange={(_, checked) =>
                        handleSetUserNotification(
                          userNotification.notificationId,
                          checked,
                        )
                      }
                    />
                  </Box>
                </ListItem>
              ))}
          </List>
        </GradientOverflow>
      </Box>
      <LoadingBackdrop loading={loading} />
    </Box>
  );
}

export default NotificationSettings;
