import {Box} from '@mui/material';
import Country from '../../models/entities/country';
import Tabs from '../common/Tabs';

export type CountryTabsProps = {
  countries: Country[];
  selectedCountryId: number | null;
  onSelectCountry: (countryId: number) => void;
};

function CountryTabs(props: CountryTabsProps) {
  const {countries, selectedCountryId, onSelectCountry} = props;

  const selectedCountryIndex = countries.findIndex(
    (country) => country.id === selectedCountryId,
  );

  function handleSelectCountry(countryIndex: number) {
    onSelectCountry(countries[countryIndex].id);
  }

  return (
    <Box>
      <Tabs
        values={countries.map((country) => country.name)}
        selectedTabIndex={selectedCountryIndex >= 0 ? selectedCountryIndex : 0}
        onTabClick={handleSelectCountry}
      />
    </Box>
  );
}

export default CountryTabs;
