import DeleteOvenPanelNotificationParams from '../models/requests/deleteOvenPanelNotification';
import DeleteOvenPanelNotificationsRequest from '../models/requests/deleteOvenPanelNotifications';
import UpdateOvenPanelNotificationRequest from '../models/requests/updateOvenPanelNotification';
import DeleteOvenPanelNotificationResponse from '../models/responses/deleteOvenPanelNotification';
import DeleteOvenPanelNotificationsResponse from '../models/responses/deleteOvenPanelNotifications';
import GetOvenPanelNotificationsResponse from '../models/responses/getOvenPanelNotifications';
import GetTotalUnseenOvenPanelNotificationsResponse from '../models/responses/getTotalUnseenOvenPanelNotifications';
import UpdateOvenPanelNotificationResponse from '../models/responses/updateOvenPanelNotification';
import baseService from './base';
import endpoints from './endpoints';

export type OvenPanelNotificationService = {
  getOvenPanelNotifications: () => Promise<GetOvenPanelNotificationsResponse>;
  getTotalUnseenOvenPanelNotifications: () => Promise<GetTotalUnseenOvenPanelNotificationsResponse>;
  updateOvenPanelNotification: (
    request: UpdateOvenPanelNotificationRequest,
  ) => Promise<UpdateOvenPanelNotificationResponse>;
  deleteOvenPanelNotifications: (
    request: DeleteOvenPanelNotificationsRequest,
  ) => Promise<DeleteOvenPanelNotificationsResponse>;
  deleteOvenPanelNotification: (
    params: DeleteOvenPanelNotificationParams,
  ) => Promise<DeleteOvenPanelNotificationResponse>;
};

const ovenPanelNotificationService: OvenPanelNotificationService = {
  getOvenPanelNotifications:
    async function (): Promise<GetOvenPanelNotificationsResponse> {
      return baseService
        .get<null, GetOvenPanelNotificationsResponse>(
          endpoints.ovenPanelNotifications,
        )
        .then((result) => result.data);
    },
  getTotalUnseenOvenPanelNotifications:
    async function (): Promise<GetTotalUnseenOvenPanelNotificationsResponse> {
      return baseService
        .get<null, GetTotalUnseenOvenPanelNotificationsResponse>(
          `${endpoints.ovenPanelNotifications}/unseen`,
        )
        .then((result) => result.data);
    },
  updateOvenPanelNotification: async function (
    request: UpdateOvenPanelNotificationRequest,
  ): Promise<UpdateOvenPanelNotificationResponse> {
    const {id: ovenPanelNotificationId} = request;
    return baseService
      .put<
        UpdateOvenPanelNotificationRequest,
        UpdateOvenPanelNotificationResponse
      >(
        `${endpoints.ovenPanelNotifications}/${ovenPanelNotificationId}`,
        request,
      )
      .then((result) => result.data);
  },
  deleteOvenPanelNotifications: async function (
    request: DeleteOvenPanelNotificationsRequest,
  ): Promise<DeleteOvenPanelNotificationsResponse> {
    return baseService
      .delete<
        null,
        DeleteOvenPanelNotificationsRequest,
        DeleteOvenPanelNotificationsResponse
      >(endpoints.ovenPanelNotifications, null, request)
      .then((result) => result.data);
  },
  deleteOvenPanelNotification: async function (
    params: DeleteOvenPanelNotificationParams,
  ): Promise<DeleteOvenPanelNotificationResponse> {
    const {ovenPanelNotificationId} = params;
    return baseService
      .delete<null, null, DeleteOvenPanelNotificationResponse>(
        `${endpoints.ovenPanelNotifications}/${ovenPanelNotificationId}`,
      )
      .then((result) => result.data);
  },
};

export default ovenPanelNotificationService;
