import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from '@mui/material';
import useErrorStore from '../../state/errors';
import arrayUtils from '../../utils/arrays';
import errorUtils from '../../utils/errors';
import {Button} from './Button';
import locals from '../../localization/locals';

function ErrorModal() {
  const errors = useErrorStore((state) => state.errors);
  const hasErrors = !arrayUtils.isNullOrEmpty(errors);

  return (
    <Dialog PaperProps={{sx: {borderRadius: '10px'}}} open={hasErrors}>
      <DialogTitle sx={{display: 'flex', justifyContent: 'center'}}>
        <Typography variant="body1" color="primary">
          {locals.getText('error_label')}
        </Typography>
      </DialogTitle>
      <DialogContent
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          minWidth: '20vw',
          minHeight: '8vh',
        }}>
        {errors.map((error, index) => (
          <Typography key={index} variant="body2" color="secondary">
            {error}
          </Typography>
        ))}
      </DialogContent>
      <DialogActions sx={{display: 'flex', justifyContent: 'center'}}>
        <Button
          style={{
            paddingBlock: '2px',
            paddingInline: '5px',
            textTransform: 'none',
            borderRadius: '10px',
          }}
          onClick={() => errorUtils.clearErrors()}>
          Ok
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default ErrorModal;
