import CreateRecipeRequest from '../models/requests/createRecipe';
import DeleteRecipeParams from '../models/requests/deleteRecipeParams';
import ExistsRecipeParams from '../models/requests/existsRecipe';
import GetCommonRecipesParams from '../models/requests/getCommonRecipes';
import GetRecipeParams from '../models/requests/getRecipe';
import GetRecipesParams from '../models/requests/getRecipes';
import UpdateRecipeRequest from '../models/requests/updateRecipe';
import CreateRecipeResponse from '../models/responses/createRecipe';
import DeleteRecipeResponse from '../models/responses/deleteRecipe';
import ExistsRecipeResponse from '../models/responses/existsRecipe';
import GetCommonRecipesResponse from '../models/responses/getCommonRecipes';
import GetRecipeResponse from '../models/responses/getRecipe';
import GetRecipesResponse from '../models/responses/getRecipes';
import UpdateRecipeResponse from '../models/responses/updateRecipe';
import baseService from './base';
import endpoints from './endpoints';

export type RecipeService = {
  existsRecipe: (params: ExistsRecipeParams) => Promise<ExistsRecipeResponse>;
  getRecipes: (params: GetRecipesParams) => Promise<GetRecipesResponse>;
  getCommonRecipes: (
    params: GetCommonRecipesParams,
  ) => Promise<GetCommonRecipesResponse>;
  getRecipe: (params: GetRecipeParams) => Promise<GetRecipeResponse>;
  createRecipe: (request: CreateRecipeRequest) => Promise<CreateRecipeResponse>;
  updateRecipe: (request: UpdateRecipeRequest) => Promise<UpdateRecipeResponse>;
  deleteRecipe: (params: DeleteRecipeParams) => Promise<DeleteRecipeResponse>;
};

const recipeService: RecipeService = {
  existsRecipe: async function (params: ExistsRecipeParams): Promise<boolean> {
    return baseService
      .get<ExistsRecipeParams, ExistsRecipeResponse>(
        `${endpoints.recipes}/exists`,
        params,
      )
      .then((response) => response.data);
  },
  getRecipes: async function (
    params: GetRecipesParams,
  ): Promise<GetRecipesResponse> {
    return baseService
      .get<GetRecipesParams, GetRecipesResponse>(endpoints.recipes, params)
      .then((response) => response.data);
  },
  getCommonRecipes: async function (
    params: GetCommonRecipesParams,
  ): Promise<GetCommonRecipesResponse> {
    return baseService
      .get<GetCommonRecipesParams, GetCommonRecipesResponse>(
        `${endpoints.recipes}/common`,
        params,
      )
      .then((response) => response.data);
  },
  getRecipe: async function (
    params: GetRecipeParams,
  ): Promise<GetRecipeResponse> {
    const {recipeId} = params;
    return baseService
      .get<null, GetRecipeResponse>(`${endpoints.recipes}/${recipeId}`)
      .then((response) => response.data);
  },
  createRecipe: async function (
    request: CreateRecipeRequest,
  ): Promise<CreateRecipeResponse> {
    return baseService
      .post<CreateRecipeRequest, CreateRecipeResponse>(
        endpoints.recipes,
        request,
      )
      .then((response) => response.data);
  },
  updateRecipe: async function (
    request: UpdateRecipeRequest,
  ): Promise<UpdateRecipeResponse> {
    const {id: recipeId} = request;
    return baseService
      .put<UpdateRecipeRequest, UpdateRecipeResponse>(
        `${endpoints.recipes}/${recipeId}`,
        request,
      )
      .then((response) => response.data);
  },
  deleteRecipe: async function (
    params: DeleteRecipeParams,
  ): Promise<DeleteRecipeResponse> {
    const {id: recipeId} = params;
    return baseService
      .delete<null, null, DeleteRecipeResponse>(
        `${endpoints.recipes}/${recipeId}`,
      )
      .then((response) => response.data);
  },
};

export default recipeService;
