import {Box, TextField, Typography} from '@mui/material';
import {useState} from 'react';
import {useNavigate} from 'react-router-dom';
import useSplashScreen from '../../hooks/common/useSplashScreen';
import locals from '../../localization/locals';
import paths from '../../routes/paths';
import stringUtils from '../../utils/strings';
import {Button} from '../common/Button';

function MigrateAccount() {
  const navigate = useNavigate();
  const {splash} = useSplashScreen();

  const [email, setEmail] = useState('');

  function handleMigrateAccount() {
    splash({
      title: locals.getText('splash_screen_account_migrated_title'),
      caption: locals.getText('splash_screen_account_migrated_caption'),
      nextAction: () => navigate(paths.welcome),
    });
  }

  return (
    <Box sx={{display: 'flex', flexDirection: 'column', width: '30vw'}}>
      <Typography
        variant="h4"
        color="secondary"
        sx={{marginBottom: '10vh', fontWeight: 500}}>
        {locals.getText('profile_migrate_account_title')}
      </Typography>
      <TextField
        variant="standard"
        sx={{marginBottom: '25vh'}}
        label={locals.getText('profile_migrate_account_email_input_label')}
        value={email}
        onChange={(event) => setEmail(event.target.value)}
      />
      <Button
        onClick={handleMigrateAccount}
        disabled={stringUtils.isNullOrWhiteSpace(email)}>
        {locals.getText('profile_migrate_account_migrate_button_label')}
      </Button>
    </Box>
  );
}

export default MigrateAccount;
