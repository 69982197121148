import {Box} from '@mui/material';
import {Fragment, useEffect, useState} from 'react';
import {useMutation, useQuery, useQueryClient} from 'react-query';
import {useNavigate, useSearchParams} from 'react-router-dom';
import useLocalization from '../../hooks/common/useLocalization';
import locals from '../../localization/locals';
import Recipe from '../../models/entities/recipe';
import RecipeType from '../../models/entities/recipeType';
import paths from '../../routes/paths';
import services from '../../services/provider';
import useAuthStore from '../../state/auth';
import useBreadcrumbsStore, {Breadcrumb} from '../../state/breadcrumbs';
import numberUtils from '../../utils/numbers';
import routerUtils from '../../utils/router';
import OvenModelList from '../bakeries/OvenModelList';
import ActionBanner from '../common/ActionBanner';
import GradientOverflow from '../common/GradientOverflow';
import LoadingBackdrop from '../common/LoadingBackdrop';
import SimpleSearch from '../common/SimpleSearch';
import Tabs from '../common/Tabs';
import {pageHeight} from '../navigation/Navbar';
import RecipeTypeMenu from './RecipeTypeMenu';
import RecipesList from './RecipesList';

function Recipes() {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const queryClient = useQueryClient();
  useLocalization();

  const setBreadcrumbs = useBreadcrumbsStore((state) => state.setBreadcrumbs);

  const user = useAuthStore((state) => state.user);

  const [searchText, setSearchText] = useState('');
  const [selectedOvenModelId, setSelectedOvenModelId] = useState(
    searchParams.has('ovenModelId')
      ? numberUtils.parseInt(searchParams.get('ovenModelId'))
      : null,
  );
  const [selectedRecipeTypeId, setSelectedRecipeTypeId] = useState<
    string | null
  >(searchParams.get('recipeTypeId'));
  const [selectedRecipeId, setSelectedRecipeId] = useState<string | null>(null);

  const {data: ovenModels = [], isLoading: loadingOvenModels} = useQuery({
    enabled: user != null,
    queryKey: ['ovenModels', {companyId: user?.companyId}],
    queryFn: () =>
      services.ovenModel.getOvenModels({companyId: user?.companyId}),
  });

  const {data: recipeTypes = [], isLoading: loadingRecipeTypes} = useQuery({
    enabled: selectedOvenModelId != null,
    queryKey: ['recipeTypes', {ovenModelId: selectedOvenModelId}],
    queryFn: () =>
      services.recipeType.getRecipeTypes({ovenModelId: selectedOvenModelId!}),
  });

  const {data: recipes = [], isLoading: loadingRecipes} = useQuery({
    enabled: selectedOvenModelId != null,
    queryKey: ['recipes', {ovenModelId: selectedOvenModelId}],
    queryFn: () =>
      services.recipe.getRecipes({ovenModelId: selectedOvenModelId!}),
  });

  const {mutate: createRecipeType, isLoading: loadingCreateRecipeType} =
    useMutation({
      mutationFn: services.recipeType.createRecipeType,
      onSuccess: (recipeType) => {
        queryClient.setQueryData<RecipeType[] | undefined>(
          ['recipeTypes', {ovenModelId: selectedOvenModelId}],
          (recipeTypes) =>
            recipeTypes != null ? [...recipeTypes, recipeType] : [recipeType],
        );
        setSelectedRecipeTypeId(recipeType.id);
        setSelectedRecipeId(null);
        queryClient.invalidateQueries('recipeTypes');
      },
    });

  const {mutate: updateRecipeType, isLoading: loadingUpdateRecipeType} =
    useMutation({
      mutationFn: services.recipeType.updateRecipeType,
      onSuccess: (updatedRecipeType) => {
        queryClient.setQueryData<RecipeType[] | undefined>(
          ['recipeTypes', {ovenModelId: selectedOvenModelId}],
          (recipeTypes) =>
            recipeTypes?.map((recipeType) =>
              recipeType.id === updatedRecipeType.id
                ? updatedRecipeType
                : recipeType,
            ),
        );
        setSelectedRecipeId(null);
        queryClient.invalidateQueries('recipeTypes');
      },
    });

  const {mutate: deleteRecipeType, isLoading: loadingDeleteRecipeType} =
    useMutation({
      mutationFn: services.recipeType.deleteRecipeType,
      onSuccess: (recipeTypeId) => {
        queryClient.setQueryData<RecipeType[] | undefined>(
          ['recipeTypes', {ovenModelId: selectedOvenModelId}],
          (recipeTypes) =>
            recipeTypes?.filter((recipeType) => recipeType.id !== recipeTypeId),
        );
        setSelectedRecipeTypeId(null);
        setSelectedRecipeId(null);
        queryClient.invalidateQueries('recipeTypes');
      },
    });

  const {mutate: deleteRecipe, isLoading: loadingDeleteRecipe} = useMutation({
    mutationFn: services.recipe.deleteRecipe,
    onSuccess: (recipeId) => {
      queryClient.setQueryData<Recipe[] | undefined>(
        ['recipes', {ovenModelId: selectedOvenModelId}],
        (recipes) => recipes?.filter((recipe) => recipe.id !== recipeId),
      );
      queryClient.invalidateQueries('recipes');
    },
  });

  const {
    mutate: removeRecipeFromRecipeType,
    isLoading: loadingRemoveRecipeFromRecipeType,
  } = useMutation({
    mutationFn: services.recipeType.removeRecipeFromRecipeType,
    onSuccess: (recipeId) => {
      queryClient.setQueryData<Recipe[] | undefined>(
        ['recipes', {ovenModelId: selectedOvenModelId}],
        (recipes) => recipes?.filter((recipe) => recipe.id !== recipeId),
      );
      queryClient.invalidateQueries(['recipes']);
    },
  });

  const selectedOvenModel =
    ovenModels.find((ovenModel) => ovenModel.id === selectedOvenModelId) ??
    null;
  const selectedRecipeTypeIndex = recipeTypes.findIndex(
    (recipeType) => recipeType.id === selectedRecipeTypeId,
  );
  const selectedRecipeType =
    selectedRecipeTypeIndex > -1 ? recipeTypes[selectedRecipeTypeIndex] : null;

  useEffect(() => {
    if (selectedOvenModelId == null) {
      setSelectedOvenModelId(ovenModels.length > 0 ? ovenModels[0].id : null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ovenModels]);

  useEffect(() => {
    const breadcrumbs: Breadcrumb[] = [
      {
        title: locals.getText('recipes_breadcrumb'),
        onClick: () => {
          setSelectedOvenModelId(
            ovenModels.length > 0 ? ovenModels[0].id : null,
          );
        },
      },
    ];
    if (selectedOvenModel != null) {
      breadcrumbs.push({
        title: selectedOvenModel.description,
        onClick: () => {
          setSelectedRecipeTypeId(null);
          setSelectedRecipeId(null);
        },
      });
    }
    if (selectedRecipeType != null) {
      breadcrumbs.push({
        title: selectedRecipeType.description,
        onClick: () => {
          setSelectedRecipeId(null);
        },
      });
    }
    setBreadcrumbs(breadcrumbs);
    return () => setBreadcrumbs([]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedOvenModel, selectedRecipeType]);

  function handleSelectOvenModel(ovenModelId: number) {
    setSelectedOvenModelId(ovenModelId);
    setSelectedRecipeTypeId(null);
    setSelectedRecipeId(null);
  }

  function getRecipeTypeTabs() {
    const tabs = recipeTypes.map((recipeType) => recipeType.description);
    tabs.unshift(locals.getText('recipe_type_all_label'));
    return tabs;
  }

  function handleChangeSelectedTab(tabIndex: number) {
    setSelectedRecipeTypeId(
      tabIndex === 0 ? null : recipeTypes[tabIndex - 1].id,
    );
    setSelectedRecipeId(null);
  }

  function handleCreateRecipeType(recipeType: RecipeType) {
    createRecipeType(recipeType);
  }

  function handleUpdateRecipeType(recipeType: RecipeType) {
    updateRecipeType(recipeType);
  }

  function handleDeleteRecipeType(recipeTypeId: string) {
    deleteRecipeType({id: recipeTypeId});
  }

  function handleCreateRecipe() {
    navigate({
      pathname: paths.createRecipe,
      search: routerUtils
        .createSearchParams({
          action: 'create',
          ovenModelId: selectedOvenModelId,
          recipeTypeId: selectedRecipeTypeId,
        })
        .toString(),
    });
  }

  function handleDeleteRecipe(recipeId: string) {
    deleteRecipe({id: recipeId});
  }

  function handleRemoveRecipeFromRecipeType(
    recipeId: string,
    recipeTypeId: string,
  ) {
    removeRecipeFromRecipeType({recipeId, recipeTypeId});
  }

  const loading =
    loadingOvenModels ||
    loadingRecipeTypes ||
    loadingRecipes ||
    loadingCreateRecipeType ||
    loadingUpdateRecipeType ||
    loadingDeleteRecipeType ||
    loadingDeleteRecipe ||
    loadingRemoveRecipeFromRecipeType;

  const renderRecipeTypeMenu = selectedOvenModel != null && !loadingRecipeTypes;
  const renderRecipeList = selectedOvenModel != null && !loadingRecipes;

  return (
    <Fragment>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          width: '20vw',
        }}>
        <Box
          sx={{
            width: '140px',
            height: `calc(${pageHeight} - 96px)`,
            marginTop: '64px',
          }}>
          <GradientOverflow>
            <OvenModelList
              ovenModels={ovenModels}
              selectedOvenModelId={
                selectedOvenModel != null ? selectedOvenModel.id : undefined
              }
              onSelectOvenModel={handleSelectOvenModel}
            />
          </GradientOverflow>
        </Box>
      </Box>
      <Box sx={{width: '80vw'}}>
        <Box sx={{display: 'flex'}}>
          <Box sx={{width: '65vw'}}>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
                height: '112px',
              }}>
              <Tabs
                values={getRecipeTypeTabs()}
                selectedTabIndex={
                  selectedRecipeTypeIndex >= 0 ? selectedRecipeTypeIndex + 1 : 0
                }
                onTabClick={handleChangeSelectedTab}
              />
              <SimpleSearch
                searchText={searchText}
                onSearchText={setSearchText}
              />
            </Box>
            <Box sx={{marginBlock: 2}}>
              <ActionBanner
                text={locals.getText('recipe_create_recipe_label')}
                onClick={handleCreateRecipe}
              />
            </Box>
          </Box>
          <Box sx={{width: '15vw'}}>
            {renderRecipeTypeMenu && (
              <RecipeTypeMenu
                selectedOvenModel={selectedOvenModel}
                selectedRecipeType={selectedRecipeType}
                onCreateRecipeType={handleCreateRecipeType}
                onUpdateRecipeType={handleUpdateRecipeType}
                onDeleteRecipeType={handleDeleteRecipeType}
              />
            )}
          </Box>
        </Box>
        <Box sx={{height: `calc(${pageHeight} - 196px)`}}>
          {renderRecipeList && (
            <RecipesList
              searchText={searchText}
              selectedRecipeType={selectedRecipeType}
              recipes={recipes}
              selectedRecipeId={selectedRecipeId}
              setSelectedRecipeId={setSelectedRecipeId}
              onDeleteRecipe={handleDeleteRecipe}
              onRemoveRecipeFromRecipeType={handleRemoveRecipeFromRecipeType}
            />
          )}
        </Box>
      </Box>
      <LoadingBackdrop loading={loading} />
    </Fragment>
  );
}

export default Recipes;
