import {Box} from '@mui/material';
import {
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  ChartData,
  ChartDataset,
  ChartOptions,
  Legend,
  LinearScale,
  Title,
  Tooltip,
} from 'chart.js';
import {Bar} from 'react-chartjs-2';
import locals from '../../localization/locals';
import {OvenPanelStat} from '../../models/entities/ovenPanel';
import dateUtils from '../../utils/dates';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
);

const grey = 'RGB(152, 150, 150)';
const transparentGrey = 'RGBA(152, 150, 150, 0.7)';
const red = 'RGB(227, 6, 19)';
const transparentRed = 'RGBA(227, 6, 19, 0.5)';

function getChartOptions(): ChartOptions<'bar'> {
  return {
    locale: 'uk',
    responsive: true,
    plugins: {
      legend: {position: 'top', labels: {color: grey}},
    },
    scales: {
      y: {
        title: {
          display: true,
          text: locals.getText('statistics_chart_y_axis_label'),
          color: grey,
        },
        ticks: {color: grey},
      },
      x: {
        title: {
          display: true,
          text: locals.getText('statistics_chart_x_axis_label'),
          color: grey,
        },
        ticks: {color: grey},
      },
    },
  };
}

function getChartData(ovenPanelStats: OvenPanelStat[]): ChartData<'bar'> {
  const labels: string[] = [];
  const offTimeDataset: ChartDataset<'bar'> = {
    label: locals.getText('statistics_chart_off_time_label'),
    data: [],
    backgroundColor: transparentGrey,
    categoryPercentage: 0.3,
  };
  const onTimeDataset: ChartDataset<'bar'> = {
    label: locals.getText('statistics_chart_on_time_label'),
    data: [],
    backgroundColor: transparentRed,
    categoryPercentage: 0.3,
  };
  const cookingTimeDataset: ChartDataset<'bar'> = {
    label: locals.getText('statistics_chart_cooking_time_label'),
    data: [],
    backgroundColor: red,
    categoryPercentage: 0.3,
  };
  ovenPanelStats.forEach((stat) => {
    labels.push(dateUtils.toLocaleDateString(stat.date));
    offTimeDataset.data.push(stat.offTimeInMinutes);
    onTimeDataset.data.push(stat.onTimeInMinutes);
    cookingTimeDataset.data.push(stat.cookingTimeInMinutes);
  });
  return {
    labels,
    datasets: [offTimeDataset, onTimeDataset, cookingTimeDataset],
  };
}

type ChartProps = {
  ovenPanelStats: OvenPanelStat[];
};

function Chart(props: ChartProps) {
  const {ovenPanelStats} = props;
  return (
    <Box sx={{width: '100%', height: '100%', maxWidth: '650px'}}>
      <Bar options={getChartOptions()} data={getChartData(ovenPanelStats)} />
    </Box>
  );
}

export default Chart;
