import locals from '../localization/locals';
import OvenPanelNotification from '../models/entities/ovenPanelNotification';
import stringUtils from './strings';

const settingsLabels: Record<string, () => string> = {
  Device_OnState: () => locals.getText('notification_settings_device_on_state'),
  Fault_ControlCommunication: () =>
    locals.getText('notification_settings_fault_control_communication'),
  Fault_MotorOvertemperature: () =>
    locals.getText('notification_settings_fault_motor_overtemperature'),
  Fault_MotorProtection: () =>
    locals.getText('notification_settings_fault_motor_protection'),
  Fault_OvenOvertemperature: () =>
    locals.getText('notification_settings_fault_oven_overtemperature'),
  Fault_OvenTempSensorBroken: () =>
    locals.getText('notification_settings_fault_oven_temp_sensor_broken'),
  Fault_ElectromotiveClosure: () =>
    locals.getText('notification_settings_fault_electromotive_closure'),
  Fault_MaximumWaterLevel: () =>
    locals.getText('notification_settings_fault_maximum_water_level'),
  Fault_MinimumWaterLevel: () =>
    locals.getText('notification_settings_fault_minimum_water_level'),
  Fault_OvenHeatingTimeout: () =>
    locals.getText('notification_settings_fault_oven_heating_timeout'),
  Fault_WaterChargingTimeout: () =>
    locals.getText('notification_settings_fault_water_charging_timeout'),
  Fault_WaterDischargingTimeout: () =>
    locals.getText('notification_settings_fault_water_discharging_timeout'),
  Fault_WaterHeatingTimeout: () =>
    locals.getText('notification_settings_fault_water_heating_timeout'),
  Fault_WaterTempSensorBroken: () =>
    locals.getText('notification_settings_fault_water_temp_sensor_broken'),
  Warning_DescalerReplace: () =>
    locals.getText('notification_settings_warning_descaler_replace'),
  Warning_DetergentRefill: () =>
    locals.getText('notification_settings_warning_detergent_refill'),
  Cycle_OnState: () => locals.getText('notification_settings_cooking_on_state'),
};

const maintenanceNotificationIds = [12, 13, 16];

const ovenPanelNotificationUtils = {
  isWarning: function (notification: OvenPanelNotification) {
    return !maintenanceNotificationIds.includes(notification.notificationId);
  },
  isMaintenance: function (notification: OvenPanelNotification) {
    return maintenanceNotificationIds.includes(notification.notificationId);
  },
  getSettingsLabel: function (parameter: string): string {
    return parameter in settingsLabels ? settingsLabels[parameter]() : '';
  },
  getTranslatedDescription: function (
    notification: OvenPanelNotification,
  ): string {
    const {parameter, value, ovenPanelIndex, ovenChamberIndex} = notification;
    let translatedDescription = '';
    if (parameter === 'Device_OnState') {
      translatedDescription =
        value === '1'
          ? locals.getText('notifications_device_switched_on')
          : locals.getText('notifications_device_switched_off');
      return stringUtils.format(
        translatedDescription,
        ((ovenPanelIndex ?? ovenChamberIndex ?? 0) + 1).toString(),
      );
    }
    if (parameter === 'Fault_ControlCommunication') {
      return locals.getText('notifications_fault_control_communication');
    }
    if (parameter === 'Fault_MotorOvertemperature') {
      return locals.getText('notifications_fault_motor_overtemperature');
    }
    if (parameter === 'Fault_MotorProtection') {
      return locals.getText('notifications_fault_motor_protection');
    }
    if (parameter === 'Fault_OvenOvertemperature') {
      return locals.getText('notifications_fault_oven_overtemperature');
    }
    if (parameter === 'Fault_OvenTempSensorBroken') {
      return locals.getText('notifications_fault_oven_temp_sensor_broken');
    }
    if (parameter === 'Fault_ElectromotiveClosure') {
      return locals.getText('notifications_fault_electromotive_closure');
    }
    if (parameter === 'Fault_MaximumWaterLevel') {
      return locals.getText('notifications_fault_maximum_water_level');
    }
    if (parameter === 'Fault_MinimumWaterLevel') {
      return locals.getText('notifications_fault_minimum_water_level');
    }
    if (parameter === 'Fault_OvenHeatingTimeout') {
      return locals.getText('notifications_fault_oven_heating_timeout');
    }
    if (parameter === 'Fault_WaterChargingTimeout') {
      return locals.getText('notifications_fault_water_charging_timeout');
    }
    if (parameter === 'Fault_WaterDischargingTimeout') {
      return locals.getText('notifications_fault_water_discharging_timeout');
    }
    if (parameter === 'Fault_WaterHeatingTimeout') {
      return locals.getText('notifications_fault_water_heating_timeout');
    }
    if (parameter === 'Fault_WaterTempSensorBroken') {
      return locals.getText('notifications_fault_water_temp_sensor_broken');
    }
    if (parameter === 'Warning_DescalerReplace') {
      return locals.getText('notifications_warning_descaler_replace');
    }
    if (parameter === 'Warning_DetergentRefill') {
      return locals.getText('notifications_warning_detergent_refill');
    }
    if (parameter === 'Cycle_OnState') {
      translatedDescription =
        value === '1'
          ? locals.getText('notifications_cooking_cycle_started')
          : locals.getText('notifications_cooking_cycle_finished');
      return stringUtils.format(
        translatedDescription,
        ((ovenPanelIndex ?? ovenChamberIndex ?? 0) + 1).toString(),
      );
    }
    return notification.description;
  },
};

export default ovenPanelNotificationUtils;
