import {Box, List, ListItem, SvgIcon, Typography} from '@mui/material';
import {ElementType, useState} from 'react';
import {ReactComponent as AcceptIcon} from '../../assets/icons/accept.svg';
import {ReactComponent as NumberOneIcon} from '../../assets/icons/number-one.svg';
import {ReactComponent as NumberTwoIcon} from '../../assets/icons/number-two.svg';
import locals from '../../localization/locals';
import colors from '../../themes/colors';
import {
  CreateOffScheduleStep,
  CreateOffScheduleSteps,
} from './CreateOffSchedule';

type MenuItemProps = {
  IconComponent: ElementType<any>;
  iconColor?: string;
  title: string;
  selected?: boolean;
  onClick?: () => void;
};

function MenuItem(props: MenuItemProps) {
  const {IconComponent, iconColor, title, selected, onClick} = props;

  const [isHovered, setIsHovered] = useState(false);

  function getIconColor() {
    if (iconColor != null) return iconColor;
    return selected ? colors.grey : colors.lightGrey;
  }

  const renderText = isHovered || selected;

  return (
    <ListItem sx={{width: '20vw'}}>
      <Box
        sx={{
          flex: 1,
          height: '25px',
          textAlign: 'right',
          paddingRight: 2,
        }}></Box>
      <Box
        sx={{display: 'flex', alignItems: 'center', cursor: 'pointer'}}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
        onClick={onClick}>
        <Box sx={{width: '25px', height: '25px', textAlign: 'right'}}>
          <SvgIcon
            component={IconComponent}
            inheritViewBox
            sx={{fontSize: '25px', color: getIconColor()}}
          />
        </Box>
        <Box sx={{width: '10vw'}}>
          {renderText && (
            <Typography
              variant="body2"
              sx={{
                color: selected ? colors.grey : colors.lightGrey,
                fontWeight: 'bold',
                marginLeft: 2,
              }}>
              {title}
            </Typography>
          )}
        </Box>
      </Box>
    </ListItem>
  );
}

type CreateOffScheduleMenuProps = {
  steps: CreateOffScheduleSteps;
  hasTime: boolean;
  selectedStep: CreateOffScheduleStep;
  setSelectedStep: (step: CreateOffScheduleStep) => void;
  onFinish: () => void;
};

function CreateOffScheduleMenu(props: CreateOffScheduleMenuProps) {
  const {steps, hasTime, selectedStep, setSelectedStep, onFinish} = props;

  const renderFinishButton = !steps.ovens.error && hasTime && !steps.time.error;

  return (
    <List>
      {steps.ovens.visible && (
        <MenuItem
          IconComponent={NumberOneIcon}
          title={locals.getText('programming_ovens_step_label')}
          selected={selectedStep === 'ovens'}
          onClick={() => setSelectedStep('ovens')}
        />
      )}
      {steps.time.visible && (
        <MenuItem
          IconComponent={NumberTwoIcon}
          title={locals.getText('programming_time_step_label')}
          selected={selectedStep === 'time'}
          onClick={() => setSelectedStep('time')}
        />
      )}
      {renderFinishButton && (
        <MenuItem
          IconComponent={AcceptIcon}
          iconColor="primary.main"
          title={locals.getText('programming_finish_button_label')}
          selected
          onClick={onFinish}
        />
      )}
    </List>
  );
}

export default CreateOffScheduleMenu;
