import {useState} from 'react';
import localStorageUtils from '../../utils/localStorage';

function useLocalStorageState<TValue>(key: string, initialValue: TValue) {
  const [storedValue, setStoredValue] = useState<TValue>(() =>
    localStorageUtils.getValue<TValue>(key, initialValue),
  );

  return [
    storedValue,
    (value: TValue | ((previousValue: TValue) => TValue)) => {
      const newValue = value instanceof Function ? value(storedValue) : value;
      localStorageUtils.setValue(key, newValue);
      setStoredValue(newValue);
    },
  ] as const;
}

export default useLocalStorageState;
