import {Box, Typography} from '@mui/material';
import {Schema, Validator} from 'jsonschema';
import {useEffect} from 'react';
import locals from '../../../../localization/locals';
import {OvenModelId} from '../../../../models/entities/ovenModel';
import {RecipePhase} from '../../../../models/entities/recipe';
import numberUtils from '../../../../utils/numbers';
import {RecipeBuild} from '../RecipeSettings';
import DurationRow from './phase/DurationRow';
import SteamExitValveOpenedRow from './phase/SteamExitValveOpenedRow';
import SteamInjectionNumberRow from './phase/SteamInjectionNumberRow';
import TemperatureRow from './phase/TemperatureRow';
import TurbineSpeedRow from './phase/TurbineSpeedRow';

function hasError(phase: RecipePhase, schema: Schema, parameter: string) {
  const errors = new Validator().validate(phase, schema).errors;
  return errors.some((error) => error.property === `instance.${parameter}`);
}

export type PhasesStepProps = {
  selectedPhaseIndex: number;
  phaseSchema: Schema;
  recipeBuild: RecipeBuild;
  setRecipeBuild: (setter: (previousValue: RecipeBuild) => RecipeBuild) => void;
  setError: (error: boolean) => void;
};

function PhaseStep(props: PhasesStepProps) {
  const {
    selectedPhaseIndex,
    phaseSchema,
    recipeBuild,
    setRecipeBuild,
    setError,
  } = props;
  const selectedPhase = recipeBuild.phases[selectedPhaseIndex] ?? {}; // TODO: review this

  useEffect(() => {
    const errors = new Validator().validate(selectedPhase, phaseSchema).errors;
    setError(errors.length > 0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedPhase]);

  function handleChangeTemperature(temperature: string) {
    if ('temperature' in selectedPhase) {
      setRecipeBuild((recipeBuild) => ({
        ...recipeBuild,
        phases: recipeBuild.phases.map((phase, index) => {
          if (index === selectedPhaseIndex) {
            return {...phase, temperature: numberUtils.parseInt(temperature)};
          }
          return phase;
        }),
      }));
    }
  }

  function handleChangeDuration(duration: string) {
    if ('duration' in selectedPhase) {
      setRecipeBuild((recipeBuild) => ({
        ...recipeBuild,
        phases: recipeBuild.phases.map((phase, index) => {
          if (index === selectedPhaseIndex) {
            return {...phase, duration: numberUtils.parseInt(duration)};
          }
          return phase;
        }),
      }));
    }
  }

  function handleChangeSteamInjectionNumber(steamInjectionNumber: number) {
    if ('steamInjectionNumber' in selectedPhase) {
      setRecipeBuild((recipeBuild) => ({
        ...recipeBuild,
        phases: recipeBuild.phases.map((phase, index) => {
          if (index === selectedPhaseIndex) {
            return {...phase, steamInjectionNumber};
          }
          return phase;
        }),
      }));
    }
  }

  function handleChangeSteamExitValveOpened(steamExitValveOpened: boolean) {
    if ('steamExitValveOpened' in selectedPhase) {
      setRecipeBuild((recipeBuild) => ({
        ...recipeBuild,
        phases: recipeBuild.phases.map((phase, index) => {
          if (index === selectedPhaseIndex) {
            return {...phase, steamExitValveOpened};
          }
          return phase;
        }),
      }));
    }
  }

  function handleChangeTurbineSpeed(turbineSpeed: number) {
    if ('turbineSpeed' in selectedPhase) {
      setRecipeBuild((recipeBuild) => ({
        ...recipeBuild,
        phases: recipeBuild.phases.map((phase, index) => {
          if (index === selectedPhaseIndex) {
            return {...phase, turbineSpeed};
          }
          return phase;
        }),
      }));
    }
  }

  function renderParameters() {
    switch (recipeBuild.ovenModelId) {
      case OvenModelId.Turboram:
        return (
          <Box sx={{width: '65vw'}}>
            <TemperatureRow
              temperature={
                'temperature' in selectedPhase
                  ? selectedPhase.temperature.toString()
                  : ''
              }
              onChange={handleChangeTemperature}
              error={hasError(selectedPhase, phaseSchema, 'temperature')}
            />
            <DurationRow
              duration={
                'duration' in selectedPhase
                  ? selectedPhase.duration.toString()
                  : ''
              }
              onChange={(value) => handleChangeDuration(value)}
              error={hasError(selectedPhase, phaseSchema, 'duration')}
            />
            <SteamInjectionNumberRow
              steamInjectionNumber={
                'steamInjectionNumber' in selectedPhase
                  ? selectedPhase.steamInjectionNumber
                  : 0
              }
              onChange={handleChangeSteamInjectionNumber}
            />
            <SteamExitValveOpenedRow
              steamExitValveOpened={
                'steamExitValveOpened' in selectedPhase
                  ? selectedPhase.steamExitValveOpened
                  : false
              }
              onChange={handleChangeSteamExitValveOpened}
            />
            <TurbineSpeedRow
              turbineSpeed={
                'turbineSpeed' in selectedPhase ? selectedPhase.turbineSpeed : 0
              }
              onChange={handleChangeTurbineSpeed}
            />
          </Box>
        );
      default:
        return (
          <Box sx={{width: '65vw', padding: 2}}>
            <Typography variant="body2" sx={{color: 'text.primary'}}>
              {locals.getText('in_development_label')}
            </Typography>
          </Box>
        );
    }
  }

  return <Box>{renderParameters()}</Box>;
}

export default PhaseStep;
