import {Box, Typography} from '@mui/material';
import {Fragment, useEffect, useState} from 'react';
import {useQuery} from 'react-query';
import {useSearchParams} from 'react-router-dom';
import useLocalization from '../../hooks/common/useLocalization';
import locals from '../../localization/locals';
import Oven from '../../models/entities/oven';
import OvenChamber from '../../models/entities/ovenChamber';
import {OvenModelId} from '../../models/entities/ovenModel';
import OvenPanel from '../../models/entities/ovenPanel';
import services from '../../services/provider';
import useBreadcrumbsStore, {Breadcrumb} from '../../state/breadcrumbs';
import numberUtils from '../../utils/numbers';
import OvenModelList from '../bakeries/OvenModelList';
import GradientOverflow from '../common/GradientOverflow';
import LoadingBackdrop from '../common/LoadingBackdrop';
import {pageHeight} from '../navigation/Navbar';
import BakeryList from './BakeryList';
import CityList from './CityList';
import CountryTabs from './CountryTabs';
import OvenStatistics from './OvenStatistics';
import OvenTable from './OvenTable';
import SearchField from './SearchField';

function OvensNotFound() {
  return (
    <Typography variant="body2" sx={{color: 'text.primary', padding: 2}}>
      {locals.getText('statistics_ovens_not_found_label')}
    </Typography>
  );
}

function Statistics() {
  const [searchParams] = useSearchParams();
  useLocalization();

  const setBreadcrumbs = useBreadcrumbsStore((state) => state.setBreadcrumbs);

  const [searchText, setSearchText] = useState('');
  const [selectedCountryId, setSelectedCountryId] = useState<number | null>(
    searchParams.has('countryId')
      ? numberUtils.parseInt(searchParams.get('countryId'))
      : null,
  );
  const [selectedCityId, setSelectedCityId] = useState<number | null>(
    searchParams.has('cityId')
      ? numberUtils.parseInt(searchParams.get('cityId'))
      : null,
  );
  const [selectedBakeryId, setSelectedBakeryId] = useState<string | null>(
    searchParams.get('bakeryId'),
  );
  const [selectedOvenModelId, setSelectedOvenModelId] =
    useState<OvenModelId | null>(
      searchParams.has('ovenModelId')
        ? numberUtils.parseInt(searchParams.get('ovenModelId'))
        : null,
    );
  const [selectedOvenGroupId, setSelectedOvenGroupId] = useState<string | null>(
    searchParams.get('ovenGroupId'),
  );
  const [selectedOvenId, setSelectedOvenId] = useState<string | null>(
    searchParams.get('ovenId'),
  );
  const [selectedOvenChamberId, setSelectedOvenChamberId] = useState<
    string | null
  >(searchParams.get('ovenChamberId'));
  const [selectedOvenPanelId, setSelectedOvenPanelId] = useState<string | null>(
    searchParams.get('ovenPanelId'),
  );

  const {data: countries = [], isLoading: loadingLocations} = useQuery({
    queryKey: ['bakeriesLocations'],
    queryFn: () => services.bakery.getBakeriesLocations({}),
  });

  const {data: ovenModels = [], isLoading: loadingOvenModels} = useQuery({
    enabled: selectedBakeryId !== null,
    queryKey: ['bakeryOvens', selectedBakeryId],
    queryFn: () =>
      services.bakery.getBakeryOvens({bakeryId: selectedBakeryId!}),
  });

  const selectedCountry =
    countries.find((country) => country.id === selectedCountryId) ?? null;
  const selectedCity =
    selectedCountry?.cities?.find((city) => city.id === selectedCityId) ?? null;
  const selectedBakery =
    selectedCity?.bakeries?.find((bakery) => bakery.id === selectedBakeryId) ??
    null;
  const selectedOvenModel =
    ovenModels.find((ovenModel) => ovenModel.id === selectedOvenModelId) ??
    null;
  const selectedOvenGroup =
    selectedOvenModel?.ovenGroups?.find(
      (ovenGroup) => ovenGroup.id === selectedOvenGroupId,
    ) ?? null;
  const selectedOven =
    (selectedOvenGroup?.ovens ?? selectedOvenModel?.ovens ?? []).find(
      (oven) => oven.id === selectedOvenId,
    ) ?? null;
  const selectedOvenChamber =
    selectedOven?.ovenChambers?.find(
      (ovenChamber) => ovenChamber.id === selectedOvenChamberId,
    ) ?? null;
  const selectedOvenPanel =
    (selectedOvenChamber?.ovenPanels ?? selectedOven?.ovenPanels ?? []).find(
      (ovenPanel) => ovenPanel.id === selectedOvenPanelId,
    ) ?? null;

  useEffect(() => {
    if (selectedCountryId == null) {
      setSelectedCountryId(countries.length > 0 ? countries[0].id : null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [countries]);

  useEffect(() => {
    if (selectedOvenModelId == null) {
      setSelectedOvenModelId(ovenModels.length > 0 ? ovenModels[0].id : null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ovenModels]);

  useEffect(() => {
    const breadcrumbs: Breadcrumb[] = [
      {
        title: locals.getText('statistics_breadcrumb'),
        onClick: () => {
          setSelectedCountryId(countries.length > 0 ? countries[0].id : null);
          setSelectedCityId(null);
          setSelectedBakeryId(null);
          setSelectedOvenModelId(null);
          setSelectedOvenGroupId(null);
          setSelectedOvenId(null);
          setSelectedOvenChamberId(null);
          setSelectedOvenPanelId(null);
        },
      },
    ];
    if (selectedCountry != null) {
      breadcrumbs.push({
        title: selectedCountry.name,
        onClick: () => {
          setSelectedCityId(null);
          setSelectedBakeryId(null);
          setSelectedOvenModelId(null);
          setSelectedOvenGroupId(null);
          setSelectedOvenId(null);
          setSelectedOvenChamberId(null);
          setSelectedOvenPanelId(null);
        },
      });
    }
    if (selectedCity != null) {
      breadcrumbs.push({
        title: selectedCity.name,
        onClick: () => {
          setSelectedBakeryId(null);
          setSelectedOvenModelId(null);
          setSelectedOvenGroupId(null);
          setSelectedOvenId(null);
          setSelectedOvenChamberId(null);
          setSelectedOvenPanelId(null);
        },
      });
    }
    if (selectedBakery != null) {
      breadcrumbs.push({
        title: selectedBakery.name,
        onClick: () => {
          setSelectedOvenModelId(
            ovenModels.length > 0 ? ovenModels[0].id : null,
          );
          setSelectedOvenGroupId(null);
          setSelectedOvenId(null);
          setSelectedOvenChamberId(null);
          setSelectedOvenPanelId(null);
        },
      });
    }
    if (selectedOvenModel != null) {
      breadcrumbs.push({
        title: selectedOvenModel.description,
        onClick: () => {
          setSelectedOvenGroupId(null);
          setSelectedOvenId(null);
          setSelectedOvenChamberId(null);
          setSelectedOvenPanelId(null);
        },
      });
    }
    if (selectedOvenGroup != null) {
      breadcrumbs.push({title: selectedOvenGroup.description ?? ''});
    }
    if (selectedOvenGroup == null && selectedOven != null) {
      breadcrumbs.push({title: selectedOven.description ?? ''});
    }
    setBreadcrumbs(breadcrumbs);
    return () => setBreadcrumbs([]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    selectedCountry,
    selectedCity,
    selectedBakery,
    selectedOvenModel,
    selectedOvenGroup,
    selectedOven,
  ]);

  function handleSelectCountry(countryId: number) {
    setSelectedCountryId(countryId);
    setSelectedCityId(null);
    setSelectedBakeryId(null);
    setSelectedOvenModelId(null);
    setSelectedOvenGroupId(null);
    setSelectedOvenId(null);
    setSelectedOvenChamberId(null);
    setSelectedOvenPanelId(null);
  }

  function handleSelectCity(cityId: number) {
    setSelectedCityId(cityId);
    setSelectedBakeryId(null);
    setSelectedOvenModelId(null);
    setSelectedOvenGroupId(null);
    setSelectedOvenId(null);
    setSelectedOvenChamberId(null);
    setSelectedOvenPanelId(null);
  }

  function handleSelectBakery(bakeryId: string) {
    setSelectedBakeryId(bakeryId);
    setSelectedOvenModelId(null);
    setSelectedOvenGroupId(null);
    setSelectedOvenId(null);
    setSelectedOvenChamberId(null);
    setSelectedOvenPanelId(null);
  }

  function handleSelectOvenModel(ovenModelId: number) {
    setSelectedOvenModelId(ovenModelId);
    setSelectedOvenGroupId(null);
    setSelectedOvenId(null);
    setSelectedOvenChamberId(null);
    setSelectedOvenPanelId(null);
  }

  function handleSelectOvenGroup(ovenGroupId: string) {
    const ovenGroup = selectedOvenModel?.ovenGroups?.find(
      (ovenGroup) => ovenGroup.id === ovenGroupId,
    );
    const oven = ovenGroup?.ovens?.reduce(
      (previous, current) =>
        (previous?.ovenGroupOrder ?? 0) > (current.ovenGroupOrder ?? 0)
          ? previous
          : current,
      undefined as Oven | undefined,
    );
    const ovenPanel = oven?.ovenPanels?.[0];
    setSelectedOvenGroupId(ovenGroup?.id ?? null);
    setSelectedOvenId(oven?.id ?? null);
    setSelectedOvenChamberId(null);
    setSelectedOvenPanelId(ovenPanel?.id ?? null);
  }

  function handleSelectOven(ovenId: string) {
    const oven = selectedOvenModel?.ovens?.find((oven) => oven.id === ovenId);
    const ovenChamber = oven?.ovenChambers?.reduce(
      (previous, current) =>
        (previous?.ovenOrder ?? 0) > (current.ovenOrder ?? 0)
          ? previous
          : current,
      undefined as OvenChamber | undefined,
    );
    const ovenPanel =
      ovenChamber != null
        ? ovenChamber.ovenPanels?.[0]
        : oven?.ovenPanels?.reduce(
            (previous, current) =>
              (previous?.ovenOrder ?? 0) > (current.ovenOrder ?? 0)
                ? previous
                : current,
            undefined as OvenPanel | undefined,
          );
    setSelectedOvenId(oven?.id ?? null);
    setSelectedOvenChamberId(ovenChamber?.id ?? null);
    setSelectedOvenPanelId(ovenPanel?.id ?? null);
  }

  function handleSelectOvenPanel(
    ovenId: string,
    ovenPanelId: string,
    ovenChamberId?: string,
  ) {
    setSelectedOvenId(ovenId);
    setSelectedOvenChamberId(ovenChamberId ?? null);
    setSelectedOvenPanelId(ovenPanelId);
  }

  function handleReturnToBakeries() {
    setSelectedBakeryId(null);
    setSelectedOvenModelId(null);
    setSelectedOvenGroupId(null);
    setSelectedOvenId(null);
    setSelectedOvenChamberId(null);
    setSelectedOvenPanelId(null);
  }

  function handleReturnToBakery() {
    setSelectedOvenModelId(ovenModels.length > 0 ? ovenModels[0].id : null);
    setSelectedOvenGroupId(null);
    setSelectedOvenId(null);
    setSelectedOvenChamberId(null);
    setSelectedOvenPanelId(null);
  }

  function handleReturnToOvenModel() {
    setSelectedOvenGroupId(null);
    setSelectedOvenId(null);
    setSelectedOvenChamberId(null);
    setSelectedOvenPanelId(null);
  }

  const loading = loadingLocations || loadingOvenModels;

  const renderCityList = selectedCity == null;
  const renderBakeryList = selectedCity != null && selectedBakery == null;
  const renderOvenModelList = selectedBakery != null;
  const renderOvenTable =
    selectedBakery != null &&
    selectedOvenGroup == null &&
    selectedOven == null &&
    !loadingOvenModels;
  const renderOvenStatistics =
    selectedOvenGroup != null || selectedOven != null;
  const ovensNotFound =
    (selectedOvenModel?.ovenGroups?.length ?? 0) === 0 &&
    (selectedOvenModel?.ovens?.length ?? 0) === 0;

  return (
    <Fragment>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          width: '20vw',
        }}>
        <Box
          sx={{
            width: '140px',
            height: `calc(${pageHeight} - 96px)`,
            marginTop: '64px',
          }}>
          {renderOvenModelList && (
            <GradientOverflow>
              <OvenModelList
                ovenModels={ovenModels}
                selectedOvenModelId={selectedOvenModelId ?? undefined}
                onSelectOvenModel={handleSelectOvenModel}
              />
            </GradientOverflow>
          )}
        </Box>
      </Box>
      <Box sx={{width: '65vw'}}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            height: '112px',
          }}>
          <CountryTabs
            countries={countries}
            selectedCountryId={selectedCountryId}
            onSelectCountry={handleSelectCountry}
          />
          <SearchField
            searchText={searchText}
            setSearchText={setSearchText}
            selectedCity={selectedCity}
            selectedBakery={selectedBakery}
            selectedOvenGroup={selectedOvenGroup}
            selectedOven={selectedOven}
            selectedOvenChamber={selectedOvenChamber}
            selectedOvenPanel={selectedOvenPanel}
            onReturnToBakeries={handleReturnToBakeries}
            onReturnToBakery={handleReturnToBakery}
            onReturnToOvenModel={handleReturnToOvenModel}
          />
        </Box>
        <Box
          sx={{
            height: `calc(${pageHeight} - 112px)`,
          }}>
          {renderCityList && (
            <CityList
              searchText={searchText}
              cities={selectedCountry?.cities ?? []}
              onSelectCity={handleSelectCity}
              citiesNotFoundMessage={
                selectedCountry == null
                  ? ''
                  : locals.getText('statistics_cities_not_found_label')
              }
            />
          )}
          {renderBakeryList && (
            <BakeryList
              searchText={searchText}
              bakeries={selectedCity?.bakeries ?? []}
              onSelectBakery={handleSelectBakery}
              bakeriesNotFoundMessage={
                selectedCity == null
                  ? ''
                  : locals.getText('statistics_bakeries_not_found_label')
              }
            />
          )}
          {renderOvenTable && ovensNotFound && <OvensNotFound />}
          {renderOvenTable && !ovensNotFound && (
            <OvenTable
              ovens={selectedOvenModel?.ovens ?? []}
              ovenGroups={selectedOvenModel?.ovenGroups ?? []}
              onSelectOvenGroup={handleSelectOvenGroup}
              onSelectOven={handleSelectOven}
            />
          )}
          {renderOvenStatistics && (
            <OvenStatistics
              selectedOvenGroup={selectedOvenGroup}
              selectedOven={selectedOven}
              selectedOvenChamberId={selectedOvenChamberId}
              selectedOvenPanelId={selectedOvenPanelId}
              onSelectOvenPanel={handleSelectOvenPanel}
            />
          )}
        </Box>
      </Box>
      <LoadingBackdrop loading={loading} />
    </Fragment>
  );
}

export default Statistics;
