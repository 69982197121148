import Oven from './oven';
import OvenGroup from './ovenGroup';
import OvenSubModel from './ovenSubModel';

export enum OvenModelId {
  Compactram = 1,
  Electram = 2,
  Modulram = 3,
  Rotoram = 4,
  Turboram = 5,
}

type OvenModel = {
  id: OvenModelId;
  description: string;
  ovenSubModels?: OvenSubModel[];
  ovens?: Oven[];
  ovenGroups?: OvenGroup[];
};

export default OvenModel;
