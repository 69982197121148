import {Box, InputBase, Typography} from '@mui/material';
import {useRef, useState} from 'react';
import {useMutation, useQuery, useQueryClient} from 'react-query';
import {v4 as uuid} from 'uuid';
import {ReactComponent as AcceptSimpleIcon} from '../../../../assets/icons/accept-simple.svg';
import {ReactComponent as AddIcon} from '../../../../assets/icons/add.svg';
import {ReactComponent as DeclineSimpleIcon} from '../../../../assets/icons/decline-simple.svg';
import locals from '../../../../localization/locals';
import RecipeType from '../../../../models/entities/recipeType';
import services from '../../../../services/provider';
import colors from '../../../../themes/colors';
import stringUtils from '../../../../utils/strings';
import Icon from '../../../common/Icon';
import IconButton from '../../../common/IconButton';
import LoadingBackdrop from '../../../common/LoadingBackdrop';
import Span from '../../../common/Span';
import Switch from '../../../common/Switch';
import {RecipeBuild} from '../RecipeSettings';

type GeneralStepProps = {
  defaultRecipeName: string;
  recipeBuild: RecipeBuild;
  setRecipeBuild: (setter: (previousValue: RecipeBuild) => RecipeBuild) => void;
  error: boolean;
  setError: (error: boolean) => void;
};

function GeneralStep(props: GeneralStepProps) {
  const {defaultRecipeName, recipeBuild, setRecipeBuild, error, setError} =
    props;
  const ovenModelId = recipeBuild.ovenModelId;
  const recipeTypeIds = new Set(recipeBuild.recipeTypeIds);

  const queryClient = useQueryClient();

  const setErrorFn = useRef<Function | null>(null);

  const [recipeAlreadyExistsError, setRecipeAlreadyExistsError] =
    useState(false);

  const [recipeTypeDescription, setRecipeTypeDescription] = useState('');

  const {data: recipeTypes = [], isLoading: loadingRecipeTypes} = useQuery({
    queryKey: ['recipeTypes', {ovenModelId}],
    queryFn: () => services.recipeType.getRecipeTypes({ovenModelId}),
  });

  const {mutate: onVerifyRecipeName} = useMutation({
    mutationFn: services.recipe.existsRecipe,
    onSuccess: (exists) => {
      setRecipeAlreadyExistsError(exists);
      setErrorFn.current?.(exists);
    },
  });

  const {mutate: createRecipeType, isLoading: loadingCreateRecipeType} =
    useMutation({
      mutationFn: services.recipeType.createRecipeType,
      onSuccess: (recipeType) => {
        queryClient.setQueryData<RecipeType[] | undefined>(
          ['recipeTypes', {ovenModelId}],
          (recipeTypes) =>
            recipeTypes != null ? [...recipeTypes, recipeType] : [recipeType],
        );
        setRecipeBuild((recipeBuild) => ({
          ...recipeBuild,
          recipeTypeIds: [...recipeBuild.recipeTypeIds, recipeType.id],
        }));
        setRecipeTypeDescription('');
        queryClient.invalidateQueries(['recipeTypes', {ovenModelId}]);
      },
    });

  function verifyRecipeName(
    ovenModelId: number,
    name: string,
    defaultName: string,
  ) {
    if (stringUtils.isNullOrWhiteSpace(name)) {
      setError(true);
      setErrorFn.current = null;
      return;
    }
    if (stringUtils.insensitiveEquals(name, defaultName)) {
      setError(true);
      setErrorFn.current = null;
      return;
    }
    setErrorFn.current = (error: boolean) => setError(error);
    onVerifyRecipeName({name, ovenModelId});
  }

  function handleCreateRecipeType() {
    if (!stringUtils.isNullOrWhiteSpace(recipeTypeDescription)) {
      createRecipeType({
        id: uuid(),
        description: recipeTypeDescription,
        ovenModelId,
      });
    }
  }

  function handleChangeRecipeName(recipeName: string) {
    setError(true);
    setRecipeBuild((recipeBuild) => ({...recipeBuild, name: recipeName}));
    verifyRecipeName(recipeBuild.ovenModelId, recipeName, defaultRecipeName);
  }

  function handleCheckRecipeType(recipeTypeId: string, checked: boolean) {
    if (checked) {
      recipeTypeIds.add(recipeTypeId);
    } else {
      recipeTypeIds.delete(recipeTypeId);
    }
    setRecipeBuild((recipeBuild) => ({
      ...recipeBuild,
      recipeTypeIds: Array.from(recipeTypeIds),
    }));
  }

  function renderRecipeTypes() {
    return (
      <Box sx={{width: '30vw'}}>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            marginBottom: 3,
          }}>
          <Typography variant="body2" sx={{color: 'text.primary'}}>
            {locals.getText('recipe_type_all_label')}
          </Typography>
          <Switch checked />
        </Box>
        {recipeTypes.map((recipeType) => (
          <Box
            key={recipeType.id}
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              marginBottom: 3,
            }}>
            <Typography variant="body2" sx={{color: 'text.primary'}}>
              {recipeType.description}
            </Typography>
            <Switch
              checked={recipeTypeIds.has(recipeType.id)}
              onChange={(_, checked) =>
                handleCheckRecipeType(recipeType.id, checked)
              }
            />
          </Box>
        ))}
      </Box>
    );
  }

  const loading = loadingRecipeTypes || loadingCreateRecipeType;

  return (
    <Box sx={{padding: 2, marginBottom: 8}}>
      <Box sx={{width: '30vw'}}>
        <Typography
          variant="body2"
          sx={{fontWeight: 'bold', color: 'text.primary', marginBottom: 1}}>
          {locals.getText('recipe_settings_general_step_recipe_name')}
        </Typography>
        <InputBase
          sx={{
            width: '100%',
            fontSize: '0.875rem',
            color: 'text.primary',
            borderBottom: '1px solid ' + colors.grey,
          }}
          value={recipeBuild.name}
          onChange={(event) => handleChangeRecipeName(event.target.value)}
          endAdornment={
            stringUtils.isNullOrWhiteSpace(recipeBuild.name) ? undefined : (
              <Icon
                IconComponent={error ? DeclineSimpleIcon : AcceptSimpleIcon}
                color={colors.grey}
                size="20px"
              />
            )
          }
        />
        <Box sx={{minHeight: '22px', textAlign: 'end', marginBottom: 2}}>
          {recipeAlreadyExistsError && (
            <Typography variant="caption" color="primary">
              {locals.getText(
                'recipe_settings_general_step_recipe_name_in_use',
              )}
            </Typography>
          )}
        </Box>
      </Box>
      <Typography
        variant="body2"
        sx={{fontWeight: 'bold', color: 'text.primary', marginBottom: 3}}>
        {locals.getText('recipe_settings_general_step_recipe_type_title')}
        {recipeBuild.recipeTypeIds.length === 0 && (
          <Span sx={{fontWeight: 'normal', color: 'text.secondary'}}>
            {` ${locals.getText(
              'recipe_settings_general_step_recipe_type_caption',
            )}`}
          </Span>
        )}
      </Typography>
      {renderRecipeTypes()}
      <Box sx={{display: 'flex', alignItems: 'center', width: '30vw'}}>
        <InputBase
          sx={{
            width: '100%',
            fontSize: '0.875rem',
            color: 'text.primary',
            borderBottom: '1px solid ' + colors.grey,
            marginRight: 6,
          }}
          placeholder={locals.getText(
            'recipe_settings_general_step_add_recipe_type',
          )}
          value={recipeTypeDescription}
          onChange={(event) => setRecipeTypeDescription(event.target.value)}
          onKeyUp={(event) =>
            event.key === 'Enter' ? handleCreateRecipeType() : null
          }
        />
        <IconButton
          IconComponent={AddIcon}
          size="small"
          iconSize="20px"
          onClick={handleCreateRecipeType}
          disabled={stringUtils.isNullOrWhiteSpace(recipeTypeDescription)}
        />
      </Box>
      <LoadingBackdrop loading={loading} />
    </Box>
  );
}

export default GeneralStep;
