import GetCleaningsParams from '../../models/requests/getCleanings';
import GetCleaningsResponse from '../../models/responses/getCleanings';
import {CleaningService} from '../cleaning';
import data from './data';

const mockCleaningService: CleaningService = {
  getCleanings: function (
    params: GetCleaningsParams,
  ): Promise<GetCleaningsResponse> {
    return new Promise((resolve) =>
      setTimeout(() => {
        const {ovenModelId} = params;
        const cleanings = data.cleanings.filter(
          (cleaning) => cleaning.ovenModelId === ovenModelId,
        );
        resolve(cleanings);
      }, 500),
    );
  },
};

export default mockCleaningService;
