import {alpha, Backdrop, CircularProgress} from '@mui/material';

type LoadingBackdropProps = {
  loading: boolean;
};

function LoadingBackdrop(props: LoadingBackdropProps) {
  const {loading} = props;
  return (
    <Backdrop
      sx={{
        color: (theme) => alpha(theme.palette.primary.main, 0.8),
        backgroundColor: (theme) => alpha(theme.palette.common.white, 0.8),
        zIndex: (theme) => theme.zIndex.drawer + 1,
      }}
      open={loading}>
      <CircularProgress color="inherit" />
    </Backdrop>
  );
}

export default LoadingBackdrop;
