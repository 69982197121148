import {OvenModelId} from '../../models/entities/ovenModel';
import GetOvenModelsParams from '../../models/requests/getOvenModels';
import GetRecipeSchemaParams from '../../models/requests/getRecipeSchema';
import GetOvenModelsResponse from '../../models/responses/getOvenModels';
import GetRecipeSchemaResponse from '../../models/responses/getRecipeSchema';
import {ApplicationError} from '../../utils/errors';
import {OvenModelService} from '../ovenModel';
import data from './data';
import {
  compactramV1PhaseSchema,
  compactramV1RecipeSchema,
  electramV1PhaseSchema,
  electramV1RecipeSchema,
  modulramV1PhaseSchema,
  modulramV1RecipeSchema,
  rotoramV1PhaseSchema,
  rotoramV1RecipeSchema,
  turboramV1PhaseSchema,
  turboramV1recipeSchema,
} from './schemas';

const mockOvenModelService: OvenModelService = {
  getOvenModels: async function (
    params: GetOvenModelsParams,
  ): Promise<GetOvenModelsResponse> {
    return new Promise((resolve) =>
      setTimeout(() => {
        const {companyId} = params;
        let ovenModels = [...data.ovenModels];
        if (companyId != null) {
          const ovenModelIds = new Set(
            data.ovens.map((oven) => oven.ovenModelId),
          );
          ovenModels = ovenModels.filter((ovenModel) =>
            ovenModelIds.has(ovenModel.id),
          );
        }
        resolve(ovenModels);
      }, 500),
    );
  },
  getRecipeSchema: async function (
    params: GetRecipeSchemaParams,
  ): Promise<GetRecipeSchemaResponse> {
    return new Promise((resolve, reject) =>
      setTimeout(() => {
        const {ovenModelId} = params;
        switch (ovenModelId) {
          case OvenModelId.Compactram:
            resolve({
              recipeSchema: compactramV1RecipeSchema,
              phaseSchema: compactramV1PhaseSchema,
            });
            return;
          case OvenModelId.Electram:
            resolve({
              recipeSchema: electramV1RecipeSchema,
              phaseSchema: electramV1PhaseSchema,
            });
            return;
          case OvenModelId.Modulram:
            resolve({
              recipeSchema: modulramV1RecipeSchema,
              phaseSchema: modulramV1PhaseSchema,
            });
            return;
          case OvenModelId.Rotoram:
            resolve({
              recipeSchema: rotoramV1RecipeSchema,
              phaseSchema: rotoramV1PhaseSchema,
            });
            return;
          case OvenModelId.Turboram:
            resolve({
              recipeSchema: turboramV1recipeSchema,
              phaseSchema: turboramV1PhaseSchema,
            });
            return;
          default:
            reject(ApplicationError.notFound('Recipe Schema'));
        }
      }, 500),
    );
  },
};

export default mockOvenModelService;
