import {ListItem, ListItemIcon, Typography} from '@mui/material';
import {ElementType} from 'react';
import colors from '../../themes/colors';
import Icon from './Icon';

type MenuItemProps = {
  text: string;
  IconComponent?: ElementType<any>;
  selected?: boolean;
  textColor?: string;
  iconColor?: string;
  selectedTextColor?: string;
  selectedIconColor?: string;
  onSelectItem?: () => void;
  disabled?: boolean;
};

function MenuItem(props: MenuItemProps) {
  const {
    text,
    IconComponent,
    selected,
    textColor,
    iconColor,
    selectedTextColor,
    selectedIconColor,
    onSelectItem,
    disabled,
  } = props;

  function getTextColor() {
    return selected
      ? selectedTextColor
        ? selectedTextColor
        : 'secondary.main'
      : textColor
      ? textColor
      : 'secondary.light';
  }

  function getIconColor() {
    if (disabled) {
      return colors.lighterGrey;
    }
    return selected
      ? selectedIconColor
        ? selectedIconColor
        : 'secondary.main'
      : iconColor
      ? iconColor
      : 'secondary.light';
  }

  function handleSelectItem() {
    if (!disabled && onSelectItem) {
      onSelectItem();
    }
  }

  return (
    <ListItem
      sx={{width: 'auto', cursor: disabled ? undefined : 'pointer'}}
      onClick={handleSelectItem}>
      {IconComponent != null && (
        <ListItemIcon sx={{minWidth: '35px'}}>
          <Icon
            IconComponent={IconComponent}
            size="25px"
            color={getIconColor()}
          />
        </ListItemIcon>
      )}
      <Typography
        variant="body2"
        noWrap
        sx={{
          fontWeight: selected ? 'bold' : '400',
          color: getTextColor(),
        }}>
        {text}
      </Typography>
    </ListItem>
  );
}

export default MenuItem;
