import RecipeType from '../../models/entities/recipeType';
import CreateRecipeTypeRequest from '../../models/requests/createRecipeType';
import DeleteRecipeTypeParams from '../../models/requests/deleteRecipeType';
import GetRecipeTypesParams from '../../models/requests/getRecipeTypes';
import RemoveRecipeFromRecipeTypeParams from '../../models/requests/removeRecipeFromRecipeType';
import UpdateRecipeTypeRequest from '../../models/requests/updateRecipeType';
import CreateRecipeTypeResponse from '../../models/responses/createRecipeType';
import DeleteRecipeTypeResponse from '../../models/responses/deleteRecipeType';
import GetRecipeTypesResponse from '../../models/responses/getRecipeTypes';
import RemoveRecipeFromRecipeTypeResponse from '../../models/responses/removeRecipeFromRecipeType';
import UpdateRecipeTypeResponse from '../../models/responses/updateRecipeType';
import {ApplicationError} from '../../utils/errors';
import stringUtils from '../../utils/strings';
import {RecipeTypeService} from '../recipeType';
import data from './data';

const mockRecipeTypeService: RecipeTypeService = {
  getRecipeTypes: function (
    params: GetRecipeTypesParams,
  ): Promise<GetRecipeTypesResponse> {
    return new Promise((resolve) =>
      setTimeout(() => {
        const {ovenModelId} = params;
        const recipeTypes = data.recipeTypes.filter(
          (recipeType) =>
            ovenModelId == null || recipeType.ovenModelId === ovenModelId,
        );
        resolve(recipeTypes);
      }, 500),
    );
  },
  createRecipeType: async function (
    request: CreateRecipeTypeRequest,
  ): Promise<CreateRecipeTypeResponse> {
    return new Promise((resolve, reject) =>
      setTimeout(() => {
        const {id, description} = request;
        if (stringUtils.anyIsNullOrWhiteSpace(id, description)) {
          reject(ApplicationError.badRequest());
          return;
        }
        const recipeType: RecipeType = {...request};
        data.recipeTypes.push(recipeType);
        resolve({...recipeType});
      }, 500),
    );
  },
  updateRecipeType: async function (
    request: UpdateRecipeTypeRequest,
  ): Promise<UpdateRecipeTypeResponse> {
    return new Promise((resolve, reject) =>
      setTimeout(() => {
        const {id: recipeTypeId} = request;
        const exists = data.recipeTypes.some(
          (recipeType) => recipeType.id === recipeTypeId,
        );
        if (!exists) {
          reject(ApplicationError.notFound('RecipeType'));
          return;
        }
        data.recipeTypes = data.recipeTypes.map((recipeType) => ({
          ...recipeType,
          description:
            recipeType.id === recipeTypeId
              ? request.description ?? recipeType.description
              : recipeType.description,
        }));
        const recipeType = data.recipeTypes.find(
          (recipeType) => recipeType.id === recipeTypeId,
        );
        resolve({...recipeType!});
      }, 500),
    );
  },
  deleteRecipeType: function (
    params: DeleteRecipeTypeParams,
  ): Promise<DeleteRecipeTypeResponse> {
    return new Promise((resolve, reject) =>
      setTimeout(() => {
        const {id: recipeTypeId} = params;
        const exists = data.recipeTypes.some(
          (recipeType) => recipeType.id === recipeTypeId,
        );
        if (!exists) {
          reject(ApplicationError.notFound('RecipeType'));
          return;
        }
        data.recipeTypes = data.recipeTypes.filter(
          (recipeType) => recipeType.id !== recipeTypeId,
        );
        data.recipes = data.recipes.map((recipe) => ({
          ...recipe,
          recipeTypes: recipe.recipeTypes?.filter(
            (recipeType) => recipeType.id !== recipeTypeId,
          ),
        }));
        resolve(recipeTypeId);
      }, 500),
    );
  },
  removeRecipeFromRecipeType: function (
    params: RemoveRecipeFromRecipeTypeParams,
  ): Promise<RemoveRecipeFromRecipeTypeResponse> {
    return new Promise((resolve, reject) =>
      setTimeout(() => {
        const {recipeTypeId, recipeId} = params;
        const exists = data.recipes.some((recipe) => recipe.id === recipeId);
        if (!exists) {
          reject(ApplicationError.notFound('Recipe'));
          return;
        }
        data.recipes = data.recipes.map((recipe) => ({
          ...recipe,
          recipeTypes:
            recipe.id === recipeId
              ? recipe.recipeTypes?.filter(
                  (recipeType) => recipeType.id !== recipeTypeId,
                )
              : recipe.recipeTypes,
        }));
        resolve(recipeId);
      }, 500),
    );
  },
};

export default mockRecipeTypeService;
