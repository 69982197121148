import {InputBase, Paper, SvgIcon, Typography} from '@mui/material';
import {Fragment} from 'react';
import {ReactComponent as SearchIcon} from '../../assets/icons/search.svg';
import locals from '../../localization/locals';
import Bakery from '../../models/entities/bakery';
import City from '../../models/entities/city';
import colors from '../../themes/colors';

export type SearchFieldProps = {
  searchText: string;
  setSearchText: (searchText: string) => void;
  selectedCity: City | null;
  selectedBakery: Bakery | null;
  onReturnToBakeries: () => void;
};

function SearchField(props: SearchFieldProps) {
  const {
    searchText,
    setSearchText,
    selectedCity,
    selectedBakery,
    onReturnToBakeries,
  } = props;

  return (
    <Paper
      elevation={4}
      sx={{
        display: 'flex',
        alignItems: 'center',
        minHeight: '36px',
        marginBlock: 2,
        paddingInline: 2,
        borderRadius: '10px',
        backgroundColor: '#F4F4F4',
      }}>
      {selectedCity != null && (
        <Fragment>
          <Typography
            variant="body2"
            sx={{fontWeight: 'bold', cursor: 'pointer'}}
            onClick={onReturnToBakeries}>
            {selectedCity.name}
          </Typography>
          <Typography variant="body2" sx={{marginInline: '5px'}}>
            |
          </Typography>
        </Fragment>
      )}
      {selectedBakery != null ? (
        <Typography variant="body2" sx={{flex: 1, fontWeight: 300}}>
          {selectedBakery.name}
        </Typography>
      ) : (
        <InputBase
          sx={{flex: 1, height: '36px', fontSize: '0.875rem'}}
          placeholder={locals.getText('bakeries_search_field_placeholder')}
          value={searchText}
          onChange={(event) => setSearchText(event.target.value)}
        />
      )}
      <SvgIcon
        component={SearchIcon}
        inheritViewBox
        sx={{fontSize: '20px', color: colors.grey, cursor: 'pointer'}}
        onClick={onReturnToBakeries}
      />
    </Paper>
  );
}

export default SearchField;
