const colors = {
  black: '#000000',
  blue: '#6395C2',
  green: '#8AC381',
  grey: '#989696',
  lightGrey: '#CBC9C9',
  lighterGrey: '#EFEFEF',
  orange: '#ED8843',
  red: '#E30613',
  white: '#FFFFFF',
};

export default colors;
