import {v4 as uuid} from 'uuid';
import Bakery from '../../models/entities/bakery';
import City from '../../models/entities/city';
import Cleaning from '../../models/entities/cleaning';
import Company from '../../models/entities/company';
import Country from '../../models/entities/country';
import District from '../../models/entities/district';
import Notification from '../../models/entities/notification';
import Oven from '../../models/entities/oven';
import OvenChamber from '../../models/entities/ovenChamber';
import OvenGroup from '../../models/entities/ovenGroup';
import OvenModel, {OvenModelId} from '../../models/entities/ovenModel';
import OvenPanel, {OvenPanelStat} from '../../models/entities/ovenPanel';
import OvenPanelNotification from '../../models/entities/ovenPanelNotification';
import OvenSubModel, {OvenSubModelId} from '../../models/entities/ovenSubModel';
import Recipe from '../../models/entities/recipe';
import RecipeType from '../../models/entities/recipeType';
import Schedule, {ScheduleType} from '../../models/entities/schedule';
import User, {UserRole} from '../../models/entities/user';
import UserNotification from '../../models/entities/userNotification';
import dateUtils, {Day} from '../../utils/dates';

let countries: Country[] = [
  {id: 1, iso: 'BRZ', name: 'Brasil'},
  {id: 2, iso: 'CHN', name: 'China'},
  {id: 3, iso: 'KOR', name: 'Coréia do Sul'},
  {id: 4, iso: 'JPN', name: 'Japão'},
  {id: 5, iso: 'PRT', name: 'Portugal'},
];

let districts: District[] = [
  {id: 1, name: 'São Paulo', countryId: countries[0].id},
  {id: 2, name: 'Rio de Janeiro', countryId: countries[0].id},
  {id: 3, name: 'Pequim', countryId: countries[1].id},
  {id: 4, name: 'Shanghai', countryId: countries[1].id},
  {id: 5, name: 'Seul', countryId: countries[2].id},
  {id: 6, name: 'Busan', countryId: countries[2].id},
  {id: 7, name: 'Tóquio', countryId: countries[3].id},
  {id: 8, name: 'Osaka', countryId: countries[3].id},
  {id: 9, name: 'Porto', countryId: countries[4].id},
  {id: 10, name: 'Lisboa', countryId: countries[4].id},
];

let cities: City[] = [
  {id: 1, name: 'São Paulo', districtId: districts[0].id},
  {id: 2, name: 'Campos do Jordão', districtId: districts[0].id},
  {id: 3, name: 'Rio de Janeiro', districtId: districts[1].id},
  {id: 4, name: 'Niteroi', districtId: districts[1].id},
  {id: 5, name: 'Pequim', districtId: districts[2].id},
  {id: 6, name: 'Shijiazhuang', districtId: districts[2].id},
  {id: 7, name: 'Shanghai', districtId: districts[3].id},
  {id: 8, name: 'Nanjing', districtId: districts[3].id},
  {id: 9, name: 'Seul', districtId: districts[4].id},
  {id: 10, name: 'Incheon', districtId: districts[4].id},
  {id: 11, name: 'Busan', districtId: districts[5].id},
  {id: 12, name: 'Daegu', districtId: districts[5].id},
  {id: 13, name: 'Tóquio', districtId: districts[6].id},
  {id: 14, name: 'Akiruno', districtId: districts[6].id},
  {id: 15, name: 'Osaka', districtId: districts[7].id},
  {id: 16, name: 'Izumi', districtId: districts[7].id},
  {id: 17, name: 'Porto', districtId: districts[8].id},
  {id: 18, name: 'Vila Nova de Gaia', districtId: districts[8].id},
  {id: 19, name: 'Lisboa', districtId: districts[9].id},
  {id: 20, name: 'Amadora', districtId: districts[9].id},
];

let companies: Company[] = [
  {id: 'ea9a3a43-900e-4c90-904f-d40878e49624', name: 'Feralbyte'},
];

let users: User[] = [
  {
    id: '19b54ca2-77af-4c11-9ea4-7f8fec60d1b7',
    name: 'Teste Feralbyte',
    email: 'teste@feralbyte.com',
    role: UserRole.Administrator,
    language: 'pt',
    companyId: companies[0].id,
  },
];

let bakeries: Bakery[] = (() => {
  let bakeries: Bakery[] = [];
  cities.forEach((city) => {
    [1, 2, 3].forEach((number) => {
      bakeries.push({
        id: uuid(),
        name: `Padaria ${city.name} ${number}`,
        cityId: city.id,
        companyId: companies[0].id,
      });
    });
  });
  return bakeries;
})();

let ovenModels: OvenModel[] = [
  {id: OvenModelId.Compactram, description: 'Compactram'},
  {id: OvenModelId.Electram, description: 'Electram'},
  {id: OvenModelId.Modulram, description: 'Modulram'},
  {id: OvenModelId.Rotoram, description: 'Rotoram'},
  {id: OvenModelId.Turboram, description: 'Turboram'},
];

let ovenSubModels: OvenSubModel[] = [
  {
    id: OvenSubModelId.TurboramSingle,
    description: 'Turboram Single',
    ovenModelId: OvenModelId.Turboram,
  },
  {
    id: OvenSubModelId.TurboramXL,
    description: 'Turboram XL',
    ovenModelId: OvenModelId.Turboram,
  },
  {
    id: OvenSubModelId.TurboramCombi,
    description: 'Turboram Combi',
    ovenModelId: OvenModelId.Turboram,
  },
  {
    id: OvenSubModelId.TurboramSinglePlusHomeModule,
    description: 'Turboram Single + Home Module',
    ovenModelId: OvenModelId.Turboram,
  },
];

let ovenGroups: OvenGroup[] = [
  ...[1, 2].map((ovenGroupNumber) => ({
    id: uuid(),
    description: `Turboram Combi ${ovenGroupNumber}`,
    ovenModelId: OvenModelId.Turboram,
    ovenSubModelId: OvenSubModelId.TurboramSingle,
    bakeryId: '',
    assignedAt: dateUtils.addDays(new Date(), -10).toISOString(),
  })),
];

let ovens: Oven[] = [
  ...[1, 2].map((ovenNumber) => ({
    id: uuid(),
    reference: uuid(),
    serialNumber: '123456789987654321',
    description: `Compactram 2000/${ovenNumber}`,
    ovenModelId: OvenModelId.Compactram,
    bakeryId: '',
    assignedAt: dateUtils.addDays(new Date(), -20).toISOString(),
  })),
  ...[1, 2].map((ovenNumber) => ({
    id: uuid(),
    reference: uuid(),
    serialNumber: '123456789987654321',
    description: `Electram 2000/${ovenNumber}`,
    ovenModelId: OvenModelId.Electram,
    bakeryId: '',
    assignedAt: dateUtils.addDays(new Date(), -20).toISOString(),
  })),
  ...[1, 2].map((ovenNumber) => ({
    id: uuid(),
    reference: uuid(),
    serialNumber: '123456789987654321',
    description: `Modulram 2000/${ovenNumber}`,
    ovenModelId: OvenModelId.Modulram,
    bakeryId: '',
    assignedAt: dateUtils.addDays(new Date(), -20).toISOString(),
  })),
  ...[1, 2].map((ovenNumber) => ({
    id: uuid(),
    reference: uuid(),
    serialNumber: '123456789987654321',
    description: `Rotoram 2000/${ovenNumber}`,
    ovenModelId: OvenModelId.Rotoram,
    bakeryId: '',
    assignedAt: dateUtils.addDays(new Date(), -20).toISOString(),
  })),
  ...[1, 2].map((ovenNumber) => ({
    id: uuid(),
    reference: uuid(),
    serialNumber: '123456789987654321',
    description: `Turboram 2000/${ovenNumber}`,
    ovenModelId: OvenModelId.Turboram,
    bakeryId: '',
    assignedAt: dateUtils.addDays(new Date(), -20).toISOString(),
  })),
  ...[3, 4].map((ovenNumber, index) => ({
    id: uuid(),
    reference: uuid(),
    serialNumber: '123456789987654321',
    description: `Turboram 2000/${ovenNumber}`,
    ovenModelId: OvenModelId.Turboram,
    ovenGroupId: ovenGroups[0].id,
    ovenGroupOrder: index,
    bakeryId: '',
    assignedAt: dateUtils.addDays(new Date(), -20).toISOString(),
  })),
  ...[5, 6].map((ovenNumber, index) => ({
    id: uuid(),
    reference: uuid(),
    serialNumber: '123456789987654321',
    description: `Turboram 2000/${ovenNumber}`,
    ovenModelId: OvenModelId.Turboram,
    ovenGroupId: ovenGroups[1].id,
    ovenGroupOrder: index,
    bakeryId: '',
    assignedAt: dateUtils.addDays(new Date(), -20).toISOString(),
  })),
];

let ovenChambers: OvenChamber[] = [
  ...[
    [4, 0],
    [4, 1],
    [4, 2],
    [5, 0],
    [5, 1],
    [5, 2],
  ].map(([ovenIndex, ovenOrder]) => ({
    id: uuid(),
    reference: uuid(),
    serialNumber: '123456789987654321',
    ovenId: ovens[ovenIndex].id,
    ovenOrder: ovenOrder,
    assignedAt: dateUtils.addDays(new Date(), -10).toISOString(),
  })),
];

let ovenPanels: OvenPanel[] = [
  ...[
    [0, 0],
    [0, 1],
    [0, 2],
    [1, 0],
    [1, 1],
    [1, 2],
    [2, 0],
    [2, 1],
    [2, 2],
    [3, 0],
    [3, 1],
    [3, 2],
  ].map(([ovenIndex, ovenOrder]) => ({
    id: uuid(),
    reference: uuid(),
    serialNumber: '123456789987654321',
    ovenId: ovens[ovenIndex].id,
    ovenOrder: ovenOrder,
  })),
  ...[0, 1, 2, 3, 4, 5].map((ovenChamberIndex) => ({
    id: uuid(),
    reference: uuid(),
    serialNumber: '123456789987654321',
    ovenChamberId: ovenChambers[ovenChamberIndex].id,
    ovenChamberOrder: 0,
  })),
  ...[6, 7, 8, 9, 10, 11, 12, 13].map((ovenIndex) => ({
    id: uuid(),
    reference: uuid(),
    serialNumber: '123456789987654321',
    ovenId: ovens[ovenIndex].id,
    ovenOrder: 0,
  })),
];

let notifications: Notification[] = [
  {
    id: 1,
    parameter: 'Device_OnState',
    description: 'Device switched state.',
    ovenModelId: OvenModelId.Turboram,
  },
  {
    id: 2,
    parameter: 'Fault_ControlCommunication',
    description: 'Master to control communication fault.',
    ovenModelId: OvenModelId.Turboram,
  },
];

let userNotifications: UserNotification[] = [
  {
    id: uuid(),
    notify: true,
    userId: '',
    notificationId: 1,
    parameter: 'Device_OnState',
    ovenModelId: OvenModelId.Turboram,
  },
  {
    id: uuid(),
    notify: true,
    userId: '',
    notificationId: 3,
    parameter: 'Fault_ControlCommunication',
    ovenModelId: OvenModelId.Turboram,
  },
];

let ovenPanelNotifications: OvenPanelNotification[] = (() => {
  const notifications: OvenPanelNotification[] = [];
  ovens.forEach((oven) => {
    [1, 2, 3].forEach((number) => {
      notifications.push({
        id: uuid(),
        notificationId: number < 3 ? 1 : 3,
        parameter: number < 3 ? 'Device_OnState' : 'Fault_ControlCommunication',
        value: number === 1 ? '1' : number === 2 ? '0' : '24',
        description:
          number < 3
            ? 'Device switched state.'
            : 'Control communication fault.',
        bakeryId: bakeries[0].id,
        bakeryName: bakeries[0].name,
        ovenId: oven.id,
        ovenDescription: oven.description ?? '',
        date: dateUtils.addDays(new Date(), -number).toISOString(),
        isSeen: notifications.length > 5,
        isFlagged: notifications.length > 35,
      });
    });
  });
  return notifications;
})();

let recipeTypes: RecipeType[] = [
  {id: uuid(), description: 'pizzas', ovenModelId: OvenModelId.Compactram},
  {id: uuid(), description: 'paes', ovenModelId: OvenModelId.Electram},
  {id: uuid(), description: 'pasteis', ovenModelId: OvenModelId.Modulram},
  {id: uuid(), description: 'bolos', ovenModelId: OvenModelId.Rotoram},
  {id: uuid(), description: 'empadas', ovenModelId: OvenModelId.Turboram},
];

let recipes: Recipe[] = [
  ...[1, 2].map((number) => ({
    id: uuid(),
    name: `pizza ${number}`,
    ovenModelId: OvenModelId.Compactram,
    procedure: `pizza ${number}`,
    modifiedAt: dateUtils.addDays(new Date(), -10).toISOString(),
    phases: [1, 2].map(() => ({
      topTemperature: 150,
      floorTemperature: 150,
      topPower: 50,
      floorPower: 50,
      duration: 40,
      steamInjectionDuration: 2,
      steamExitValveOpened: true,
    })),
    recipeTypes: [recipeTypes[0]],
  })),
  ...[1, 2].map((number) => ({
    id: uuid(),
    name: `pao ${number}`,
    ovenModelId: OvenModelId.Electram,
    procedure: `pao ${number}`,
    modifiedAt: dateUtils.addDays(new Date(), -12).toISOString(),
    phases: [1, 2].map(() => ({
      topTemperature: 150,
      floorTemperature: 150,
      topPower: 50,
      floorPower: 50,
      duration: 40,
      steamInjectionDuration: 2,
      steamExitValveOpened: true,
    })),
    recipeTypes: [recipeTypes[1]],
  })),
  ...[1, 2].map((number) => ({
    id: uuid(),
    name: `pastel ${number}`,
    ovenModelId: OvenModelId.Modulram,
    procedure: `pastel ${number}`,
    modifiedAt: dateUtils.addDays(new Date(), -14).toISOString(),
    phases: [1, 2].map(() => ({
      topTemperature: 150,
      floorTemperature: 150,
      topPower: 50,
      floorPower: 50,
      duration: 40,
      steamInjectionDuration: 2,
      steamExitValveOpened: true,
    })),
    recipeTypes: [recipeTypes[2]],
  })),
  ...[1, 2].map((number) => ({
    id: uuid(),
    name: `bolo ${number}`,
    ovenModelId: OvenModelId.Rotoram,
    procedure: `bolo ${number}`,
    modifiedAt: dateUtils.addDays(new Date(), -16).toISOString(),
    phases: [1, 2].map(() => ({
      temperature: 150,
      duration: 40,
      steamInjectionNumber: 2,
      steamExitValveOpened: true,
      turbineRestSeconds: 15,
    })),
    recipeTypes: [recipeTypes[3]],
  })),
  ...[1, 2].map((number) => ({
    id: uuid(),
    name: `empada ${number}`,
    ovenModelId: OvenModelId.Turboram,
    procedure: `empada ${number}`,
    modifiedAt: dateUtils.addDays(new Date(), -18).toISOString(),
    phases: [1, 2].map(() => ({
      temperature: 150,
      duration: 40,
      steamInjectionNumber: 2,
      steamExitValveOpened: true,
      turbineSpeed: 0,
    })),
    recipeTypes: [recipeTypes[4]],
  })),
];

let ovenPanelStatistics: OvenPanelStat[] = [4, 5, 6, 6, 5, 4, 4, 5, 6, 6].map(
  (number, index) => ({
    date: dateUtils.addDays(new Date(), -index).toISOString(),
    onTimeInMinutes: 1440 * (number * 0.1),
    offTimeInMinutes: 1440 * (1 - number * 0.1),
    cookingTimeInMinutes: 1440 * (number * 0.1 - 0.1),
  }),
);

let cleanings: Cleaning[] = [
  {
    id: 1,
    description: 'lavagem ligeira',
    duration: 35,
    ovenModelId: OvenModelId.Turboram,
  },
  {
    id: 2,
    description: 'lavagem média',
    duration: 90,
    ovenModelId: OvenModelId.Turboram,
  },
  {
    id: 3,
    description: 'lavagem intensa',
    duration: 130,
    ovenModelId: OvenModelId.Turboram,
  },
];

let schedules: Schedule[] = [
  ...[
    [0, 1, 0],
    [2, 1, 0],
    [4, 1, 0],
    [6, 2, 2],
    [8, 2, 2],
    [10, 2, 2],
    [12, 3, 4],
    [14, 3, 4],
    [16, 3, 4],
    [18, 4, 6],
    [20, 5, 8],
    [22, 5, 8],
    [24, 5, 8],
  ].map(([ovenPanelIndex, ovenModelId, recipeIndex]) => ({
    id: uuid(),
    type: ScheduleType.Recipe,
    day: 1 as Day,
    startTime: '08:00',
    endTime: '09:20',
    recipeId: recipes[recipeIndex].id,
    ovenPanelId: ovenPanels[ovenPanelIndex].id,
    ovenModelId,
  })),
  ...[
    [0, 1, 1],
    [2, 1, 1],
    [4, 1, 1],
    [6, 2, 3],
    [8, 2, 3],
    [10, 2, 3],
    [12, 3, 5],
    [14, 3, 5],
    [16, 3, 5],
    [18, 4, 7],
    [20, 5, 9],
    [22, 5, 9],
    [24, 5, 9],
  ].map(([ovenPanelIndex, ovenModelId, recipeIndex]) => ({
    id: uuid(),
    type: ScheduleType.Recipe,
    day: 1 as Day,
    startTime: '10:30',
    endTime: '11:50',
    recipeId: recipes[recipeIndex].id,
    ovenPanelId: ovenPanels[ovenPanelIndex].id,
    ovenModelId,
  })),
  ...[22, 24].map((ovenPanelIndex) => ({
    id: uuid(),
    type: ScheduleType.Cleaning,
    day: 1 as Day,
    startTime: '21:00',
    endTime: '23:10',
    cleaningId: cleanings[2].id,
    ovenPanelId: ovenPanels[ovenPanelIndex].id,
    ovenModelId: OvenModelId.Turboram,
  })),
  ...[
    [2, 1],
    [6, 2],
    [10, 2],
    [14, 3],
    [18, 4],
    [22, 5],
  ].map(([ovenPanelIndex, ovenModelId]) => ({
    id: uuid(),
    type: ScheduleType.Off,
    day: 1 as Day,
    startTime: '22:00',
    endTime: '22:00',
    ovenPanelId: ovenPanels[ovenPanelIndex].id,
    ovenModelId,
  })),
];

function getBakery(bakery: Bakery) {
  const city = cities.find((city) => city.id === bakery.cityId);
  const district = districts.find(
    (district) => district.id === city?.districtId,
  );
  const country = countries.find(
    (country) => country.id === district?.countryId,
  );
  return {
    ...bakery,
    cityName: city?.name,
    districtId: district?.id,
    districtName: district?.name,
    countryId: country?.id,
    countryName: country?.name,
  };
}

function getOvenGroup(ovenGroup: OvenGroup): OvenGroup {
  return {
    ...ovenGroup,
    ovens: ovens
      .filter((oven) => oven.ovenGroupId === ovenGroup.id)
      .map(getOven),
  };
}

function getOven(oven: Oven): Oven {
  return {
    ...oven,
    ovenChambers: ovenChambers
      .filter((ovenChamber) => ovenChamber.ovenId === oven.id)
      .map((ovenChamber) => ({
        ...ovenChamber,
        ovenPanels: ovenPanels.filter(
          (ovenPanel) => ovenPanel.ovenChamberId === ovenChamber.id,
        ),
      })),
    ovenPanels: ovenPanels.filter((ovenPanel) => ovenPanel.ovenId === oven.id),
  };
}

function getSchedule(schedule: Schedule): Schedule {
  const recipe = recipes.find((recipe) => recipe.id === schedule.recipeId);
  const cleaning = cleanings.find(
    (cleaning) => cleaning.id === schedule.cleaningId,
  );
  const ovenPanel = ovenPanels.find(
    (ovenPanel) => ovenPanel.id === schedule.ovenPanelId,
  );
  const ovenChamber = ovenChambers.find(
    (ovenChamber) => ovenChamber.id === ovenPanel?.ovenChamberId,
  );
  const oven = ovens.find(
    (oven) => oven.id === ovenPanel?.ovenId || ovenChamber?.ovenId,
  );
  const ovenGroup = ovenGroups.find(
    (ovenGroup) => ovenGroup.id === oven?.ovenGroupId,
  );
  return {
    ...schedule,
    recipeName: recipe?.name,
    cleaningDescription: cleaning?.description,
    ovenPanelIndex: ovenPanel?.ovenOrder ?? ovenPanel?.ovenChamberOrder,
    ovenChamberId: ovenChamber?.id,
    ovenChamberIndex:
      schedule.ovenModelId < 4
        ? ovenChamber?.ovenOrder ??
          ovenPanel?.ovenOrder ??
          ovenPanel?.ovenChamberOrder
        : undefined,
    ovenId: oven?.id,
    ovenIndex: oven?.ovenGroupOrder,
    ovenDescription: oven?.description,
    ovenGroupId: ovenGroup?.id,
    ovenGroupDescription: ovenGroup?.description,
  };
}

const data = {
  countries,
  districts,
  cities,
  companies,
  users,
  bakeries,
  ovenModels,
  ovenSubModels,
  ovenGroups,
  ovens,
  ovenChambers,
  ovenPanels,
  recipeTypes,
  recipes,
  notifications,
  userNotifications,
  ovenPanelNotifications,
  ovenPanelStatistics,
  cleanings,
  schedules,
  getBakery,
  getOvenGroup,
  getOven,
  getSchedule,
};

export default data;
