type NullableArray<TValue> = TValue[] | null | undefined;

const arrayUtils = {
  count: function <TValue>(
    arr: TValue[],
    predicate: (item: TValue, index: number) => boolean,
  ) {
    let count = 0;
    arr.forEach((item, index) => {
      if (predicate(item, index)) {
        count++;
      }
    });
    return count;
  },
  isNullOrEmpty: function <TValue>(arr: NullableArray<TValue>) {
    return arr == null || arr.length === 0;
  },
  hasMultipleItems: function <TValue>(arr: NullableArray<TValue>) {
    return arr != null && arr.length > 1;
  },
  join: function <TValue, TKey>(
    keyPredicate: (item: TValue, index: number) => TKey,
    ...arrays: TValue[][]
  ) {
    const map = new Map<TKey, TValue>();
    arrays.forEach((array) => {
      array.forEach((item, index) => {
        const key = keyPredicate(item, index);
        map.set(key, item);
      });
    });
    return Array.from(map.values());
  },
  hasDuplicateKey: function <TValue, TKey>(
    arr: TValue[],
    keyPredicate: (item: TValue, index: number) => TKey,
  ) {
    const keySet = new Set<TKey>();
    for (let index = 0; index < arr.length; index++) {
      const item = arr[index];
      const key = keyPredicate(item, index);
      if (keySet.has(key)) {
        return true;
      }
      keySet.add(key);
    }
    return false;
  },
};

export default arrayUtils;
