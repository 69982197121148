import {Box, Paper, Typography} from '@mui/material';
import {Fragment, useEffect, useState} from 'react';
import {useMutation, useQuery, useQueryClient} from 'react-query';
import {useNavigate, useSearchParams} from 'react-router-dom';
import {v4 as uuid} from 'uuid';
import {ReactComponent as DeclineIcon} from '../../assets/icons/decline.svg';
import useQueryOnce from '../../hooks/common/useQueryOnce';
import locals from '../../localization/locals';
import OvenModel from '../../models/entities/ovenModel';
import Schedule, {ScheduleType} from '../../models/entities/schedule';
import OverlapsScheduleParams from '../../models/requests/overlapsSchedule';
import paths from '../../routes/paths';
import services from '../../services/provider';
import useBreadcrumbsStore, {Breadcrumb} from '../../state/breadcrumbs';
import {Day} from '../../utils/dates';
import numberUtils from '../../utils/numbers';
import routerUtils from '../../utils/router';
import scheduleUtils, {TimeSlot} from '../../utils/schedules';
import stringUtils from '../../utils/strings';
import IconButton from '../common/IconButton';
import LoadingBackdrop from '../common/LoadingBackdrop';
import Span from '../common/Span';
import CreateOffScheduleMenu from './CreateOffScheduleMenu';
import OvenList from './OvenList';
import SelectTime from './SelectTime';

function getStepTitle(step: CreateOffScheduleStep) {
  switch (step) {
    case 'ovens':
      return locals.getText('programming_ovens_step_title');
    case 'time':
      return locals.getText('programming_time_step_title');
  }
}

function hasMultipleOvenPanels(ovenModel?: OvenModel) {
  let ovenPanelCount = 0;
  for (const ovenGroup of ovenModel?.ovenGroups ?? []) {
    if (ovenPanelCount > 1) return true;
    for (const oven of ovenGroup.ovens ?? []) {
      if (ovenPanelCount > 1) return true;
      ovenPanelCount += oven.ovenChambers?.length ?? 0;
      ovenPanelCount += oven.ovenPanels?.length ?? 0;
    }
  }
  for (const oven of ovenModel?.ovens ?? []) {
    if (ovenPanelCount > 1) return true;
    ovenPanelCount += oven.ovenChambers?.length ?? 0;
    ovenPanelCount += oven.ovenPanels?.length ?? 0;
  }
  return ovenPanelCount > 1;
}

export type CreateOffScheduleSteps = {
  ovens: {visible: boolean; error: boolean};
  time: {visible: boolean; error: boolean};
};

export type CreateOffScheduleStep = keyof CreateOffScheduleSteps;

export type OvenPanelData = {
  id: string;
  ovenChamberId?: string;
  ovenId?: string;
  ovenGroupId?: string;
};

function CreateOffSchedule() {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const queryClient = useQueryClient();

  const setBreadcrumbs = useBreadcrumbsStore((state) => state.setBreadcrumbs);

  const bakeryId = searchParams.get('bakeryId');
  const ovenModelId = numberUtils.parseInt(searchParams.get('ovenModelId'), 1);
  const scheduleId = searchParams.get('scheduleId');

  const [steps, setSteps] = useState<CreateOffScheduleSteps>({
    ovens: {visible: true, error: true},
    time: {visible: false, error: true},
  });
  const [selectedStep, setSelectedStep] =
    useState<CreateOffScheduleStep>('ovens');

  const [defaultValues, setDefaultValues] = useState<Partial<Schedule>>({});

  const [selectedOvenPanels, setSelectedOvenPanels] = useState<OvenPanelData[]>(
    [],
  );
  const selectedOvenPanelIds = selectedOvenPanels.map((panel) => panel.id);
  const selectedOvenPanelIdsSet = new Set(selectedOvenPanelIds);
  const [days, setDays] = useState<Day[]>([]);
  const daysSet = new Set(days);
  const [time, setTime] = useState('00:00');
  const [shadowTime, setShadowTime] = useState('00:00');
  const [isOverlapping, setIsOverlapping] = useState(false);
  const [availableTimeSlots, setAvailableTimeSlots] = useState<TimeSlot[]>([]);
  const hasTime = days.length > 0;

  const {isLoading: loadingSchedule} = useQueryOnce({
    enabled: scheduleId != null,
    queryFn: () => services.schedule.getSchedule({scheduleId: scheduleId!}),
    onSuccess: (schedule) => {
      if (schedule.type !== ScheduleType.Off) return;
      setSelectedOvenPanels([
        {
          id: schedule.ovenPanelId,
          ovenChamberId: schedule.ovenChamberId,
          ovenId: schedule.ovenId,
          ovenGroupId: schedule.ovenGroupId,
        },
      ]);
      setDefaultValues({
        id: schedule.id,
        ovenPanelId: schedule.ovenPanelId,
        endTime: schedule.startTime,
      });
      setTime(schedule.endTime);
      setDays([schedule.day]);
      setSteps({
        ovens: {visible: true, error: false},
        time: {visible: true, error: false},
      });
    },
  });

  const {data: bakery, isLoading: loadingBakery} = useQuery({
    enabled: bakeryId != null,
    queryKey: ['bakery', bakeryId],
    queryFn: () => services.bakery.getBakery({bakeryId: bakeryId!}),
  });

  const {data: ovenModel, isLoading: loadingOvens} = useQuery({
    enabled: bakeryId != null,
    queryKey: ['bakeryOvens', bakeryId, {ovenModelId}],
    queryFn: () =>
      services.bakery.getBakeryOvens({bakeryId: bakeryId!, ovenModelId}),
    select: (ovenModels) => ovenModels[0],
  });

  const {mutate: onValidateTime} = useMutation({
    mutationFn: services.schedule.overlapsSchedule,
    onSuccess: ({overlaps, availableTimeSlots}) => {
      setIsOverlapping(overlaps);
      setAvailableTimeSlots(availableTimeSlots ?? []);
      setSteps((steps) => ({
        ...steps,
        time: {...steps.time, error: overlaps},
      }));
    },
  });

  function validateTime(params: OverlapsScheduleParams) {
    const timeRegex = /^([0-1]?[0-9]|2[0-3]):[0-5][0-9]$/;
    if (timeRegex.test(params.startTime)) {
      onValidateTime(params);
    }
  }

  const {
    mutate: createOrUpdateSchedules,
    isLoading: loadingCreateOrUpdateSchedules,
  } = useMutation({
    mutationFn: services.schedule.createOrUpdateSchedules,
    onSuccess: (_, request) => {
      queryClient.invalidateQueries('schedules');
      navigate({
        pathname: paths.programming,
        search: routerUtils
          .createSearchParams({
            countryId: bakery?.countryId,
            cityId: bakery?.cityId,
            bakeryId: bakery?.id,
            ovenModelId,
            day: request.schedules[0]?.day,
          })
          .toString(),
      });
    },
    onError: scheduleUtils.handleError,
  });

  useEffect(() => {
    const breadcrumbs: Breadcrumb[] = [];
    breadcrumbs.push({
      title: locals.getText('programming_breadcrumb'),
      onClick: () => navigate(paths.programming),
    });
    if (bakery != null) {
      breadcrumbs.push({
        title: bakery.countryName ?? '',
        onClick: () =>
          navigate({
            pathname: paths.programming,
            search: routerUtils
              .createSearchParams({
                countryId: bakery.countryId,
              })
              .toString(),
          }),
      });
      breadcrumbs.push({
        title: bakery.cityName ?? '',
        onClick: () =>
          navigate({
            pathname: paths.programming,
            search: routerUtils
              .createSearchParams({
                countryId: bakery.countryId,
                cityId: bakery.cityId,
              })
              .toString(),
          }),
      });
      breadcrumbs.push({
        title: bakery.name,
        onClick: () =>
          navigate({
            pathname: paths.programming,
            search: routerUtils
              .createSearchParams({
                countryId: bakery.countryId,
                cityId: bakery.cityId,
                bakeryId: bakery.id,
              })
              .toString(),
          }),
      });
      if (ovenModel != null) {
        breadcrumbs.push({
          title: ovenModel.description,
          onClick: () =>
            navigate({
              pathname: paths.programming,
              search: routerUtils
                .createSearchParams({
                  countryId: bakery.countryId,
                  cityId: bakery.cityId,
                  bakeryId: bakery.id,
                  ovenModelId: ovenModel.id,
                })
                .toString(),
            }),
        });
      }
    }
    breadcrumbs.push({
      title: locals.getText('add_off_schedule_breadcrumb'),
      onClick: () => {
        setDefaultValues({});
        setSelectedOvenPanels([]);
        setDays([]);
        setTime('00:00');
        setShadowTime('00:00');
        setIsOverlapping(false);
        setAvailableTimeSlots([]);
        setSteps({
          ovens: {visible: true, error: true},
          time: {visible: false, error: true},
        });
        setSelectedStep('ovens');
      },
    });
    setBreadcrumbs(breadcrumbs);
    return () => setBreadcrumbs([]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bakery, ovenModelId]);

  function handleSelectOvenPanel(ovenPanel: OvenPanelData) {
    if (selectedOvenPanelIdsSet.has(ovenPanel.id)) {
      selectedOvenPanelIdsSet.delete(ovenPanel.id);
      setSelectedOvenPanels((selectedOvenPanels) =>
        selectedOvenPanels.filter(
          (selectedOvenPanel) => selectedOvenPanel.id !== ovenPanel.id,
        ),
      );
    } else {
      selectedOvenPanelIdsSet.add(ovenPanel.id);
      setSelectedOvenPanels((selectedOvenPanels) => [
        ...selectedOvenPanels,
        ovenPanel,
      ]);
    }
    setSteps((steps) => ({
      ...steps,
      ovens: {...steps.ovens, error: selectedOvenPanelIdsSet.size === 0},
      time: {visible: true, error: true},
    }));
    if (
      selectedOvenPanelIdsSet.size === 1 &&
      !hasMultipleOvenPanels(ovenModel)
    ) {
      setSelectedStep('time');
    }
    validateTime({
      ovenPanelIds: Array.from(selectedOvenPanelIdsSet),
      days,
      startTime: time,
      endTime: time,
      ignoreScheduleIds:
        defaultValues.id != null ? [defaultValues.id] : undefined,
    });
  }

  function handleSelectDay(day: Day) {
    if (daysSet.has(day)) {
      daysSet.delete(day);
    } else {
      daysSet.add(day);
    }
    setDays(Array.from(daysSet));
    setSteps((steps) => ({
      ...steps,
      time: {...steps.time, error: true},
    }));
    validateTime({
      ovenPanelIds: selectedOvenPanelIds,
      days: Array.from(daysSet),
      startTime: time,
      endTime: time,
      ignoreScheduleIds:
        defaultValues.id != null ? [defaultValues.id] : undefined,
    });
  }

  function handleSelectAllDays() {
    setDays([1, 2, 3, 4, 5, 6, 0]);
    setSteps((steps) => ({
      ...steps,
      time: {...steps.time, error: true},
    }));
    validateTime({
      ovenPanelIds: selectedOvenPanelIds,
      days: [1, 2, 3, 4, 5, 6, 0],
      startTime: time,
      endTime: time,
      ignoreScheduleIds:
        defaultValues.id != null ? [defaultValues.id] : undefined,
    });
  }

  function handleSetTime(time: string) {
    setShadowTime('');
    setTime(time);
    setSteps((steps) => ({
      ...steps,
      time: {...steps.time, error: true},
    }));
    validateTime({
      ovenPanelIds: selectedOvenPanelIds,
      days,
      startTime: time,
      endTime: time,
      ignoreScheduleIds:
        defaultValues.id != null ? [defaultValues.id] : undefined,
    });
  }

  function handleTimeFocus() {
    setShadowTime(time);
    setTime('');
    setSteps((steps) => ({...steps, time: {...steps.time, error: true}}));
  }

  function handleTimeBlur() {
    if (stringUtils.isNullOrWhiteSpace(time)) {
      if (!stringUtils.isNullOrWhiteSpace(shadowTime)) {
        setTime(shadowTime);
        validateTime({
          ovenPanelIds: selectedOvenPanelIds,
          days,
          startTime: shadowTime,
          endTime: shadowTime,
          ignoreScheduleIds:
            defaultValues.id != null ? [defaultValues.id] : undefined,
        });
        return;
      }
      setTime('00:00');
      validateTime({
        ovenPanelIds: selectedOvenPanelIds,
        days,
        startTime: '00:00',
        endTime: '00:00',
        ignoreScheduleIds:
          defaultValues.id != null ? [defaultValues.id] : undefined,
      });
    }
  }

  function handleCreateOrUpdateSchedules() {
    let usedDefaultValues = false;
    const schedules: Schedule[] = [];
    selectedOvenPanels.forEach((ovenPanel) => {
      days.forEach((day) => {
        let scheduleId = uuid();
        if (ovenPanel.id === defaultValues.ovenPanelId && !usedDefaultValues) {
          scheduleId = defaultValues.id!;
          usedDefaultValues = true;
        }
        schedules.push({
          id: scheduleId,
          type: ScheduleType.Off,
          day,
          startTime: time,
          endTime: time,
          ovenPanelId: ovenPanel.id,
          ovenModelId,
        });
      });
    });
    createOrUpdateSchedules({schedules});
  }

  const loading =
    loadingSchedule ||
    loadingBakery ||
    loadingOvens ||
    loadingCreateOrUpdateSchedules;
  const renderOverlappingError =
    selectedStep === 'time' && steps.time.error && isOverlapping && hasTime;

  return (
    <Fragment>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          width: '20vw',
          marginBlock: '112px',
        }}>
        <CreateOffScheduleMenu
          steps={steps}
          hasTime={hasTime}
          selectedStep={selectedStep}
          setSelectedStep={setSelectedStep}
          onFinish={handleCreateOrUpdateSchedules}
        />
      </Box>
      <Box sx={{width: '80vw'}}>
        <Box sx={{display: 'flex'}}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between',
              width: '65vw',
              height: '112px',
            }}>
            <Paper
              elevation={4}
              sx={{
                display: 'flex',
                alignItems: 'center',
                width: '100%',
                marginBlock: 2,
                paddingBlock: 1,
                paddingInline: 2,
                borderRadius: '10px',
                backgroundColor: '#F4F4F4',
              }}>
              <Typography variant="body2" sx={{fontWeight: 'bold'}}>
                {locals.getText('programming_off_programming_title')}
              </Typography>
            </Paper>
            <Typography
              variant="body2"
              sx={{
                fontWeight: 'bold',
                color: 'text.primary',
                paddingInline: 2,
              }}>
              {getStepTitle(selectedStep)}
            </Typography>
            <Box sx={{minHeight: '23px', paddingInline: 2}}>
              {renderOverlappingError && (
                <Typography
                  variant="caption"
                  sx={{
                    color: 'primary.main',
                    paddingTop: '3px',
                  }}>
                  <Span sx={{fontWeight: 'bold'}}>
                    {locals.getText(
                      'programming_overlapping_times_error_title',
                    )}
                  </Span>
                  <Span>
                    {` - ${locals.getText(
                      'programming_overlapping_times_error_caption',
                    )} `}
                  </Span>
                  {availableTimeSlots.map((timeSlot, index) => (
                    <Span key={index}>
                      {`${timeSlot.startTime} - ${timeSlot.endTime}${
                        index < availableTimeSlots.length - 1 ? ' | ' : ''
                      }`}
                    </Span>
                  ))}
                </Typography>
              )}
            </Box>
          </Box>
          <Box sx={{width: '15vw', textAlign: 'center', padding: 2.2}}>
            <IconButton
              IconComponent={DeclineIcon}
              activeColor="secondary"
              onClick={() =>
                navigate({
                  pathname: paths.programming,
                  search: routerUtils
                    .createSearchParams({
                      countryId: bakery?.countryId,
                      cityId: bakery?.cityId,
                      bakeryId: bakery?.id,
                      ovenModelId,
                    })
                    .toString(),
                })
              }
            />
          </Box>
        </Box>
        <Box>
          {selectedStep === 'ovens' && (
            <OvenList
              ovenGroups={ovenModel?.ovenGroups ?? []}
              ovens={ovenModel?.ovens ?? []}
              selectedOvenPanelIds={selectedOvenPanels.map(
                (ovenPanel) => ovenPanel.id,
              )}
              onSelectOvenPanel={handleSelectOvenPanel}
              ovensNotFoundMessage={
                loadingOvens
                  ? ''
                  : locals.getText('programming_ovens_not_found_label')
              }
            />
          )}
          {selectedStep === 'time' && (
            <SelectTime
              startTime={time}
              endTime=""
              setStartTime={handleSetTime}
              selectedDays={days}
              onSelectDay={handleSelectDay}
              onSelectAllDays={handleSelectAllDays}
              error={hasTime && steps.time.error}
              onStartTimeFocus={handleTimeFocus}
              onStartTimeBlur={handleTimeBlur}
            />
          )}
        </Box>
      </Box>
      <LoadingBackdrop loading={loading} />
    </Fragment>
  );
}

export default CreateOffSchedule;
