import {createTheme} from '@mui/material';
import colors from './colors';

export default createTheme({
  palette: {
    primary: {
      light: colors.red,
      main: colors.red,
      dark: colors.red,
    },
    secondary: {
      light: colors.lightGrey,
      main: colors.grey,
      dark: colors.grey,
    },
    text: {
      primary: colors.grey,
      secondary: colors.lightGrey,
      disabled: colors.lighterGrey,
    },
  },
  components: {
    MuiButton: {
      defaultProps: {
        style: {
          padding: '6px',
          fontSize: '18px',
          textTransform: 'none',
          borderRadius: '10px',
          color: colors.white,
        },
      },
    },
    MuiInputBase: {
      defaultProps: {
        spellCheck: false,
      },
    },
    MuiTableCell: {
      defaultProps: {
        style: {
          border: 'none',
        },
      },
    },
  },
});
