import {Box} from '@mui/material';
import {Fragment} from 'react';
import {Navigate, Outlet, Route, Routes as RRDRoutes} from 'react-router-dom';
import AuthLayout from '../components/auth/AuthLayout';
import Login from '../components/auth/Login';
import PasswordRecovery from '../components/auth/PasswordRecovery';
import PasswordReset from '../components/auth/PasswordReset';
import Signup from '../components/auth/Signup';
import Welcome from '../components/auth/Welcome';
import Bakeries from '../components/bakeries/Bakeries';
import BakeriesLayout from '../components/bakeries/BakeriesLayout';
import BakeryInvite from '../components/bakeries/BakeryInvite';
import CreateBakery from '../components/bakeries/CreateBakery';
import NotFound from '../components/common/NotFound';
import Dashboard from '../components/navigation/Dashboard';
import {
  AuthNavbar,
  DashboardNavbar,
  pageHeight,
} from '../components/navigation/Navbar';
import ChangePassword from '../components/profile/ChangePassword';
import Information from '../components/profile/Information';
import MigrateAccount from '../components/profile/MigrateAccount';
import Notifications from '../components/profile/Notifications';
import ProfileLayout from '../components/profile/ProfileLayout';
import Settings from '../components/profile/Settings';
import CreateCleaningSchedule from '../components/programming/CreateCleaningSchedule';
import CreateOffSchedule from '../components/programming/CreateOffSchedule';
import CreateRecipeSchedule from '../components/programming/CreateRecipeSchedule';
import ProgrammingLayout from '../components/programming/ProgrammingLayout';
import Schedules from '../components/programming/Schedules';
import Recipes from '../components/recipes/Recipes';
import RecipesLayout from '../components/recipes/RecipesLayout';
import RecipeSettings from '../components/recipes/settings/RecipeSettings';
import Statistics from '../components/statistics/Statistics';
import StatisticsLayout from '../components/statistics/StatisticsLayout';
import paths from './paths';
import PrivateRoute from './PrivateRoute';

function Main({auth = false}) {
  return (
    <Fragment>
      {auth ? <AuthNavbar /> : <DashboardNavbar />}
      <Box
        component="main"
        style={{
          width: '100vw',
          height: pageHeight,
          backgroundColor: '#FFFFFF',
        }}>
        <Outlet />
      </Box>
    </Fragment>
  );
}

function Routes() {
  return (
    <RRDRoutes>
      <Route element={<Main auth />}>
        <Route path={paths.home} element={<AuthLayout />}>
          <Route index element={<Navigate to={paths.welcome} replace />} />
          <Route path={paths.welcome} element={<Welcome />} />
          <Route path={paths.login} element={<Login />} />
          <Route path={paths.passwordRecovery} element={<PasswordRecovery />} />
          <Route path={paths.passwordReset} element={<PasswordReset />} />
          <Route path={paths.signup} element={<Signup />} />
          <Route element={<PrivateRoute />}>
            <Route
              path={paths.profileChangePassword}
              element={<ChangePassword />}
            />
            <Route
              path={paths.profileMigrateAccount}
              element={<MigrateAccount />}
            />
            <Route path={paths.bakeriesCreate} element={<CreateBakery />} />
            <Route path={paths.bakeriesInvite} element={<BakeryInvite />} />
          </Route>
        </Route>
      </Route>
      <Route element={<PrivateRoute />}>
        <Route element={<Main />}>
          <Route path={paths.dashboard} element={<Dashboard />} />
          <Route path={paths.profile} element={<ProfileLayout />}>
            <Route
              index
              element={<Navigate to={paths.profileInformation} replace />}
            />
            <Route path={paths.profileInformation} element={<Information />} />
            <Route
              path={paths.profileNotifications}
              element={<Notifications />}
            />
            <Route path={paths.profileSettings} element={<Settings />} />
          </Route>
          <Route path={paths.bakeries} element={<BakeriesLayout />}>
            <Route index element={<Bakeries />} />
          </Route>
          <Route path={paths.recipes} element={<RecipesLayout />}>
            <Route index element={<Recipes />} />
            <Route path={paths.createRecipe} element={<RecipeSettings />} />
            <Route path={paths.editRecipe} element={<RecipeSettings />} />
          </Route>
          <Route path={paths.statistics} element={<StatisticsLayout />}>
            <Route index element={<Statistics />} />
          </Route>
          <Route path={paths.programming} element={<ProgrammingLayout />}>
            <Route index element={<Schedules />} />
            <Route
              path={paths.createRecipeSchedule}
              element={<CreateRecipeSchedule />}
            />
            <Route
              path={paths.createCleaningSchedule}
              element={<CreateCleaningSchedule />}
            />
            <Route
              path={paths.createOffSchedule}
              element={<CreateOffSchedule />}
            />
          </Route>
        </Route>
      </Route>
      <Route path="*" element={<NotFound />} />
    </RRDRoutes>
  );
}

export default Routes;
