import {Box, Paper, Typography} from '@mui/material';
import {useEffect, useMemo, useState} from 'react';
import {useMutation, useQuery, useQueryClient} from 'react-query';
import {useNavigate} from 'react-router-dom';
import {ReactComponent as DeleteIcon} from '../../assets/icons/delete.svg';
import {ReactComponent as FlagIcon} from '../../assets/icons/flag.svg';
import useLocalization from '../../hooks/common/useLocalization';
import locals from '../../localization/locals';
import OvenPanelNotification from '../../models/entities/ovenPanelNotification';
import paths from '../../routes/paths';
import services from '../../services/provider';
import useBreadcrumbsStore from '../../state/breadcrumbs';
import dateUtils from '../../utils/dates';
import ovenPanelNotificationUtils from '../../utils/notifications';
import IconButton from '../common/IconButton';
import LoadingBackdrop from '../common/LoadingBackdrop';
import Span from '../common/Span';
import Switch from '../common/Switch';
import Tabs from '../common/Tabs';
import {pageHeight} from '../navigation/Navbar';
import NotificationList from './NotificationList';

function Notifications() {
  const navigate = useNavigate();
  const [language] = useLocalization();
  const queryClient = useQueryClient();

  const setBreadcrumbs = useBreadcrumbsStore((state) => state.setBreadcrumbs);

  const tabs = [
    locals.getText('profile_notifications_tabs_warnings_label'),
    locals.getText('profile_notifications_tabs_maintenance_label'),
    locals.getText('profile_notifications_tabs_flagged_label'),
  ];
  const [selectedTabIndex, setSelectedTabIndex] = useState(0);

  useEffect(() => {
    setBreadcrumbs([
      {
        title: locals.getText('profile_breadcrumb'),
        onClick: () => navigate(paths.profile),
      },
      {
        title: locals.getText('profile_notifications_breadcrumb'),
        onClick: () => {
          navigate(paths.profileNotifications);
          setSelectedTabIndex(0);
          setSelectedNotificationId('');
        },
      },
      {title: tabs[selectedTabIndex]},
    ]);
    return () => setBreadcrumbs([]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedTabIndex, language]);

  const {data: notifications, isLoading: loadingNotifications} = useQuery({
    queryKey: ['ovenPanelNotifications'],
    queryFn: () => services.ovenPanelNotification.getOvenPanelNotifications(),
  });

  const {mutate: updateNotification} = useMutation({
    mutationFn: services.ovenPanelNotification.updateOvenPanelNotification,
    onMutate: async (request) => {
      await queryClient.cancelQueries('ovenPanelNotifications');
      const previousNotifications = queryClient.getQueryData<
        OvenPanelNotification[]
      >(['ovenPanelNotifications']);
      queryClient.setQueryData<OvenPanelNotification[] | undefined>(
        ['ovenPanelNotifications'],
        (notifications) =>
          notifications?.map((notification) =>
            notification.id === request.id
              ? {
                  ...notification,
                  isSeen:
                    request.isSeen !== undefined
                      ? request.isSeen
                      : notification.isSeen,
                  isFlagged:
                    request.isFlagged !== undefined
                      ? request.isFlagged
                      : notification.isFlagged,
                }
              : notification,
          ),
      );
      return {previousNotifications};
    },
    onError: (error, request, context) => {
      queryClient.setQueryData(
        ['ovenPanelNotifications'],
        context?.previousNotifications,
      );
    },
    onSettled: () => {
      queryClient.invalidateQueries('totalUnseenOvenPanelNotifications');
      queryClient.invalidateQueries('ovenPanelNotifications');
    },
  });

  const {mutate: deleteNotifications, isLoading: loadingDeleteNotifications} =
    useMutation({
      mutationFn: services.ovenPanelNotification.deleteOvenPanelNotifications,
      onSuccess: (deletedNotificationIds) => {
        queryClient.invalidateQueries('totalUnseenOvenPanelNotifications');
        queryClient.setQueryData<OvenPanelNotification[] | undefined>(
          ['ovenPanelNotifications'],
          (notifications) =>
            notifications?.filter(
              (notification) =>
                !deletedNotificationIds.includes(notification.id),
            ),
        );
      },
    });

  const {mutate: deleteNotification, isLoading: loadingDeleteNotification} =
    useMutation({
      mutationFn: services.ovenPanelNotification.deleteOvenPanelNotification,
      onSuccess: (deletedNotificationId) => {
        queryClient.invalidateQueries('totalUnseenOvenPanelNotifications');
        queryClient.setQueryData<OvenPanelNotification[] | undefined>(
          ['ovenPanelNotifications'],
          (notifications) =>
            notifications?.filter(
              (notification) => notification.id !== deletedNotificationId,
            ),
        );
      },
    });

  const [selectedNotificationId, setSelectedNotificationId] = useState('');
  const selectedNotification = notifications?.find(
    (notification) => notification.id === selectedNotificationId,
  );

  const [isSelectingMultiple, setIsSelectingMultiple] = useState(false);
  const [selectedNotificationIds, setSelectedNotificationIds] = useState<
    string[]
  >([]);

  const totals = useMemo(() => {
    let unseenWarnings = 0;
    let unseenMaintenance = 0;
    let flagged = 0;
    notifications?.forEach((notification) => {
      if (
        ovenPanelNotificationUtils.isWarning(notification) &&
        !notification.isSeen
      ) {
        unseenWarnings++;
      }
      if (
        ovenPanelNotificationUtils.isMaintenance(notification) &&
        !notification.isSeen
      ) {
        unseenMaintenance++;
      }
      if (notification.isFlagged) {
        flagged++;
      }
    });
    return {unseenWarnings, unseenMaintenance, flagged};
  }, [notifications]);

  const sortedNotifications = useMemo(
    () =>
      Array.from(notifications ?? []).sort((notificationA, notificationB) => {
        if (notificationA.isSeen === notificationB.isSeen) {
          return (
            new Date(notificationB.date).getTime() -
            new Date(notificationA.date).getTime()
          );
        }
        return notificationA.isSeen ? 1 : -1;
      }),
    [notifications],
  );

  const filteredNotifications = sortedNotifications.filter(
    (notification) =>
      (selectedTabIndex === 0 &&
        ovenPanelNotificationUtils.isWarning(notification)) ||
      (selectedTabIndex === 1 &&
        ovenPanelNotificationUtils.isMaintenance(notification)) ||
      (selectedTabIndex === 2 && notification.isFlagged),
  );

  function handleChangeSelectedTab(tabIndex: number) {
    setSelectedNotificationId('');
    setIsSelectingMultiple(false);
    setSelectedNotificationIds([]);
    setSelectedTabIndex(tabIndex);
  }

  function handleSelectAllNotifications(checked: boolean) {
    if (checked) {
      setIsSelectingMultiple(true);
      setSelectedNotificationIds(
        filteredNotifications.map((notification) => notification.id),
      );
      return;
    }
    setIsSelectingMultiple(false);
    setSelectedNotificationIds([]);
  }

  function handleSelectNotification(notificationId: string) {
    setSelectedNotificationId(notificationId);
    updateNotification({id: notificationId, isSeen: true});
  }

  function handleSwitchNotification(notificationId: string, checked: boolean) {
    setSelectedNotificationIds((notificationIds) => {
      if (checked) return [...notificationIds, notificationId];
      return notificationIds.filter((id) => id !== notificationId);
    });
  }

  function handleFlagNotification(notificationId: string, isFlagged: boolean) {
    updateNotification({id: notificationId, isFlagged: !isFlagged});
  }

  function handleDeleteNotification(notificationId: string) {
    deleteNotification({ovenPanelNotificationId: notificationId});
  }

  function handleDeleteSelectedNotifications() {
    deleteNotifications(
      {ids: selectedNotificationIds},
      {onSuccess: () => setIsSelectingMultiple(false)},
    );
  }

  const loading =
    loadingNotifications ||
    loadingDeleteNotifications ||
    loadingDeleteNotification;

  const renderSelectAllSwitch =
    selectedNotification == null && filteredNotifications.length > 0;

  return (
    <Box sx={{width: '80vw'}}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          height: '112px',
        }}>
        <Tabs
          hideIndicator
          values={[
            `${tabs[0]}${
              totals.unseenWarnings > 0 ? ` (${totals.unseenWarnings})` : ''
            }`,
            `${tabs[1]}${
              totals.unseenMaintenance > 0
                ? ` (${totals.unseenMaintenance})`
                : ''
            }`,
            `${tabs[2]}${totals.flagged > 0 ? ` (${totals.flagged})` : ''}`,
          ]}
          selectedTabIndex={selectedTabIndex}
          onTabClick={handleChangeSelectedTab}
        />
        {renderSelectAllSwitch && (
          <Box sx={{display: 'flex', alignItems: 'center'}}>
            <Box sx={{width: '65vw', textAlign: 'right', padding: 2}}>
              <Typography
                variant="body2"
                sx={{fontWeight: 100, color: 'text.primary'}}>
                {locals.getText('profile_notifications_select_all_label')}
              </Typography>
            </Box>
            <Box sx={{width: '15vw', padding: 1}}>
              <Switch
                checked={isSelectingMultiple}
                onChange={(_, checked) => handleSelectAllNotifications(checked)}
              />
              {isSelectingMultiple && (
                <IconButton
                  IconComponent={DeleteIcon}
                  sx={{marginLeft: 2}}
                  onClick={handleDeleteSelectedNotifications}
                />
              )}
            </Box>
          </Box>
        )}
        {selectedNotification != null && (
          <Box sx={{display: 'flex', paddingBlock: 2}}>
            <Paper
              elevation={4}
              sx={{
                display: 'flex',
                width: '65vw',
                paddingBlock: 1,
                paddingInline: 2,
                borderRadius: '10px',
                backgroundColor: '#F4F4F4',
              }}>
              <Typography variant="body2" sx={{flex: 1}}>
                <Span sx={{fontWeight: 'bold'}}>
                  {selectedNotification.ovenDescription}
                </Span>
                <Span sx={{marginInline: '5px'}}>|</Span>
                <Span>{selectedNotification.bakeryName}</Span>
              </Typography>
              <Typography
                variant="body2"
                sx={{fontWeight: 500, color: 'text.primary'}}>
                {dateUtils.toLocaleDateString(selectedNotification.date)}
              </Typography>
            </Paper>
            <Box sx={{margin: 'auto'}}>
              <IconButton
                IconComponent={DeleteIcon}
                onClick={() =>
                  handleDeleteNotification(selectedNotification.id)
                }
              />
            </Box>
          </Box>
        )}
      </Box>
      <Box sx={{height: `calc(${pageHeight} - 112px)`, paddingBottom: 6}}>
        {selectedNotification != null && (
          <Box sx={{display: 'flex'}}>
            <Box sx={{width: '65vw', padding: 2}}>
              <Typography
                variant="body2"
                color="secondary"
                whiteSpace="pre-line">
                {ovenPanelNotificationUtils.getTranslatedDescription(
                  selectedNotification,
                )}
              </Typography>
            </Box>
            <Box sx={{marginInline: 'auto'}}>
              <IconButton
                IconComponent={FlagIcon}
                activeColor="primary"
                isActive={selectedNotification.isFlagged}
                onClick={() =>
                  handleFlagNotification(
                    selectedNotification.id,
                    selectedNotification.isFlagged,
                  )
                }
              />
            </Box>
          </Box>
        )}
        {selectedNotification == null && (
          <NotificationList
            notifications={filteredNotifications}
            onSelectNotification={handleSelectNotification}
            onSwitchNotification={handleSwitchNotification}
            onDeleteNotification={handleDeleteNotification}
            isSelectingMultiple={isSelectingMultiple}
            selectedNotificationIds={selectedNotificationIds}
            notificationsNotFoundLabel={
              loading
                ? ''
                : locals.getText('profile_notifications_not_found_label')
            }
          />
        )}
      </Box>
      <LoadingBackdrop loading={loading} />
    </Box>
  );
}

export default Notifications;
