import {NavigateFunction} from 'react-router-dom';
import {create} from 'zustand';
import {queryClient} from '../App';
import {subscriptionToken} from '../firebase/app';
import User from '../models/entities/user';
import paths from '../routes/paths';
import services, {ServiceType, serviceType} from '../services/provider';
import cryptoUtils from '../utils/crypto';
import errorUtils from '../utils/errors';
import localStorageUtils from '../utils/localStorage';
import {clearErrorStore} from './errors';
import {clearBreadcrumbsStore} from './breadcrumbs';

type AuthStore = {
  token: string | null;
  user: User | null;
  isLoading: boolean;
  setUser: (user: User) => void;
};

const useAuthStore = create<AuthStore>((set) => ({
  token: localStorageUtils.getValue('token', null),
  user: null,
  isLoading: false,
  setUser: (user: User) => set({user}),
}));

function clearAuthStore() {
  useAuthStore.setState({token: null, user: null});
}

function setAuthorizationToken(token: string) {
  const rememberMe = localStorageUtils.getValue('rememberme', false);
  if (rememberMe) {
    localStorageUtils.setValue('token', token);
  }
  services.base.setAuthorizationToken(token);
}

function removeAuthorizationToken() {
  localStorageUtils.removeValue('token');
  services.base.removeAuthorizationToken();
}

function subscribeToPushNotifications(user: User) {
  services.user
    .subscribe({userId: user.id, token: subscriptionToken})
    .then(() => {
      console.log('subscribed to push notifications');
    })
    .catch((error) => {
      console.log('could not subscribe to push notifications');
    });
}

function unsubscribeToPushNotifications() {
  const user = useAuthStore.getState().user;
  if (user != null) {
    services.user
      .unsubscribe({userId: user.id, token: subscriptionToken})
      .then(() => {
        console.log('unsubscribed to push notifications');
      })
      .catch((error) => {
        console.log('could not unsubscribe to push notifications');
      });
  }
}

export function setAuthData(token: string, user: User) {
  setAuthorizationToken(token);
  subscribeToPushNotifications(user);
  useAuthStore.setState({token, user});
}

function getUserId(token: string) {
  const decodedToken = cryptoUtils.decodeJwt(token!, {id: ''});
  return serviceType === ServiceType.Web ? decodedToken.id : token!;
}

export async function persistedLogin(navigate: NavigateFunction) {
  try {
    const token = localStorageUtils.getValue<string | null>('token', null);
    if (token == null) {
      logout();
      return;
    }
    setAuthorizationToken(token);
    const userId = getUserId(token);
    navigate(paths.dashboard);
    useAuthStore.setState({token, isLoading: true});
    const user = await services.user.getUser({userId});
    useAuthStore.setState({user});
  } catch (error) {
    logout();
    errorUtils.handleError(error);
  } finally {
    useAuthStore.setState({isLoading: false});
  }
}

export function logout() {
  unsubscribeToPushNotifications();
  removeAuthorizationToken();
  queryClient.clear();
  clearAuthStore();
  clearErrorStore();
  clearBreadcrumbsStore();
}

export default useAuthStore;
