import {Box, TextField, Typography} from '@mui/material';
import {useState} from 'react';
import {useMutation} from 'react-query';
import {Link, useNavigate} from 'react-router-dom';
import useLocalStorageState from '../../hooks/common/useLocalStorageState';
import useLocalization from '../../hooks/common/useLocalization';
import locals from '../../localization/locals';
import paths from '../../routes/paths';
import services from '../../services/provider';
import {setAuthData} from '../../state/auth';
import cryptoUtils from '../../utils/crypto';
import errorUtils, {ApplicationErrorCode} from '../../utils/errors';
import stringUtils from '../../utils/strings';
import {Button} from '../common/Button';
import LoadingBackdrop from '../common/LoadingBackdrop';
import Switch from '../common/Switch';

function Login() {
  const navigate = useNavigate();
  useLocalization();

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const [invalidEmailOrPassword, setInvalidEmailOrPassword] = useState(false);

  const [rememberMe, setRememberMe] = useLocalStorageState('rememberme', false);

  const {mutate: login, isLoading} = useMutation({
    mutationFn: services.auth.login,
    onSuccess: (result) => {
      setAuthData(result.token, result.user);
      navigate(paths.dashboard);
    },
    onError: (error) =>
      errorUtils.handleMatch(
        error,
        [ApplicationErrorCode.InvalidEmailOrPassword],
        () => setInvalidEmailOrPassword(true),
      ),
  });

  function handleLogin() {
    login({email, password: cryptoUtils.sha256(password)});
  }

  return (
    <Box sx={{display: 'flex', flexDirection: 'column', width: '30vw'}}>
      <Typography
        variant="h4"
        color="secondary"
        sx={{
          marginBottom: invalidEmailOrPassword ? '2vh' : '8vh',
          fontWeight: 500,
        }}>
        {locals.getText('login_title')}
      </Typography>
      {invalidEmailOrPassword && (
        <Typography
          variant="caption"
          color="primary"
          sx={{marginBottom: '6vh', fontWeight: 500}}>
          {locals.getText('login_invalid_email_or_password_label')}
        </Typography>
      )}
      <TextField
        type="email"
        variant="standard"
        sx={{marginBottom: '4vh'}}
        label={locals.getText('login_email_input_label')}
        value={email}
        onChange={(event) => setEmail(event.target.value)}
      />
      <TextField
        type="password"
        variant="standard"
        label={locals.getText('login_password_input_label')}
        value={password}
        onChange={(event) => setPassword(event.target.value)}
        onKeyUp={(event) => (event.key === 'Enter' ? handleLogin() : null)}
      />
      <Typography
        variant="caption"
        color="secondary"
        sx={{
          width: '100%',
          textAlign: 'right',
          marginTop: '1vh',
          marginBottom: '4vh',
        }}>
        <Link to={paths.passwordRecovery}>
          {locals.getText('login_forgot_password_label')}
        </Link>
      </Typography>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'right',
          marginBottom: '8vh',
        }}>
        <Typography
          variant="caption"
          color="secondary"
          sx={{marginRight: '1vw'}}>
          {locals.getText('login_remember_password_label')}
        </Typography>
        <Switch
          checked={rememberMe}
          onChange={(_, checked) => setRememberMe(checked)}
        />
      </Box>
      <Button
        onClick={handleLogin}
        disabled={stringUtils.anyIsNullOrWhiteSpace(email, password)}>
        {locals.getText('login_login_button_label')}
      </Button>
      <LoadingBackdrop loading={isLoading} />
    </Box>
  );
}

export default Login;
