import {
  Box,
  ClickAwayListener,
  Collapse,
  ListItem,
  Typography,
} from '@mui/material';
import {useState} from 'react';
import {ReactComponent as DeclineIcon} from '../../assets/icons/decline.svg';
import {ReactComponent as DeleteIcon} from '../../assets/icons/delete.svg';
import {ReactComponent as EditIcon} from '../../assets/icons/edit.svg';
import {ReactComponent as LocationIcon} from '../../assets/icons/location.svg';
import locals from '../../localization/locals';
import Recipe from '../../models/entities/recipe';
import dateUtils from '../../utils/dates';
import IconButton from '../common/IconButton';

export type RecipeListItemAction =
  | 'update'
  | 'delete'
  | 'locate'
  | 'removeFromRecipeType';

type RecipeListItemProps = {
  recipe: Recipe;
  selected?: boolean;
  onClick?: () => void;
  onActionClick?: (action: RecipeListItemAction) => void;
  onClickAway?: () => void;
  canRemoveFromRecipeType?: boolean;
};

function RecipesListItem(props: RecipeListItemProps) {
  const {
    recipe,
    selected,
    onClick,
    onActionClick,
    onClickAway,
    canRemoveFromRecipeType,
  } = props;

  const [hovered, setHovered] = useState(false);

  const color = hovered || selected ? 'primary.main' : 'text.primary';

  return (
    <ClickAwayListener onClickAway={() => onClickAway?.()}>
      <ListItem sx={{margin: 0, padding: 0}}>
        <Box>
          <Box sx={{display: 'flex', alignItems: 'center'}}>
            <Box
              sx={{display: 'flex', alignItems: 'center', cursor: 'pointer'}}
              onMouseEnter={() => setHovered(true)}
              onMouseLeave={() => setHovered(false)}
              onClick={(event) => {
                event.stopPropagation();
                onClick?.();
              }}>
              <Box sx={{width: '35vw', padding: 2}}>
                <Typography
                  variant="body2"
                  sx={{
                    fontWeight: hovered || selected ? 'bold' : 'normal',
                    color,
                  }}>
                  {recipe.name}
                </Typography>
              </Box>
              <Box sx={{width: '30vw', textAlign: 'end', padding: 2}}>
                <Typography variant="body2" sx={{color}}>
                  {`${locals.getText(
                    'recipe_modified_at_label',
                  )}: ${dateUtils.toLocaleDateString(recipe.modifiedAt ?? '')}`}
                </Typography>
              </Box>
            </Box>
            {canRemoveFromRecipeType && (
              <Box sx={{width: '15vw', textAlign: 'center', padding: 1}}>
                <IconButton
                  IconComponent={DeclineIcon}
                  onClick={() => onActionClick?.('removeFromRecipeType')}
                />
              </Box>
            )}
          </Box>
          <Collapse in={selected} unmountOnExit>
            <Box sx={{display: 'flex', margin: 2}}>
              <IconButton
                IconComponent={EditIcon}
                onClick={() => onActionClick?.('update')}
              />
              <Box width="25px" />
              <IconButton
                IconComponent={LocationIcon}
                onClick={() => onActionClick?.('locate')}
              />
              <Box width="25px" />
              <IconButton
                IconComponent={DeleteIcon}
                onClick={() => onActionClick?.('delete')}
              />
            </Box>
          </Collapse>
        </Box>
      </ListItem>
    </ClickAwayListener>
  );
}

export default RecipesListItem;
