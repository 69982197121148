import ChangeOvenPanelStatusParams from '../models/requests/changeOvenPanelStatus';
import GetOvenPanelStatisticsParams from '../models/requests/getOvenPanelStatistics';
import ChangeOvenPanelStatusResponse from '../models/responses/changeOvenPanelStatus';
import GetOvenPanelStatisticsResponse from '../models/responses/getOvenPanelStatistics';
import baseService from './base';
import endpoints from './endpoints';

export type OvenPanelsService = {
  changeOvenPanelStatus: (
    params: ChangeOvenPanelStatusParams,
  ) => Promise<ChangeOvenPanelStatusResponse>;
  getOvenPanelStatistics: (
    params: GetOvenPanelStatisticsParams,
  ) => Promise<GetOvenPanelStatisticsResponse>;
};

const ovenPanelsService: OvenPanelsService = {
  changeOvenPanelStatus: async function (
    params: ChangeOvenPanelStatusParams,
  ): Promise<boolean> {
    const {ovenPanelId} = params;
    return baseService
      .put<null, ChangeOvenPanelStatusResponse>(
        `${endpoints.ovenPanels}/${ovenPanelId}/status`,
        null,
      )
      .then((result) => result.data);
  },
  getOvenPanelStatistics: async function (
    params: GetOvenPanelStatisticsParams,
  ): Promise<GetOvenPanelStatisticsResponse> {
    const {ovenPanelId} = params;
    return baseService
      .get<GetOvenPanelStatisticsParams, GetOvenPanelStatisticsResponse>(
        `${endpoints.ovenPanels}/${ovenPanelId}/statistics`,
        params,
      )
      .then((result) => result.data);
  },
};

export default ovenPanelsService;
