import {
  Box,
  Tab as MuiTab,
  Tabs as MuiTabs,
  SxProps,
  TabProps,
  TabScrollButtonProps,
} from '@mui/material';
import {ReactNode, useEffect} from 'react';
import colors from '../../themes/colors';
import Hidden from './Hidden';

function Tab(props: TabProps) {
  const {sx, ...rest} = props;
  return (
    <MuiTab
      sx={{
        alignItems: 'flex-start',
        minWidth: 'auto',
        maxWidth: 'auto',
        textTransform: 'none',
        fontSize: '0.875rem',
        color: 'text.secondary',
        '&.Mui-selected': {
          fontWeight: 'bold',
          color: 'text.primary',
        },
        ...sx,
      }}
      disableRipple
      {...rest}
    />
  );
}

type TabsProps = {
  values: string[];
  selectedTabIndex?: number;
  hideIndicator?: boolean;
  onChangeSelectedTab?: (tabIndex: number) => void;
  onTabClick?: (tabIndex: number) => void;
  tabSx?: SxProps;
};

function Tabs(props: TabsProps) {
  const {
    values,
    selectedTabIndex,
    hideIndicator,
    onChangeSelectedTab,
    onTabClick,
    tabSx,
  } = props;

  useEffect(() => {
    const scrollContainer = document.querySelector(
      '.MuiTabs-scroller',
    ) as HTMLDivElement;
    let isDragging = false;
    let startX = 0;
    let scrollLeft = 0;
    const handleMouseDown = (event: MouseEvent) => {
      isDragging = true;
      startX = event.pageX - scrollContainer.offsetLeft;
      scrollLeft = scrollContainer.scrollLeft;
    };
    const handleMouseUp = () => {
      isDragging = false;
    };
    const handleMouseMove = (event: MouseEvent) => {
      if (isDragging) {
        event.preventDefault();
        const x = event.pageX - scrollContainer.offsetLeft;
        const scroll = x - startX;
        scrollContainer.scrollLeft = scrollLeft - scroll;
      }
    };
    const handleMouseLeave = () => {
      isDragging = false;
    };
    scrollContainer.addEventListener('mousedown', handleMouseDown, false);
    scrollContainer.addEventListener('mouseup', handleMouseUp, false);
    scrollContainer.addEventListener('mousemove', handleMouseMove);
    scrollContainer.addEventListener('mouseleave', handleMouseLeave, false);
    return () => {
      scrollContainer.removeEventListener('mousedown', handleMouseDown);
      scrollContainer.removeEventListener('mouseup', handleMouseUp);
      scrollContainer.removeEventListener('mousemove', handleMouseMove);
      scrollContainer.removeEventListener('mouseleave', handleMouseLeave);
    };
  }, []);

  return (
    <MuiTabs
      sx={{
        position: 'relative',
        overflow: 'visible',
        flex: 1,
        '&:after': {
          content: hideIndicator ? undefined : '" "',
          position: 'absolute',
          bottom: '-2px',
          width: '100%',
          height: '7px',
          borderRadius: '4px',
          backgroundColor: colors.lighterGrey,
          zIndex: 2,
        },
        '& .MuiTabs-indicator': {
          display: hideIndicator ? 'none' : 'flex',
          justifyContent: 'center',
          height: '3px',
          backgroundColor: colors.lighterGrey,
          zIndex: 3,
        },
        '& .MuiTabs-indicatorSpan': {
          display: hideIndicator ? 'none' : undefined,
          width: '80%',
          height: '3px',
          borderRadius: '2px',
          backgroundColor: 'secondary.main',
          zIndex: 3,
        },
      }}
      variant="scrollable"
      scrollButtons="auto"
      TabIndicatorProps={{
        children: <span className="MuiTabs-indicatorSpan" />,
      }}
      ScrollButtonComponent={(props: TabScrollButtonProps) =>
        props.disabled ? null : (
          <Box
            sx={{
              flexShrink: 0,
              width: '100px',
              marginLeft: props.direction === 'right' ? '-100px' : undefined,
              marginRight: props.direction === 'left' ? '-100px' : undefined,
              background: `linear-gradient(to ${props.direction}, transparent, white)`,
              pointerEvents: 'none',
              zIndex: 1,
            }}
          />
        )
      }
      value={selectedTabIndex}
      onChange={(_, tabIndex) => onChangeSelectedTab?.(tabIndex)}>
      {values.map((value, index) => (
        <Tab
          sx={tabSx}
          key={value}
          label={value}
          onClick={() => onTabClick?.(index)}
        />
      ))}
    </MuiTabs>
  );
}

type TabPanelProps = {
  index: number;
  selectedTabIndex: number;
  children?: ReactNode;
};

export function TabPanel(props: TabPanelProps) {
  const {index, selectedTabIndex, children} = props;
  return <Hidden hidden={index !== selectedTabIndex}>{children}</Hidden>;
}

export default Tabs;
