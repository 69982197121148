import {Schema} from 'jsonschema';

export const compactramV1RecipeSchema: Schema = {
  id: '/CompactramV1',
  type: 'object',
  properties: {
    name: {type: 'string', required: true},
    description: {type: 'string'},
    procedure: {type: 'string'},
    image: {type: 'string'},
    phases: {
      type: 'array',
      items: {$ref: '/CompactramV1Phase'},
      minItems: 1,
      maxItems: 4,
    },
    ovenModelId: {type: 'number', required: true},
    ovenSubModelId: {type: 'number'},
    companyId: {type: 'string', required: true},
  },
};

export const compactramV1PhaseSchema: Schema = {
  id: '/CompactramV1Phase',
  type: 'object',
  properties: {
    topTemperature: {
      type: 'number',
      minimum: 0,
      maximum: 350,
      required: true,
    },
    floorTemperature: {
      type: 'number',
      minimum: 0,
      maximum: 350,
      required: true,
    },
    topPower: {type: 'number', minimum: -30, maximum: 30, required: true},
    floorPower: {type: 'number', minimum: -30, maximum: 30, required: true},
    duration: {type: 'number', minimum: 1, maximum: 99, required: true},
    steamInjectionDuration: {
      type: 'number',
      minimum: 0,
      maximum: 4,
      required: true,
    },
    steamExitValveOpened: {type: 'boolean', required: true},
  },
};

export const electramV1RecipeSchema: Schema = {
  id: '/ElectramV1',
  type: 'object',
  properties: {
    name: {type: 'string', required: true},
    description: {type: 'string'},
    procedure: {type: 'string'},
    image: {type: 'string'},
    phases: {
      type: 'array',
      items: {$ref: '/ElectramV1Phase'},
      minItems: 1,
      maxItems: 4,
    },
    ovenModelId: {type: 'number', required: true},
    ovenSubModelId: {type: 'number'},
    companyId: {type: 'string', required: true},
  },
};

export const electramV1PhaseSchema: Schema = {
  id: '/ElectramV1Phase',
  type: 'object',
  properties: {
    topTemperature: {
      type: 'number',
      minimum: 0,
      maximum: 350,
      required: true,
    },
    floorTemperature: {
      type: 'number',
      minimum: 0,
      maximum: 350,
      required: true,
    },
    topPower: {type: 'number', minimum: -30, maximum: 30, required: true},
    floorPower: {type: 'number', minimum: -30, maximum: 30, required: true},
    duration: {type: 'number', minimum: 1, maximum: 99, required: true},
    steamInjectionDuration: {
      type: 'number',
      minimum: 0,
      maximum: 4,
      required: true,
    },
    steamExitValveOpened: {type: 'boolean', required: true},
  },
};

export const modulramV1RecipeSchema: Schema = {
  id: '/ModulramV1',
  type: 'object',
  properties: {
    name: {type: 'string', required: true},
    description: {type: 'string'},
    procedure: {type: 'string'},
    image: {type: 'string'},
    phases: {
      type: 'array',
      items: {$ref: '/ModulramV1Phase'},
      minItems: 1,
      maxItems: 4,
    },
    ovenModelId: {type: 'number', required: true},
    ovenSubModelId: {type: 'number'},
    companyId: {type: 'string', required: true},
  },
};

export const modulramV1PhaseSchema: Schema = {
  id: '/ModulramV1Phase',
  type: 'object',
  properties: {
    topTemperature: {
      type: 'number',
      minimum: 0,
      maximum: 350,
      required: true,
    },
    floorTemperature: {
      type: 'number',
      minimum: 0,
      maximum: 350,
      required: true,
    },
    topPower: {type: 'number', minimum: -30, maximum: 30, required: true},
    floorPower: {type: 'number', minimum: -30, maximum: 30, required: true},
    duration: {type: 'number', minimum: 1, maximum: 99, required: true},
    steamInjectionDuration: {
      type: 'number',
      minimum: 0,
      maximum: 4,
      required: true,
    },
    steamExitValveOpened: {type: 'boolean', required: true},
  },
};

export const rotoramV1RecipeSchema: Schema = {
  id: '/RotoramV1',
  type: 'object',
  properties: {
    name: {type: 'string', required: true},
    description: {type: 'string'},
    procedure: {type: 'string'},
    image: {type: 'string'},
    phases: {
      type: 'array',
      items: {$ref: '/RotoramV1Phase'},
      minItems: 1,
      maxItems: 4,
    },
    ovenModelId: {type: 'number', required: true},
    ovenSubModelId: {type: 'number'},
    companyId: {type: 'string', required: true},
  },
};

export const rotoramV1PhaseSchema: Schema = {
  id: '/RotoramV1Phase',
  type: 'object',
  properties: {
    temperature: {type: 'number', minimum: 1, maximum: 300, required: true},
    duration: {type: 'number', minimum: 1, maximum: 99, required: true},
    steamInjectionNumber: {
      type: 'number',
      minimum: 0,
      maximum: 5,
      required: true,
    },
    steamExitValveOpened: {type: 'boolean', required: true},
    turbineRestSeconds: {
      type: 'number',
      minimum: 0,
      maximum: 60,
      required: true,
    },
  },
};

export const turboramV1recipeSchema: Schema = {
  id: '/TurboramV1',
  type: 'object',
  properties: {
    name: {type: 'string', required: true},
    description: {type: 'string'},
    procedure: {type: 'string'},
    image: {type: 'string'},
    phases: {
      type: 'array',
      items: {$ref: '/TurboramV1Phase'},
      minItems: 1,
      maxItems: 4,
    },
    ovenModelId: {type: 'number', required: true},
    ovenSubModelId: {type: 'number'},
    companyId: {type: 'string', required: true},
  },
};

export const turboramV1PhaseSchema: Schema = {
  id: '/TurboramV1Phase',
  type: 'object',
  properties: {
    temperature: {type: 'number', minimum: 1, maximum: 300, required: true},
    duration: {type: 'number', minimum: 1, maximum: 99, required: true},
    steamInjectionNumber: {
      type: 'number',
      minimum: 0,
      maximum: 5,
      required: true,
    },
    steamExitValveOpened: {type: 'boolean', required: true},
    turbineSpeed: {type: 'number', minimum: 0, maximum: 1, required: true},
  },
};
