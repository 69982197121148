import {Box} from '@mui/material';
import {daysArray} from '../../utils/dates';
import Tabs from '../common/Tabs';

export type DayTabsProps = {
  selectedDay: Day;
  onChangeSelectedDay: (day: Day) => void;
};

function DayTabs(props: DayTabsProps) {
  const {selectedDay, onChangeSelectedDay} = props;

  const selectedTabIndex = daysArray.findIndex(
    (day) => day.value === selectedDay,
  );

  function handleSelectDay(dayIndex: number) {
    onChangeSelectedDay(daysArray[dayIndex].value);
  }

  return (
    <Box>
      <Tabs
        values={daysArray.map((day) => day.label())}
        selectedTabIndex={selectedTabIndex >= 0 ? selectedTabIndex : 0}
        onTabClick={handleSelectDay}
        tabSx={{fontWeight: 300}}
      />
    </Box>
  );
}

export default DayTabs;
