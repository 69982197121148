import {Box, InputBase, Popover, PopoverOrigin, SvgIcon} from '@mui/material';
import {ReactNode, useState} from 'react';
import {ReactComponent as AcceptIcon} from '../../assets/icons/accept.svg';
import {ReactComponent as DeclineIcon} from '../../assets/icons/decline.svg';
import colors from '../../themes/colors';

type ConfirmPopoverProps = {
  anchorEl?: HTMLElement | null;
  placeHolder?: string;
  value?: string;
  onChange?: (value: string) => void;
  onAccept: () => void;
  onDecline: () => void;
  anchorOrigin?: PopoverOrigin;
  transformOrigin?: PopoverOrigin;
  messageElement?: ReactNode;
};

function ConfirmPopover(props: ConfirmPopoverProps) {
  const {
    anchorEl,
    placeHolder,
    value,
    onChange,
    onAccept,
    onDecline,
    anchorOrigin = {vertical: 'center', horizontal: 'left'},
    transformOrigin = {vertical: 'center', horizontal: 'right'},
    messageElement,
  } = props;

  const [showPlaceholder, setShowPlaceholder] = useState(true);

  const open = Boolean(anchorEl);

  return (
    <Popover
      open={open}
      anchorEl={anchorEl}
      anchorOrigin={anchorOrigin}
      transformOrigin={transformOrigin}
      PaperProps={{
        elevation: 4,
        sx: {
          display: 'flex',
          alignItems: 'center',
          borderRadius: '10px',
          backgroundColor: 'primary.main',
        },
      }}
      BackdropProps={{
        invisible: false,
        sx: {backgroundColor: 'RGBA(255, 255, 255, 0.8)'},
      }}
      onClose={onDecline}>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          width: '65vw',
          height: '36px',
          padding: 2,
          paddingInline: 2,
        }}>
        {messageElement != null ? (
          messageElement
        ) : (
          <InputBase
            sx={{
              flex: 1,
              fontSize: '0.875rem',
              fontWeight: 500,
              color: colors.white,
              input: {
                '&::placeholder': {
                  opacity: 1,
                },
              },
            }}
            placeholder={showPlaceholder ? placeHolder : ''}
            value={value}
            onChange={(event) => onChange?.(event.target.value)}
            onKeyUp={(event) => (event.key === 'Enter' ? onAccept() : null)}
            onFocus={() => setShowPlaceholder(false)}
            onBlur={() => setShowPlaceholder(true)}
          />
        )}
        <SvgIcon
          sx={{
            fontSize: '20px',
            color: colors.white,
            cursor: 'pointer',
            marginRight: 2,
          }}
          inheritViewBox
          component={DeclineIcon}
          onClick={onDecline}
        />
        <SvgIcon
          sx={{
            fontSize: '20px',
            color: colors.white,
            cursor: 'pointer',
          }}
          inheritViewBox
          component={AcceptIcon}
          onClick={onAccept}
        />
      </Box>
    </Popover>
  );
}

export default ConfirmPopover;
