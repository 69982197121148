import {create} from 'zustand';
import locals, {Language} from '../localization/locals';

type LanguageStore = {
  language: Language;
  setLanguage: (language: Language) => void;
};

const useLanguageStore = create<LanguageStore>((set) => ({
  language: locals.getLanguage(),
  setLanguage: (language: Language) => {
    locals.setLanguage(language);
    set({language});
  },
}));

export default useLanguageStore;
