import {v4 as uuid} from 'uuid';
import User, {UserRole} from '../../models/entities/user';
import LoginRequest from '../../models/requests/login';
import RecoverPasswordRequest from '../../models/requests/recoverPassword';
import ResetPasswordRequest from '../../models/requests/resetPassword';
import SignupRequest from '../../models/requests/signup';
import LoginResponse from '../../models/responses/login';
import RecoverPasswordResponse from '../../models/responses/recoverPassword';
import ResetPasswordResponse from '../../models/responses/resetPassword';
import SignupResponse from '../../models/responses/signup';
import {ApplicationError} from '../../utils/errors';
import stringUtils from '../../utils/strings';
import {AuthService} from '../auth';
import data from './data';

const mockAuthService: AuthService = {
  login: async function (request: LoginRequest): Promise<LoginResponse> {
    const {email, password} = request;
    return new Promise((resolve, reject) =>
      setTimeout(() => {
        if (stringUtils.anyIsNullOrWhiteSpace(email, password)) {
          reject(ApplicationError.badRequest());
          return;
        }
        const user = data.users.find((user) => user.email === email);
        if (user == null) {
          reject(ApplicationError.invalidEmailOrPassword());
          return;
        }
        resolve({token: user.id, user: {...user}});
      }, 500),
    );
  },
  recoverPassword: async function (
    request: RecoverPasswordRequest,
  ): Promise<RecoverPasswordResponse> {
    const {email} = request;
    return new Promise((resolve, reject) =>
      setTimeout(() => {
        if (stringUtils.isNullOrWhiteSpace(email)) {
          reject(ApplicationError.badRequest());
          return;
        }
        const user = data.users.find((user) => user.email === email);
        if (user == null) {
          reject(ApplicationError.invalidEmailOrPassword());
          return;
        }
        resolve(true);
      }, 500),
    );
  },
  resetPassword: async function (
    request: ResetPasswordRequest,
  ): Promise<ResetPasswordResponse> {
    const {email, recoveryCode, newPassword} = request;
    return new Promise((resolve, reject) =>
      setTimeout(() => {
        if (
          stringUtils.anyIsNullOrWhiteSpace(email, recoveryCode, newPassword)
        ) {
          reject(ApplicationError.badRequest());
          return;
        }
        const user = data.users.find((user) => user.email === email);
        if (user == null || recoveryCode === '12345') {
          reject(ApplicationError.invalidEmailOrPassword());
          return;
        }
        resolve({token: user.id, user: {...user}});
      }, 500),
    );
  },
  signup: async function (request: SignupRequest): Promise<SignupResponse> {
    return new Promise((resolve, reject) =>
      setTimeout(() => {
        const {name, code, email, password, language} = request;
        if (stringUtils.anyIsNullOrWhiteSpace(name, code, email, password)) {
          reject(ApplicationError.badRequest());
          return;
        }
        if (email === 'invalid@ramalhos.com') {
          reject(ApplicationError.invalidEmailOrPassword());
          return;
        }
        if (code === '12345') {
          reject(ApplicationError.invalidInviteCode());
          return;
        }
        const user: User = {
          id: uuid(),
          name,
          email,
          role: UserRole.Administrator,
          language,
          companyId: data.companies[0].id,
        };
        data.users.push(user);
        resolve({token: user.id, user: {...user}});
      }, 500),
    );
  },
};

export default mockAuthService;
