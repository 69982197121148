import {addDays, addMinutes, differenceInMinutes, format} from 'date-fns';
import locals from '../localization/locals';
import numberUtils from './numbers';

export enum Day {
  Sunday = 0,
  Monday = 1,
  Tuesday = 2,
  Wednesday = 3,
  Thursday = 4,
  Friday = 5,
  Saturday = 6,
}

type DayObject = {value: Day; label: () => string};

export const days: Record<Day, DayObject> = {
  0: {value: Day.Sunday, label: () => locals.getText('sunday')},
  1: {value: Day.Monday, label: () => locals.getText('monday')},
  2: {value: Day.Tuesday, label: () => locals.getText('tuesday')},
  3: {value: Day.Wednesday, label: () => locals.getText('wednesday')},
  4: {value: Day.Thursday, label: () => locals.getText('thursday')},
  5: {value: Day.Friday, label: () => locals.getText('friday')},
  6: {value: Day.Saturday, label: () => locals.getText('saturday')},
};

export const daysArray: DayObject[] = [
  days[1],
  days[2],
  days[3],
  days[4],
  days[5],
  days[6],
  days[0],
];

type DateType = Date | string | number;

const dateUtils = {
  isValid: function (date: DateType) {
    const _date = new Date(date);
    return _date instanceof Date && !isNaN(_date.getTime());
  },
  format: function (date: DateType, pattern: string) {
    const _date = this.isValid(date) ? new Date(date) : new Date();
    return format(_date, pattern);
  },
  toLocaleDateString: function (date: DateType) {
    return this.isValid(date) ? format(new Date(date), 'dd/MM/yyyy') : '';
  },
  addDays: function (date: DateType, days: number) {
    let _date = this.isValid(date) ? new Date(date) : new Date();
    _date = addDays(_date, days);
    return _date;
  },
  addMinutes: function (date: DateType, minutes: number) {
    let _date = this.isValid(date) ? new Date(date) : new Date();
    _date = addMinutes(_date, minutes);
    return _date;
  },
  differenceInMinutes: function (dateA: DateType, dateB: DateType) {
    const _date1 = this.isValid(dateA) ? new Date(dateA) : new Date();
    const _date2 = this.isValid(dateB) ? new Date(dateB) : new Date();
    return differenceInMinutes(_date1, _date2);
  },
  parseDay: function (
    day?: string | number | null,
    fallbackValue: Day = Day.Sunday,
  ): Day {
    const dayNumber =
      typeof day !== 'number' ? numberUtils.parseInt(day, fallbackValue) : day;
    return dayNumber >= 0 && dayNumber <= 6
      ? (dayNumber as Day)
      : fallbackValue;
  },
};

export default dateUtils;
