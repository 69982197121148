import {Box} from '@mui/material';
import {Fragment, useEffect, useState} from 'react';
import {useQuery} from 'react-query';
import {useNavigate} from 'react-router-dom';
import useLocalization from '../../hooks/common/useLocalization';
import locals from '../../localization/locals';
import {OvenModelId} from '../../models/entities/ovenModel';
import paths from '../../routes/paths';
import services from '../../services/provider';
import useBreadcrumbsStore, {Breadcrumb} from '../../state/breadcrumbs';
import ActionBanner from '../common/ActionBanner';
import LoadingBackdrop from '../common/LoadingBackdrop';
import BakeryList from './BakeryList';
import BakeryMenu, {BakeryMenuItem} from './BakeryMenu';
import BakeryOvens from './BakeryOvens';
import BakerySettings from './BakerySettings';
import CityList from './CityList';
import CountryTabs from './CountryTabs';
import SearchField from './SearchField';

function Bakeries() {
  const navigate = useNavigate();
  useLocalization();

  const setBreadcrumbs = useBreadcrumbsStore((state) => state.setBreadcrumbs);

  const [searchText, setSearchText] = useState('');
  const [selectedCountryId, setSelectedCountryId] = useState<number | null>(
    null,
  );
  const [selectedCityId, setSelectedCityId] = useState<number | null>(null);
  const [selectedBakeryId, setSelectedBakeryId] = useState<string | null>(null);

  const [selectedMenuItem, setSelectedMenuItem] =
    useState<BakeryMenuItem>('settings');
  const [selectedOvenModelId, setSelectedOvenModelId] =
    useState<OvenModelId | null>(null);
  const [selectedOvenGroupId, setSelectedOvenGroupId] = useState<string | null>(
    null,
  );
  const [selectedOvenId, setSelectedOvenId] = useState<string | null>(null);

  const {data: countries = [], isLoading: loadingLocations} = useQuery({
    queryKey: ['bakeriesLocations'],
    queryFn: () => services.bakery.getBakeriesLocations({}),
  });

  const {data: ovenModels = [], isLoading: loadingOvenModels} = useQuery({
    enabled: selectedBakeryId !== null,
    queryKey: ['bakeryOvens', selectedBakeryId],
    queryFn: () =>
      services.bakery.getBakeryOvens({bakeryId: selectedBakeryId!}),
  });

  const selectedCountry =
    countries.find((country) => country.id === selectedCountryId) ?? null;
  const selectedCity =
    selectedCountry?.cities?.find((city) => city.id === selectedCityId) ?? null;
  const selectedBakery =
    selectedCity?.bakeries?.find((bakery) => bakery.id === selectedBakeryId) ??
    null;
  const selectedOvenModel =
    ovenModels.find((ovenModel) => ovenModel.id === selectedOvenModelId) ??
    null;

  useEffect(() => {
    if (selectedCountryId == null) {
      setSelectedCountryId(countries.length > 0 ? countries[0].id : null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [countries]);

  useEffect(() => {
    if (selectedOvenModelId == null) {
      setSelectedOvenModelId(ovenModels.length > 0 ? ovenModels[0].id : null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ovenModels]);

  useEffect(() => {
    const breadcrumbs: Breadcrumb[] = [
      {
        title: locals.getText('bakeries_breadcrumb'),
        onClick: () => {
          setSearchText('');
          setSelectedCountryId(countries.length > 0 ? countries[0].id : null);
          setSelectedCityId(null);
          setSelectedBakeryId(null);
          setSelectedMenuItem('settings');
          setSelectedOvenModelId(null);
          setSelectedOvenGroupId(null);
          setSelectedOvenId(null);
        },
      },
    ];
    if (selectedCountry != null) {
      breadcrumbs.push({
        title: selectedCountry.name,
        onClick: () => {
          setSelectedCityId(null);
          setSelectedBakeryId(null);
          setSelectedMenuItem('settings');
          setSelectedOvenModelId(null);
          setSelectedOvenGroupId(null);
          setSelectedOvenId(null);
        },
      });
    }
    if (selectedCity != null) {
      breadcrumbs.push({
        title: selectedCity.name,
        onClick: () => {
          setSelectedBakeryId(null);
          setSelectedMenuItem('settings');
          setSelectedOvenModelId(null);
          setSelectedOvenGroupId(null);
          setSelectedOvenId(null);
        },
      });
    }
    if (selectedBakery != null) {
      breadcrumbs.push({
        title: selectedBakery.name,
        onClick: () => {
          setSelectedMenuItem('settings');
          setSelectedOvenModelId(
            ovenModels.length > 0 ? ovenModels[0].id : null,
          );
          setSelectedOvenGroupId(null);
          setSelectedOvenId(null);
        },
      });
      if (selectedMenuItem === 'settings') {
        breadcrumbs.push({
          title: locals.getText('bakery_settings_breadcrumb'),
          onClick: () => {
            setSelectedMenuItem('settings');
            setSelectedOvenModelId(
              ovenModels.length > 0 ? ovenModels[0].id : null,
            );
            setSelectedOvenGroupId(null);
            setSelectedOvenId(null);
          },
        });
      }
      if (selectedMenuItem === 'ovens') {
        breadcrumbs.push({
          title: locals.getText('bakery_ovens_breadcrumb'),
          onClick: () => {
            setSelectedMenuItem('ovens');
            setSelectedOvenModelId(
              ovenModels.length > 0 ? ovenModels[0].id : null,
            );
            setSelectedOvenGroupId(null);
            setSelectedOvenId(null);
          },
        });
      }
      if (selectedMenuItem === 'recipes') {
        breadcrumbs.push({
          title: locals.getText('bakery_recipes_breadcrumb'),
          onClick: () => {
            setSelectedMenuItem('recipes');
            setSelectedOvenModelId(
              ovenModels.length > 0 ? ovenModels[0].id : null,
            );
            setSelectedOvenGroupId(null);
            setSelectedOvenId(null);
          },
        });
      }
    }
    if (selectedMenuItem === 'ovens' && selectedOvenModel != null) {
      breadcrumbs.push({title: selectedOvenModel.description});
    }
    setBreadcrumbs(breadcrumbs);
    return () => setBreadcrumbs([]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    selectedCountry,
    selectedCity,
    selectedBakery,
    selectedMenuItem,
    selectedOvenModel,
  ]);

  function handleSelectCountry(countryId: number) {
    setSearchText('');
    setSelectedCountryId(countryId);
    setSelectedCityId(null);
    setSelectedBakeryId(null);
    setSelectedMenuItem('settings');
    setSelectedOvenModelId(null);
    setSelectedOvenGroupId(null);
    setSelectedOvenId(null);
  }

  function handleSelectCity(cityId: number) {
    setSearchText('');
    setSelectedCityId(cityId);
    setSelectedBakeryId(null);
    setSelectedMenuItem('settings');
    setSelectedOvenModelId(null);
    setSelectedOvenGroupId(null);
    setSelectedOvenId(null);
  }

  function handleSelectBakery(bakeryId: string) {
    setSearchText('');
    setSelectedBakeryId(bakeryId);
    setSelectedMenuItem('settings');
    setSelectedOvenModelId(null);
    setSelectedOvenGroupId(null);
    setSelectedOvenId(null);
  }

  function handleSelectMenuItem(menuItem: BakeryMenuItem) {
    setSelectedMenuItem(menuItem);
    setSelectedOvenModelId(ovenModels.length > 0 ? ovenModels[0].id : null);
    setSelectedOvenGroupId(null);
    setSelectedOvenId(null);
  }

  function handleSelectOvenModel(ovenModelId: OvenModelId) {
    setSelectedOvenModelId(ovenModelId);
    setSelectedOvenGroupId(null);
    setSelectedOvenId(null);
  }

  function handleSelectOvenGroup(ovenGroupId: string) {
    setSelectedOvenGroupId(
      ovenGroupId === selectedOvenGroupId ? null : ovenGroupId,
    );
  }

  function handleSelectOven(ovenId: string) {
    setSelectedOvenId(ovenId === selectedOvenId ? null : ovenId);
  }

  function handleReturnToBakeries() {
    setSelectedBakeryId(null);
    setSelectedMenuItem('settings');
    setSelectedOvenModelId(null);
    setSelectedOvenGroupId(null);
    setSelectedOvenId(null);
  }

  const loading = loadingLocations || loadingOvenModels;

  const renderBakeryMenu = selectedBakery != null;
  const renderActionBanner = selectedBakery == null;
  const renderCityList = selectedCity == null;
  const renderBakeryList = selectedCity != null && selectedBakery == null;
  const renderBakerySettings =
    selectedCountry != null &&
    selectedCity != null &&
    selectedBakery != null &&
    selectedMenuItem === 'settings';
  const renderBakeryOvens =
    selectedBakery != null && selectedMenuItem === 'ovens';

  return (
    <Fragment>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          width: '20vw',
          marginBlock: '112px',
        }}>
        {renderBakeryMenu && (
          <BakeryMenu
            selectedItem={selectedMenuItem}
            onSelectItem={handleSelectMenuItem}
          />
        )}
      </Box>
      <Box sx={{width: '80vw'}}>
        <Box sx={{width: '65vw'}}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between',
              height: '112px',
            }}>
            <CountryTabs
              countries={countries}
              selectedCountryId={selectedCountryId}
              onSelectCountry={handleSelectCountry}
            />
            <SearchField
              searchText={searchText}
              setSearchText={setSearchText}
              selectedCity={selectedCity}
              selectedBakery={selectedBakery}
              onReturnToBakeries={handleReturnToBakeries}
            />
          </Box>
          {renderActionBanner && (
            <Box sx={{marginBlock: 2}}>
              <ActionBanner
                text={locals.getText('bakeries_create_bakery_button_label')}
                onClick={() => navigate(paths.bakeriesCreate)}
              />
            </Box>
          )}
          {renderCityList && (
            <CityList
              searchText={searchText}
              cities={selectedCountry?.cities ?? []}
              onSelectCity={handleSelectCity}
              citiesNotFoundMessage={
                selectedCountry == null
                  ? ''
                  : locals.getText('bakeries_cities_not_found_label')
              }
            />
          )}
          {renderBakeryList && (
            <BakeryList
              searchText={searchText}
              bakeries={selectedCity?.bakeries ?? []}
              onSelectBakery={handleSelectBakery}
              bakeriesNotFoundMessage={
                selectedCity == null
                  ? ''
                  : locals.getText('bakeries_bakeries_not_found_label')
              }
            />
          )}
        </Box>
        {renderBakerySettings && (
          <BakerySettings
            selectedCountry={selectedCountry}
            selectedCity={selectedCity}
            selectedBakery={selectedBakery}
          />
        )}
        {renderBakeryOvens && (
          <BakeryOvens
            ovenModels={ovenModels}
            selectedCountryId={selectedCountryId}
            selectedCityId={selectedCityId}
            selectedBakeryId={selectedBakeryId}
            selectedOvenModel={selectedOvenModel}
            selectedOvenGroupId={selectedOvenGroupId}
            selectedOvenId={selectedOvenId}
            onSelectOvenModel={handleSelectOvenModel}
            onSelectOvenGroup={handleSelectOvenGroup}
            onSelectOven={handleSelectOven}
          />
        )}
      </Box>
      <LoadingBackdrop loading={loading} />
    </Fragment>
  );
}

export default Bakeries;
