import {colors, InputBase, Paper, SvgIcon} from '@mui/material';
import {useEffect, useState} from 'react';
import {ReactComponent as SearchIcon} from '../../assets/icons/search.svg';
import locals from '../../localization/locals';

function SimpleSearch(props: SimpleSearchProps) {
  const {onSearchText} = props;

  const [searchText, setSearchText] = useState(props.searchText || '');

  useEffect(() => {
    setSearchText(props.searchText || '');
  }, [props.searchText]);

  return (
    <Paper
      elevation={4}
      sx={{
        display: 'flex',
        alignItems: 'center',
        minHeight: '36px',
        marginBlock: 2,
        paddingInline: 2,
        borderRadius: '10px',
        backgroundColor: '#F4F4F4',
        flex: 1,
      }}>
      <InputBase
        sx={{
          flex: 1,
          height: '36px',
          fontSize: '0.875rem',
        }}
        placeholder={locals.getText('bakeries_search_field_placeholder')}
        value={searchText}
        onChange={(event) => onSearchText?.(event.target.value)}
      />
      <SvgIcon
        component={SearchIcon}
        inheritViewBox
        sx={{fontSize: '20px', color: colors.grey, cursor: 'pointer'}}
      />
    </Paper>
  );
}

type SimpleSearchProps = {
  onSearchText?: (text: string) => void;
  searchText?: string;
};

export default SimpleSearch;
