import GetCitiesParams from '../../models/requests/getCities';
import GetDistrictsParams from '../../models/requests/getDistricts';
import GetCitiesResponse from '../../models/responses/getCities';
import GetCountriesResponse from '../../models/responses/getCountries';
import GetDistrictsResponse from '../../models/responses/getDistricts';
import {LocationService} from '../location';
import data from './data';

const mockLocationService: LocationService = {
  getCountries: async function (): Promise<GetCountriesResponse> {
    return new Promise((resolve) =>
      setTimeout(() => resolve(data.countries), 500),
    );
  },
  getDistricts: async function (
    params: GetDistrictsParams,
  ): Promise<GetDistrictsResponse> {
    return new Promise((resolve) =>
      setTimeout(() => {
        const {countryId} = params;
        const districts = data.districts.filter(
          (district) => countryId == null || district.countryId === countryId,
        );
        resolve(districts);
      }, 500),
    );
  },
  getCities: async function (
    params: GetCitiesParams,
  ): Promise<GetCitiesResponse> {
    return new Promise((resolve) =>
      setTimeout(() => {
        const {districtId} = params;
        const cities = data.cities.filter(
          (city) => districtId == null || city.districtId === districtId,
        );
        resolve(cities);
      }, 500),
    );
  },
};

export default mockLocationService;
