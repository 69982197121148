import {Box, List, ListItem, Typography} from '@mui/material';
import {useState} from 'react';
import {ReactComponent as DeclineIcon} from '../../assets/icons/decline.svg';
import OvenPanelNotification from '../../models/entities/ovenPanelNotification';
import dateUtils from '../../utils/dates';
import ovenPanelNotificationUtils from '../../utils/notifications';
import GradientOverflow from '../common/GradientOverflow';
import IconButton from '../common/IconButton';
import Span from '../common/Span';
import Switch from '../common/Switch';

type NotificationListItemProps = {
  notification: OvenPanelNotification;
  onSelect: () => void;
  onSwitch: (checked: boolean) => void;
  onDelete: () => void;
  isSelectingMultiple: boolean;
  selected: boolean;
};

function NotificationListItem(props: NotificationListItemProps) {
  const {
    notification,
    onSelect,
    onSwitch,
    onDelete,
    isSelectingMultiple,
    selected,
  } = props;

  const [isHovered, setIsHovered] = useState(false);

  function getColor() {
    if (isHovered) return 'primary.main';
    return notification.isSeen ? 'text.secondary' : 'text.primary';
  }

  return (
    <ListItem
      sx={{alignItems: 'center', color: getColor(), margin: 0, padding: 0}}>
      <Box
        sx={{display: 'flex', alignItems: 'center', cursor: 'pointer'}}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
        onClick={onSelect}>
        <Box sx={{width: '50vw', padding: 2}}>
          <Typography variant="body2">
            <Span sx={{fontWeight: 700}}>
              {`${notification.ovenDescription} | `}
            </Span>
            <Span>{`${notification.bakeryName} | `}</Span>
            <Span sx={{fontWeight: 100}}>
              {ovenPanelNotificationUtils.getTranslatedDescription(
                notification,
              )}
            </Span>
          </Typography>
        </Box>
        <Box sx={{width: '15vw', textAlign: 'right', padding: 2}}>
          <Typography variant="body2">
            {dateUtils.toLocaleDateString(notification.date)}
          </Typography>
        </Box>
      </Box>
      <Box sx={{width: '15vw', padding: 1}}>
        {isSelectingMultiple ? (
          <Switch
            checked={selected}
            onChange={(_, checked) => onSwitch?.(checked)}
          />
        ) : (
          <IconButton IconComponent={DeclineIcon} onClick={onDelete} />
        )}
      </Box>
    </ListItem>
  );
}

type NotificationListProps = {
  notifications: OvenPanelNotification[];
  onSelectNotification: (notificationId: string) => void;
  onSwitchNotification: (notificationId: string, checked: boolean) => void;
  onDeleteNotification: (notificationId: string) => void;
  isSelectingMultiple: boolean;
  selectedNotificationIds: string[];
  notificationsNotFoundLabel: string;
};

function NotificationList(props: NotificationListProps) {
  const {
    notifications,
    onSelectNotification,
    onDeleteNotification,
    onSwitchNotification,
    isSelectingMultiple,
    selectedNotificationIds,
    notificationsNotFoundLabel,
  } = props;

  return (
    <GradientOverflow hideScrollbar>
      <List sx={{margin: 0, padding: 0}}>
        {notifications.length === 0 && (
          <ListItem sx={{margin: 0, padding: 0}}>
            <Typography
              variant="body2"
              sx={{color: 'text.primary', padding: 2}}>
              {notificationsNotFoundLabel}
            </Typography>
          </ListItem>
        )}
        {notifications.map((notification) => (
          <NotificationListItem
            key={notification.id}
            notification={notification}
            onSelect={() => onSelectNotification(notification.id)}
            onSwitch={(checked) =>
              onSwitchNotification(notification.id, checked)
            }
            onDelete={() => onDeleteNotification(notification.id)}
            isSelectingMultiple={isSelectingMultiple}
            selected={selectedNotificationIds.includes(notification.id)}
          />
        ))}
      </List>
    </GradientOverflow>
  );
}

export default NotificationList;
