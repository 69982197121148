import {
  Box,
  ClickAwayListener,
  Collapse,
  List,
  ListItem,
  Typography,
} from '@mui/material';
import {Fragment, useMemo} from 'react';
import {ReactComponent as DeleteIcon} from '../../assets/icons/delete.svg';
import locals from '../../localization/locals';
import Oven from '../../models/entities/oven';
import OvenGroup from '../../models/entities/ovenGroup';
import {OvenModelId} from '../../models/entities/ovenModel';
import dateUtils from '../../utils/dates';
import GradientOverflow from '../common/GradientOverflow';
import IconButton from '../common/IconButton';
import OvenPanelMenu, {OvenPanelMenuAction} from './OvenPanelMenu';

type OvenGroupListItemProps = {
  ovenGroup: OvenGroup;
  onSelectOvenGroup: (ovenGroupId: string) => void;
  onActionClick: (
    ovenGroupId: string,
    ovenId: string,
    ovenPanelId: string,
    action: OvenPanelMenuAction,
  ) => void;
  selectedOvenGroupId: string;
  selectedOvenId: string;
};

function OvenGroupListItem(props: OvenGroupListItemProps) {
  const {
    ovenGroup,
    onSelectOvenGroup,
    onActionClick,
    selectedOvenGroupId,
    selectedOvenId,
  } = props;

  const selected = ovenGroup.id === selectedOvenGroupId;
  const selectedAnother = (selectedOvenGroupId || selectedOvenId) && !selected;

  const ovens = useMemo(
    () =>
      Array.from(ovenGroup.ovens ?? []).sort(
        (ovenA, ovenB) =>
          (ovenA.ovenGroupOrder ?? 0) - (ovenB.ovenGroupOrder ?? 0),
      ),
    [ovenGroup],
  );

  const color = selected ? 'primary.main' : 'text.primary';

  return (
    <ClickAwayListener onClickAway={() => onSelectOvenGroup('')}>
      <ListItem
        sx={{margin: 0, padding: 0, opacity: selectedAnother ? 0.5 : 1}}>
        <Box sx={{paddingBlock: 2}}>
          {ovens.map((oven, index) => (
            <Fragment key={oven.id}>
              <Box sx={{display: 'flex', alignItems: 'center'}}>
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    cursor: 'pointer',
                  }}
                  onClick={(event) => {
                    event.stopPropagation();
                    onSelectOvenGroup(ovenGroup.id);
                  }}>
                  <Box sx={{width: 'calc(40vw - 75px)', paddingInline: 2}}>
                    {index === 0 && (
                      <Typography
                        variant="body2"
                        sx={{fontWeight: 'bold', color}}>
                        {ovenGroup.description}
                      </Typography>
                    )}
                    <Typography variant="body2" sx={{color}}>
                      {`${locals.getText(
                        'bakery_ovens_oven_serial_number_label',
                      )} ${(oven.ovenGroupOrder ?? 0) + 1}: ${
                        oven.serialNumber
                      }`}
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      width: 'calc(25vw - 75px)',
                      textAlign: 'end',
                      paddingInline: 2,
                    }}>
                    {!selected && index === 0 && (
                      <Typography variant="body2" sx={{color}}>
                        {dateUtils.toLocaleDateString(
                          ovenGroup.assignedAt ?? '',
                        )}
                      </Typography>
                    )}
                    {selected && (
                      <Typography variant="body2" sx={{color}}>
                        {dateUtils.toLocaleDateString(oven.assignedAt ?? '')}
                      </Typography>
                    )}
                  </Box>
                </Box>
                <Box
                  sx={{width: '15vw', textAlign: 'center', paddingInline: 2}}>
                  {!selected && index === 0 && (
                    <IconButton IconComponent={DeleteIcon} disabled />
                  )}
                  {selected && (
                    <IconButton IconComponent={DeleteIcon} disabled />
                  )}
                </Box>
              </Box>
              <Collapse in={selected} unmountOnExit>
                <Box
                  sx={{
                    marginTop: 4,
                    marginBottom: index === ovens.length - 1 ? 2 : 4,
                    marginInline: 2,
                  }}>
                  {oven.ovenPanels?.map((ovenPanel) => (
                    <OvenPanelMenu
                      key={ovenPanel.id}
                      onClick={(action) =>
                        onActionClick?.(
                          ovenGroup.id,
                          oven.id,
                          ovenPanel.id,
                          action,
                        )
                      }
                    />
                  ))}
                </Box>
              </Collapse>
            </Fragment>
          ))}
        </Box>
      </ListItem>
    </ClickAwayListener>
  );
}

type ChamberOvenListItemProps = {
  oven: Oven;
  onSelectOven: (ovenId: string) => void;
  onActionClick: (
    ovenId: string,
    ovenChamberId: string,
    ovenPanelId: string,
    action: OvenPanelMenuAction,
  ) => void;
  selectedOvenGroupId: string;
  selectedOvenId: string;
};

function ChamberOvenListItem(props: ChamberOvenListItemProps) {
  const {
    oven,
    onSelectOven,
    onActionClick,
    selectedOvenGroupId,
    selectedOvenId,
  } = props;

  const selected = oven.id === selectedOvenId;
  const selectedAnother = (selectedOvenGroupId || selectedOvenId) && !selected;

  const ovenChambers = useMemo(
    () =>
      Array.from(oven.ovenChambers ?? []).sort(
        (ovenChamberA, ovenChamberB) =>
          (ovenChamberA.ovenOrder ?? 0) - (ovenChamberB.ovenOrder ?? 0),
      ),
    [oven],
  );

  const color = selected ? 'primary.main' : 'text.primary';

  return (
    <ClickAwayListener onClickAway={() => onSelectOven('')}>
      <ListItem
        sx={{margin: 0, padding: 0, opacity: selectedAnother ? 0.5 : 1}}>
        <Box sx={{paddingBlock: 2}}>
          {ovenChambers.map((ovenChamber, index) => (
            <Fragment key={ovenChamber.id}>
              <Box sx={{display: 'flex', alignItems: 'center'}}>
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    cursor: 'pointer',
                  }}
                  onClick={(event) => {
                    event.stopPropagation();
                    onSelectOven(oven.id);
                  }}>
                  <Box
                    sx={{
                      width: 'calc(40vw - 75px)',
                      paddingInline: 2,
                    }}>
                    {index === 0 && (
                      <Typography
                        variant="body2"
                        sx={{fontWeight: 'bold', color}}>
                        {oven.description}
                      </Typography>
                    )}
                    <Typography variant="body2" sx={{color}}>
                      {`${locals.getText(
                        'bakery_ovens_chamber_serial_number_label',
                      )} ${(ovenChamber.ovenOrder ?? 0) + 1}: ${
                        ovenChamber.serialNumber
                      }`}
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      width: 'calc(25vw - 75px)',
                      textAlign: 'end',
                      paddingInline: 2,
                    }}>
                    {!selected && index === 0 && (
                      <Typography variant="body2" sx={{color}}>
                        {dateUtils.toLocaleDateString(oven.assignedAt ?? '')}
                      </Typography>
                    )}
                    {selected && (
                      <Typography variant="body2" sx={{color}}>
                        {dateUtils.toLocaleDateString(
                          ovenChamber.assignedAt ?? '',
                        )}
                      </Typography>
                    )}
                  </Box>
                </Box>
                <Box
                  sx={{
                    width: '15vw',
                    textAlign: 'center',
                    paddingInline: 2,
                  }}>
                  {!selected && index === 0 && (
                    <IconButton IconComponent={DeleteIcon} disabled />
                  )}
                  {selected && (
                    <IconButton IconComponent={DeleteIcon} disabled />
                  )}
                </Box>
              </Box>
              <Collapse in={selected} unmountOnExit>
                <Box
                  sx={{
                    marginTop: 4,
                    marginBottom: index === ovenChambers.length - 1 ? 2 : 4,
                    marginInline: 2,
                  }}>
                  {ovenChamber.ovenPanels?.map((ovenPanel) => (
                    <OvenPanelMenu
                      key={ovenPanel.id}
                      onClick={(action) =>
                        onActionClick?.(
                          oven.id,
                          ovenChamber.id,
                          ovenPanel.id,
                          action,
                        )
                      }
                    />
                  ))}
                </Box>
              </Collapse>
            </Fragment>
          ))}
        </Box>
      </ListItem>
    </ClickAwayListener>
  );
}

type OvenListItemProps = {
  oven: Oven;
  onSelectOven: (ovenId: string) => void;
  onActionClick: (
    ovenId: string,
    ovenPanelId: string,
    action: OvenPanelMenuAction,
  ) => void;
  selectedOvenGroupId: string;
  selectedOvenId: string;
};

function OvenListItem(props: OvenListItemProps) {
  const {
    oven,
    onSelectOven,
    onActionClick,
    selectedOvenGroupId,
    selectedOvenId,
  } = props;

  const selected = oven.id === selectedOvenId;
  const selectedAnother = (selectedOvenGroupId || selectedOvenId) && !selected;

  const ovenPanels = useMemo(
    () =>
      Array.from(oven.ovenPanels ?? []).sort(
        (ovenPanelA, ovenPanelB) =>
          (ovenPanelA.ovenOrder ?? 0) - (ovenPanelB.ovenOrder ?? 0),
      ),
    [oven],
  );

  const color = selected ? 'primary.main' : 'text.primary';

  return (
    <ClickAwayListener onClickAway={() => onSelectOven('')}>
      <ListItem
        sx={{margin: 0, padding: 0, opacity: selectedAnother ? 0.5 : 1}}>
        <Box>
          <Box sx={{display: 'flex', alignItems: 'center'}}>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                cursor: 'pointer',
              }}
              onClick={(event) => {
                event.stopPropagation();
                onSelectOven(oven.id);
              }}>
              <Box sx={{width: 'calc(40vw - 75px)', padding: 2}}>
                <Typography variant="body2" sx={{fontWeight: 'bold', color}}>
                  {oven.description}
                </Typography>
                <Typography variant="body2" sx={{color}}>
                  {`${locals.getText('bakery_ovens_serial_number_label')}: ${
                    oven.serialNumber
                  }`}
                </Typography>
              </Box>
              <Box
                sx={{
                  width: 'calc(25vw - 75px)',
                  textAlign: 'end',
                  padding: 2,
                }}>
                <Typography variant="body2" sx={{color}}>
                  {dateUtils.toLocaleDateString(oven.assignedAt ?? '')}
                </Typography>
              </Box>
            </Box>
            <Box
              sx={{
                width: '15vw',
                textAlign: 'center',
                padding: 1,
              }}>
              <IconButton IconComponent={DeleteIcon} disabled />
            </Box>
          </Box>
          <Collapse in={selected} unmountOnExit>
            <Box sx={{margin: 2}}>
              {ovenPanels.map((ovenPanel) => (
                <OvenPanelMenu
                  key={ovenPanel.id}
                  hasMultiple={ovenPanels.length > 1}
                  ovenOrder={ovenPanel.ovenOrder ?? 0}
                  onClick={(action) =>
                    onActionClick?.(oven.id, ovenPanel.id, action)
                  }
                />
              ))}
            </Box>
          </Collapse>
        </Box>
      </ListItem>
    </ClickAwayListener>
  );
}

export type OvenTableProps = {
  ovenGroups: OvenGroup[];
  ovens: Oven[];
  selectedOvenGroupId: string;
  selectedOvenId: string;
  onSelectOvenGroup: (ovenGroupId: string) => void;
  onSelectOven: (ovenId: string) => void;
  onActionClick: (
    ovenId: string,
    ovenPanelId: string,
    action: OvenPanelMenuAction,
    ovenChamberId?: string,
    ovenGroupId?: string,
  ) => void;
};

function OvenList(props: OvenTableProps) {
  const {
    ovenGroups,
    ovens,
    selectedOvenGroupId,
    selectedOvenId,
    onSelectOvenGroup,
    onSelectOven,
    onActionClick,
  } = props;

  return (
    <GradientOverflow hideScrollbar containerProps={{sx: {marginLeft: 2}}}>
      <List sx={{margin: 0, padding: 0}}>
        {ovens.map((oven) =>
          oven.ovenModelId === OvenModelId.Modulram ? (
            <ChamberOvenListItem
              key={oven.id}
              oven={oven}
              onSelectOven={onSelectOven}
              onActionClick={(ovenId, ovenChamberId, ovenPanelId, action) =>
                onActionClick(ovenId, ovenPanelId, action, ovenChamberId)
              }
              selectedOvenGroupId={selectedOvenGroupId}
              selectedOvenId={selectedOvenId}
            />
          ) : (
            <OvenListItem
              key={oven.id}
              oven={oven}
              onSelectOven={onSelectOven}
              onActionClick={(ovenId, ovenPanelId, action) =>
                onActionClick?.(ovenId, ovenPanelId, action)
              }
              selectedOvenGroupId={selectedOvenGroupId}
              selectedOvenId={selectedOvenId}
            />
          ),
        )}
        {ovenGroups.map((ovenGroup) => (
          <OvenGroupListItem
            key={ovenGroup.id}
            ovenGroup={ovenGroup}
            onSelectOvenGroup={onSelectOvenGroup}
            onActionClick={(ovenGroupId, ovenId, ovenPanelId, action) =>
              onActionClick?.(
                ovenId,
                ovenPanelId,
                action,
                undefined,
                ovenGroupId,
              )
            }
            selectedOvenGroupId={selectedOvenGroupId}
            selectedOvenId={selectedOvenId}
          />
        ))}
      </List>
    </GradientOverflow>
  );
}

export default OvenList;
