import {alpha, Box, Typography} from '@mui/material';
import {ElementType, useState} from 'react';
import colors from '../../themes/colors';
import Icon from '../common/Icon';

type DashboardMenuItemProps = {
  icon: ElementType<any>;
  text: string;
  onClick?: () => void;
  disabled?: boolean;
};

function DashboardMenuItem(props: DashboardMenuItemProps) {
  const {icon, text, onClick, disabled = false} = props;

  const [color, setColor] = useState<'primary' | 'secondary'>('secondary');

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        cursor: disabled ? undefined : 'pointer',
      }}
      onClick={() => (disabled ? null : onClick?.())}
      onMouseEnter={() => (disabled ? null : setColor('primary'))}
      onMouseLeave={() => (disabled ? null : setColor('secondary'))}>
      <Icon
        IconComponent={icon}
        color={disabled ? alpha(colors.red, 0.5) : 'primary'}
        size="80px"
      />
      <Typography
        variant="h6"
        color={disabled ? 'text.secondary' : color}
        sx={{marginTop: '2vh', fontWeight: '400'}}>
        {text}
      </Typography>
    </Box>
  );
}

export default DashboardMenuItem;
