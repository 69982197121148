import {ThemeProvider} from '@mui/material';
import {PropsWithChildren} from 'react';
import {QueryClient, QueryClientProvider} from 'react-query';
import {BrowserRouter, useNavigate} from 'react-router-dom';
import ErrorModal from './components/common/ErrorModal';
import SplashScreen from './components/common/SplashScreen';
import {FirebaseSubscriber} from './firebase/Subscriber';
import {getSubscriptionToken} from './firebase/app';
import useEffectOnce from './hooks/common/useEffectOnce';
import Routes from './routes/Routes';
import {persistedLogin} from './state/auth';
import theme from './themes/default';
import errorUtils from './utils/errors';

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: false,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
      onError: (error) => errorUtils.handleError(error),
    },
    mutations: {
      retry: false,
      onError: (error) => errorUtils.handleError(error),
    },
  },
});

function AppContainer(props: PropsWithChildren) {
  const navigate = useNavigate();

  useEffectOnce(() => {
    getSubscriptionToken();
    persistedLogin(navigate);
  });

  return <FirebaseSubscriber>{props.children}</FirebaseSubscriber>;
}

function App() {
  return (
    <BrowserRouter>
      <QueryClientProvider client={queryClient}>
        <ThemeProvider theme={theme}>
          <AppContainer>
            <Routes />
            <ErrorModal />
            <SplashScreen />
          </AppContainer>
        </ThemeProvider>
      </QueryClientProvider>
    </BrowserRouter>
  );
}

export default App;
