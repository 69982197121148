import CreateBakeryRequest from '../models/requests/createBakery';
import GetBakeriesLocationsParams from '../models/requests/getBakeriesLocations';
import GetBakeryParams from '../models/requests/getBakery';
import GetBakeryOvensParams from '../models/requests/getBakeryOvens';
import UpdateBakeryRequest from '../models/requests/updateBakery';
import CreateBakeryResponse from '../models/responses/createBakery';
import GetBakeriesLocationsResponse from '../models/responses/getBakeriesLocations';
import GetBakeryResponse from '../models/responses/getBakery';
import GetBakeryOvensResponse from '../models/responses/getBakeryOvens';
import UpdateBakeryResponse from '../models/responses/updateBakery';
import baseService from './base';
import endpoints from './endpoints';

export type BakeryService = {
  getBakeriesLocations(
    params: GetBakeriesLocationsParams,
  ): Promise<GetBakeriesLocationsResponse>;
  getBakery: (params: GetBakeryParams) => Promise<GetBakeryResponse>;
  getBakeryOvens(params: GetBakeryOvensParams): Promise<GetBakeryOvensResponse>;
  createBakery(request: CreateBakeryRequest): Promise<CreateBakeryResponse>;
  updateBakery(request: UpdateBakeryRequest): Promise<UpdateBakeryResponse>;
};

const bakeryService: BakeryService = {
  getBakeriesLocations: async function (
    params: GetBakeriesLocationsParams,
  ): Promise<GetBakeriesLocationsResponse> {
    return baseService
      .get<GetBakeriesLocationsParams, GetBakeriesLocationsResponse>(
        `${endpoints.bakeries}/locations`,
        params,
      )
      .then((result) => result.data);
  },
  getBakery: async function (
    params: GetBakeryParams,
  ): Promise<GetBakeryResponse> {
    return baseService
      .get<null, GetBakeryResponse>(`${endpoints.bakeries}/${params.bakeryId}`)
      .then((result) => result.data);
  },
  getBakeryOvens: async function (
    params: GetBakeryOvensParams,
  ): Promise<GetBakeryOvensResponse> {
    const {bakeryId} = params;
    return baseService
      .get<GetBakeryOvensParams, GetBakeryOvensResponse>(
        `${endpoints.bakeries}/${bakeryId}/ovens`,
        params,
      )
      .then((result) => result.data);
  },
  createBakery: async function (
    request: CreateBakeryRequest,
  ): Promise<CreateBakeryResponse> {
    return baseService
      .post<CreateBakeryRequest, CreateBakeryResponse>(
        endpoints.bakeries,
        request,
      )
      .then((result) => result.data);
  },
  updateBakery: async function (
    request: UpdateBakeryRequest,
  ): Promise<UpdateBakeryResponse> {
    const {id: bakeryId} = request;
    return baseService
      .put<UpdateBakeryRequest, UpdateBakeryResponse>(
        `${endpoints.bakeries}/${bakeryId}`,
        request,
      )
      .then((result) => result.data);
  },
};

export default bakeryService;
