import {Box, Typography} from '@mui/material';
import {ReactComponent as LogoIcon} from '../../assets/icons/logo.svg';
import colors from '../../themes/colors';
import Icon from './Icon';

function NotFound() {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100vw',
        height: '100vh',
        backgroundColor: 'primary.main',
      }}>
      <Icon IconComponent={LogoIcon} size="80px" color={colors.white} />
      <Typography
        variant="h4"
        sx={{
          marginTop: 4,
          marginBottom: 2,
          color: colors.white,
          fontWeight: 'bold',
        }}>
        404 - Page Not Found
      </Typography>
      <Typography variant="h6" sx={{color: colors.white, fontWeight: 'bold'}}>
        The page you are looking for does not exist.
      </Typography>
    </Box>
  );
}

export default NotFound;
