import {List, ListItem, Typography} from '@mui/material';
import {useNavigate} from 'react-router-dom';
import useSplashScreen from '../../hooks/common/useSplashScreen';
import locals from '../../localization/locals';
import Recipe from '../../models/entities/recipe';
import RecipeType from '../../models/entities/recipeType';
import paths from '../../routes/paths';
import routerUtils from '../../utils/router';
import stringUtils from '../../utils/strings';
import GradientOverflow from '../common/GradientOverflow';
import RecipesListItem, {RecipeListItemAction} from './RecipesListItem';

export type RecipeListProps = {
  searchText: string;
  selectedRecipeType: RecipeType | null;
  recipes: Recipe[];
  selectedRecipeId: string | null;
  setSelectedRecipeId: (recipeId: string | null) => void;
  onDeleteRecipe: (recipeId: string) => void;
  onRemoveRecipeFromRecipeType(recipeId: string, recipeTypeId: string): void;
};

function RecipeList(props: RecipeListProps) {
  const {
    searchText,
    selectedRecipeType,
    recipes,
    selectedRecipeId,
    setSelectedRecipeId,
    onDeleteRecipe,
    onRemoveRecipeFromRecipeType,
  } = props;

  const navigate = useNavigate();
  const {splash} = useSplashScreen();

  function filterRecipes() {
    return recipes.filter(
      (recipe) =>
        (stringUtils.unicodeStartsWith(recipe.name, searchText) ||
          stringUtils.unicodeIncludes(recipe.name, ` ${searchText}`)) &&
        (selectedRecipeType == null ||
          recipe.recipeTypes?.some(
            (recipeType) => recipeType.id === selectedRecipeType.id,
          )),
    );
  }

  function handleRecipeAction(recipeId: string, action: RecipeListItemAction) {
    switch (action) {
      case 'update':
        navigate({
          pathname: paths.editRecipe,
          search: routerUtils
            .createSearchParams({action: 'update', recipeId})
            .toString(),
        });
        break;
      case 'locate':
        navigate({
          pathname: paths.editRecipe,
          search: routerUtils
            .createSearchParams({action: 'locate', recipeId})
            .toString(),
        });
        break;
      case 'delete':
        splash({
          title: locals.getText('splash_screen_delete_recipe_title'),
          caption: locals.getText('splash_screen_delete_recipe_caption'),
          subCaption: locals.getText('splash_screen_delete_recipe_subcaption'),
          acceptAction: () => onDeleteRecipe(recipeId),
          declineAction: () => {},
        });
        break;
      case 'removeFromRecipeType':
        if (selectedRecipeType != null) {
          onRemoveRecipeFromRecipeType(recipeId, selectedRecipeType.id);
        }
        break;
    }
  }

  const filteredRecipes = filterRecipes();

  return (
    <GradientOverflow hideScrollbar>
      <List sx={{margin: 0, padding: 0}}>
        {filteredRecipes.length === 0 && (
          <ListItem sx={{margin: 0, padding: 0}}>
            <Typography
              variant="body2"
              sx={{color: 'text.primary', padding: 2}}>
              {locals.getText('recipes_recipes_not_found_label')}
            </Typography>
          </ListItem>
        )}
        {filteredRecipes.map((recipe) => (
          <RecipesListItem
            key={recipe.id}
            recipe={recipe}
            selected={recipe.id === selectedRecipeId}
            onClick={() =>
              setSelectedRecipeId(
                recipe.id === selectedRecipeId ? null : recipe.id,
              )
            }
            onActionClick={(action) => handleRecipeAction(recipe.id, action)}
            onClickAway={() => setSelectedRecipeId(null)}
            canRemoveFromRecipeType={selectedRecipeType != null}
          />
        ))}
      </List>
    </GradientOverflow>
  );
}

export default RecipeList;
