import {Box, Paper, Typography} from '@mui/material';
import {Validator} from 'jsonschema';
import {Fragment, useEffect, useState} from 'react';
import {useMutation, useQuery, useQueryClient} from 'react-query';
import {useNavigate, useSearchParams} from 'react-router-dom';
import {v4 as uuid} from 'uuid';
import useLocalization from '../../../hooks/common/useLocalization';
import useQueryOnce from '../../../hooks/common/useQueryOnce';
import locals from '../../../localization/locals';
import Recipe, {RecipePhase} from '../../../models/entities/recipe';
import paths from '../../../routes/paths';
import services from '../../../services/provider';
import useBreadcrumbsStore, {Breadcrumb} from '../../../state/breadcrumbs';
import numberUtils from '../../../utils/numbers';
import ovenModelUtils from '../../../utils/ovenModels';
import routerUtils from '../../../utils/router';
import LoadingBackdrop from '../../common/LoadingBackdrop';
import Span from '../../common/Span';
import {pageHeight} from '../../navigation/Navbar';
import RecipeSettingsMenu from './RecipeSettingsMenu';
import GeneralStep from './steps/GeneralStep';
import LocationStep from './steps/LocationStep';
import PhaseStep from './steps/PhaseStep';
import ProceduresStep from './steps/ProcedureStep';

function getEmptyPhase(): RecipePhase {
  return {
    temperature: 0,
    duration: 0,
    steamInjectionNumber: 0,
    steamExitValveOpened: false,
    turbineSpeed: 0,
  };
}

function getEmptyRecipeBuild(
  ovenModelId: number,
  recipeTypeId?: string | null,
): RecipeBuild {
  return {
    id: uuid(),
    name: '',
    procedure: '',
    phases: [getEmptyPhase()],
    ovenModelId,
    recipeTypeIds: recipeTypeId != null ? [recipeTypeId] : [],
    bakeryIds: [],
  };
}

function getStepBreadcrumb(step: RecipeSettingsStep, phaseIndex: number) {
  switch (step) {
    case 'general':
      return locals.getText('recipe_settings_phase_general');
    case 'procedure':
      return locals.getText('recipe_settings_phase_procedure');
    case 'phases':
      return `${locals.getText('recipe_settings_phase_phase')}${
        phaseIndex + 1
      }`;
    case 'location':
      return locals.getText('recipe_settings_phase_location');
  }
}

function getStepTitle(step: RecipeSettingsStep, phaseIndex: number) {
  switch (step) {
    case 'general':
      return locals.getText('recipe_settings_general_step_title');
    case 'procedure':
      return locals.getText('recipe_settings_procedures_step_title');
    case 'phases':
      return `${locals.getText('recipe_settings_phases_step_title')} ${
        phaseIndex + 1
      }`;
    case 'location':
      return '';
  }
}

export type RecipeSettingsAction = 'create' | 'update' | 'locate';

export type RecipeSettingsSteps = {
  general: {visible: boolean; error: boolean};
  procedure: {visible: boolean; error: boolean};
  phases: {visible: boolean; errors: boolean[]};
  location: {visible: boolean; error: boolean};
};

export type RecipeSettingsStep = keyof RecipeSettingsSteps;

export type RecipeBuild = {
  id: string;
  name: string;
  procedure: string;
  phases: RecipePhase[];
  ovenModelId: number;
  recipeTypeIds: string[];
  bakeryIds: string[];
};

function RecipeSettings() {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  useLocalization();

  const [searchParams] = useSearchParams();
  const action = (searchParams.get('action') ??
    'create') as RecipeSettingsAction;
  const ovenModelId = searchParams.has('ovenModelId')
    ? numberUtils.parseInt(searchParams.get('ovenModelId'), 1)
    : 1;
  const recipeTypeId = searchParams.get('recipeTypeId');
  const recipeId = searchParams.get('recipeId');

  const setBreadcrumbs = useBreadcrumbsStore((state) => state.setBreadcrumbs);

  const [steps, setSteps] = useState<RecipeSettingsSteps>({
    general: {visible: action !== 'locate', error: true},
    procedure: {visible: false, error: false},
    phases: {visible: false, errors: [true]},
    location: {visible: action === 'locate', error: false},
  });
  const [selectedStep, setSelectedStep] = useState<RecipeSettingsStep>(
    action === 'locate' ? 'location' : 'general',
  );
  const [recipeBuild, setRecipeBuild] = useState<RecipeBuild>(
    getEmptyRecipeBuild(ovenModelId, recipeTypeId),
  );

  const [selectedPhaseIndex, setSelectedPhaseIndex] = useState(0);

  const {data: recipe, isLoading: loadingRecipe} = useQueryOnce({
    enabled: recipeId != null,
    queryFn: () => services.recipe.getRecipe({recipeId: recipeId!}),
    onSuccess: (recipe) => {
      setRecipeBuild({
        id: recipe.id,
        name: recipe.name,
        procedure: recipe.procedure ?? '',
        phases: recipe.phases,
        ovenModelId: recipe.ovenModelId,
        recipeTypeIds:
          recipe.recipeTypes?.map((recipeType) => recipeType.id) ?? [],
        bakeryIds: recipe.bakeries?.map((bakery) => bakery.id) ?? [],
      });
      if (action === 'update') {
        setSteps({
          general: {visible: true, error: false},
          procedure: {visible: true, error: false},
          phases: {visible: true, errors: recipe.phases.map(() => false)},
          location: {visible: false, error: false},
        });
      }
      if (action === 'locate') {
        setSteps({
          general: {visible: false, error: false},
          procedure: {visible: false, error: false},
          phases: {visible: false, errors: recipe.phases.map(() => false)},
          location: {visible: true, error: false},
        });
      }
    },
  });

  const {data: recipeSchema, isLoading: loadingRecipeSchema} = useQuery({
    queryKey: ['recipeSchema', recipeBuild.ovenModelId],
    queryFn: () =>
      services.ovenModel.getRecipeSchema({
        ovenModelId: recipeBuild.ovenModelId,
      }),
    onSuccess: ({phaseSchema}) => {
      setSteps((steps) => ({
        ...steps,
        phases: {
          visible: steps.phases.visible,
          errors: recipeBuild.phases.map((phase) => {
            const errors = new Validator().validate(phase, phaseSchema).errors;
            return errors.length > 0;
          }),
        },
      }));
    },
  });

  const {mutate: createRecipe, isLoading: loadingCreateRecipe} = useMutation({
    mutationFn: services.recipe.createRecipe,
    onSuccess: (recipe) => {
      queryClient.setQueryData<Recipe[] | undefined>(
        ['recipes', {ovenModelId: recipeBuild.ovenModelId}],
        (recipes) => (recipes != null ? [...recipes, recipe] : [recipe]),
      );
      queryClient.invalidateQueries('recipes');
      navigate({
        pathname: paths.recipes,
        search: routerUtils
          .createSearchParams({
            ovenModelId: recipeBuild.ovenModelId,
            recipeTypeId: recipeBuild.recipeTypeIds?.[0],
          })
          .toString(),
      });
    },
  });

  const {mutate: updateRecipe, isLoading: loadingUpdateRecipe} = useMutation({
    mutationFn: services.recipe.updateRecipe,
    onSuccess: (updatedRecipe) => {
      queryClient.setQueryData<Recipe[] | undefined>(
        ['recipes', {ovenModelId: recipeBuild.ovenModelId}],
        (recipes) =>
          recipes?.map((recipe) =>
            recipe.id === updatedRecipe.id ? updatedRecipe : recipe,
          ),
      );
      queryClient.invalidateQueries('recipes');
      navigate({
        pathname: paths.recipes,
        search: routerUtils
          .createSearchParams({
            ovenModelId: recipeBuild.ovenModelId,
            recipeTypeId: recipeBuild.recipeTypeIds?.[0],
          })
          .toString(),
      });
    },
  });

  useEffect(() => {
    const breadcrumbs: Breadcrumb[] = [
      {
        title: locals.getText('recipes_breadcrumb'),
        onClick: () => navigate(paths.recipes),
      },
    ];
    if (action === 'create') {
      breadcrumbs.push({
        title: locals.getText('recipe_settings_create_recipe_breadcrumb'),
        onClick: () => {
          setSteps({
            general: {visible: true, error: true},
            procedure: {visible: false, error: false},
            phases: {visible: false, errors: [true]},
            location: {visible: false, error: false},
          });
          setSelectedStep('general');
          setRecipeBuild(getEmptyRecipeBuild(ovenModelId, recipeTypeId));
        },
      });
    }
    if (action === 'update') {
      breadcrumbs.push({
        title: locals.getText('recipe_settings_update_recipe_breadcrumb'),
        onClick: () => {
          setSteps((steps) => ({
            ...steps,
            general: {visible: true, error: false},
            procedure: {visible: true, error: false},
            phases: {
              visible: true,
              errors: recipe?.phases.map(() => false) ?? [],
            },
          }));
          setSelectedStep('general');
          setRecipeBuild({
            id: recipe?.id ?? '',
            name: recipe?.name ?? '',
            procedure: recipe?.procedure ?? '',
            phases: recipe?.phases ?? [],
            ovenModelId: recipe?.ovenModelId ?? 1,
            recipeTypeIds:
              recipe?.recipeTypes?.map((recipeType) => recipeType.id) ?? [],
            bakeryIds: recipe?.bakeries?.map((bakery) => bakery.id) ?? [],
          });
        },
      });
    }
    if (action !== 'locate') {
      breadcrumbs.push({
        title: getStepBreadcrumb(selectedStep, selectedPhaseIndex),
      });
    }
    if (action === 'locate') {
      breadcrumbs.push({
        title: locals.getText('recipe_settings_locate_breadcrumb'),
      });
    }
    setBreadcrumbs(breadcrumbs);
    return () => setBreadcrumbs([]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedStep, selectedPhaseIndex]);

  function handleCreateOrUpdateRecipe() {
    if (action === 'create') {
      createRecipe({
        id: uuid(),
        name: recipeBuild.name,
        procedure: recipeBuild.procedure,
        phases: recipeBuild.phases,
        ovenModelId: recipeBuild.ovenModelId,
        schemaVersion: 'v1',
        recipeTypeIds: recipeBuild.recipeTypeIds,
        bakeryIds: recipeBuild.bakeryIds,
      });
      return;
    }
    updateRecipe({
      id: recipeBuild.id,
      name: recipeBuild.name,
      procedure: recipeBuild.procedure,
      phases: recipeBuild.phases,
      recipeTypeIds: recipeBuild.recipeTypeIds,
      bakeryIds: action === 'update' ? undefined : recipeBuild.bakeryIds,
    });
  }

  function renderStep() {
    switch (selectedStep) {
      case 'general':
        return (
          <GeneralStep
            defaultRecipeName={recipe?.name ?? ''}
            recipeBuild={recipeBuild}
            setRecipeBuild={setRecipeBuild}
            error={steps.general.error}
            setError={(error) =>
              setSteps((steps) => ({
                ...steps,
                general: {...steps.general, error},
              }))
            }
          />
        );
      case 'procedure':
        return (
          <ProceduresStep
            recipeBuild={recipeBuild}
            setRecipeBuild={setRecipeBuild}
          />
        );
      case 'phases':
        return (
          <PhaseStep
            selectedPhaseIndex={selectedPhaseIndex}
            phaseSchema={recipeSchema?.phaseSchema ?? {}}
            recipeBuild={recipeBuild}
            setRecipeBuild={setRecipeBuild}
            setError={(error) =>
              setSteps((steps) => ({
                ...steps,
                phases: {
                  ...steps.phases,
                  errors: steps.phases.errors.map((phaseError, index) =>
                    index === selectedPhaseIndex ? error : phaseError,
                  ),
                },
              }))
            }
          />
        );
      case 'location':
        return (
          <LocationStep
            recipeBuild={recipeBuild}
            setRecipeBuild={setRecipeBuild}
          />
        );
    }
  }

  const loading =
    loadingRecipe ||
    loadingRecipeSchema ||
    loadingCreateRecipe ||
    loadingUpdateRecipe;

  const renderBanner = selectedStep !== 'location';

  return (
    <Fragment>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          width: '20vw',
          marginBlock: '112px',
        }}>
        <RecipeSettingsMenu
          action={action}
          selectedStep={selectedStep}
          setSelectedStep={setSelectedStep}
          steps={steps}
          setSteps={setSteps}
          selectedPhaseIndex={selectedPhaseIndex}
          setSelectedPhaseIndex={setSelectedPhaseIndex}
          recipeBuild={recipeBuild}
          setRecipeBuild={setRecipeBuild}
          onFinish={handleCreateOrUpdateRecipe}
        />
      </Box>
      <Box sx={{width: '65vw'}}>
        {renderBanner && (
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between',
              height: '112px',
            }}>
            <Paper
              elevation={4}
              sx={{
                display: 'flex',
                alignItems: 'center',
                width: '100%',
                marginBlock: 2,
                paddingBlock: 1,
                paddingInline: 2,
                borderRadius: '10px',
                backgroundColor: '#F4F4F4',
              }}>
              <Typography variant="body2">
                <Span sx={{fontWeight: 'bold'}}>
                  {action === 'create'
                    ? `${locals.getText('recipe_settings_new_recipe_label')} | `
                    : `${recipe?.name} | `}
                </Span>
                {ovenModelUtils.getDescription(recipeBuild.ovenModelId)}
              </Typography>
            </Paper>
            <Typography
              variant="body2"
              sx={{
                fontWeight: 'bold',
                color: 'text.primary',
                marginBlock: 2,
                paddingInline: 2,
              }}>
              {getStepTitle(selectedStep, selectedPhaseIndex)}
            </Typography>
          </Box>
        )}
        <Box sx={{height: `calc(${pageHeight} - 112px)`}}>{renderStep()}</Box>
      </Box>
      <LoadingBackdrop loading={loading} />
    </Fragment>
  );
}

export default RecipeSettings;
