import CreateOrUpdateSchedulesRequest from '../models/requests/createOrUpdateSchedules';
import DeleteScheduleParams from '../models/requests/deleteSchedule';
import GetNearestAvailableTimeSlotParams from '../models/requests/getNearestAvailableTimeSlot';
import GetScheduleParams from '../models/requests/getSchedule';
import GetSchedulesParams from '../models/requests/getSchedules';
import OverlapsScheduleParams from '../models/requests/overlapsSchedule';
import CreateOrUpdateSchedulesResponse from '../models/responses/createOrUpdateSchedules';
import DeleteScheduleResponse from '../models/responses/deleteSchedule';
import GetNearestAvailableTimeSlotResponse from '../models/responses/getNearestAvailableTimeSlot';
import GetScheduleResponse from '../models/responses/getSchedule';
import GetSchedulesResponse from '../models/responses/getSchedules';
import OverlapsScheduleResponse from '../models/responses/overlapsSchedule';
import baseService from './base';
import endpoints from './endpoints';

export type ScheduleService = {
  getSchedules: (params: GetSchedulesParams) => Promise<GetSchedulesResponse>;
  getSchedule: (params: GetScheduleParams) => Promise<GetScheduleResponse>;
  getNearestAvailableTimeSlot: (
    params: GetNearestAvailableTimeSlotParams,
  ) => Promise<GetNearestAvailableTimeSlotResponse>;
  overlapsSchedule: (
    params: OverlapsScheduleParams,
  ) => Promise<OverlapsScheduleResponse>;
  createOrUpdateSchedules: (
    request: CreateOrUpdateSchedulesRequest,
  ) => Promise<CreateOrUpdateSchedulesResponse>;
  deleteSchedule: (
    params: DeleteScheduleParams,
  ) => Promise<DeleteScheduleResponse>;
};

const scheduleService: ScheduleService = {
  getSchedules: async function (
    params: GetSchedulesParams,
  ): Promise<GetSchedulesResponse> {
    return baseService
      .get<GetSchedulesParams, GetSchedulesResponse>(
        endpoints.schedules,
        params,
      )
      .then((result) => result.data);
  },
  getSchedule: async function (
    params: GetScheduleParams,
  ): Promise<GetScheduleResponse> {
    const {scheduleId} = params;
    return baseService
      .get<null, GetScheduleResponse>(
        `${endpoints.schedules}/${scheduleId}`,
        null,
      )
      .then((result) => result.data);
  },
  getNearestAvailableTimeSlot: async function (
    params: GetNearestAvailableTimeSlotParams,
  ): Promise<GetNearestAvailableTimeSlotResponse> {
    return baseService
      .get<
        GetNearestAvailableTimeSlotParams,
        GetNearestAvailableTimeSlotResponse
      >(`${endpoints.schedules}/nearest-available-time-slot`, params)
      .then((result) => result.data);
  },
  overlapsSchedule: async function (
    params: OverlapsScheduleParams,
  ): Promise<OverlapsScheduleResponse> {
    return baseService
      .get<OverlapsScheduleParams, OverlapsScheduleResponse>(
        `${endpoints.schedules}/overlaps`,
        params,
      )
      .then((result) => result.data);
  },
  createOrUpdateSchedules: async function (
    request: CreateOrUpdateSchedulesRequest,
  ): Promise<CreateOrUpdateSchedulesResponse> {
    return baseService
      .post<CreateOrUpdateSchedulesRequest, CreateOrUpdateSchedulesResponse>(
        `${endpoints.schedules}/batch`,
        request,
      )
      .then((result) => result.data);
  },
  deleteSchedule: async function (
    params: DeleteScheduleParams,
  ): Promise<DeleteScheduleResponse> {
    return baseService
      .delete<DeleteScheduleParams, null, DeleteScheduleResponse>(
        `${endpoints.schedules}/${params.scheduleId}`,
      )
      .then((result) => result.data);
  },
};

export default scheduleService;
