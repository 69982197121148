import {Box, List, ListItem, Typography} from '@mui/material';
import locals from '../../localization/locals';
import Cleaning from '../../models/entities/cleaning';
import cleaningUtils from '../../utils/cleaning';
import GradientOverflow from '../common/GradientOverflow';
import Switch from '../common/Switch';
import {pageHeight} from '../navigation/Navbar';
import {useState} from 'react';

function getTime(duration: number) {
  const hours = Math.floor(duration / 60);
  const minutes = duration % 60;
  return `${hours}h ${minutes}`;
}

type CleaningListItemProps = {
  cleaning: Cleaning;
  selected: boolean;
  onSelectCleaning: () => void;
};

function CleaningListItem(props: CleaningListItemProps) {
  const {cleaning, selected, onSelectCleaning} = props;

  const [isHovered, setIsHovered] = useState(false);

  const color = isHovered ? 'primary.main' : 'text.primary';

  return (
    <ListItem
      sx={{margin: 0, padding: 0, color, cursor: 'pointer'}}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      onClick={onSelectCleaning}>
      <Box sx={{width: '40vw', padding: 2}}>
        <Typography variant="body2">
          {cleaningUtils.getDescription(
            cleaning.ovenModelId,
            cleaning.id,
            cleaning.description,
          )}
        </Typography>
      </Box>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'flex-end',
          width: '25vw',
          padding: 2,
        }}>
        <Typography variant="body2" sx={{marginRight: 2}}>{`${locals.getText(
          'programming_approximate_duration_label',
        )}. ${getTime(cleaning.duration)} ${locals.getText(
          'programming_minutes_label',
        )}`}</Typography>
        <Switch checked={selected} />
      </Box>
    </ListItem>
  );
}

type CleaningListProps = {
  cleanings: Cleaning[];
  selectedCleaningId: number | null;
  onSelectCleaning: (cleaningId: number) => void;
  cleaningsNotFoundMessage?: string;
};

function CleaningList(props: CleaningListProps) {
  const {
    cleanings,
    selectedCleaningId,
    onSelectCleaning,
    cleaningsNotFoundMessage,
  } = props;

  return (
    <Box
      sx={{
        width: '65vw',
        height: `calc(${pageHeight} - 112px)`,
        paddingBottom: 6,
      }}>
      <GradientOverflow hideScrollbar>
        <List sx={{margin: 0, padding: 0}}>
          {cleanings.map((cleaning) => (
            <CleaningListItem
              key={cleaning.id}
              cleaning={cleaning}
              selected={cleaning.id === selectedCleaningId}
              onSelectCleaning={() => onSelectCleaning(cleaning.id)}
            />
          ))}
          {cleanings.length === 0 && (
            <ListItem sx={{margin: 0, padding: 2}}>
              <Typography variant="body2" sx={{color: 'text.primary'}}>
                {cleaningsNotFoundMessage}
              </Typography>
            </ListItem>
          )}
        </List>
      </GradientOverflow>
    </Box>
  );
}

export default CleaningList;
