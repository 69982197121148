import {useQuery} from 'react-query';
import {useLocation, useNavigate} from 'react-router-dom';
import {ReactComponent as InfoIcon} from '../../assets/icons/info.svg';
import {ReactComponent as NewNotificationsIcon} from '../../assets/icons/new-notification.svg';
import {ReactComponent as NotificationsIcon} from '../../assets/icons/notification.svg';
import {ReactComponent as SettingsIcon} from '../../assets/icons/settings.svg';
import useLocalization from '../../hooks/common/useLocalization';
import locals from '../../localization/locals';
import paths from '../../routes/paths';
import services from '../../services/provider';
import Menu from '../common/Menu';

function ProfileMenu() {
  const navigate = useNavigate();
  const {pathname} = useLocation();
  useLocalization();

  const {data: totalUnseenNotifications} = useQuery({
    queryKey: ['totalUnseenOvenPanelNotifications'],
    queryFn: () =>
      services.ovenPanelNotification.getTotalUnseenOvenPanelNotifications(),
  });

  const hasUnseenNotifications = (totalUnseenNotifications ?? 0) > 0;

  return (
    <Menu
      textColor="transparent"
      items={[
        {
          text: locals.getText('profile_menu_information_label'),
          icon: InfoIcon,
          selected: pathname.startsWith(paths.profileInformation),
          onSelectItem: () => navigate(paths.profileInformation),
        },
        {
          text: locals.getText('profile_menu_notifications_label'),
          icon: hasUnseenNotifications
            ? NewNotificationsIcon
            : NotificationsIcon,
          selected: pathname.startsWith(paths.profileNotifications),
          onSelectItem: () => navigate(paths.profileNotifications),
        },
        {
          text: locals.getText('profile_menu_settings_label'),
          icon: SettingsIcon,
          selected: pathname.startsWith(paths.profileSettings),
          onSelectItem: () => navigate(paths.profileSettings),
        },
      ]}
    />
  );
}

export default ProfileMenu;
