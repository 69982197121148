import {Box, ClickAwayListener} from '@mui/material';
import {Fragment, useState} from 'react';
import {ReactComponent as ClockIcon} from '../../assets/icons/clock.svg';
import {ReactComponent as MenuIcon} from '../../assets/icons/menu.svg';
import {ReactComponent as OvenIcon} from '../../assets/icons/oven.svg';
import colors from '../../themes/colors';
import IconButton from '../common/IconButton';
import {ScheduleOrderBy} from './Schedules';

type SchedulesMenuProps = {
  orderBy: ScheduleOrderBy;
  setOrderBy: (orderBy: ScheduleOrderBy) => void;
};

function SchedulesMenu(props: SchedulesMenuProps) {
  const {orderBy, setOrderBy} = props;

  const [open, setOpen] = useState(false);

  return (
    <ClickAwayListener onClickAway={() => setOpen(false)}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          height: '164px',
        }}>
        <Box sx={{display: 'flex', justifyContent: 'center', width: '15vw'}}>
          <IconButton
            IconComponent={MenuIcon}
            activeColor={colors.grey}
            inactiveColor={colors.lightGrey}
            isActive={open}
            onClick={() => setOpen((previousValue) => !previousValue)}
          />
        </Box>
        {open && (
          <Fragment>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                width: '15vw',
                minHeight: '36px',
              }}>
              <IconButton
                IconComponent={ClockIcon}
                inactiveColor={colors.lightGrey}
                isActive={orderBy === 'time'}
                onClick={() => {
                  setOpen(false);
                  setOrderBy('time');
                }}
              />
            </Box>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                width: '15vw',
                minHeight: '36px',
              }}>
              <IconButton
                IconComponent={OvenIcon}
                inactiveColor={colors.lightGrey}
                isActive={orderBy === 'oven'}
                onClick={() => {
                  setOpen(false);
                  setOrderBy('oven');
                }}
              />
            </Box>
            <Box sx={{minHeight: '36px'}} />
          </Fragment>
        )}
      </Box>
    </ClickAwayListener>
  );
}

export default SchedulesMenu;
