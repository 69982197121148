import {Drawer, List} from '@mui/material';
import {useNavigate} from 'react-router-dom';
import {ReactComponent as BakeriesIcon} from '../../assets/icons/bakery.svg';
import {ReactComponent as ProfileIcon} from '../../assets/icons/profile.svg';
import {ReactComponent as ProgrammingIcon} from '../../assets/icons/programming.svg';
import {ReactComponent as RecipesIcon} from '../../assets/icons/recipes.svg';
import {ReactComponent as StatisticsIcon} from '../../assets/icons/statistics.svg';
import locals from '../../localization/locals';
import paths from '../../routes/paths';
import colors from '../../themes/colors';
import DrawerMenuItem from './DrawerMenuItem';

type DrawerMenuProps = {
  open: boolean;
  onClose: () => void;
};

function DrawerMenu(props: DrawerMenuProps) {
  const {open, onClose} = props;

  const navigate = useNavigate();

  return (
    <Drawer
      anchor="left"
      BackdropProps={{invisible: true}}
      PaperProps={{
        sx: {
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          width: {xs: '80vw', sm: '60vw', md: '40vw'},
          padding: '15vh 4vw 15vh 4vw',
          boxShadow: 'none',
          background: `linear-gradient(to right, ${colors.white} 50%, transparent)`,
        },
        onClick: onClose,
      }}
      open={open}
      onClose={onClose}
      keepMounted>
      <List
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          height: '100%',
        }}
        onClick={onClose}>
        <DrawerMenuItem
          icon={RecipesIcon}
          text={locals.getText('menu_recipes_label')}
          onClick={() => navigate(paths.recipes)}
        />
        <DrawerMenuItem
          icon={ProgrammingIcon}
          text={locals.getText('menu_programming_label')}
          onClick={() => navigate(paths.programming)}
        />
        <DrawerMenuItem
          icon={BakeriesIcon}
          text={locals.getText('menu_bakeries_label')}
          onClick={() => navigate(paths.bakeries)}
        />
        <DrawerMenuItem
          icon={StatisticsIcon}
          text={locals.getText('menu_statistics_label')}
          onClick={() => navigate(paths.statistics)}
        />
        <DrawerMenuItem
          icon={ProfileIcon}
          text={locals.getText('menu_profile_label')}
          onClick={() => navigate(paths.profile)}
        />
      </List>
    </Drawer>
  );
}

export default DrawerMenu;
