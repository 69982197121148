import sha256 from 'crypto-js/sha256';
import jwtDecode from 'jwt-decode';

const cryptoUtils = {
  sha256: function (value: string) {
    return sha256(value).toString();
  },
  decodeJwt: function <TData>(token: string, fallbackValue: TData) {
    try {
      return jwtDecode<TData>(token);
    } catch (error) {
      return fallbackValue;
    }
  },
};

export default cryptoUtils;
