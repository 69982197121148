import ChangeOvenPanelStatusParams from '../../models/requests/changeOvenPanelStatus';
import GetOvenPanelStatisticsParams from '../../models/requests/getOvenPanelStatistics';
import ChangeOvenPanelStatusResponse from '../../models/responses/changeOvenPanelStatus';
import GetOvenPanelStatisticsResponse from '../../models/responses/getOvenPanelStatistics';
import {OvenPanelsService} from '../ovenPanel';
import data from './data';

const mockOvenPanelsService: OvenPanelsService = {
  changeOvenPanelStatus: async function (
    params: ChangeOvenPanelStatusParams,
  ): Promise<ChangeOvenPanelStatusResponse> {
    return new Promise((resolve) => setTimeout(() => resolve(true), 500));
  },
  getOvenPanelStatistics: async function (
    params: GetOvenPanelStatisticsParams,
  ): Promise<GetOvenPanelStatisticsResponse> {
    return new Promise((resolve) =>
      setTimeout(() => {
        const {numberOfDays} = params;
        const statistics = data.ovenPanelStatistics.slice(0, numberOfDays);
        resolve(statistics);
      }, 500),
    );
  },
};

export default mockOvenPanelsService;
