import {Box, TextField, Typography} from '@mui/material';
import {useState} from 'react';
import {useMutation} from 'react-query';
import {useNavigate} from 'react-router-dom';
import useLocalization from '../../hooks/common/useLocalization';
import useSplashScreen from '../../hooks/common/useSplashScreen';
import locals from '../../localization/locals';
import paths from '../../routes/paths';
import services from '../../services/provider';
import errorUtils, {ApplicationErrorCode} from '../../utils/errors';
import stringUtils from '../../utils/strings';
import {Button} from '../common/Button';
import LoadingBackdrop from '../common/LoadingBackdrop';

function PasswordRecovery() {
  const navigate = useNavigate();
  const {splash} = useSplashScreen();
  useLocalization();

  const [email, setEmail] = useState('');

  const [invalidEmail, setInvalidEmail] = useState(false);

  const {mutate: recoverPassword, isLoading} = useMutation({
    mutationFn: services.auth.recoverPassword,
    onSuccess: () =>
      splash({
        title: locals.getText('splash_screen_recovery_code_title'),
        caption: locals.getText('splash_screen_recovery_code_caption'),
        nextAction: () => navigate(`${paths.passwordReset}?email=${email}`),
      }),
    onError: (error) =>
      errorUtils.handleMatch(
        error,
        [ApplicationErrorCode.InvalidEmailOrPassword],
        () => setInvalidEmail(true),
      ),
  });

  function handleRecoverPassword() {
    recoverPassword({email});
  }

  return (
    <Box sx={{display: 'flex', flexDirection: 'column', width: '30vw'}}>
      <Typography
        variant="h4"
        color="secondary"
        sx={{
          width: '20vw',
          marginBottom: invalidEmail ? '2vh' : '8vh',
          fontWeight: '500',
        }}>
        {locals.getText('password_recovery_title')}
      </Typography>
      {invalidEmail && (
        <Typography
          variant="caption"
          color="primary"
          sx={{marginBottom: '6vh', fontWeight: 500}}>
          {locals.getText('password_recovery_invalid_email_label')}
        </Typography>
      )}
      <TextField
        type="email"
        variant="standard"
        sx={{marginBottom: '25vh'}}
        label={locals.getText('password_recovery_email_input_label')}
        value={email}
        onChange={(event) => setEmail(event.target.value)}
        onKeyUp={(event) =>
          event.key === 'Enter' ? handleRecoverPassword() : null
        }
      />
      <Button
        onClick={handleRecoverPassword}
        disabled={stringUtils.isNullOrWhiteSpace(email)}>
        {locals.getText('password_recovery_recovery_button_label')}
      </Button>
      <LoadingBackdrop loading={isLoading} />
    </Box>
  );
}

export default PasswordRecovery;
