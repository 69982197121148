import {Box, Typography} from '@mui/material';
import locals from '../../../../../localization/locals';
import Switch from '../../../../common/Switch';

type TurbineSpeedRowProps = {
  turbineSpeed: number;
  onChange: (turbineSpeed: number) => void;
};

function TurbineSpeedRow(props: TurbineSpeedRowProps) {
  const {turbineSpeed, onChange} = props;

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        width: '100%',
        paddingBlock: 1,
        paddingInline: 2,
      }}>
      <Box>
        <Typography
          variant="body2"
          sx={{fontWeight: 'bold', color: 'text.primary'}}>
          {locals.getText('recipe_settings_phases_turbine_speed_label')}
        </Typography>
        <Typography variant="caption" sx={{color: 'text.secondary'}}>
          {locals.getText('recipe_settings_phases_turbine_speed_message')}
        </Typography>
      </Box>
      <Box sx={{display: 'flex', flexDirection: 'column'}}>
        <Box sx={{display: 'flex', marginBottom: 2}}>
          <Typography
            variant="body2"
            sx={{marginRight: 3, color: 'text.primary'}}>
            {locals.getText(
              'recipe_settings_phases_turbine_speed_action_label',
            ) + ' 1'}
          </Typography>
          <Switch
            checked={turbineSpeed === 0}
            onChange={(_, checked) => onChange(checked ? 0 : 1)}
          />
        </Box>
        <Box sx={{display: 'flex'}}>
          <Typography
            variant="body2"
            sx={{marginRight: 3, color: 'text.primary'}}>
            {locals.getText(
              'recipe_settings_phases_turbine_speed_action_label',
            ) + ' 2'}
          </Typography>
          <Switch
            checked={turbineSpeed === 1}
            onChange={(_, checked) => onChange(checked ? 1 : 0)}
          />
        </Box>
      </Box>
    </Box>
  );
}

export default TurbineSpeedRow;
