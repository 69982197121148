import {OvenModelId} from './ovenModel';

export enum ScheduleType {
  Recipe = 1,
  Cleaning = 2,
  Off = 3,
}

type Schedule = {
  id: string;
  type: ScheduleType;
  day: Day;
  startTime: string;
  endTime: string;
  recipeId?: string;
  recipeName?: string;
  cleaningId?: number;
  cleaningDescription?: string;
  ovenPanelId: string;
  ovenPanelIndex?: number;
  ovenChamberId?: string;
  ovenChamberIndex?: number;
  ovenId?: string;
  ovenDescription?: string;
  ovenIndex?: number;
  ovenGroupId?: string;
  ovenGroupDescription?: string;
  ovenModelId: OvenModelId;
};

export default Schedule;
