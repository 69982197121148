import {Box, List, ListItem, SvgIcon, Typography} from '@mui/material';
import {Fragment} from 'react';
import {ReactComponent as ClockIcon} from '../../assets/icons/clock.svg';
import locals from '../../localization/locals';
import {daysArray} from '../../utils/dates';
import stringUtils from '../../utils/strings';
import GradientOverflow from '../common/GradientOverflow';
import Switch from '../common/Switch';
import {pageHeight} from '../navigation/Navbar';
import TimeInput from './TimeInput';

type SelectTimeProps = {
  startTime: string;
  endTime: string;
  setStartTime: (time: string) => void;
  selectedDays: Day[];
  onSelectDay: (day: Day) => void;
  onSelectAllDays: () => void;
  error?: boolean;
  turnOffAfterSchedule?: boolean;
  setTurnOffAfterSchedule?: (turnOffAfterSchedule: boolean) => void;
  turnOffAfterScheduleLabel?: string;
  onStartTimeFocus?: () => void;
  onStartTimeBlur?: () => void;
  showStartTimePlaceholder?: boolean;
};

function SelectTime(props: SelectTimeProps) {
  const {
    startTime,
    endTime,
    setStartTime,
    selectedDays,
    onSelectDay,
    onSelectAllDays,
    error,
    turnOffAfterSchedule,
    setTurnOffAfterSchedule,
    turnOffAfterScheduleLabel,
    onStartTimeFocus,
    onStartTimeBlur,
    showStartTimePlaceholder,
  } = props;

  const color = error ? 'primary.main' : 'text.primary';

  const showTurnOffAfterScheduleOption = setTurnOffAfterSchedule != null;

  return (
    <Box sx={{width: '65vw', padding: 2}}>
      <Box sx={{display: 'flex', alignItems: 'center', marginBottom: 4}}>
        <SvgIcon
          component={ClockIcon}
          inheritViewBox
          sx={{fontSize: '25px', color, marginRight: 3}}
        />
        <TimeInput
          value={startTime.replace(':', '')}
          onChange={(value) =>
            setStartTime(value.replace(/(\d{2})(\d{2})/, '$1:$2'))
          }
          onFocus={onStartTimeFocus}
          onBlur={onStartTimeBlur}
          color={color}
          showPlaceholder={showStartTimePlaceholder}
        />
        {!stringUtils.isNullOrWhiteSpace(endTime) && (
          <Fragment>
            <Typography variant="body2" sx={{marginInline: '5px', color}}>
              -
            </Typography>
            <Box sx={{opacity: error ? 0.5 : 1}}>
              <TimeInput
                value={endTime.replace(':', '')}
                onChange={() => {}}
                color={color}
                disabled
              />
            </Box>
          </Fragment>
        )}
      </Box>
      {showTurnOffAfterScheduleOption && (
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            width: '30vw',
            marginBottom: 4,
          }}>
          <Typography
            variant="body2"
            sx={{flex: 1, fontWeight: 'bold', color: 'text.primary'}}>
            {turnOffAfterScheduleLabel}
          </Typography>
          <Switch
            value={turnOffAfterSchedule}
            onChange={(_, checked) => setTurnOffAfterSchedule(checked)}
          />
        </Box>
      )}
      <Box
        sx={{
          width: '30vw',
          height: showTurnOffAfterScheduleOption
            ? `calc(${pageHeight} - 248px)`
            : `calc(${pageHeight} - 196px)`,
          paddingBottom: 6,
        }}>
        <GradientOverflow hideScrollbar>
          <List sx={{margin: 0, padding: 0, color: 'text.primary'}}>
            {daysArray.map((day) => (
              <ListItem
                key={day.value}
                sx={{margin: 0, padding: 0, marginBottom: 3}}>
                <Typography variant="body2" sx={{flex: 1}}>
                  {day.label()}
                </Typography>
                <Switch
                  size="small"
                  checked={selectedDays.includes(day.value)}
                  onChange={() => onSelectDay(day.value)}
                />
              </ListItem>
            ))}
            <ListItem sx={{margin: 0, padding: 0, marginBottom: 3}}>
              <Typography variant="body2" sx={{flex: 1}}>
                {locals.getText('programming_all_label')}
              </Typography>
              <Switch
                size="small"
                checked={selectedDays.length === 7}
                onChange={onSelectAllDays}
              />
            </ListItem>
          </List>
        </GradientOverflow>
      </Box>
    </Box>
  );
}

export default SelectTime;
