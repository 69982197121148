import locals from '../localization/locals';
import Schedule from '../models/entities/schedule';
import dateUtils from './dates';
import errorUtils, {ApplicationErrorCode} from './errors';

export type TimeSlot = {
  startTime: string;
  endTime: string;
};

// time format: 'HH:mm'
const scheduleUtils = {
  isValidTime: function (time?: string) {
    const regex = /^([01]\d|2[0-3]):([0-5]\d)$|^24:00$/;
    return time != null && regex.test(time);
  },
  parseTime: function (time?: string, fallbackValue = '00:00') {
    return time != null && this.isValidTime(time) ? time : fallbackValue;
  },
  increaseTime: function (time: string, minutes: number) {
    const _time = this.parseTime(time);
    let date = new Date(`2022-01-01T${_time}:00.000Z`);
    date = dateUtils.addMinutes(date, minutes);
    return dateUtils.format(date, 'HH:mm');
  },
  timeDifference: function (startTime: string, endTime: string) {
    const _startTime = this.parseTime(startTime);
    const _endTime = this.parseTime(endTime);
    const startDate = new Date(`2022-01-01T${_startTime}:00.000Z`);
    const endDate = new Date(`2022-01-01T${_endTime}:00.000Z`);
    return dateUtils.differenceInMinutes(endDate, startDate);
  },
  overlaps: function (timeSlotA: TimeSlot, timeSlotB: TimeSlot) {
    return (
      (timeSlotB.startTime <= timeSlotA.startTime &&
        timeSlotB.endTime >= timeSlotA.startTime) ||
      (timeSlotA.startTime <= timeSlotB.startTime &&
        timeSlotA.endTime >= timeSlotB.startTime)
    );
  },
  mergeOverlappingSchedules: function (schedules: Schedule[]) {
    const mergedSchedules: Schedule[] = [];
    if (schedules.length === 0) {
      return mergedSchedules;
    }
    mergedSchedules.push(schedules[0]);
    let currentSchedule = schedules[0];
    for (let index = 1; index < schedules.length; index++) {
      const nextSchedule = schedules[index];
      if (index === 0) {
        mergedSchedules.push(schedules[index]);
        continue;
      }
      if (this.overlaps(currentSchedule, nextSchedule)) {
        if (nextSchedule.endTime > currentSchedule.endTime) {
          currentSchedule.endTime = nextSchedule.endTime;
        }
        continue;
      }
      mergedSchedules.push(nextSchedule);
      currentSchedule = nextSchedule;
    }
    return mergedSchedules;
  },
  getAvailableTimeSlots: function (schedules: Schedule[], minimumDuration = 1) {
    const availableTimeSlots: TimeSlot[] = [];
    const mergedSchedules = this.mergeOverlappingSchedules(schedules);
    for (let index = 0; index < mergedSchedules.length; index++) {
      const currentSchedule = mergedSchedules[index];
      const nextSchedule = mergedSchedules[index + 1];
      if (
        index === 0 &&
        currentSchedule.startTime > '00:00' &&
        this.timeDifference(
          '00:00',
          this.increaseTime(currentSchedule.startTime, -1),
        ) >= minimumDuration
      ) {
        availableTimeSlots.push({
          startTime: '00:00',
          endTime: this.increaseTime(currentSchedule.startTime, -1),
        });
      }
      if (
        index === mergedSchedules.length - 1 &&
        this.increaseTime(currentSchedule.endTime, 1) < '24:00' &&
        this.timeDifference(
          this.increaseTime(currentSchedule.endTime, 1),
          '24:00',
        ) >= minimumDuration
      ) {
        availableTimeSlots.push({
          startTime: this.increaseTime(currentSchedule.endTime, 1),
          endTime: '24:00',
        });
      }
      if (
        nextSchedule != null &&
        this.timeDifference(
          this.increaseTime(currentSchedule.endTime, 1),
          this.increaseTime(nextSchedule.startTime, -1),
        ) >= minimumDuration
      ) {
        availableTimeSlots.push({
          startTime: this.increaseTime(currentSchedule.endTime, 1),
          endTime: this.increaseTime(nextSchedule.startTime, -1),
        });
      }
    }
    return availableTimeSlots;
  },
  getNearestAvailableTimeSlot: function (
    schedules: Schedule[],
    durationInMinutes: number,
    defaultStartTime = '00:00',
  ) {
    let startTime = defaultStartTime;
    let endTime = this.increaseTime(startTime, durationInMinutes);
    schedules.forEach((schedule) => {
      const overlaps = this.overlaps(schedule, {startTime, endTime});
      if (overlaps) {
        startTime = this.increaseTime(schedule.endTime, 1);
        endTime = scheduleUtils.increaseTime(startTime, durationInMinutes);
      }
    });
    return {startTime, endTime};
  },
  handleError: function (error: any) {
    const applicationError = errorUtils.applicationError(error);
    if (applicationError.code === ApplicationErrorCode.ScheduleOverlapping) {
      errorUtils.setError(
        locals.getText('programming_schedule_overlapping_error_message'),
      );
      return;
    }
    if (
      applicationError.code ===
      ApplicationErrorCode.OvenPanelScheduleSlotsNotAvailable
    ) {
      errorUtils.setError(
        locals.getText('programming_oven_panel_schedule_slots_not_available'),
      );
      return;
    }
    errorUtils.handleError(error);
  },
};

export default scheduleUtils;
