import Bakery from '../../models/entities/bakery';
import OvenModel from '../../models/entities/ovenModel';
import CreateBakeryRequest from '../../models/requests/createBakery';
import GetBakeriesLocationsParams from '../../models/requests/getBakeriesLocations';
import GetBakeryParams from '../../models/requests/getBakery';
import GetBakeryOvensParams from '../../models/requests/getBakeryOvens';
import UpdateBakeryRequest from '../../models/requests/updateBakery';
import CreateBakeryResponse from '../../models/responses/createBakery';
import GetBakeriesLocationsResponse from '../../models/responses/getBakeriesLocations';
import GetBakeryResponse from '../../models/responses/getBakery';
import GetBakeryOvensResponse from '../../models/responses/getBakeryOvens';
import UpdateBakeryResponse from '../../models/responses/updateBakery';
import {ApplicationError} from '../../utils/errors';
import stringUtils from '../../utils/strings';
import {BakeryService} from '../bakery';
import data from './data';

const mockBakeriesService: BakeryService = {
  getBakeriesLocations: async function (
    params: GetBakeriesLocationsParams,
  ): Promise<GetBakeriesLocationsResponse> {
    return new Promise((resolve) =>
      setTimeout(() => {
        const countries = data.countries.map((country) => {
          const districts = new Set(
            data.districts
              .filter((district) => district.countryId === country.id)
              .map((district) => district.id),
          );
          return {
            id: country.id,
            iso: country.iso,
            name: country.name,
            cities: data.cities
              .filter((city) => districts.has(city.districtId))
              .map((city) => ({
                id: city.id,
                name: city.name,
                districtId: city.districtId,
                bakeries: data.bakeries
                  .filter((bakery) => bakery.cityId === city.id)
                  .map((bakery) => data.getBakery(bakery)),
              })),
          };
        });
        resolve(countries);
      }, 500),
    );
  },
  getBakery: async function (
    params: GetBakeryParams,
  ): Promise<GetBakeryResponse> {
    return new Promise((resolve, reject) =>
      setTimeout(() => {
        const {bakeryId} = params;
        const bakery = data.bakeries.find((bakery) => bakery.id === bakeryId);
        if (bakery == null) {
          reject(ApplicationError.notFound('Bakery'));
          return;
        }
        resolve(data.getBakery(bakery));
      }, 500),
    );
  },
  getBakeryOvens: async function (
    params: GetBakeryOvensParams,
  ): Promise<GetBakeryOvensResponse> {
    return new Promise((resolve, reject) =>
      setTimeout(() => {
        const {bakeryId, ovenModelId} = params;
        const bakery = data.bakeries.find((bakery) => bakery.id === bakeryId);
        if (bakery == null) {
          reject(ApplicationError.notFound('Bakery'));
          return;
        }
        const ovenModels: OvenModel[] = data.ovenModels
          .filter(
            (ovenModel) => ovenModelId == null || ovenModel.id === ovenModelId,
          )
          .map((ovenModel) => ({
            id: ovenModel.id,
            description: ovenModel.description,
            ovens: data.ovens
              .filter(
                (oven) =>
                  oven.ovenModelId === ovenModel.id && oven.ovenGroupId == null,
              )
              .map((oven) => data.getOven(oven)),
            ovenGroups: data.ovenGroups
              .filter((ovenGroup) => ovenGroup.ovenModelId === ovenModel.id)
              .map((ovenGroup) => data.getOvenGroup(ovenGroup)),
          }))
          .filter(
            (ovenModel) =>
              ovenModel.ovens.length > 0 || ovenModel.ovenGroups.length > 0,
          );
        resolve(ovenModels);
      }, 500),
    );
  },
  createBakery: async function (
    request: CreateBakeryRequest,
  ): Promise<CreateBakeryResponse> {
    return new Promise((resolve, reject) =>
      setTimeout(() => {
        const {id, name, companyId} = request;
        if (stringUtils.anyIsNullOrWhiteSpace(id, name, companyId)) {
          reject(ApplicationError.badRequest());
          return;
        }
        const bakery: Bakery = {...request};
        data.bakeries.push(bakery);
        resolve(bakery);
      }, 500),
    );
  },
  updateBakery: async function (
    request: UpdateBakeryRequest,
  ): Promise<UpdateBakeryResponse> {
    return new Promise((resolve, reject) =>
      setTimeout(() => {
        const {id: bakeryId} = request;
        const exists = data.bakeries.some((bakery) => bakery.id === bakeryId);
        if (!exists) {
          reject(ApplicationError.notFound('Bakery'));
          return;
        }
        data.bakeries = data.bakeries.map((bakery) => ({
          ...bakery,
          name:
            bakery.id === bakeryId ? request.name ?? bakery.name : bakery.name,
        }));
        const bakery = data.bakeries.find((bakery) => bakery.id === bakeryId);
        resolve(bakery!);
      }, 500),
    );
  },
};

export default mockBakeriesService;
