import CreateOrUpdateSchedulesRequest from '../../models/requests/createOrUpdateSchedules';
import DeleteScheduleParams from '../../models/requests/deleteSchedule';
import GetNearestAvailableTimeSlotParams from '../../models/requests/getNearestAvailableTimeSlot';
import GetScheduleParams from '../../models/requests/getSchedule';
import GetSchedulesParams from '../../models/requests/getSchedules';
import OverlapsScheduleParams from '../../models/requests/overlapsSchedule';
import CreateOrUpdateSchedulesResponse from '../../models/responses/createOrUpdateSchedules';
import DeleteScheduleResponse from '../../models/responses/deleteSchedule';
import GetNearestAvailableTimeSlotResponse from '../../models/responses/getNearestAvailableTimeSlot';
import GetScheduleResponse from '../../models/responses/getSchedule';
import GetSchedulesResponse from '../../models/responses/getSchedules';
import OverlapsScheduleResponse from '../../models/responses/overlapsSchedule';
import arrayUtils from '../../utils/arrays';
import {ApplicationError} from '../../utils/errors';
import scheduleUtils from '../../utils/schedules';
import {ScheduleService} from '../schedule';
import data from './data';

const mockScheduleService: ScheduleService = {
  getSchedules: function (
    params: GetSchedulesParams,
  ): Promise<GetSchedulesResponse> {
    return new Promise((resolve) =>
      setTimeout(() => {
        const {ovenModelId, day} = params;
        const schedules = Array.from(data.schedules)
          .filter(
            (schedule) =>
              (ovenModelId == null || schedule.ovenModelId === ovenModelId) &&
              (day == null || schedule.day === day),
          )
          .sort((scheduleA, scheduleB) => {
            if (scheduleA.startTime > scheduleB.startTime) return 1;
            if (scheduleA.startTime < scheduleB.startTime) return -1;
            return 0;
          })
          .map((schedule) => data.getSchedule(schedule));
        resolve(schedules);
      }, 500),
    );
  },
  getSchedule: async function (
    params: GetScheduleParams,
  ): Promise<GetScheduleResponse> {
    return new Promise((resolve, reject) =>
      setTimeout(() => {
        const {scheduleId} = params;
        const schedule = data.schedules.find(
          (schedule) => schedule.id === scheduleId,
        );
        if (schedule == null) {
          reject(ApplicationError.notFound('Schedule'));
          return;
        }
        resolve(data.getSchedule(schedule));
      }, 500),
    );
  },
  getNearestAvailableTimeSlot: function (
    params: GetNearestAvailableTimeSlotParams,
  ): Promise<GetNearestAvailableTimeSlotResponse> {
    return new Promise((resolve) =>
      setTimeout(() => {
        const {ovenPanelIds, day, duration} = params;
        const schedules = Array.from(data.schedules)
          .filter(
            (schedule) =>
              ovenPanelIds.includes(schedule.ovenPanelId) &&
              schedule.day === day,
          )
          .sort((scheduleA, scheduleB) => {
            if (scheduleA.startTime > scheduleB.startTime) return 1;
            if (scheduleA.startTime < scheduleB.startTime) return -1;
            return 0;
          });
        const timeSlot = scheduleUtils.getNearestAvailableTimeSlot(
          schedules,
          duration,
        );
        resolve(timeSlot);
      }, 500),
    );
  },
  overlapsSchedule: function (
    params: OverlapsScheduleParams,
  ): Promise<OverlapsScheduleResponse> {
    return new Promise((resolve) =>
      setTimeout(() => {
        const {
          ovenPanelIds,
          days,
          startTime,
          endTime,
          ignoreScheduleIds = [],
        } = params;
        const schedules = Array.from(data.schedules)
          .filter(
            (schedule) =>
              !ignoreScheduleIds.includes(schedule.id) &&
              ovenPanelIds.includes(schedule.ovenPanelId) &&
              days.includes(schedule.day),
          )
          .sort((scheduleA, scheduleB) => {
            if (scheduleA.startTime > scheduleB.startTime) return 1;
            if (scheduleA.startTime < scheduleB.startTime) return -1;
            return 0;
          });
        const overlaps = schedules.some((schedule) =>
          scheduleUtils.overlaps({startTime, endTime}, schedule),
        );
        resolve({
          overlaps,
          availableTimeSlots: overlaps
            ? scheduleUtils.getAvailableTimeSlots(schedules)
            : undefined,
        });
      }, 500),
    );
  },
  createOrUpdateSchedules: function (
    request: CreateOrUpdateSchedulesRequest,
  ): Promise<CreateOrUpdateSchedulesResponse> {
    return new Promise((resolve) =>
      setTimeout(() => {
        data.schedules = arrayUtils.join(
          (schedule) => schedule.id,
          data.schedules,
          request.schedules,
        );
        const schedules = data.schedules
          .filter((schedule) =>
            request.schedules.some(
              (updatedSchedule) => updatedSchedule.id === schedule.id,
            ),
          )
          .map((schedule) => data.getSchedule(schedule));
        resolve(schedules);
      }, 500),
    );
  },
  deleteSchedule: function (
    params: DeleteScheduleParams,
  ): Promise<DeleteScheduleResponse> {
    return new Promise((resolve, reject) =>
      setTimeout(() => {
        const {scheduleId} = params;
        const exists = data.schedules.some(
          (schedule) => schedule.id === scheduleId,
        );
        if (!exists) {
          reject(ApplicationError.notFound('Schedule'));
          return;
        }
        data.schedules = data.schedules.filter(
          (schedule) => schedule.id !== scheduleId,
        );
        resolve(scheduleId);
      }, 500),
    );
  },
};

export default mockScheduleService;
