import {Box, TextField, Typography} from '@mui/material';
import {useState} from 'react';
import {useMutation} from 'react-query';
import {useNavigate} from 'react-router-dom';
import useLocalization from '../../hooks/common/useLocalization';
import useSplashScreen from '../../hooks/common/useSplashScreen';
import locals from '../../localization/locals';
import paths from '../../routes/paths';
import services from '../../services/provider';
import {setAuthData} from '../../state/auth';
import cryptoUtils from '../../utils/crypto';
import errorUtils, {ApplicationErrorCode} from '../../utils/errors';
import stringUtils from '../../utils/strings';
import {Button} from '../common/Button';
import LoadingBackdrop from '../common/LoadingBackdrop';

function Signup() {
  const navigate = useNavigate();
  const {splash} = useSplashScreen();
  useLocalization();

  const [name, setName] = useState('');
  const [code, setCode] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const [invalidEmail, setInvalidEmail] = useState(false);

  const {mutate: signup, isLoading} = useMutation({
    mutationFn: services.auth.signup,
    onSuccess: (result) => {
      setAuthData(result.token, result.user);
      splash({
        title: locals.getText('splash_screen_create_bakery_title'),
        caption: locals.getText('splash_screen_create_bakery_caption'),
        nextAction: () => navigate(`${paths.bakeriesCreate}?fromSignup=true`),
      });
    },
    onError: (error) =>
      errorUtils.handleMatch(
        error,
        [
          ApplicationErrorCode.InvalidEmailOrPassword,
          ApplicationErrorCode.InvalidInviteCode,
        ],
        () => setInvalidEmail(true),
      ),
  });

  function handleSignup() {
    signup({
      name,
      code,
      email,
      password: cryptoUtils.sha256(password),
      language: 'en',
    });
  }

  return (
    <Box sx={{display: 'flex', flexDirection: 'column', width: '30vw'}}>
      <Typography
        variant="h4"
        color="secondary"
        sx={{marginBottom: invalidEmail ? '2vh' : '4vh', fontWeight: 500}}>
        {locals.getText('signup_title')}
      </Typography>
      {invalidEmail && (
        <Typography
          variant="caption"
          color="primary"
          sx={{marginBottom: '2vh', fontWeight: 500}}>
          {locals.getText('signup_invalid_email_or_code_label')}
        </Typography>
      )}
      <TextField
        variant="standard"
        sx={{marginBottom: '4vh'}}
        label={locals.getText('signup_name_input_label')}
        value={name}
        onChange={(event) => setName(event.target.value)}
      />
      <TextField
        variant="standard"
        sx={{marginBottom: '4vh'}}
        label={locals.getText('signup_code_input_label')}
        value={code}
        onChange={(event) => setCode(event.target.value)}
      />
      <TextField
        type="email"
        variant="standard"
        sx={{marginBottom: '4vh'}}
        label={locals.getText('signup_email_input_label')}
        value={email}
        onChange={(event) => setEmail(event.target.value)}
      />
      <TextField
        type="password"
        variant="standard"
        sx={{marginBottom: '10vh'}}
        label={locals.getText('signup_password_input_label')}
        value={password}
        onChange={(event) => setPassword(event.target.value)}
        onKeyUp={(event) => (event.key === 'Enter' ? handleSignup() : null)}
      />
      <Button
        onClick={handleSignup}
        disabled={stringUtils.anyIsNullOrWhiteSpace(
          name,
          code,
          email,
          password,
        )}>
        {locals.getText('signup_next_button_label')}
      </Button>
      <LoadingBackdrop loading={isLoading} />
    </Box>
  );
}

export default Signup;
