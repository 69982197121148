import {Box, TextField, Typography} from '@mui/material';
import {useState} from 'react';
import {useMutation, useQuery} from 'react-query';
import {useNavigate, useSearchParams} from 'react-router-dom';
import {v4 as uuid} from 'uuid';
import useSplashScreen from '../../hooks/common/useSplashScreen';
import locals from '../../localization/locals';
import paths from '../../routes/paths';
import services from '../../services/provider';
import useAuthStore from '../../state/auth';
import numberUtils from '../../utils/numbers';
import stringUtils from '../../utils/strings';
import {Button} from '../common/Button';
import LoadingBackdrop from '../common/LoadingBackdrop';
import LoadingBox from '../common/LoadingBox';
import {SearchableSimpleList} from '../common/SimpleList';

function Name() {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const [name, setName] = useState('');

  function handleNext() {
    searchParams.set('name', name);
    navigate(`${paths.bakeriesCreate}?${searchParams}`);
  }

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        width: '30vw',
      }}>
      <Typography
        variant="h4"
        color="secondary"
        sx={{fontWeight: 500, marginBottom: '15vh'}}>
        {locals.getText('create_bakery_title')}
      </Typography>
      <TextField
        variant="standard"
        sx={{marginBottom: '20vh'}}
        label={locals.getText('create_bakery_name_input_label')}
        value={name}
        onChange={(event) => setName(event.target.value)}
        onKeyUp={(event) => (event.key === 'Enter' ? handleNext() : null)}
      />
      <Button
        sx={{marginTop: '10vh'}}
        onClick={handleNext}
        disabled={stringUtils.isNullOrWhiteSpace(name)}>
        {locals.getText('create_bakery_next_button_label')}
      </Button>
    </Box>
  );
}

function Countries() {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const [selectedCountryId, setSelectedCountryId] = useState<string>();

  const {data: countries = [], isLoading} = useQuery({
    queryKey: ['countries'],
    queryFn: () => services.location.getCountries(),
  });

  return (
    <Box sx={{display: 'flex', flexDirection: 'column', width: '30vw'}}>
      <Typography
        variant="h4"
        color="secondary"
        sx={{marginBottom: '5vh', fontWeight: 500}}>
        {locals.getText('create_bakery_country_title')}
      </Typography>
      <Box sx={{height: '30vh', overflow: 'auto'}}>
        {isLoading ? (
          <LoadingBox />
        ) : (
          <SearchableSimpleList
            options={countries.map((country) => ({
              id: country.id.toString(),
              primary: country.name,
            }))}
            selectedOption={selectedCountryId}
            onChangeSelectedOption={setSelectedCountryId}
          />
        )}
      </Box>
      <Button
        sx={{marginTop: '10vh'}}
        onClick={() => {
          searchParams.set('countryId', selectedCountryId!);
          navigate(`${paths.bakeriesCreate}?${searchParams}`);
        }}
        disabled={!selectedCountryId}>
        {locals.getText('create_bakery_next_button_label')}
      </Button>
    </Box>
  );
}

function Districts() {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const countryId = numberUtils.parseInt(searchParams.get('countryId'), 1);

  const [selectedDistrictId, setSelectedDistrictId] = useState<string>();

  const {data: districts = [], isLoading} = useQuery({
    queryKey: ['districts', {countryId}],
    queryFn: () => services.location.getDistricts({countryId}),
  });

  return (
    <Box sx={{display: 'flex', flexDirection: 'column', width: '30vw'}}>
      <Typography
        variant="h4"
        color="secondary"
        sx={{marginBottom: '5vh', fontWeight: 500}}>
        {locals.getText('create_bakery_district_title')}
      </Typography>
      <Box sx={{height: '30vh', overflow: 'auto'}}>
        {isLoading ? (
          <LoadingBox />
        ) : (
          <SearchableSimpleList
            options={districts.map((district) => ({
              id: district.id.toString(),
              primary: district.name,
            }))}
            selectedOption={selectedDistrictId}
            onChangeSelectedOption={setSelectedDistrictId}
          />
        )}
      </Box>
      <Button
        sx={{marginTop: '10vh'}}
        onClick={() => {
          searchParams.set('districtId', selectedDistrictId!);
          navigate(`${paths.bakeriesCreate}?${searchParams}`);
        }}
        disabled={!selectedDistrictId}>
        {locals.getText('create_bakery_next_button_label')}
      </Button>
    </Box>
  );
}

function Cities() {
  const navigate = useNavigate();
  const {splash} = useSplashScreen();

  const user = useAuthStore((state) => state.user);

  const [searchParams] = useSearchParams();
  const name = searchParams.get('name');
  const districtId = numberUtils.parseInt(searchParams.get('districtId'), 1);
  const fromSignup = searchParams.get('fromSignup') === 'true';

  const [selectedCityId, setSelectedCityId] = useState<string>();

  const {data: cities = [], isLoading: loadingCities} = useQuery({
    queryKey: ['cities', {districtId}],
    queryFn: () => services.location.getCities({districtId}),
  });

  const {mutate: createBakery, isLoading: loadingCreateBakery} = useMutation({
    mutationFn: services.bakery.createBakery,
    onSuccess: () => {
      if (fromSignup) {
        splash({
          title: locals.getText('splash_screen_account_created_title'),
          caption: locals.getText('splash_screen_account_created_caption'),
          nextAction: () => navigate(paths.dashboard),
        });
        return;
      }
      navigate(paths.bakeries);
    },
  });

  function handleCreateBakery() {
    createBakery({
      id: uuid(),
      name: name ?? '',
      cityId: numberUtils.parseInt(selectedCityId, 1),
      companyId: user?.companyId ?? '',
    });
  }

  return (
    <Box sx={{display: 'flex', flexDirection: 'column', width: '30vw'}}>
      <Typography
        variant="h4"
        color="secondary"
        sx={{marginBottom: '5vh', fontWeight: 500}}>
        {locals.getText('create_bakery_city_title')}
      </Typography>
      <Box sx={{height: '30vh', overflow: 'auto'}}>
        {loadingCities ? (
          <LoadingBox />
        ) : (
          <SearchableSimpleList
            options={cities.map((city) => ({
              id: city.id.toString(),
              primary: city.name,
            }))}
            selectedOption={selectedCityId}
            onChangeSelectedOption={setSelectedCityId}
          />
        )}
      </Box>
      <Button
        sx={{marginTop: '10vh'}}
        onClick={handleCreateBakery}
        disabled={!selectedCityId}>
        {locals.getText('create_bakery_next_button_label')}
      </Button>
      <LoadingBackdrop loading={loadingCreateBakery} />
    </Box>
  );
}

function CreateBakery() {
  const [searchParams] = useSearchParams();

  const renderCountries =
    searchParams.has('name') && !searchParams.has('countryId');
  const renderDistricts =
    searchParams.has('countryId') && !searchParams.has('districtId');
  const renderCities =
    searchParams.has('districtId') && !searchParams.has('cityId');

  if (renderCountries) return <Countries />;

  if (renderDistricts) return <Districts />;

  if (renderCities) return <Cities />;

  return <Name />;
}

export default CreateBakery;
