import {Box, Typography} from '@mui/material';
import {Fragment} from 'react';
import {useMutation} from 'react-query';
import {useNavigate} from 'react-router-dom';
import locals from '../../localization/locals';
import OvenModel, {OvenModelId} from '../../models/entities/ovenModel';
import paths from '../../routes/paths';
import services from '../../services/provider';
import routerUtils from '../../utils/router';
import GradientOverflow from '../common/GradientOverflow';
import {pageHeight} from '../navigation/Navbar';
import OvenList from './OvenList';
import OvenModelList from './OvenModelList';
import {OvenPanelMenuAction} from './OvenPanelMenu';

function OvensNotFound() {
  return (
    <Box sx={{width: '65vw', padding: 2}}>
      <Typography variant="body2" color="secondary">
        {locals.getText('bakery_ovens_not_found_label')}
      </Typography>
    </Box>
  );
}

type BakeryOvensProps = {
  ovenModels: OvenModel[];
  selectedCountryId: number | null;
  selectedCityId: number | null;
  selectedBakeryId: string | null;
  selectedOvenModel: OvenModel | null;
  selectedOvenGroupId: string | null;
  selectedOvenId: string | null;
  onSelectOvenModel: (ovenModelId: OvenModelId) => void;
  onSelectOvenGroup: (ovenGroupId: string) => void;
  onSelectOven: (ovenId: string) => void;
};

function BakeryOvens(props: BakeryOvensProps) {
  const {
    ovenModels,
    selectedCountryId,
    selectedCityId,
    selectedBakeryId,
    selectedOvenModel,
    selectedOvenGroupId,
    selectedOvenId,
    onSelectOvenModel,
    onSelectOvenGroup,
    onSelectOven,
  } = props;

  const navigate = useNavigate();

  const {mutate: changeOvenPanelStatus} = useMutation({
    mutationFn: services.ovenPanel.changeOvenPanelStatus,
  });

  function handleActionClick(
    ovenId: string,
    ovenPanelId: string,
    action: OvenPanelMenuAction,
    ovenChamberId?: string,
    ovenGroupId?: string,
  ) {
    if (action === OvenPanelMenuAction.Status) {
      changeOvenPanelStatus({ovenPanelId});
      return;
    }
    if (action === OvenPanelMenuAction.Recipes) {
      navigate({
        pathname: paths.recipes,
        search: routerUtils
          .createSearchParams({
            ovenModelId: selectedOvenModel?.id,
          })
          .toString(),
      });
      return;
    }
    if (action === OvenPanelMenuAction.Programming) {
      navigate({
        pathname: paths.programming,
        search: routerUtils
          .createSearchParams({
            countryId: selectedCountryId,
            cityId: selectedCityId,
            bakeryId: selectedBakeryId,
            ovenModelId: selectedOvenModel?.id,
          })
          .toString(),
      });
      return;
    }
    if (action === OvenPanelMenuAction.Statistics) {
      navigate({
        pathname: paths.statistics,
        search: routerUtils
          .createSearchParams({
            countryId: selectedCountryId,
            cityId: selectedCityId,
            bakeryId: selectedBakeryId,
            ovenModelId: selectedOvenModel?.id,
            ovenGroupId,
            ovenId,
            ovenChamberId,
            ovenPanelId,
          })
          .toString(),
      });
      return;
    }
  }

  return (
    <Box
      sx={{
        display: 'flex',
        height: `calc(${pageHeight} - 112px)`,
        paddingBottom: 6,
      }}>
      {ovenModels.length === 0 ? (
        <OvensNotFound />
      ) : (
        <Fragment>
          <Box sx={{width: '150px', marginTop: 2}}>
            <GradientOverflow>
              <OvenModelList
                ovenModels={ovenModels}
                selectedOvenModelId={selectedOvenModel?.id ?? undefined}
                onSelectOvenModel={onSelectOvenModel}
              />
            </GradientOverflow>
          </Box>
          <OvenList
            ovenGroups={selectedOvenModel?.ovenGroups ?? []}
            ovens={selectedOvenModel?.ovens ?? []}
            selectedOvenGroupId={selectedOvenGroupId ?? ''}
            selectedOvenId={selectedOvenId ?? ''}
            onSelectOvenGroup={onSelectOvenGroup}
            onSelectOven={onSelectOven}
            onActionClick={handleActionClick}
          />
        </Fragment>
      )}
    </Box>
  );
}

export default BakeryOvens;
