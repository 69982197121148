import authService, {AuthService} from './auth';
import bakeriesService, {BakeryService} from './bakery';
import baseService from './base';
import cleaningService, {CleaningService} from './cleaning';
import {locationService, LocationService} from './location';
import mockAuthService from './mock/auth';
import mockBakeriesService from './mock/bakeries';
import mockCleaningService from './mock/cleaning';
import mockLocationService from './mock/location';
import mockOvenModelService from './mock/ovenModel';
import mockOvenPanelsService from './mock/ovenPanel';
import mockOvenPanelNotificationService from './mock/ovenPanelNotification';
import mockRecipesService from './mock/recipe';
import mockRecipeTypeService from './mock/recipeType';
import mockScheduleService from './mock/schedule';
import mockUserService from './mock/user';
import ovenModelService, {OvenModelService} from './ovenModel';
import ovenPanelsService, {OvenPanelsService} from './ovenPanel';
import ovenPanelNotificationService, {
  OvenPanelNotificationService,
} from './ovenPanelNotification';
import recipesService, {RecipeService} from './recipe';
import recipeTypeService, {RecipeTypeService} from './recipeType';
import scheduleService, {ScheduleService} from './schedule';
import userService, {UserService} from './user';

export enum ServiceType {
  Web = 'web',
  Mock = 'mock',
}

export let serviceType = (process.env.REACT_APP_SERVICE_TYPE ??
  'mock') as ServiceType;

type Services = {
  base: typeof baseService;
  auth: AuthService;
  user: UserService;
  location: LocationService;
  bakery: BakeryService;
  ovenModel: OvenModelService;
  ovenPanel: OvenPanelsService;
  recipeType: RecipeTypeService;
  recipe: RecipeService;
  cleaning: CleaningService;
  schedule: ScheduleService;
  ovenPanelNotification: OvenPanelNotificationService;
};

const services: Services = {
  get base() {
    return baseService;
  },
  get auth(): AuthService {
    return serviceType === ServiceType.Web ? authService : mockAuthService;
  },
  get user(): UserService {
    return serviceType === ServiceType.Web ? userService : mockUserService;
  },
  get location(): LocationService {
    return serviceType === ServiceType.Web
      ? locationService
      : mockLocationService;
  },
  get bakery(): BakeryService {
    return serviceType === ServiceType.Web
      ? bakeriesService
      : mockBakeriesService;
  },
  get ovenModel(): OvenModelService {
    return serviceType === ServiceType.Web
      ? ovenModelService
      : mockOvenModelService;
  },
  get ovenPanel(): OvenPanelsService {
    return serviceType === ServiceType.Web
      ? ovenPanelsService
      : mockOvenPanelsService;
  },
  get recipeType(): RecipeTypeService {
    return serviceType === ServiceType.Web
      ? recipeTypeService
      : mockRecipeTypeService;
  },
  get recipe(): RecipeService {
    return serviceType === ServiceType.Web
      ? recipesService
      : mockRecipesService;
  },
  get cleaning(): CleaningService {
    return serviceType === ServiceType.Web
      ? cleaningService
      : mockCleaningService;
  },
  get schedule(): ScheduleService {
    return serviceType === ServiceType.Web
      ? scheduleService
      : mockScheduleService;
  },
  get ovenPanelNotification(): OvenPanelNotificationService {
    return serviceType === ServiceType.Web
      ? ovenPanelNotificationService
      : mockOvenPanelNotificationService;
  },
};

export default services;
