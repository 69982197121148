import DeleteOvenPanelNotificationParams from '../../models/requests/deleteOvenPanelNotification';
import DeleteOvenPanelNotificationsRequest from '../../models/requests/deleteOvenPanelNotifications';
import UpdateOvenPanelNotificationRequest from '../../models/requests/updateOvenPanelNotification';
import DeleteOvenPanelNotificationResponse from '../../models/responses/deleteOvenPanelNotification';
import DeleteOvenPanelNotificationsResponse from '../../models/responses/deleteOvenPanelNotifications';
import GetOvenPanelNotificationsResponse from '../../models/responses/getOvenPanelNotifications';
import GetTotalUnseenOvenPanelNotificationsResponse from '../../models/responses/getTotalUnseenOvenPanelNotifications';
import UpdateOvenPanelNotificationResponse from '../../models/responses/updateOvenPanelNotification';
import arrayUtils from '../../utils/arrays';
import {ApplicationError} from '../../utils/errors';
import {OvenPanelNotificationService} from '../ovenPanelNotification';
import data from './data';

const mockOvenPanelNotificationService: OvenPanelNotificationService = {
  getOvenPanelNotifications:
    async function (): Promise<GetOvenPanelNotificationsResponse> {
      return new Promise((resolve) =>
        setTimeout(() => {
          const filteredOvenPanelNotifications =
            data.ovenPanelNotifications.filter((ovenPanelNotification) =>
              data.userNotifications.some(
                (userNotification) =>
                  userNotification.notificationId ===
                    ovenPanelNotification.notificationId &&
                  userNotification.notify,
              ),
            );
          resolve(filteredOvenPanelNotifications);
        }, 500),
      );
    },
  getTotalUnseenOvenPanelNotifications:
    async function (): Promise<GetTotalUnseenOvenPanelNotificationsResponse> {
      return new Promise((resolve) =>
        setTimeout(() => {
          const filteredOvenPanelNotifications =
            data.ovenPanelNotifications.filter((ovenPanelNotification) =>
              data.userNotifications.some(
                (userNotification) =>
                  userNotification.notificationId ===
                    ovenPanelNotification.notificationId &&
                  userNotification.notify,
              ),
            );
          const result = arrayUtils.count(
            filteredOvenPanelNotifications,
            (notification) => !notification.isSeen,
          );
          resolve(result);
        }, 500),
      );
    },
  updateOvenPanelNotification: async function (
    request: UpdateOvenPanelNotificationRequest,
  ): Promise<UpdateOvenPanelNotificationResponse> {
    return new Promise((resolve, reject) =>
      setTimeout(() => {
        const {id: notificationId} = request;
        const exists = data.ovenPanelNotifications.some(
          (notification) => notification.id === notificationId,
        );
        if (!exists) {
          reject(ApplicationError.notFound('Notification'));
          return;
        }
        data.ovenPanelNotifications = data.ovenPanelNotifications.map(
          (notification) => ({
            ...notification,
            isSeen:
              notification.id === notificationId
                ? request.isSeen ?? notification.isSeen
                : notification.isSeen,
            isFlagged:
              notification.id === notificationId
                ? request.isFlagged ?? notification.isFlagged
                : notification.isFlagged,
          }),
        );
        const notification = data.ovenPanelNotifications.find(
          (notification) => notification.id === notificationId,
        );
        resolve({...notification!});
      }, 500),
    );
  },
  deleteOvenPanelNotifications: async function (
    request: DeleteOvenPanelNotificationsRequest,
  ): Promise<DeleteOvenPanelNotificationsResponse> {
    return new Promise((resolve) =>
      setTimeout(() => {
        const {ids} = request;
        if (arrayUtils.isNullOrEmpty(ids)) {
          resolve([]);
          return;
        }
        data.ovenPanelNotifications = data.ovenPanelNotifications.filter(
          (notification) => !ids!.includes(notification.id),
        );
        resolve([...ids!]);
      }, 500),
    );
  },
  deleteOvenPanelNotification: async function (
    params: DeleteOvenPanelNotificationParams,
  ): Promise<DeleteOvenPanelNotificationResponse> {
    return new Promise((resolve, reject) =>
      setTimeout(() => {
        const {ovenPanelNotificationId} = params;
        const exists = data.ovenPanelNotifications.some(
          (notification) => notification.id === ovenPanelNotificationId,
        );
        if (!exists) {
          reject(ApplicationError.notFound('Notification'));
          return;
        }
        data.ovenPanelNotifications = data.ovenPanelNotifications.filter(
          (notification) => notification.id !== ovenPanelNotificationId,
        );
        resolve(ovenPanelNotificationId);
      }, 500),
    );
  },
};

export default mockOvenPanelNotificationService;
