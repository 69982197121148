import {Badge, Box} from '@mui/material';
import {useQuery} from 'react-query';
import {useNavigate} from 'react-router-dom';
import {ReactComponent as BakeriesIcon} from '../../assets/icons/bakery.svg';
import {ReactComponent as ProfileIcon} from '../../assets/icons/profile.svg';
import {ReactComponent as ProgrammingIcon} from '../../assets/icons/programming.svg';
import {ReactComponent as RecipesIcon} from '../../assets/icons/recipes.svg';
import {ReactComponent as StatisticsIcon} from '../../assets/icons/statistics.svg';
import locals from '../../localization/locals';
import paths from '../../routes/paths';
import services from '../../services/provider';
import DashboardMenuItem from './DashboardMenuItem';

function DashboardMenu() {
  const navigate = useNavigate();

  const {data: totalUnseenNotifications} = useQuery({
    queryKey: ['totalUnseenOvenPanelNotifications'],
    queryFn: () =>
      services.ovenPanelNotification.getTotalUnseenOvenPanelNotifications(),
  });

  const hasUnseenNotifications = (totalUnseenNotifications ?? 0) > 0;

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        flexWrap: 'wrap',
      }}>
      <DashboardMenuItem
        icon={RecipesIcon}
        text={locals.getText('menu_recipes_label')}
        onClick={() => navigate(paths.recipes)}
      />
      <DashboardMenuItem
        icon={ProgrammingIcon}
        text={locals.getText('menu_programming_label')}
        onClick={() => navigate(paths.programming)}
      />
      <DashboardMenuItem
        icon={BakeriesIcon}
        text={locals.getText('menu_bakeries_label')}
        onClick={() => navigate(paths.bakeries)}
      />
      <DashboardMenuItem
        icon={StatisticsIcon}
        text={locals.getText('menu_statistics_label')}
        onClick={() => navigate(paths.statistics)}
      />
      <Badge
        sx={{
          '& .MuiBadge-badge': {
            minWidth: '12px',
            width: '12px',
            height: '12px',
            padding: 0,
          },
        }}
        color="primary"
        badgeContent=" "
        invisible={!hasUnseenNotifications}>
        <DashboardMenuItem
          icon={ProfileIcon}
          text={locals.getText('menu_profile_label')}
          onClick={() => navigate(paths.profile)}
        />
      </Badge>
    </Box>
  );
}

export default DashboardMenu;
