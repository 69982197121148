import {Box, List, ListItem, Typography} from '@mui/material';
import {useState} from 'react';
import {ReactComponent as DeclineIcon} from '../../assets/icons/decline.svg';
import locals from '../../localization/locals';
import Schedule, {ScheduleType} from '../../models/entities/schedule';
import colors from '../../themes/colors';
import cleaningUtils from '../../utils/cleaning';
import GradientOverflow from '../common/GradientOverflow';
import IconButton from '../common/IconButton';
import Span from '../common/Span';

type ScheduleListItemProps = {
  schedule: Schedule;
  onSelectSchedule: () => void;
  onDeleteSchedule: () => void;
};

function ScheduleListItem(props: ScheduleListItemProps) {
  const {schedule, onSelectSchedule, onDeleteSchedule} = props;

  const [isHovered, setIsHovered] = useState(false);

  function getDescriptionText() {
    switch (schedule.type) {
      case ScheduleType.Recipe:
        return schedule.recipeName ?? '';
      case ScheduleType.Cleaning:
        return `${locals.getText(
          'programming_cleaning_label',
        )} ${cleaningUtils.getDescription(
          schedule.ovenModelId,
          schedule.cleaningId ?? 0,
        )}`;
      case ScheduleType.Off:
        return 'off';
    }
  }

  function getLocationText() {
    let location =
      schedule.ovenGroupDescription ?? schedule.ovenDescription ?? '';
    if (schedule.ovenIndex != null) {
      location += ` ${locals.getText(
        schedule.ovenIndex === 0
          ? 'programming_bottom_oven_label'
          : 'programming_top_oven_label',
      )}`;
    } else if (schedule.ovenChamberIndex != null) {
      location += ` ${locals.getText('programming_chamber_label')} ${
        schedule.ovenChamberIndex + 1
      }`;
    }
    return location;
  }

  function getTimeSlotText() {
    switch (schedule.type) {
      case ScheduleType.Recipe:
      case ScheduleType.Cleaning:
        return `${schedule.startTime} - ${schedule.endTime}`;
      case ScheduleType.Off:
        return schedule.startTime;
    }
  }

  function getColor() {
    if (isHovered) {
      return 'primary.main';
    }
    switch (schedule.type) {
      case ScheduleType.Recipe:
        return 'text.primary';
      case ScheduleType.Cleaning:
        return colors.blue;
      case ScheduleType.Off:
        return colors.orange;
    }
  }

  return (
    <ListItem sx={{margin: 0, padding: 0}}>
      <Box sx={{display: 'flex', alignItems: 'center'}}>
        <Box
          sx={{display: 'flex', alignItems: 'center', cursor: 'pointer'}}
          onMouseEnter={() => setIsHovered(true)}
          onMouseLeave={() => setIsHovered(false)}
          onClick={onSelectSchedule}>
          <Box sx={{width: '50vw', padding: 2}}>
            <Typography variant="body2">
              <Span sx={{fontWeight: 'bold', color: getColor()}}>
                {getDescriptionText()}
              </Span>
              <Span
                sx={{
                  fontWeight: 'bold',
                  color: getColor(),
                  marginInline: '5px',
                }}>
                |
              </Span>
              <Span sx={{color: isHovered ? 'primary.main' : 'text.primary'}}>
                {getLocationText()}
              </Span>
            </Typography>
          </Box>
          <Box sx={{width: '15vw', textAlign: 'right', padding: 2}}>
            <Typography
              sx={{color: isHovered ? 'primary.main' : 'text.primary'}}
              variant="body2">
              {getTimeSlotText()}
            </Typography>
          </Box>
        </Box>
        <Box sx={{width: '15vw', textAlign: 'center', padding: 1}}>
          <IconButton IconComponent={DeclineIcon} onClick={onDeleteSchedule} />
        </Box>
      </Box>
    </ListItem>
  );
}

export type ScheduleListProps = {
  schedules: Schedule[];
  onSelectSchedule: (scheduleId: string) => void;
  onDeleteSchedule: (scheduleId: string) => void;
  schedulesNotFoundMessage?: string;
};

function ScheduleList(props: ScheduleListProps) {
  const {
    schedules,
    onSelectSchedule,
    onDeleteSchedule,
    schedulesNotFoundMessage,
  } = props;
  return (
    <GradientOverflow hideScrollbar>
      <List sx={{margin: 0, padding: 0}}>
        {schedules.length > 0 ? (
          schedules.map((schedule) => (
            <ScheduleListItem
              key={schedule.id}
              schedule={schedule}
              onSelectSchedule={() => onSelectSchedule(schedule.id)}
              onDeleteSchedule={() => onDeleteSchedule(schedule.id)}
            />
          ))
        ) : (
          <ListItem sx={{margin: 0, padding: 2}}>
            <Typography variant="body2" sx={{color: 'text.primary'}}>
              {schedulesNotFoundMessage}
            </Typography>
          </ListItem>
        )}
      </List>
    </GradientOverflow>
  );
}

export default ScheduleList;
