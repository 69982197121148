import {Box, InputBase, styled, Typography} from '@mui/material';
import {ReactComponent as Add} from '../../../../../assets/icons/add.svg';
import {ReactComponent as Subtract} from '../../../../../assets/icons/subtract.svg';
import locals from '../../../../../localization/locals';
import IconButton from '../../../../common/IconButton';

const InputBaseStyled = styled(InputBase)(() => ({
  '& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button': {
    display: 'none',
  },
  '& input[type=number]': {
    MozAppearance: 'textfield',
  },
  '& input': {
    textAlign: 'center',
  },
}));

type SteamInjectionNumberRowProps = {
  steamInjectionNumber: number;
  onChange: (steamInjectionNumber: number) => void;
};

function SteamInjectionNumberRow(props: SteamInjectionNumberRowProps) {
  const {steamInjectionNumber, onChange} = props;

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        width: '100%',
        paddingBlock: 1,
        paddingInline: 2,
      }}>
      <Box>
        <Typography
          variant="body2"
          sx={{fontWeight: 'bold', color: 'text.primary'}}>
          {locals.getText('recipe_settings_phases_steam_injection_label')}
        </Typography>
        <Typography variant="caption" sx={{color: 'text.secondary'}}>
          {locals.getText('recipe_settings_phases_steam_injection_message')}
        </Typography>
      </Box>
      <InputBaseStyled
        sx={{width: '80px', fontSize: '0.875rem', color: 'text.primary'}}
        value={steamInjectionNumber}
        startAdornment={
          steamInjectionNumber === 0 ? (
            <Box sx={{minWidth: '30px'}} />
          ) : (
            <IconButton
              IconComponent={Subtract}
              iconSize={'16px'}
              onClick={() => onChange(steamInjectionNumber - 1)}
            />
          )
        }
        endAdornment={
          steamInjectionNumber === 5 ? (
            <Box sx={{minWidth: '30px'}} />
          ) : (
            <IconButton
              IconComponent={Add}
              iconSize={'16px'}
              onClick={() => onChange(steamInjectionNumber + 1)}
            />
          )
        }
      />
    </Box>
  );
}

export default SteamInjectionNumberRow;
