import GetCitiesParams from '../models/requests/getCities';
import GetDistrictsParams from '../models/requests/getDistricts';
import GetCitiesResponse from '../models/responses/getCities';
import GetCountriesResponse from '../models/responses/getCountries';
import GetDistrictsResponse from '../models/responses/getDistricts';
import baseService from './base';
import endpoints from './endpoints';

export type LocationService = {
  getCountries: () => Promise<GetCountriesResponse>;
  getDistricts: (params: GetDistrictsParams) => Promise<GetDistrictsResponse>;
  getCities: (params: GetCitiesParams) => Promise<GetCitiesResponse>;
};

export const locationService: LocationService = {
  getCountries: async function (): Promise<GetCountriesResponse> {
    return baseService
      .get<null, GetCountriesResponse>(endpoints.countries)
      .then((result) => result.data);
  },
  getDistricts: async function (
    params: GetDistrictsParams,
  ): Promise<GetDistrictsResponse> {
    return baseService
      .get<GetDistrictsParams, GetDistrictsResponse>(
        endpoints.districts,
        params,
      )
      .then((result) => result.data);
  },
  getCities: async function (
    params: GetCitiesParams,
  ): Promise<GetCitiesResponse> {
    return baseService
      .get<GetCitiesParams, GetCitiesResponse>(endpoints.cities, params)
      .then((result) => result.data);
  },
};
