import {Box} from '@mui/material';
import {useEffect} from 'react';
import {useNavigate} from 'react-router-dom';
import useLocalization from '../../hooks/common/useLocalization';
import locals from '../../localization/locals';
import paths from '../../routes/paths';
import useBreadcrumbsStore from '../../state/breadcrumbs';
import Tabs from '../common/Tabs';
import InformationList from './InformationList';

function Information() {
  const navigate = useNavigate();
  const [language] = useLocalization();

  const setBreadcrumbs = useBreadcrumbsStore((state) => state.setBreadcrumbs);

  const tabs = [locals.getText('profile_information_tabs_preferences_label')];

  useEffect(() => {
    setBreadcrumbs([
      {
        title: locals.getText('profile_breadcrumb'),
        onClick: () => navigate(paths.profile),
      },
      {
        title: locals.getText('profile_information_breadcrumb'),
        onClick: () => navigate(paths.profileInformation),
      },
      {title: tabs[0]},
    ]);
    return () => setBreadcrumbs([]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [language]);

  return (
    <Box sx={{width: '80vw'}}>
      <Box sx={{height: '112px'}}>
        <Tabs hideIndicator values={tabs} selectedTabIndex={0} />
      </Box>
      <InformationList />
    </Box>
  );
}

export default Information;
