import GetOvenModelsParams from '../models/requests/getOvenModels';
import GetRecipeSchemaParams from '../models/requests/getRecipeSchema';
import GetOvenModelsResponse from '../models/responses/getOvenModels';
import GetRecipeSchemaResponse from '../models/responses/getRecipeSchema';
import baseService from './base';
import endpoints from './endpoints';

export type OvenModelService = {
  getOvenModels: (
    params: GetOvenModelsParams,
  ) => Promise<GetOvenModelsResponse>;
  getRecipeSchema: (
    params: GetRecipeSchemaParams,
  ) => Promise<GetRecipeSchemaResponse>;
};

const ovenModelService: OvenModelService = {
  getOvenModels: async function (
    params: GetOvenModelsParams,
  ): Promise<GetOvenModelsResponse> {
    return baseService
      .get<GetOvenModelsParams, GetOvenModelsResponse>(
        endpoints.ovenModels,
        params,
      )
      .then((response) => response.data);
  },
  getRecipeSchema: async function (
    params: GetRecipeSchemaParams,
  ): Promise<GetRecipeSchemaResponse> {
    const {ovenModelId} = params;
    return baseService
      .get<GetRecipeSchemaParams, GetRecipeSchemaResponse>(
        `${endpoints.ovenModels}/${ovenModelId}/recipe-schema`,
        params,
      )
      .then((response) => response.data);
  },
};

export default ovenModelService;
