import {Box, Typography} from '@mui/material';
import locals from '../../../../../localization/locals';
import Switch from '../../../../common/Switch';

type SteamExitValveOpenedRowProps = {
  steamExitValveOpened: boolean;
  onChange: (steamExitValveOpened: boolean) => void;
};

function SteamExitValveOpenedRow(props: SteamExitValveOpenedRowProps) {
  const {steamExitValveOpened, onChange} = props;

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        width: '100%',
        paddingTop: 1,
        paddingBottom: 3,
        paddingInline: 2,
      }}>
      <Box>
        <Typography
          variant="body2"
          sx={{fontWeight: 'bold', color: 'text.primary'}}>
          {locals.getText('recipe_settings_phases_steam_exit_valve_label')}
        </Typography>
      </Box>
      <Switch
        checked={steamExitValveOpened}
        onChange={(_, checked) => onChange(checked)}
      />
    </Box>
  );
}

export default SteamExitValveOpenedRow;
