import Recipe from '../../models/entities/recipe';
import CreateRecipeRequest from '../../models/requests/createRecipe';
import DeleteRecipeParams from '../../models/requests/deleteRecipeParams';
import ExistsRecipeParams from '../../models/requests/existsRecipe';
import GetCommonRecipesParams from '../../models/requests/getCommonRecipes';
import GetRecipeParams from '../../models/requests/getRecipe';
import GetRecipesParams from '../../models/requests/getRecipes';
import UpdateRecipeRequest from '../../models/requests/updateRecipe';
import CreateRecipeResponse from '../../models/responses/createRecipe';
import DeleteRecipeResponse from '../../models/responses/deleteRecipe';
import GetCommonRecipesResponse from '../../models/responses/getCommonRecipes';
import GetRecipeResponse from '../../models/responses/getRecipe';
import GetRecipesResponse from '../../models/responses/getRecipes';
import UpdateRecipeResponse from '../../models/responses/updateRecipe';
import {ApplicationError} from '../../utils/errors';
import {RecipeService} from '../recipe';
import data from './data';

const mockRecipeService: RecipeService = {
  existsRecipe: async function (params: ExistsRecipeParams): Promise<boolean> {
    return new Promise((resolve) =>
      setTimeout(() => {
        const {ovenModelId, name} = params;
        const recipe = data.recipes.find(
          (recipe) =>
            (ovenModelId == null || recipe.ovenModelId === ovenModelId) &&
            (name == null || recipe.name.toLowerCase() === name.toLowerCase()),
        );
        resolve(recipe != null);
      }, 500),
    );
  },
  getRecipes: async function (
    params: GetRecipesParams,
  ): Promise<GetRecipesResponse> {
    return new Promise((resolve) =>
      setTimeout(() => {
        const {ovenModelId} = params;
        const recipes = data.recipes.filter(
          (recipe) => ovenModelId == null || recipe.ovenModelId === ovenModelId,
        );
        resolve(recipes);
      }, 500),
    );
  },
  getCommonRecipes: async function (
    params: GetCommonRecipesParams,
  ): Promise<GetCommonRecipesResponse> {
    return new Promise((resolve) =>
      setTimeout(() => {
        const {ovenModelId} = params;
        const recipes = data.recipes.filter(
          (recipe) => ovenModelId == null || recipe.ovenModelId === ovenModelId,
        );
        resolve(recipes);
      }, 500),
    );
  },
  getRecipe: async function (
    params: GetRecipeParams,
  ): Promise<GetRecipeResponse> {
    return new Promise((resolve, reject) =>
      setTimeout(() => {
        const {recipeId} = params;
        const recipe = data.recipes.find((recipe) => recipe.id === recipeId);
        if (recipe == null) {
          reject(ApplicationError.notFound('Recipe'));
          return;
        }
        resolve({...recipe});
      }, 500),
    );
  },
  createRecipe: async function (
    request: CreateRecipeRequest,
  ): Promise<CreateRecipeResponse> {
    return new Promise((resolve) =>
      setTimeout(() => {
        const bakeryIdsSet = new Set(request.bakeryIds);
        const recipeTypeIdsSet = new Set(request.recipeTypeIds);
        const recipe: Recipe = {
          ...request,
          modifiedAt: new Date().toISOString(),
          recipeTypes: data.recipeTypes.filter((recipeType) =>
            recipeTypeIdsSet.has(recipeType.id),
          ),
          bakeries: data.bakeries.filter((bakery) =>
            bakeryIdsSet.has(bakery.id),
          ),
        };
        data.recipes.push(recipe);
        resolve({...recipe});
      }, 500),
    );
  },
  updateRecipe: async function (
    request: UpdateRecipeRequest,
  ): Promise<UpdateRecipeResponse> {
    return new Promise((resolve, reject) =>
      setTimeout(() => {
        const {id: recipeId} = request;
        const exists = data.recipes.some((recipe) => recipe.id === recipeId);
        if (!exists) {
          reject(ApplicationError.notFound('Recipe'));
          return;
        }
        const recipeTypeIdsSet = new Set(request.recipeTypeIds);
        const bakeryIdsSet = new Set(request.bakeryIds);
        data.recipes = data.recipes.map((recipe) => {
          if (recipe.id === recipeId) {
            return {
              ...recipe,
              name: request.name ?? recipe.name,
              procedure: request.procedure ?? recipe.procedure,
              phases: request.phases ?? recipe.phases,
              modifiedAt: new Date().toISOString(),
              recipeTypes:
                request.recipeTypeIds != null
                  ? data.recipeTypes.filter((recipeType) =>
                      recipeTypeIdsSet.has(recipeType.id),
                    )
                  : recipe.recipeTypes,
              bakeries:
                request.bakeryIds != null
                  ? data.bakeries.filter((bakery) =>
                      bakeryIdsSet.has(bakery.id),
                    )
                  : recipe.bakeries,
            };
          }
          return recipe;
        });
        const recipe = data.recipes.find((recipe) => recipe.id === recipeId);
        resolve({...recipe!});
      }, 500),
    );
  },
  deleteRecipe: async function (
    params: DeleteRecipeParams,
  ): Promise<DeleteRecipeResponse> {
    return new Promise((resolve, reject) =>
      setTimeout(() => {
        const {id: recipeId} = params;
        const recipe = data.recipes.find((recipe) => recipe.id === recipeId);
        if (recipe == null) {
          reject(ApplicationError.notFound('Recipe'));
          return;
        }
        data.recipes = data.recipes.filter((recipe) => recipe.id !== recipeId);
        resolve(recipeId);
      }, 500),
    );
  },
};
export default mockRecipeService;
