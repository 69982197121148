import Oven from './oven';
import OvenGroup from './ovenGroup';
import {OvenModelId} from './ovenModel';

export enum OvenSubModelId {
  TurboramSingle = 1,
  TurboramXL = 2,
  TurboramCombi = 3,
  TurboramSinglePlusHomeModule = 4,
}

type OvenSubModel = {
  id: OvenSubModelId;
  description: string;
  ovenModelId: OvenModelId;
  ovenModelDescription?: string;
  ovens?: Oven[];
  ovenGroups?: OvenGroup[];
};

export default OvenSubModel;
