import GetCleaningsParams from '../models/requests/getCleanings';
import GetCleaningsResponse from '../models/responses/getCleanings';
import baseService from './base';
import endpoints from './endpoints';

export type CleaningService = {
  getCleanings: (params: GetCleaningsParams) => Promise<GetCleaningsResponse>;
};

const cleaningService: CleaningService = {
  getCleanings: async function (
    params: GetCleaningsParams,
  ): Promise<GetCleaningsResponse> {
    return baseService
      .get<GetCleaningsParams, GetCleaningsResponse>(
        endpoints.cleanings,
        params,
      )
      .then((result) => result.data);
  },
};

export default cleaningService;
