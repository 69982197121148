import CreateRecipeTypeRequest from '../models/requests/createRecipeType';
import DeleteRecipeTypeParams from '../models/requests/deleteRecipeType';
import GetRecipeTypesParams from '../models/requests/getRecipeTypes';
import RemoveRecipeFromRecipeTypeParams from '../models/requests/removeRecipeFromRecipeType';
import UpdateRecipeTypeRequest from '../models/requests/updateRecipeType';
import CreateRecipeTypeResponse from '../models/responses/createRecipeType';
import DeleteRecipeResponse from '../models/responses/deleteRecipe';
import GetRecipeTypesResponse from '../models/responses/getRecipeTypes';
import RemoveRecipeFromRecipeTypeResponse from '../models/responses/removeRecipeFromRecipeType';
import UpdateRecipeTypeResponse from '../models/responses/updateRecipeType';
import baseService from './base';
import endpoints from './endpoints';

export type RecipeTypeService = {
  getRecipeTypes: (
    params: GetRecipeTypesParams,
  ) => Promise<GetRecipeTypesResponse>;
  createRecipeType: (
    request: CreateRecipeTypeRequest,
  ) => Promise<CreateRecipeTypeResponse>;
  updateRecipeType: (
    request: UpdateRecipeTypeRequest,
  ) => Promise<UpdateRecipeTypeResponse>;
  deleteRecipeType: (
    params: DeleteRecipeTypeParams,
  ) => Promise<DeleteRecipeResponse>;
  removeRecipeFromRecipeType: (
    params: RemoveRecipeFromRecipeTypeParams,
  ) => Promise<RemoveRecipeFromRecipeTypeResponse>;
};

const recipeTypeService: RecipeTypeService = {
  getRecipeTypes: async function (
    params: GetRecipeTypesParams,
  ): Promise<GetRecipeTypesResponse> {
    return baseService
      .get<GetRecipeTypesParams, GetRecipeTypesResponse>(
        endpoints.recipeTypes,
        params,
      )
      .then((response) => response.data);
  },
  createRecipeType: async function (
    request: CreateRecipeTypeRequest,
  ): Promise<CreateRecipeTypeResponse> {
    return baseService
      .post<CreateRecipeTypeRequest, CreateRecipeTypeResponse>(
        endpoints.recipeTypes,
        request,
      )
      .then((response) => response.data);
  },
  updateRecipeType: async function (
    request: UpdateRecipeTypeRequest,
  ): Promise<UpdateRecipeTypeResponse> {
    const {id: recipeTypeId} = request;
    return baseService
      .put<UpdateRecipeTypeRequest, UpdateRecipeTypeResponse>(
        `${endpoints.recipeTypes}/${recipeTypeId}`,
        request,
      )
      .then((response) => response.data);
  },
  deleteRecipeType: async function (
    params: DeleteRecipeTypeParams,
  ): Promise<DeleteRecipeResponse> {
    const {id: recipeTypeId} = params;
    return baseService
      .delete<null, null, DeleteRecipeResponse>(
        `${endpoints.recipeTypes}/${recipeTypeId}`,
      )
      .then((response) => response.data);
  },
  removeRecipeFromRecipeType: async function (
    params: RemoveRecipeFromRecipeTypeParams,
  ): Promise<RemoveRecipeFromRecipeTypeResponse> {
    const {recipeTypeId, recipeId} = params;
    return baseService
      .delete<null, null, RemoveRecipeFromRecipeTypeResponse>(
        `${endpoints.recipeTypes}/${recipeTypeId}/recipes/${recipeId}`,
      )
      .then((response) => response.data);
  },
};

export default recipeTypeService;
