import {IconButton as MuiIconButton, SxProps} from '@mui/material';
import {ElementType, MouseEvent, useEffect, useState} from 'react';
import colors from '../../themes/colors';
import Icon from './Icon';

export type IconButtonProps = {
  IconComponent: ElementType<any>;
  onClick?: (event: MouseEvent<HTMLButtonElement>) => void;
  size?: 'small' | 'medium' | 'large';
  iconSize?: string;
  isActive?: boolean;
  disabled?: boolean;
  activeColor?: string;
  inactiveColor?: string;
  disabledColor?: string;
  sx?: SxProps;
};

function IconButton(props: IconButtonProps) {
  const {
    IconComponent,
    onClick,
    size = 'small',
    iconSize,
    isActive,
    disabled,
    activeColor = 'primary',
    inactiveColor = 'secondary',
    disabledColor = colors.lighterGrey,
    sx,
  } = props;

  const [color, setColor] = useState(inactiveColor);

  useEffect(() => {
    setColor(isActive ? activeColor : inactiveColor);
  }, [isActive, activeColor, inactiveColor]);

  function mouseEventHandler(entered: boolean) {
    if (!isActive) {
      setColor(entered ? activeColor : inactiveColor);
    }
  }

  return (
    <MuiIconButton
      sx={sx}
      size={size}
      disableRipple
      disabled={disabled}
      onClick={onClick}
      onMouseEnter={() => mouseEventHandler(true)}
      onMouseLeave={() => mouseEventHandler(false)}>
      <Icon
        IconComponent={IconComponent}
        size={iconSize}
        color={disabled ? disabledColor : color}
      />
    </MuiIconButton>
  );
}

export default IconButton;
