import UserNotification from '../../models/entities/userNotification';
import ChangePasswordRequest from '../../models/requests/changePassword';
import GetUserParams from '../../models/requests/getUser';
import GetUserNotificationsParams from '../../models/requests/getUserNotifications';
import SetUserNotificationRequest from '../../models/requests/setUserNotification';
import SubscribeRequest from '../../models/requests/subscribe';
import UnsubscribeRequest from '../../models/requests/unsubscribe';
import UpdateUserRequest from '../../models/requests/updateUser';
import ChangePasswordResponse from '../../models/responses/changePassword';
import GetUserResponse from '../../models/responses/getUser';
import GetUserNotificationsResponse from '../../models/responses/getUserNotifications';
import SubscribeResponse from '../../models/responses/subscribe';
import UnsubscribeResponse from '../../models/responses/unsubscribe';
import UpdateUserResponse from '../../models/responses/updateUser';
import {ApplicationError} from '../../utils/errors';
import stringUtils from '../../utils/strings';
import {UserService} from '../user';
import data from './data';

const mockUserService: UserService = {
  async getUser(params: GetUserParams): Promise<GetUserResponse> {
    return new Promise((resolve, reject) =>
      setTimeout(() => {
        const {userId} = params;
        const user = data.users.find((user) => user.id === userId);
        if (user == null) {
          reject(ApplicationError.notFound('User'));
          return;
        }
        resolve({...user});
      }, 500),
    );
  },
  async updateUser(request: UpdateUserRequest): Promise<UpdateUserResponse> {
    return new Promise((resolve, reject) =>
      setTimeout(() => {
        const {id: userId} = request;
        const exists = data.users.some((user) => user.id === userId);
        if (!exists) {
          reject(ApplicationError.notFound('User'));
          return;
        }
        data.users = data.users.map((user) => ({
          ...user,
          name: user.id === userId ? request.name ?? user.name : user.name,
        }));
        const user = data.users.find((user) => user.id === userId);
        resolve({...user!});
      }, 500),
    );
  },
  async changePassword(
    request: ChangePasswordRequest,
  ): Promise<ChangePasswordResponse> {
    const {currentPassword, newPassword} = request;
    return new Promise((resolve, reject) =>
      setTimeout(() => {
        const {id: userId} = request;
        if (stringUtils.anyIsNullOrWhiteSpace(currentPassword, newPassword)) {
          reject(ApplicationError.badRequest());
          return;
        }
        if (currentPassword === '12345') {
          reject(ApplicationError.invalidEmailOrPassword());
          return;
        }
        const exists = data.users.some((user) => user.id === userId);
        if (!exists) {
          reject(ApplicationError.notFound('User'));
          return;
        }
        resolve(true);
      }, 500),
    );
  },
  getUserNotifications: function (
    params: GetUserNotificationsParams,
  ): Promise<GetUserNotificationsResponse> {
    return new Promise((resolve) =>
      setTimeout(() => resolve([...data.userNotifications]), 500),
    );
  },
  setUserNotification: function (
    request: SetUserNotificationRequest,
  ): Promise<UserNotification> {
    return new Promise((resolve, reject) =>
      setTimeout(() => {
        const {notificationId} = request;
        const exists = data.userNotifications.some(
          (userNotification) =>
            userNotification.notificationId === notificationId,
        );
        if (!exists) {
          reject(ApplicationError.notFound('NotificationSettings'));
          return;
        }
        data.userNotifications = data.userNotifications.map(
          (userNotification) => ({
            ...userNotification,
            notify:
              userNotification.notificationId === notificationId
                ? request.notify
                : userNotification.notify,
          }),
        );
        const userNotification = data.userNotifications.find(
          (userNotification) =>
            userNotification.notificationId === notificationId,
        );

        resolve({...userNotification!});
      }, 500),
    );
  },
  subscribe: function (request: SubscribeRequest): Promise<SubscribeResponse> {
    return new Promise((resolve) => setTimeout(() => resolve(true), 500));
  },
  unsubscribe: function (
    request: UnsubscribeRequest,
  ): Promise<UnsubscribeResponse> {
    return new Promise((resolve) => setTimeout(() => resolve(true), 500));
  },
};

export default mockUserService;
