import {Box, TextField, Typography} from '@mui/material';
import {useState} from 'react';
import {useMutation} from 'react-query';
import {useNavigate, useSearchParams} from 'react-router-dom';
import useEffectOnce from '../../hooks/common/useEffectOnce';
import useLocalization from '../../hooks/common/useLocalization';
import useSplashScreen from '../../hooks/common/useSplashScreen';
import locals from '../../localization/locals';
import paths from '../../routes/paths';
import services from '../../services/provider';
import {setAuthData} from '../../state/auth';
import cryptoUtils from '../../utils/crypto';
import errorUtils, {ApplicationErrorCode} from '../../utils/errors';
import stringUtils from '../../utils/strings';
import {Button} from '../common/Button';
import LoadingBackdrop from '../common/LoadingBackdrop';

function PasswordReset() {
  const navigate = useNavigate();
  const {splash} = useSplashScreen();
  const [searchParams] = useSearchParams();
  useLocalization();

  const [recoveryCode, setRecoveryCode] = useState('');
  const [newPassword, setNewPassword] = useState('');

  const [invalidCredentials, setInvalidCredentials] = useState(false);

  useEffectOnce(() => {
    const email = searchParams.get('email');
    if (stringUtils.isNullOrWhiteSpace(email)) {
      navigate(paths.passwordRecovery);
    }
  });

  const {mutate: resetPassword, isLoading} = useMutation({
    mutationFn: services.auth.resetPassword,
    onSuccess: (result) => {
      setAuthData(result.token, result.user);
      splash({
        title: locals.getText('splash_screen_password_recovered_title'),
        caption: locals.getText('splash_screen_password_recovered_caption'),
        nextAction: () => navigate(paths.dashboard),
      });
    },
    onError: (error) =>
      errorUtils.handleMatch(
        error,
        [ApplicationErrorCode.InvalidEmailOrRecoveryCode],
        () => setInvalidCredentials(true),
      ),
  });

  function handleResetPassword() {
    const email = searchParams.get('email');
    resetPassword({
      email: email!,
      recoveryCode,
      newPassword: cryptoUtils.sha256(newPassword),
    });
  }

  return (
    <Box sx={{display: 'flex', flexDirection: 'column', width: '30vw'}}>
      <Typography
        variant="h4"
        color="secondary"
        sx={{
          marginBottom: invalidCredentials ? '2vh' : '8vh',
          fontWeight: '500',
        }}>
        {locals.getText('password_recovery_login_title')}
      </Typography>
      {invalidCredentials && (
        <Typography
          variant="caption"
          color="primary"
          sx={{marginBottom: '6vh', fontWeight: 500}}>
          {locals.getText(
            'password_recovery_login_invalid_email_or_code_label',
          )}
        </Typography>
      )}
      <TextField
        variant="standard"
        sx={{marginBottom: '4vh'}}
        label={locals.getText('password_recovery_login_code_input_label')}
        value={recoveryCode}
        onChange={(event) => setRecoveryCode(event.target.value)}
      />
      <TextField
        type="password"
        variant="standard"
        sx={{marginBottom: '20vh'}}
        label={locals.getText(
          'password_recovery_login_new_password_input_label',
        )}
        value={newPassword}
        onChange={(event) => setNewPassword(event.target.value)}
        onKeyUp={(event) =>
          event.key === 'Enter' ? handleResetPassword() : null
        }
      />
      <Button
        onClick={handleResetPassword}
        disabled={stringUtils.anyIsNullOrWhiteSpace(recoveryCode, newPassword)}>
        {locals.getText('password_recovery_login_login_button_label')}
      </Button>
      <LoadingBackdrop loading={isLoading} />
    </Box>
  );
}

export default PasswordReset;
