import {Box} from '@mui/material';
import useLocalization from '../../hooks/common/useLocalization';
import {Language, languages} from '../../localization/locals';
import SimpleList from '../common/SimpleList';
import {pageHeight} from '../navigation/Navbar';

function LanguageSettings() {
  const [language, setLanguage] = useLocalization();

  return (
    <Box
      sx={{
        height: `calc(${pageHeight} - 112px)`,
        padding: 2,
        paddingBottom: 6,
      }}>
      <SimpleList
        options={languages.map((language) => ({
          id: language.language,
          primary: language.name(),
        }))}
        selectedOption={language}
        onChangeSelectedOption={(language) => setLanguage(language as Language)}
      />
    </Box>
  );
}

export default LanguageSettings;
