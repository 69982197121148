export enum UserRole {
  Administrator,
  Manager,
}

type User = {
  id: string;
  name: string;
  email: string;
  role: UserRole;
  language: string;
  companyId: string;
};

export default User;
