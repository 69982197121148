import LoginRequest from '../models/requests/login';
import RecoverPasswordRequest from '../models/requests/recoverPassword';
import ResetPasswordRequest from '../models/requests/resetPassword';
import SignupRequest from '../models/requests/signup';
import LoginResponse from '../models/responses/login';
import RecoverPasswordResponse from '../models/responses/recoverPassword';
import ResetPasswordResponse from '../models/responses/resetPassword';
import SignupResponse from '../models/responses/signup';
import baseService from './base';
import endpoints from './endpoints';

export type AuthService = {
  login: (request: LoginRequest) => Promise<LoginResponse>;
  recoverPassword: (
    request: RecoverPasswordRequest,
  ) => Promise<RecoverPasswordResponse>;
  resetPassword: (
    request: ResetPasswordRequest,
  ) => Promise<ResetPasswordResponse>;
  signup: (request: SignupRequest) => Promise<SignupResponse>;
};

const authService: AuthService = {
  login: async function (request: LoginRequest): Promise<LoginResponse> {
    return baseService
      .post<LoginRequest, LoginResponse>(endpoints.login, request)
      .then((result) => result.data);
  },
  recoverPassword: async function (
    request: RecoverPasswordRequest,
  ): Promise<RecoverPasswordResponse> {
    return baseService
      .post<RecoverPasswordRequest, RecoverPasswordResponse>(
        endpoints.passwordRecovery,
        request,
      )
      .then((result) => result.data);
  },
  resetPassword: async function (
    request: ResetPasswordRequest,
  ): Promise<ResetPasswordResponse> {
    return baseService
      .post<ResetPasswordRequest, ResetPasswordResponse>(
        endpoints.passwordReset,
        request,
      )
      .then((result) => result.data);
  },
  signup: async function (request: SignupRequest): Promise<SignupResponse> {
    return baseService
      .post<SignupRequest, SignupResponse>(endpoints.signup, request)
      .then((result) => result.data);
  },
};

export default authService;
