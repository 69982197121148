import axios, {AxiosError, AxiosResponse} from 'axios';
import Response from '../models/responses/response';
import {ApplicationError} from '../utils/errors';
import localStorageUtils from '../utils/localStorage';
import stringUtils from '../utils/strings';

const contentType = 'application/json';

const baseService = {
  init: function () {
    try {
      axios.defaults.baseURL = process.env.REACT_APP_API_BASE_URL;
      axios.defaults.headers.common['Accept'] = contentType;
      axios.defaults.headers.post['Content-Type'] = contentType;
      const token = localStorageUtils.getValue('token', '');
      if (!stringUtils.isNullOrWhiteSpace(token)) {
        axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
      }
    } catch (error) {}
  },
  setAuthorizationToken: function (token: string) {
    axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
  },
  removeAuthorizationToken: function () {
    axios.defaults.headers.common['Authorization'] = '';
  },
  get: async function <TParams, TResponse>(
    url: string,
    params?: TParams,
  ): Promise<Response<TResponse>> {
    return this.getResult(axios.get<Response<TResponse>>(url, {params}));
  },
  post: async function <TRequest, TResponse>(
    url: string,
    request: TRequest,
  ): Promise<Response<TResponse>> {
    return this.getResult(axios.post<Response<TResponse>>(url, request));
  },
  put: async function <TRequest, TResponse>(
    url: string,
    request: TRequest,
  ): Promise<Response<TResponse>> {
    return this.getResult(axios.put<Response<TResponse>>(url, request));
  },
  delete: async function <TParams, TData, TResponse>(
    url: string,
    params?: TParams,
    data?: TData,
  ): Promise<Response<TResponse>> {
    return this.getResult(
      axios.delete<Response<TResponse>>(url, {params, data}),
    );
  },
  getResult: async function <TResponse>(
    requestPromise: Promise<AxiosResponse<Response<TResponse>>>,
  ): Promise<Response<TResponse>> {
    return requestPromise
      .then((response: AxiosResponse<Response<TResponse>>) => response.data)
      .catch((error: AxiosError<Response<TResponse>>) => {
        if (error.response?.data?.error != null) {
          throw error.response.data.error;
        }
        throw ApplicationError.internalError(error.message);
      });
  },
};

baseService.init();

export default baseService;
