import {Box, Typography} from '@mui/material';
import {useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import useLocalization from '../../hooks/common/useLocalization';
import locals from '../../localization/locals';
import paths from '../../routes/paths';
import useBreadcrumbsStore from '../../state/breadcrumbs';
import Tabs from '../common/Tabs';
import About from './About';
import LanguageSettings from './LanguageSettings';
import NotificationSettings from './NotificationSettings';

function Settings() {
  const navigate = useNavigate();
  const [language] = useLocalization();

  const setBreadcrumbs = useBreadcrumbsStore((state) => state.setBreadcrumbs);

  const tabs = [
    locals.getText('profile_settings_tab_language_label'),
    locals.getText('profile_settings_tab_notifications_label'),
    locals.getText('profile_settings_tab_about_label'),
  ];
  const [selectedTabIndex, setSelectedTabIndex] = useState(0);

  useEffect(() => {
    setBreadcrumbs([
      {
        title: locals.getText('profile_breadcrumb'),
        onClick: () => navigate(paths.profile),
      },
      {
        title: locals.getText('profile_settings_breadcrumb'),
        onClick: () => {
          navigate(paths.profileSettings);
          setSelectedTabIndex(0);
        },
      },
      {title: tabs[selectedTabIndex]},
    ]);
    return () => setBreadcrumbs([]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedTabIndex, language]);

  return (
    <Box sx={{width: '65vw'}}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          height: '112px',
        }}>
        <Box>
          <Tabs
            hideIndicator
            values={tabs}
            selectedTabIndex={selectedTabIndex}
            onTabClick={setSelectedTabIndex}
          />
        </Box>
        {selectedTabIndex === 1 && (
          <Typography
            variant="body2"
            sx={{fontWeight: 'bold', color: 'text.primary', padding: 2}}>
            {locals.getText('notification_settings_title')}
          </Typography>
        )}
      </Box>
      {selectedTabIndex === 0 && <LanguageSettings />}
      {selectedTabIndex === 1 && <NotificationSettings />}
      {selectedTabIndex === 2 && <About />}
    </Box>
  );
}

export default Settings;
