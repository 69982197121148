import {Box, ClickAwayListener, Typography} from '@mui/material';
import {Fragment, useRef, useState} from 'react';
import {v4 as uuid} from 'uuid';
import {ReactComponent as AddIcon} from '../../assets/icons/add.svg';
import {ReactComponent as DeleteIcon} from '../../assets/icons/delete.svg';
import {ReactComponent as EditIcon} from '../../assets/icons/edit.svg';
import {ReactComponent as Menu} from '../../assets/icons/menu.svg';
import locals from '../../localization/locals';
import OvenModel, {OvenModelId} from '../../models/entities/ovenModel';
import RecipeType from '../../models/entities/recipeType';
import colors from '../../themes/colors';
import stringUtils from '../../utils/strings';
import ConfirmPopover from '../common/ConfirmPopover';
import IconButton from '../common/IconButton';
import Span from '../common/Span';

function CreateRecipeTypeMenuItem({
  ovenModelId,
  onCreateRecipeType,
}: {
  ovenModelId: OvenModelId;
  onCreateRecipeType: (recipeType: RecipeType) => void;
}) {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const [description, setDescription] = useState('');

  const containerRef = useRef<HTMLDivElement | null>(null);

  function handleCreateRecipeType() {
    if (!stringUtils.isNullOrWhiteSpace(description)) {
      onCreateRecipeType({id: uuid(), description, ovenModelId});
    }
    setDescription('');
    setAnchorEl(null);
  }

  const open = Boolean(anchorEl);

  return (
    <Box
      ref={containerRef}
      sx={{display: 'flex', justifyContent: 'center', width: '15vw'}}>
      <IconButton
        sx={{zIndex: open ? 1500 : undefined}}
        IconComponent={AddIcon}
        isActive={open}
        inactiveColor={colors.lightGrey}
        onClick={() => {
          setDescription('');
          setAnchorEl(open ? null : containerRef.current);
        }}
      />
      <ConfirmPopover
        anchorEl={anchorEl}
        placeHolder={locals.getText('recipe_add_recipe_type_label')}
        value={description}
        onChange={setDescription}
        onAccept={handleCreateRecipeType}
        onDecline={() => {
          setDescription('');
          setAnchorEl(null);
        }}
      />
    </Box>
  );
}

function UpdateRecipeTypeMenuItem({
  recipeType,
  onUpdateRecipeType,
}: {
  recipeType: RecipeType;
  onUpdateRecipeType: (recipeType: RecipeType) => void;
}) {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const [description, setDescription] = useState(recipeType.description);

  const containerRef = useRef<HTMLDivElement | null>(null);

  function handleUpdateRecipeType() {
    if (!stringUtils.isNullOrWhiteSpace(description)) {
      onUpdateRecipeType({...recipeType, description});
    }
    setAnchorEl(null);
  }

  const open = Boolean(anchorEl);

  return (
    <Box
      ref={containerRef}
      sx={{display: 'flex', justifyContent: 'center', width: '15vw'}}>
      <IconButton
        sx={{zIndex: open ? 1500 : undefined}}
        IconComponent={EditIcon}
        isActive={open}
        inactiveColor={colors.lightGrey}
        onClick={() => {
          setDescription(recipeType.description);
          setAnchorEl(open ? null : containerRef.current);
        }}
      />
      <ConfirmPopover
        anchorEl={anchorEl}
        placeHolder={locals.getText('recipe_add_recipe_type_label')}
        value={description}
        onChange={setDescription}
        onAccept={handleUpdateRecipeType}
        onDecline={() => {
          setDescription(recipeType.description);
          setAnchorEl(null);
        }}
      />
    </Box>
  );
}

function DeleteRecipeTypeMenuItem({
  recipeType,
  onDeleteRecipeType,
}: {
  recipeType: RecipeType;
  onDeleteRecipeType: (recipeTypeId: string) => void;
}) {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const containerRef = useRef<HTMLDivElement | null>(null);

  function handleDeleteRecipeType() {
    onDeleteRecipeType(recipeType.id);
    setAnchorEl(null);
  }

  const open = Boolean(anchorEl);

  return (
    <Box
      ref={containerRef}
      sx={{display: 'flex', justifyContent: 'center', width: '15vw'}}>
      <IconButton
        sx={{zIndex: open ? 1500 : undefined}}
        IconComponent={DeleteIcon}
        isActive={open}
        inactiveColor={colors.lightGrey}
        onClick={() => setAnchorEl(open ? null : containerRef.current)}
      />
      <ConfirmPopover
        anchorEl={anchorEl}
        placeHolder={locals.getText('recipe_add_recipe_type_label')}
        messageElement={
          <Typography
            variant="body2"
            sx={{flex: 1, fontWeight: 500, color: colors.white}}>
            <Span>{locals.getText('recipe_delete_recipe_type_label')}</Span>
            <Span sx={{fontWeight: 800}}>{` ${recipeType.description}`}</Span>
            <Span>?</Span>
          </Typography>
        }
        onAccept={handleDeleteRecipeType}
        onDecline={() => setAnchorEl(null)}
      />
    </Box>
  );
}

export type RecipeTypeMenuProps = {
  selectedOvenModel: OvenModel;
  selectedRecipeType: RecipeType | null;
  onCreateRecipeType: (recipeType: RecipeType) => void;
  onUpdateRecipeType: (recipeType: RecipeType) => void;
  onDeleteRecipeType: (recipeTypeId: string) => void;
};

function RecipeTypeMenu(props: RecipeTypeMenuProps) {
  const {
    selectedOvenModel,
    selectedRecipeType,
    onCreateRecipeType,
    onUpdateRecipeType,
    onDeleteRecipeType,
  } = props;

  const [open, setOpen] = useState(false);

  function handleCreateRecipeType(recipeType: RecipeType) {
    onCreateRecipeType(recipeType);
    setOpen(false);
  }

  function handleUpdateRecipeType(recipeType: RecipeType) {
    onUpdateRecipeType(recipeType);
    setOpen(false);
  }

  function handleDeleteRecipeType(recipeTypeId: string) {
    onDeleteRecipeType(recipeTypeId);
    setOpen(false);
  }

  const renderMenu = open;
  const renderUpdateRecipeTypeMenuItem = selectedRecipeType != null;
  const renderDeleteRecipeTypeMenuItem = selectedRecipeType != null;

  return (
    <ClickAwayListener onClickAway={() => setOpen(false)}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          height: '164px',
        }}>
        <Box sx={{display: 'flex', justifyContent: 'center', width: '15vw'}}>
          <IconButton
            IconComponent={Menu}
            onClick={() => setOpen((previousValue) => !previousValue)}
            activeColor={colors.grey}
            inactiveColor={colors.lightGrey}
            isActive={open}
          />
        </Box>
        {renderMenu && (
          <Fragment>
            <Box sx={{minHeight: '36px'}}>
              <CreateRecipeTypeMenuItem
                ovenModelId={selectedOvenModel.id}
                onCreateRecipeType={handleCreateRecipeType}
              />
            </Box>
            <Box sx={{minHeight: '36px'}}>
              {renderUpdateRecipeTypeMenuItem && (
                <UpdateRecipeTypeMenuItem
                  recipeType={selectedRecipeType}
                  onUpdateRecipeType={handleUpdateRecipeType}
                />
              )}
            </Box>
            <Box sx={{minHeight: '36px'}}>
              {renderDeleteRecipeTypeMenuItem && (
                <DeleteRecipeTypeMenuItem
                  recipeType={selectedRecipeType}
                  onDeleteRecipeType={handleDeleteRecipeType}
                />
              )}
            </Box>
          </Fragment>
        )}
      </Box>
    </ClickAwayListener>
  );
}

export default RecipeTypeMenu;
