import {Box, TextField, Typography} from '@mui/material';
import {useState} from 'react';
import {useMutation} from 'react-query';
import {useNavigate} from 'react-router-dom';
import useSplashScreen from '../../hooks/common/useSplashScreen';
import locals from '../../localization/locals';
import paths from '../../routes/paths';
import services from '../../services/provider';
import useAuthStore from '../../state/auth';
import cryptoUtils from '../../utils/crypto';
import errorUtils, {ApplicationErrorCode} from '../../utils/errors';
import stringUtils from '../../utils/strings';
import {Button} from '../common/Button';
import LoadingBackdrop from '../common/LoadingBackdrop';

function ChangePassword() {
  const navigate = useNavigate();
  const {splash} = useSplashScreen();

  const user = useAuthStore((state) => state.user);

  const [currentPassword, setCurrentPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');

  const [invalidPassword, setInvalidPassword] = useState(false);

  const {mutate: changePassword, isLoading} = useMutation({
    mutationFn: services.user.changePassword,
    onSuccess: () =>
      splash({
        title: locals.getText('splash_screen_password_changed_title'),
        caption: locals.getText('splash_screen_password_changed_caption'),
        nextAction: () => navigate(paths.profileInformation),
      }),
    onError: (error) =>
      errorUtils.handleMatch(
        error,
        [ApplicationErrorCode.InvalidEmailOrPassword],
        () => setInvalidPassword(true),
      ),
  });

  function handleChangePassword() {
    if (user != null) {
      changePassword({
        id: user.id,
        currentPassword: cryptoUtils.sha256(currentPassword),
        newPassword: cryptoUtils.sha256(newPassword),
      });
    }
  }

  return (
    <Box sx={{display: 'flex', flexDirection: 'column', width: '30vw'}}>
      <Typography
        variant="h4"
        color="secondary"
        sx={{
          marginBottom: invalidPassword ? '2vh' : '8vh',
          fontWeight: '500',
        }}>
        {locals.getText('profile_change_password_title')}
      </Typography>
      {invalidPassword && (
        <Typography
          variant="caption"
          color="primary"
          sx={{marginBottom: '6vh', fontWeight: 500}}>
          {locals.getText('profile_change_password_invalid_password_label')}
        </Typography>
      )}
      <TextField
        type="password"
        variant="standard"
        sx={{marginBottom: '4vh'}}
        label={locals.getText(
          'profile_change_password_current_password_input_label',
        )}
        value={currentPassword}
        onChange={(event) => setCurrentPassword(event.target.value)}
      />
      <TextField
        type="password"
        sx={{marginBottom: '20vh'}}
        variant="standard"
        label={locals.getText(
          'profile_change_password_new_password_input_label',
        )}
        value={newPassword}
        onChange={(event) => setNewPassword(event.target.value)}
        onKeyUp={(event) =>
          event.key === 'Enter' ? handleChangePassword() : null
        }
      />
      <Button
        onClick={handleChangePassword}
        disabled={stringUtils.anyIsNullOrWhiteSpace(
          currentPassword,
          newPassword,
        )}>
        {locals.getText('profile_change_password_change_password_button_label')}
      </Button>
      <LoadingBackdrop loading={isLoading} />
    </Box>
  );
}

export default ChangePassword;
