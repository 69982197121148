import {useEffect, useState} from 'react';
import errorUtils from '../../utils/errors';

function useQueryOnce<TData>({
  enabled,
  queryFn,
  onSuccess,
  onError,
}: {
  enabled?: boolean;
  queryFn: () => Promise<TData>;
  onSuccess?: (data: TData) => void;
  onError?: (error: any) => void;
  onSettled?: () => void;
}) {
  const [data, setData] = useState<TData>();
  const [error, setError] = useState<any>();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        const data = await queryFn();
        setData(data);
        onSuccess?.(data);
      } catch (error) {
        setError(error);
        if (onError != null) {
          onError(error);
          return;
        }
        errorUtils.handleError(errorUtils.applicationError(error));
      } finally {
        setIsLoading(false);
      }
    };
    if (enabled) {
      fetchData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {data, error, isLoading};
}

export default useQueryOnce;
